import currency from 'currency.js'
import { currenciesMap } from './currencies'

export function formatPrice(price, currencyCode) {
  const data = currenciesMap[currencyCode]
  if (!data) return ''

  const formatted = currency(price, {
    fromCents: true,
    symbol: data.symbol,
    precision: data.decimal_digits
  })

  return `${data.prefix}${formatted.value}${data.suffix}`
}
