export function getProductSteps({ intl, locale, acceptedMakerTermsAt }) {
  return [
    {
      name: 'product-preview',
      target: '#product-preview',
      content: intl.formatMessage({ id: 'PRODUCT_PREVIEW_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'product-preview-actions',
      target: '.preview-actions',
      content: intl.formatMessage({ id: 'PREVIEW_ACTIONS_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'product-preview-epub',
      target: '#view-epub-button',
      title: intl.formatMessage({ id: 'PRODUCT_PREVIEW_EPUB' }),
      content: intl.formatMessage({ id: 'PRODUCT_PREVIEW_EPUB_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'product-download-epub',
      target: '#download-epub-button',
      title: intl.formatMessage({ id: 'PRODUCT_DOWNLOAD_EPUB' }),
      content: intl.formatMessage({ id: 'PRODUCT_DOWNLOAD_EPUB_CAPTION' }),
      locale,
      placement: 'bottom',
    },

    {
      name: 'epub-downloaded',
      target: 'body',
      title: intl.formatMessage({ id: 'PRODUCT_TOUR_EXPORT' }),
      content: intl.formatMessage({ id: 'PRODUCT_TOUR_EXPORT_CAPTION' }),
      locale,
      placement: 'bottom',
    },

    {
      name: 'product-preview-web',
      target: '#view-web-button',
      content: intl.formatMessage({ id: 'PRODUCT_PREVIEW_WEB_CAPTION' }),
      locale,
      placement: 'bottom',
    },

    {
      name: 'product-general-view',
      target: '#product-general-view',
      title: intl.formatMessage({ id: 'PRODUCT_GENERAL_VIEW' }),
      content: intl.formatMessage({ id: 'PRODUCT_GENERAL_VIEW_CAPTION' }),
      locale,
      placement: 'right',
    },

    {
      name: 'product-general-view-2',
      target: '#product-preview',
      content: intl.formatMessage({ id: 'PRODUCT_GENERAL_VIEW_2' }),
      locale,
      placement: 'right',
    },
    {
      name: 'product-editor',
      target: '#go-to-editor',
      content: intl.formatMessage({ id: 'PRODUCT_EDITOR_CAPTION' }),
      locale,
      placement: 'right',
    },

    {
      name: 'content-stats',
      target: '#content-stats-1',
      content: intl.formatMessage({ id: 'CONTENT_STATS_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'collection',
      target: '#collection-0',
      content: intl.formatMessage({ id: 'COLLECTION_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'collection-view',
      target: '#collection-item-list',
      content: intl.formatMessage({ id: 'COLLECTION_VIEW_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'collection-item',
      target: '#collection-item-0',
      content: intl.formatMessage({ id: 'COLLECTION_ITEM_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'editor',
      target: '#editor-panel .input-wrapper.h1',
      content: intl.formatMessage({ id: 'EDITOR_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'product-view-2',
      target: '.nav-list-item',
      content: intl.formatMessage({ id: 'PRODUCT_VIEW_2_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },


    {
      name: 'product-offerings',
      target: '#go-to-offerings',
      content: intl.formatMessage({ id: 'PRODUCT_OFFERINGS_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'offerings',
      target: '#offerings-body',
      content: intl.formatMessage({ id: 'OFFERINGS_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },


    {
      name: 'product-access',
      target: '#go-to-access',
      content: intl.formatMessage({ id: 'PRODUCT_ACCESS_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'access',
      target: '#access-body',
      content: intl.formatMessage({ id: 'ACCESS_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },


    /* PRODUCT VERSIONS START */
    ...!acceptedMakerTermsAt ? [] : [{
      name: 'product-versions',
      target: '#go-to-versions',
      content: intl.formatMessage({ id: 'PRODUCT_VERSIONS_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'product-versions-list',
      target: '#version-list',
      content: intl.formatMessage({ id: 'PRODUCT_VERSIONS_LIST_CAPTION' }),
      locale,
      placement: 'right',
    },

    {
      name: 'product-versions-new',
      target: '#new-version-button',
      content: intl.formatMessage({ id: 'PRODUCT_VERSIONS_NEW_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'product-version-is-public',
      target: '#is_public_label',
      content: intl.formatMessage({ id: 'PRODUCT_VERSION_IS_PUBLIC_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'product-version-submit',
      target: '#submit-version-button',
      content: intl.formatMessage({ id: 'PRODUCT_VERSION_SUBMIT_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },

    {
      name: 'new-product-version',
      target: '#latest-version',
      content: intl.formatMessage({ id: 'NEW_PRODUCT_VERSION_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'new-product-version-openable',
      target: '.preview-actions',
      content: intl.formatMessage({ id: 'NEW_PRODUCT_VERSION_OPENABLE_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'new-product-version-openable-2',
      target: '#open-live-button',
      content: intl.formatMessage({ id: 'NEW_PRODUCT_VERSION_OPENABLE_2_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'new-product-version-openable-3',
      target: 'body',
      content: intl.formatMessage({ id: 'NEW_PRODUCT_VERSION_OPENABLE_3_TOUR_CAPTION' }),
      locale,
    }],
    /* PRODUCT VERSIONS END */
  ]
}

