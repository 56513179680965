import React from 'react'
import Auth from './lib/contexts/auth'
import useStore from './lib/store'

export default function AuthProvider(props) {
  const { auth, setAuth } = useStore(s => ({
    auth: s.auth.user ? s.auth : (s.auth.loggedOut ? {} : (props.auth || {})),
    setAuth: s.setAuth
  }))

  const value = {
    auth,
    setAuth
  }

  return (
    <Auth.Provider value={value}>
      {props.children}
    </Auth.Provider>
  )
}
