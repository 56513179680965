import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import StripeVerification from '../../StripeVerification'
import styles from '../index.module.scss'

function VerificationPage(props) {
  return (
    <div className={styles.wrapper} id='verification'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='VERIFICATION' />
        </div>
      </div>
      <div className={styles.body}>
        <StripeVerification {...props} />
      </div>
    </div>
  )
}

export default VerificationPage
