import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getCurrencySymbol } from 'shared/helpers/getCurrencySymbol'
import styles from './index.module.scss'

export default function SelectedPlan({ state }) {
  return (
    <>
      <div className='flex-field'>
        <h4>
          <FormattedMessage id='PLAN' />
        </h4>
        <span>
          <FormattedMessage id={state.selectedPlan?.name} /> <FormattedMessage id={state.selectedPeriod} />
        </span>
      </div>
      <div className='flex-field'>
        <h4>
          <FormattedMessage id={`${state.selectedPeriod}_RATE`} />
        </h4>
        {state.selectedPeriod === 'MONTHLY' && (
          <span>
            <FormattedMessage
              id='MONTHLY_PLAN_RATE'
              values={{
                rate: (<span>{getCurrencySymbol(state.selectedPlan?.currency_code)}{state.selectedPlan?.monthly_price_per_seat / 100}</span>)
              }}
            />
          </span>
        )}
        {state.selectedPeriod === 'YEARLY' && (
          <span>
            <FormattedMessage
              id='YEARLY_PLAN_RATE'
              values={{
                rate: (<span>{getCurrencySymbol(state.selectedPlan?.currency_code)}{state.selectedPlan?.yearly_price_per_seat / 100}</span>)
              }}
            />
          </span>
        )}
      </div>
    </>
  )
}
