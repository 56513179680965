import ru from './ru'
import en from './en'
import ja from './ja'
// import ko from './ko'
// import kk from './kk'

const messagesBy = {
  ru,
  en,
  ja,
  // ko,
  // kk,
}

export default messagesBy
