import React from 'react'
import * as Y from 'yjs'
import { HocuspocusProvider } from '@hocuspocus/provider'
import UserPicture from 'shared/UserPicture'
import { YJS_URL } from '../../lib/config'
import './styles.scss'

export default function Awareness(props) {
  const [state, setState] = React.useState({
    users: {}
  })

  const updateUsersFromStates = React.useCallback((states) => {
    const users = {}

    states.forEach((one) => {
      if (one.user) {
        if (!users[one.user.id]) {
          users[one.user.id] = {
            ...one.user,
            clients: [{
              clientId: one.clientId,
              view: one.view
            }]
          }
        } else {
          users[one.user.id].clients.push({
            clientId: one.clientId,
            view: one.view
          })
        }
      }
    })

    setState({ users })
  }, [])

  const provider = React.useMemo(() => {
    if (!props.activeWorkspace?.id || !props.auth.token) return null
    const ydoc = new Y.Doc()
    const name = `${props.activeWorkspace.id}.awareness`
    return new HocuspocusProvider({
      url: YJS_URL,
      name,
      document: ydoc,
      token: props.auth.token,
      // Listen for updates …
      onOpen() {
        // …
      },
      onConnect() {
        // …
      },
      onAuthenticated() {
        // …
      },
      onAuthenticationFailed: ({ reason }) => {
        // …
      },
      onStatus: ({ status }) => {
        // …
      },
      onMessage: ({ event, message }) => {
        // …
      },
      onOutgoingMessage: ({ message }) => {
        // …
      },
      onSynced: ({ state }) => {
        // …
      },
      onClose: ({ event }) => {
        // …
      },
      onDisconnect: ({ event }) => {
        // …
      },
      onDestroy() {
        // …
      },
      onAwarenessUpdate: ({ states }) => {
      },
      onAwarenessChange: ({ states }) => {
        updateUsersFromStates(states)
      },
      onStateless: ({ payload }) => {
        // ...
        // the provider can also send a custom message to the server: provider.sendStateless('any string payload')
      }
    })
  }, [props.activeWorkspace?.id])

  React.useEffect(() => {
    if (provider && props.user?.id) {
      // Share any information you like
      provider.setAwarenessField('user', {
        id: props.user?.id,
        name: props.user?.username,
        email: props.user?.email,
        picture_url: props.user?.picture_url,
        color: '#ffcc00',
        view: props.location.pathname,
      })
    }
  }, [provider, props.user, props.location.pathname])

  return (
    <div className='awareness-wrapper'>
      {Object.values(state.users).filter(u => u.id !== props?.user?.id).map((user) => (
        <div key={user.id} className='awareness-user'>
          <UserPicture user={user} />
          {/* {user.clients.length} clients */}
          {/* <span style={{ color: client.user.color}}>{client.user.name}</span> */}
          {/* {client.user.view} */}
        </div>
      ))}
    </div>
  )
}
