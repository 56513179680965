import * as React from 'react'
import WorkspaceInviteBase from '../../../WorkspaceInviteBase'
import styles from '../../index.module.scss'

function Invites(props) {
  return (
    <div className={styles.body}>
      <WorkspaceInviteBase {...props} />
    </div>
  )
}

export default Invites
