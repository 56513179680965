import React from 'react'

export function useClickOutside(ref, onClickOut) {
  React.useEffect(() => {
    const onClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOut?.()
      }
    }
    document.addEventListener('click', onClick, true)
    return () => document.removeEventListener('click', onClick, true)
  }, [ref, onClickOut])
}
