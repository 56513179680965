import React from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'

export function useTumbler(defaultValue) {
  const [view, setView] = React.useState(defaultValue)
  const show = React.useCallback(function(newView) {
    return e => setView(newView)
  }, [])

  const next = React.useCallback(function(items) {
    const index = items.findIndex(o => o === view)
    const nextItem = items[index + 1] || items[0]
    return e => setView(nextItem)
  }, [view])

  return {
    view,
    next,
    show
  }
}

export default function TumblerComponent(props) {
  const {
    caption,
    view,
    show,
    next,
    items = []
  } = props

  return (
    <span className='tumbler-wrapper'>
      {caption && (<span className='caption'>{caption}</span>)}
      <div className={`buttons ${view}`} onClick={next(items)}>
        <div className='roller' />
        {items.map((item) => (
          <button
            key={item}
            className={view === item ? 'active' : ''}
          >
            <FormattedMessage id={item} />
          </button>
        ))}
      </div>
    </span>
  )
}
