import React from 'react'
import LoginForm from '../LoginForm'
import JoinForm from '../JoinForm'
import UIControls from '../UIControls'
import Pitch from './Pitch'
import './styles.scss'

const AuthWindow = (props) => {
  const auth = props.auth
  const isLogin = props.location.pathname?.includes('/login')
  const isJoin = props.location.pathname?.includes('/join')
  const isPitch = !isLogin && !isJoin

  return (
    <>
      <div className={`auth-overlay ${auth.isAuthenticated ? '' : 'is-active'}`} />

      {!auth.isAuthenticated && (
        <div className='auth-window'>
          {isPitch && <Pitch />}
          {!auth.isAuthenticated && isLogin && <LoginForm />}
          {!auth.isAuthenticated && isJoin && <JoinForm />}
        </div>
      )}

      {!auth.isAuthenticated && (
        <UIControls {...props} fixed />
      )}
    </>
  )
}

export default AuthWindow
