import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LogoSVG from 'shared/icons/prodmake-icon.svg'
import { useLiveQuery } from 'dexie-react-hooks'
import { Helmet } from 'react-helmet'
import Mountains from '../Mountains'
import { useGlobalStore } from '../../lib/global'
import UserSwitcher from '../UserSwitcher'
import OnboardingScreen from './OnboardingScreen'
import SelectScreen from './SelectScreen'
import UIControls from '../UIControls'
import './styles.scss'

const WelcomeScreen = (props) => {
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb,
  }))
  const intl = useIntl()

  const invites = useLiveQuery(() => {
    if (!auth?.user?.id || !accountDb) return []
    return accountDb.invites.where({ status: 'PENDING' }).toArray()
  }, [auth, accountDb], [])

  if (!auth?.isAuthenticated || !props.user) return null

  const hasUserOnboarded = props?.user?.data?.has_onboarded

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'WELCOME' })}
        </title>
      </Helmet>
      <main className={`welcomeScreenWrapper ${hasUserOnboarded ? 'is-minimal' : 'is-wide'}`}>
        <div className={`welcomeScreenBlock`}>
          <div className='welcomeScreenBlockHeader'>
            <div>
              {!hasUserOnboarded && (
                <span className='logo-link'>
                  <LogoSVG />
                </span>
              )}
              {hasUserOnboarded && (
                <FormattedMessage id='WELCOME' defaultMessage='Welcome' />
              )}
            </div>
            <UserSwitcher />
          </div>
        </div>
        {!hasUserOnboarded && (
          <OnboardingScreen {...props} invites={invites} />
        )}
        {hasUserOnboarded && (
          <SelectScreen {...props} invites={invites} />
        )}
      </main>
      {hasUserOnboarded && (
        <div className='canvas-wrapper'>
          <Mountains />
        </div>
      )}
      <UIControls {...props} fixed />
    </>
  )
}

export default WelcomeScreen
