import React from 'react'
import { navigate } from '@reach/router'
import { useGlobalStore } from '../../lib/global'

export default function Logout(props) {
  const { logout, cleanUserStore } = useGlobalStore(s => ({
    logout: s.logout,
    cleanUserStore: s.cleanUserStore
  }))

  const onLogout = React.useCallback(async () => {
    logout()
    await cleanUserStore()
    if (props.onLogout) props.onLogout()
    navigate('/')
    setTimeout(() => {
      window.location.reload()
    }, 100)
    return false
  }, [])

  return (
    <button onClick={onLogout}>Logout</button>
  )
}
