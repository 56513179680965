import React from 'react'
import objectPath from 'object-path'
import { useGlobalStore } from '../../lib/global'

export default function Input(props) {
  const { tmpStruct, editingLocale } = useGlobalStore(s => s)
  const id = props.id || ''
  const i18n = props.i18n
  const path = props.path
  const valueKey = path && i18n ? `${path}.${editingLocale}` : path
  const name = path && i18n ? `${path}.${editingLocale}` : (props.name || path)
  const value = i18n ? objectPath.get(tmpStruct, valueKey) : props.value

  const onChange = React.useCallback((ev) => {
    if (typeof props.onChange === 'function') {
      const { name, value, checked, type } = ev.currentTarget
      props.onChange({
        currentTarget: {
          name,
          value,
          checked,
          type
        }
      })
    }
    return false
  }, [props.onChange])

  const onBlur = React.useCallback((ev) => {
    if (typeof props.onBlur === 'function') {
      const { name, value, checked, type } = ev.currentTarget
      props.onBlur({
        currentTarget: {
          name,
          value,
          checked,
          type
        }
      })
    }
    return false
  }, [props.onBlur])

  return (
    <div className={`input-wrapper ${props.className || ''}`}>
      {(props.label && props.type !== 'checkbox') && (
        <label htmlFor={id} className='input-label'>
          {props.label}
        </label>
      )}
      {['text', 'email', 'number'].includes(props.type) && (
        <input
          style={props.style}
          className={props.className}
          id={id}
          name={name}
          value={value ?? ''}
          type={props.type || 'text'}
          placeholder={props.placeholder || ''}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={props.autoFocus}
        />
      )}
      {['textarea'].includes(props.type) && (
        <textarea
          style={props.style}
          className={props.className}
          id={id}
          name={name}
          value={value ?? ''}
          resize={props.resize || 'none'}
          placeholder={props.placeholder || ''}
          rows={props.rows || 2}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={props.autoFocus}
        />
      )}
      {['checkbox'].includes(props.type) && (
        <label htmlFor={id}>
          <input
            id={id}
            type='checkbox'
            name={props.name}
            value={props.value || ''}
            checked={props.checked}
            onChange={onChange}
          />
          {props.label}
        </label>
      )}
    </div>
  )
}
