import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export default function validateInput(data, intl) {
  const errors = {}

  if (data.fullname !== null && Validator.isEmpty(data.fullname)) {
    errors.fullname = intl.formatMessage({ id: 'REQUIRED' })
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
