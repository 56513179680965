import * as React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGlobalStore } from '../../../../lib/global'
import UserPicture from '../../../UserPicture'
import styles from '../../index.module.scss'
import './styles.scss'

const workspaceRoles = ['ADMIN', 'USER']

function AllMembers({ activeWorkspace }) {
  const intl = useIntl()
  const { auth, databases, accountDb, location } = useGlobalStore(s => ({
    auth: s.auth,
    databases: s.databases,
    accountDb: s.accountDb,
    location: s.location
  }))

  const db = databases[activeWorkspace?.id]

  const members = useLiveQuery(() => {
    if (!db) return []
    return db.members.toArray()
  }, [db], [])

  const onRemove = React.useCallback(member => e => {
    db.members.update(member.id, { status: 'INACTIVE' })
  })

  const setRole = React.useCallback(member => e => {
    const { value } = e.currentTarget

    db.members.update(member.id, { role: value })
  }, [db, activeWorkspace?.id])

  return (
    <div className={styles.body}>
      <div className={styles.section}>
        <div className={styles.sectionBody}>
          {!members?.length && (
            <div>
              <FormattedMessage id='NO_MEMBERS_FOUND' />
            </div>
          )}
          {members?.map(member => (
            <div className={`member-list-row ${member.status !== 'ACTIVE' ? 'member-list-row--inactive' : ''}`} key={`${member.account_id}-w-settings`}>
              <div className={'member-list-info'}>
                <UserPicture
                  user={member.data}
                />
                <div className={'member-list-IDs'}>
                  <strong>
                    {member?.data?.profiles?.en?.fullname || member?.data?.username}
                  </strong>
                  <p>
                    {member?.data?.email}
                  </p>
                </div>
              </div>
              <div className={'member-list-access'}>
                {member?.account_id === auth?.user?.id && (
                  <div className={'member-list-role'}>
                    <FormattedMessage id={member?.role || ''} /> (<FormattedMessage id='YOU' />)
                  </div>
                )}
                {member?.account_id !== auth?.user?.id && (
                  <div className={'member-list-role'}>
                    {activeWorkspace?.role === 'ADMIN' && member.status === 'ACTIVE' && (
                      <select value={member.role} onChange={setRole(member)}>
                        {workspaceRoles.map(role => (
                          <option value={role}>
                            {intl.formatMessage({ id: role })}
                          </option>
                        ))}
                      </select>
                    )}
                    {(activeWorkspace?.role !== 'ADMIN' || member.status !== 'ACTIVE') && (
                      <span><FormattedMessage id={member.role || ''} /></span>
                    )}
                  </div>
                )}
                <div className={'member-list-status'}>
                  <FormattedMessage id={member.status} />
                </div>
                {activeWorkspace?.role === 'ADMIN' && member?.account_id !== auth?.user?.id && member?.status === 'ACTIVE' && (
                  <button onClick={onRemove(member)}>
                    <FormattedMessage id='REMOVE' />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AllMembers
