import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../index.module.scss'
import AvatarForm from './AvatarForm'
import ProfileForm from './ProfileForm'

const SettingsProfileContainer = (props) => {
  return (
    <div className={styles.wrapper} id='profile'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='PROFILE' />
        </div>
      </div>
      <div className={`${styles.body} profile`}>
        <AvatarForm {...props} />
        <ProfileForm {...props} />
      </div>
    </div>
  )
}

export default SettingsProfileContainer
