import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../index.module.scss'
import BillingSetup from '../../BillingSetup'

export default function BillingPage() {
  return (
    <div className={styles.wrapper} id='billing'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='BILLING' />
        </div>
      </div>
      <div className={`${styles.body} billing`}>
        <BillingSetup />
      </div>
    </div>
  )
}
