import dayjs from 'shared/utils/dayjs'

export function useWorkspaceSubscription({ activeWorkspace }) {
  const maxProducts = activeWorkspace?.meta?.data?.plan?.max_products
  const freeTrialEndDate = activeWorkspace?.meta?.data?.subscription?.free_trial_end_date

  const isOnTrial = (freeTrialEndDate && dayjs().isBefore(freeTrialEndDate))
  const isTrialLapsed = freeTrialEndDate && !dayjs().isBefore(freeTrialEndDate)
  const trialEnds = dayjs(freeTrialEndDate).fromNow()

  return {
    trialEnds,
    freeTrialEndDate,
    isTrialLapsed,
    isOnTrial,
    maxProducts
  }
}
