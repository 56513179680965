import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from '@reach/router'
import BackSVG from 'shared/icons/feather/arrow-left.svg'
import * as config from 'shared/config'
import dayjs from 'shared/utils/dayjs'

export default function Cancel(props) {
  const intl = useIntl()
  const activePlan = props.activeWorkspace?.meta?.data?.plan
  const activeSubscription = props.activeWorkspace?.meta?.data?.subscription
  const subscriptionCanceledAt = activeSubscription?.canceled_at
  const isAdmin = props.activeWorkspace.role === 'ADMIN' || true

  const onCancelWorkspacePlan = React.useCallback(async () => {
    try {
      await fetch(`${config.API_URL}/v1/workspaces/${props.activeWorkspace.id}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.auth.token}`
        },
        body: JSON.stringify({ locale: intl.locale }),
      })
    } catch (err) {
      console.log(err)
    }
  })

  return (
    <div className='workspace-plan-step'>
      <div className='workspace-plan-step-head'>
        <div>
          <Link to={`${props.location.pathname}?wsettings=1&tab=plans`} className='back-button'>
            <BackSVG />
            <FormattedMessage id='BACK' />
          </Link>
        </div>
        <h4 className='title'>
          <FormattedMessage id='CANCEL_SUBSCRIPTION' />
        </h4>
      </div>
      <div className='workspace-plan-step-body'>
        <p>
          {activeSubscription?.free_trial_end_date && !subscriptionCanceledAt && (
            <FormattedMessage
              id='YOUR_CURRENT_FREE_TRIAL_PLAN'
              values={{
                planName: intl.formatMessage({ id: activePlan?.name }),
                planPeriod: intl.formatMessage({ id: activePlan?.period }),
                trialUntil: dayjs(activeSubscription?.free_trial_end_date).locale(intl.locale).format('MMM D, YYYY')
              }}
            />
          )}
          {activeSubscription?.paid_until && !subscriptionCanceledAt && (
            <FormattedMessage
              id='YOUR_CURRENT_PAID_PLAN'
              values={{
                planName: intl.formatMessage({ id: activePlan?.name }),
                planPeriod: intl.formatMessage({ id: activePlan?.period }),
                paidUntil: dayjs(activeSubscription?.paid_until).locale(intl.locale).format('MMM D, YYYY')
              }}
            />
          )}
          {subscriptionCanceledAt && (
            <FormattedMessage
              id='SUBSCRIPTION_PLAN_WAS_CANCELED'
              values={{
                planName: intl.formatMessage({ id: activePlan?.name }),
                planPeriod: intl.formatMessage({ id: activePlan?.period }),
                canceledAt: dayjs(subscriptionCanceledAt).locale(intl.locale).format('MMM D, YYYY'),
                untilDate: dayjs(activeSubscription?.paid_until || activeSubscription?.free_trial_end_date).locale(intl.locale).format('MMM D, YYYY')
              }}
            />
          )}
        </p>
      </div>
      {!subscriptionCanceledAt && isAdmin && (
        <div className='workspace-plan-step-footer single'>
          <button onClick={onCancelWorkspacePlan} className='cta-button'>
            <FormattedMessage id='CANCEL_SUBSCRIPTION' />
          </button>
          <span className='cta-button-caption'>
            <FormattedMessage id='YOUR_CURRENT_PLAN_WILL_NOT_RENEW' />
          </span>
        </div>
      )}
      {!subscriptionCanceledAt && !isAdmin && (
        <div className='workspace-plan-step-footer single'>
          <p>
            <FormattedMessage id='ONLY_WORKSPACE_ADMIN_CAN_CANCEL_PLAN' />
          </p>
        </div>
      )}
    </div>
  )
}
