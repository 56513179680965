import React from 'react'
import styles from './index.module.scss'

export function WorkspaceIcon({ workspace, isEnlarged }) {
  const className = `${styles.workspaceIcon} workspace-icon ${isEnlarged ? 'enlarged' : ''}`
  return (
    <>
      {workspace?.info?.picture_url && (
        <img src={workspace.info.picture_url} className={className} alt='Workspace icon' />
      )}
      {!workspace && (
        <img src={'/apple-touch-icon.png'} className={className} alt='Prodmake icon' />
      )}
      {workspace && !workspace?.info?.picture_url && (
        <span className={className}>
          {workspace?.info?.name?.[0] || 'W'}
        </span>
      )}
    </>
  )
}

export default function WorkspaceTitle({
  workspace,
  withName = true,
  withEllipsis = false,
  isEnlarged = false,
  linkTitle = ''
}) {
  const isLink = !!linkTitle
  return (
    <div className={`${styles.workspaceTitleWrapper} ${isEnlarged ? 'enlarged' : ''}`}>
      {!isLink && !workspace && (
        <>
          <WorkspaceIcon workspace={workspace} isEnlarged={isEnlarged} />
          <div className={`${styles.workspaceName} ${withEllipsis ? 'withEllipsis' : ''}`}>
            Dashboard
          </div>
        </>
      )}
      {!isLink && workspace && (
        <>
          <WorkspaceIcon workspace={workspace} isEnlarged={isEnlarged} />
          {withName && (
            <div className={`${styles.workspaceName} ${withEllipsis ? 'withEllipsis' : ''}`}>
              {workspace?.info?.name || 'Untitled'}
            </div>
          )}
        </>
      )}
      {isLink && linkTitle && (
        <>
          <WorkspaceIcon workspace={workspace} isEnlarged={isEnlarged} />
          <div className={`${styles.workspaceName} ${withEllipsis ? 'withEllipsis' : ''}`}>
            {linkTitle}
          </div>
        </>
      )}
    </div>
  )
}
