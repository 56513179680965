import React from 'react'
import { useIntl } from 'react-intl'
import Select from '../Select'

const options = [{
  label: 'CORPORATION',
  value: 'CORPORATION',
}, {
  label: 'LLC',
  value: 'LLC',
}, {
  label: 'SOLE_PROPRIETORSHIP',
  value: 'SOLE_PROPRIETORSHIP'
}, {
  label: 'PARTNERSHIP',
  value: 'PARTNERSHIP'
}, {
  label: 'NON_PROFIT',
  value: 'NON_PROFIT'
}]

export default function BusinessTypeSelector(props) {
  const id = props.id || ''
  const intl = useIntl()
  const [value, setValue] = React.useState('')

  const changeHandler = ({ value }) => {
    setValue(value)
    if (typeof props.onChange === 'function') {
      props.onChange(value)
    }
  }

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const _selectedValue = options.find(o => o.value === value)
  const selectedValue = !_selectedValue ? null : {
    label: intl.formatMessage({ id: _selectedValue.label }),
    value: _selectedValue.value
  }

  return (
    <div className={`input-wrapper ${props.className || ''}`}>
      {props.label && (
        <label htmlFor={id} className='input-label'>
          {props.label}
        </label>
      )}
      <Select
        options={options.map(o => {
          return {
            ...o,
            label: intl.formatMessage({ id: o.label })
          }
        })}
        value={selectedValue}
        onChange={changeHandler}
        placeholder={intl.formatMessage({ id: 'SELECT_BUSINESS_TYPE' })}
        noOptionsMessage={() => intl.formatMessage({ id: 'NOT_FOUND' })}
      />
    </div>
  )
}
