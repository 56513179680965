export const basicCopy = {
  ja: `
    <p>
      すべての料金プランには、セルフパブリッシングのための機能が含まれています：
    </p>
    <ul>
      <li>
        一元化されたワークスペース
      </li>
      <li>
        WYSIWYGエディタ
      </li>
      <li>
        複数言語サポート（グローバル展開のため）
      </li>
      <li>
        ウェブサイト公開の可能性
      </li>
      <li>
        PDF、EPUBへのエクスポート
      </li>
      <li>
        バージョン管理
      </li>
      <li>
        基本的な分析
      </li>
      <li>
        モネタイゼーション
      </li>
      <li>
        複数通貨での取引
      </li>
      <li>
        機械学習による翻訳 機械学習によるフォーマット
      </li>
    </ul>
  `,
  ko: `
    <p>
      모든 요금제에는 셀프 출판을 위한 기능이 포함되어 있습니다:
    </p>
    <ul>
      <li>
        통합된 작업 공간
      </li>
      <li>
        WYSIWYG 에디터
      </li>
      <li>
        다국어 지원 (글로벌 확장을 위해)
      </li>
      <li>
        웹사이트 게시 기능
      </li>
      <li>
        PDF, EPUB으로 내보내기
      </li>
      <li>
        버전 관리
      </li>
      <li>
        기본 분석
      </li>
      <li>
        수익 창출
      </li>
      <li>
        다중 통화 거래
      </li>
      <li>
        기계 학습을 통한 번역 & 포매팅
      </li>
    </ul>
  `,
  kk: `
    <p>
      Барлық тарифтер өздік жариялау үшін функционалды қамтыды:
    </p>
    <ul>
      <li>
        Біріккен жұмыс орны
      </li>
      <li>
        WYSIWYG редакторы
      </li>
      <li>
        Көптілді тілдерге қолдау (халықаралық деңгейге шығу үшін)
      </li>
      <li>
        Веб-сайттарды жариялау мүмкіндігі
      </li>
      <li>
        PDF, EPUB дегенге экспорттау
      </li>
      <li>
        Нұсқа басқару
      </li>
      <li>
        Негізгі талдау
      </li>
      <li>
        Монетизация
      </li>
      <li>
        Түрлі валютада сауда
      </li>
      <li>
        Машина үйренісі арқылы аударма, пішімдеу
      </li>
    </ul>
  `,
  ru: `
    <p>
      Все тарифы включают функционал для самиздата:
    </p>
    <ul>
      <li>
        Единое рабочее пространство
      </li>
      <li>
        WYSIWYG-редактор
      </li>
      <li>
        Мульти-язычность
      </li>
      <li>
        Возможность публикации веб-сайтов
      </li>
      <li>
        Экспорт в PDF, EPUB
      </li>
      <li>
        Версионирование
      </li>
      <li>
        Базовая аналитика
      </li>
      <li>
        Монетизация
      </li>
      <li>
        Коммерция в разных валютах
      </li>
      <li>
        Перевод на другие языки
      </li>
      <li>
        Авто-исправление
      </li>
    </ul>
  `,
  en: `
    <p>
      All plans include features for you to self-publish:
    </p>
    <ul>
      <li>
        Unified workspace
      </li>
      <li>
        WYSIWIG editor
      </li>
      <li>
        Multi-language support (go global)
      </li>
      <li>
        Ability to publish as websites
      </li>
      <li>
        Export to PDF, EPUB
      </li>
      <li>
        Versioning
      </li>
      <li>
        Basic analytics
      </li>
      <li>
        Monetization via paywalls
      </li>
      <li>
        Multi-currency offerings
      </li>
      <li>
        AI translations & formatting
      </li>
    </ul>
  `
}

export const plans = [{
  name: 'NOVICE',
  monthly_price_per_seat: 1900,
  yearly_price_per_seat: 18200,
  currency_code: 'USD',
  max_members: 1,
  max_products: 3,
  fee_rate: 15,
  free_trial_in_days: 7,
  features: {
    ja: `
      <p>
        <em>単独で働く</em>人のための導入プラン。初心者や作成者に最適。
      </p>
      <ul>
        <li>ロイヤリティ85%</li>
        <li>最大3つの製品</li>
        <li>シングルユーザーのみ</li>
      </ul>
    `,
    ru: `
      <p>
        Вводный план для <em>работающего в одиночку</em>. Идеально для начинающих.
      </p>
      <ul>
        <li>авторские отчисления 85%</li>
        <li>до 3 продуктов</li>
        <li>один пользователь</li>
      </ul>
    `,
    en: `
      <p>
        An introductory plan for a person <em>working solo</em>. Ideal for aspiring makers, authors.
      </p>
      <ul>
        <li>royalties of 85%</li>
        <li>up to 3 products</li>
        <li>single-user only</li>
        <li>300 AI calls per month</li>
      </ul>
    `
  },
}, {
  name: 'ADVANCED',
  monthly_price_per_seat: 5900,
  yearly_price_per_seat: 56600,
  currency_code: 'USD',
  max_members: 3,
  max_products: 7,
  fee_rate: 10,
  free_trial_in_days: 7,
  features: {
    ja: `
      <p>
        "ノービス"に含まれるすべてに加えて：
      </p>
      <ul>
        <li>ロイヤリティ90%</li>
        <li>最大6つの製品</li>
        <li>最大5人のメンバー</li>
        <li>優先サポート</li>
        <li>高度な分析</li>
      </ul>
    `,
    ru: `
      <p>
        Всё, что в "Новичок", плюс:
      </p>
      <ul>
        <li>роялти 90%</li>
        <li>до 6 продуктов</li>
        <li>до 5 участников</li>
        <li>приоритетная поддержка</li>
        <li>расширенная аналитика</li>
      </ul>
    `,
    en: `
      <p>
        Everything in “Novice” plus:
      </p>
      <ul>
        <li>royalties of 90%</li>
        <li>up to 6 products</li>
        <li>up to 5 members</li>
        <li>priority support</li>
        <li>advanced analytics</li>
        <li>600 AI calls per month</li>
      </ul>
    `
  },
}, {
  name: 'ESTABLISHED',
  monthly_price_per_seat: 24900,
  yearly_price_per_seat: 239000,
  currency_code: 'USD',
  max_members: 8,
  max_products: 15,
  fee_rate: 7,
  free_trial_in_days: 7,
  features: {
    ja: `
      <p>
        "アドバンスド"に含まれるすべてに加えて：
      </p>
      <ul>
        <li>ロイヤリティ93%</li>
        <li>最大15つの製品</li>
        <li>最大8人のメンバー</li>
        <li>優先サポート</li>
        <li>高度な分析</li>
      </ul>
    `,
    ru: `
      <p>
        Всё, что в "Продвинутом", и:
      </p>
      <ul>
        <li>авторские отчисления 93%</li>
        <li>до 15 продуктов</li>
        <li>до 8 участников</li>
        <li>приоритетная поддержка</li>
        <li>расширенная аналитика</li>
      </ul>
    `,
    en: `
      <p>
        Everything in “Advanced” plus:
      </p>
      <ul>
        <li>royalties of 93%</li>
        <li>up to 15 products</li>
        <li>up to 8 members</li>
        <li>priority support</li>
        <li>advanced analytics</li>
        <li>1200 AI calls per month</li>
      </ul>
    `
  }
}]
