import { FormattedMessage } from 'react-intl'
import { Link } from '@reach/router'

import { getTasksSteps } from './getTasksSteps'
import { getWelcomeSteps } from './getWelcomeSteps'
import { getIdeasSteps } from './getIdeasSteps'
import { getProductSteps } from './getProductSteps'
import { getWorkspaceSteps } from './getWorkspaceSteps'

export function getSteps({ type, intl, location, activeWorkspace }) {
  const acceptedMakerTermsAt = activeWorkspace?.meta?.data?.accepted_maker_terms_at

  const locale = {
    next: (
      <strong aria-label="next">
        {intl.formatMessage({ id: 'NEXT' })}
      </strong>
    ),
    skip: intl.formatMessage({ id: 'SKIP' }),
    back: intl.formatMessage({ id: 'BACK' }),
    close: intl.formatMessage({ id: 'CLOSE' }),
    last: intl.formatMessage({ id: 'FINISH' }),
    open: intl.formatMessage({ id: 'OPEN' }),
  }

  const steps = [
    ...type === 'full' ? [{
      name: 'welcome',
      target: 'body',
      title: intl.formatMessage({ id: 'WELCOME_TO_PRODMAKE' }),
      content: intl.formatMessage({ id: 'WELCOME_TO_PRODMAKE_CAPTION' }),
      locale,
      placement: 'center',
    }] : [],
    ...type === 'full' ? getWelcomeSteps({ intl, locale }) : [],
    ...type === 'full' ? getWorkspaceSteps({ intl, locale }) : [],
    ...type === 'full' ? getProductSteps({ intl, locale, acceptedMakerTermsAt }) : [],
    ...type === 'full' ? getIdeasSteps({ intl, locale }) : [],
    ...type === 'full' ? getTasksSteps({ intl, locale }) : [],
    ...type === 'full' ? [
      {
        name: 'final-cta',
        target: '#tips-group',
        content: intl.formatMessage({ id: 'FINAL_TOUR_CAPTION' }),
        locale,
        placement: 'bottom',
      },
      {
        name: 'final',
        target: 'body',
        placement: 'center',
        locale,
        content: (
          <div>
            <p>
              <FormattedMessage
                id='FINAL_TOUR_CHECK_OUT'
                values={{
                  link: <Link to={`${location.pathname}/../../../help`}>
                    <FormattedMessage id='IN_HELP_SECTION' />
                  </Link>
                }}
              />
            </p>
            <p><FormattedMessage id='IF_YOU_HAVE_ANY_QS' /></p>
          </div>
        ),
      }
    ] : []
  ]

  return steps
}

