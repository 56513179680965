export default {
  code: 'ru',

  CURRENT_LANGUAGE: 'Русский',
  en: 'Английский',
  ja: 'Японский',
  ko: 'Корейский',
  fr: 'Французский',
  ru: 'Русский',
  de: 'Немецкий',
  kk: 'Казахский',

  // ui
  FULLSCREEN_ENTER: 'Полный экран',
  FULLSCREEN_EXIT: 'Не полный экран',
  CLOSE: 'Закрыть',
  ADD: 'Добавить',
  ENABLE: 'Включить',
  DISABLE: 'Выключить',
  REMOVE: 'Удалить',
  NAME: 'Название',
  NO_NAVIGATION_SET: 'Навигация пуста.',
  NO_COMMERCE_SET: 'Коммерция выключена.',

  SETTINGS: 'Настройки',
  DASHBOARD: 'Дашборд',
  PRICE: 'Цена',
  WELCOME: 'Добро пожаловать',
  WELCOME_BACK: 'Добро пожаловать назад',

  // context
  OVERVIEW: 'Обзор',
  EDITOR: 'Редактор',
  CONTENT: 'Контент',
  GENERAL: 'Общее',
  NAVIGATION: 'Навигация',
  DESIGN: 'Дизайн',
  STYLE: 'Стиль',
  COVER: 'Обложка',
  MARKETING: 'Маркетинг',
  AUDIENCE: 'Аудитория',
  SOCIAL: 'Социальные сети',
  COMMERCE: 'Коммерция',
  OFFERINGS: 'Предложения',
  ADVANCED: 'Продвинутый',
  COLLECTIONS: 'Коллекции',
  DOMAINS: 'Домены',
  EXPORT: 'Экспорт',
  LIVE: 'LIVE',
  VERSIONS: 'Версии',
  ANALYTICS: 'Аналитика',
  COMING_SOON: 'Скоро',
  MARKETS: 'Рынки',
  TITLE: 'Название',
  DESCRIPTION: 'Описание',
  SHOW_PLAIN_COVER: 'Показать простую обложку',
  SHOW_COMING_SOON: 'Показать “Будет скоро”',
  TAGLINES: 'Слоганы',
  TAGLINE: 'Слоган',
  DANGER_ZONE: 'Зона опасности',
  TRASH: 'Корзина',
  RESTORE: 'Восстановить',
  DELETE_FOREVER: 'Удалить навсегда',
  MENU: 'Меню',
  TERMS: 'Условия',
  REFUND_POLICY: 'Политика возврата',
  PRIVACY_POLICY: 'Политика конфиденциальности',
  DOWNLOADS: 'Загрузки',
  FEEDBACK: 'Обратная связь',
  HELP: 'Помощь',
  UNTITLED: 'Без названия',
  PRODUCTS: 'Продукты',
  LOGIN: 'Войти',
  LOGOUT: 'Выйти',
  PAYOUTS: 'Выплаты',
  CUSTOMERS: 'Клиенты',
  THEMES: 'Темы',
  LOADING: 'Загрузка',
  PRODMAKE: 'Продмэйк',

  MADE_WITH: 'Сделано на',
  LANGUAGES: 'Языки',
  LANGUAGE: 'Язык',

  ALREADY_PURCHASED: 'Уже приобрели',
  GET_FULL_ACCESS: 'Полный доступ',

  USD: 'Доллар США',
  BALANCE: 'Баланс',
  TOTAL_BALANCE: 'Общий Баланс',
  PROFIT: 'Прибыль',
  REVENUE: 'Оборот',
  TURNOVER: 'Оборот',
  TRANSACTIONS: 'Транзакции',
  DATE: 'Дата',
  CURRENCY: 'Валюта',
  TRANSACTION_CURRENCY: 'Валюта транзакции',
  MONEY_IN: 'Пополнение средств',
  MONEY_OUT: 'Вывод средств',
  FEE: 'Коммисия',
  STATUS: 'Статус',
  EMAIL: 'Email',
  PRODUCT: 'Продукт',
  VERSION: 'Версия',
  OFFERING: 'Предложение',
  WITHDRAW: 'Вывести',
  UPGRADE_TO_PRO: 'Перейти на Pro',
  QUICK_ACCESS: 'Быстрый доступ',
  GETTING_STARTED: 'Шаги новичка',
  GETTING_TRACTION: 'Начать расти',
  SUGGESTED_FOR_YOU: 'Для вас',

  WORKSPACE_SETTINGS: 'Настройки пространства',
  CREATE_WORKSPACE: 'Создать пространство',
  JOIN_WORKSPACE: 'Присоединиться к …',
  GO_TO_STORE: 'Перейти в магазин',

  RESTORE_PURCHASE: 'Восстановить покупку',
  PRODUCT_VIEWS: 'Просмотр продуктов',
  SALES: 'Продажи',
  CONVERSION_RATE: 'Конверсия',
  PRODMAKE_PRODUCT_TITLE: 'Продукт Продмэйк',
  PRODMAKE_DESCRIPTION: 'Делай продукты с Продмэйк',
  SECURE_ACCESS_LINK: 'Безопасная ссылка доступа на ваш email',
  ALREADY_PURCHASED_SUBMITTED: 'Успешно принято. Проверьте вашу почту.',
  ERROR: 'Ошибка',
  MONEY_BACK_GUARANTEE_POINT: 'Гарантия возврата денег (14 дней)',
  INSTANT_ACCESS_POINT: 'Мгновенный доступ: веб, .pdf, .epub',
  FUTURE_UPDATES_POINT: 'Будущие обновления',
  PARTNER_PERKS_POINT: 'Привилегии от партнеров (~$800)',
  LAST_UPDATED_POINT: 'Последнее обновление',
  GET_FOR_FREE_SUBMITTED: 'Теперь у вас есть доступ к бесплатной версии.',
  GET_FOR_FREE_NOW: 'Получить бесплатно',
  WIP: 'Рабочая версия',
  POWERED_BY_PRODMAKE: 'Создано на Продмэйк',
  RAW_HTML: 'HTML',
  EPUB: 'Epub',
  SHORT_ID: 'Короткий ID',
  NEW: 'Новый',
  NO_PRODUCT_PUBLISHED: 'Продукт не был опубликован',
  PREVIEW: 'Предпросмотр',
  VIEWING: 'Просмотр',
  OPEN_LIVE: 'Открыть в новом окне',
  BACK_TO_WEB: 'Назад к полной версии',
  NO_ITEMS_TO_DISPLAY_RAW: 'Нечего показать',
  BACK_TO_HOMEPAGE: 'Назад на главную',
  ACCESS_NOW_FOR_FREE: 'Попробовать бесплатно',
  GET_ACCESS_NOW: 'Запросить доступ',
  BE_FIRST_TO_ACCESS: 'Запросить уведомление',
  CHANGE_STRUCT_COVER: 'Сменить обложку',
  CHANGE_LOGO: 'Сменить логотип',
  CHANGE_AVATAR: 'Сменить аватар',
  CATEGORY: 'Категория',
  MANUALS: 'Справочники',
  BOOKS: 'Книги',
  GUIDES: 'Путеводители',
  PAGES: 'Страницы',
  LAST_RELEASE: 'Релиз',
  AGE_RATING: 'Для возраста',

  SERIF: 'С засечкой',
  SANS_SERIF: 'Без засечки',
  MONOSPACE: 'Моноширинный',
  FULL_WIDTH: 'Полная ширина',
  NARROW: 'Узкий',
  SIMPLE: 'Простой',
  BLOCK: 'Блок',
  MINIMAL: 'Минимал',
  FONTS: 'Шрифты',
  CHAPTERS: 'Главы',
  FOOTER: 'Подвал',
  REQUEST_ACCESS: 'Запросить доступ',
  NOW: 'сейчас',
  BUY: 'Купить',
  STAY_TUNED: 'Подписаться',
  VIEWING_LIMITED_VERSION: 'Вы просматриваете ограниченную версию.',
  VIEWING_LIMITED_VERSION_OBFUSCATED: 'Вы просматриваете демо-версию.',
  TO_SEE_CONTENT: 'Для полного доступа',
  ABOUT_AUTHOR: 'Об авторе',
  RENDERING: 'Рендеринг',
  SERIES_NAME: 'Название серии',
  BOOK_NUMBER: 'Номер книги',
  PUBLISHER: 'Издатель',
  DEDICATION: 'Посвящение',
  MESSAGE: 'Сообщение',
  EBOOK_ISBN: 'ISBN книги',
  OPTIONAL: 'Необязательно',
  AUTHOR: 'Автор',
  TITLE_PAGE: 'Титульная страница',
  WEB: 'Веб',
  EBOOK: 'Е-книга',
  PDF: 'PDF',
  SHOW_PUBLICATION_DATE: 'Показать дату публикации',
  SHOW_RELEASE_DATE: 'Показать дату обновления',
  SHOW_VERSION: 'Показать версию',
  DOWNLOAD: 'Скачать',
  REGENERATE: 'Пересоздать',
  SHOW_AUTHOR_INFO: 'Показать информацию об авторе',
  SHOW_WORKSPACE_INFO: 'Показать информацию о пространстве',
  BIO: 'Био',
  PICTURE_URL: 'Изображение',
  OVERRIDE: 'Переписать',
  CONTRIBUTORS: 'Соавторы',
  IMAGE: 'Изображение',
  SHOW: 'Показать',
  COLLECTION: 'Коллекция',
  LINKS: 'Ссылки',
  REMOVE_SECTION: 'Удалить секцию',
  INCLUSION_AND_ACCESS: 'Включает в себя',
  FULL_ACCESS: 'Полный доступ',
  DELETE_ITEM: 'Удалить',
  COLLECTION_TITLE: 'Название коллекции',
  SLUG: 'Слаг',
  REMOVE_COLLECTION: 'Удалить коллекцию',
  INCLUDES_COLLECTION_ITEMS: 'Включает {count}/{total} материалов из коллекции:',
  WITH_FULL_ACCESS: 'Полный доступ',
  VISIBLE_ITEMS: '{count}/{total} с полным доступом',
  OFFERING_AVAILABLE_FOR_FREE: 'Это предложение будет доступно бесплатно.',
  CURRENCY_AMOUNT_TERMS: 'Минимальная сумма в валюте {name} равна {symbol}{min_amount}, максимум {symbol}{max_amount}.',
  PREVIEW_VERSION: 'Версия для предпросмотра',
  TOC: 'Содержание',
  DOMAIN_SETUP_MESSAGE: 'Вам нужно будет указать ваш домен на *.prodmake.com с использованием записи CNAME.',
  VALIDATE: 'Подтвердить',
  REMOVE_DOMAIN: 'Удалить домен',
  WORDS: 'Кол-во слов',
  ORIGINALLY_PUBLISHED: 'Оригинал опубликован',
  LAST_UPDATED: 'Последнее обновление',
  ORIGINALLY_PUBLISHED_AT: 'Оригинал опубликован {date}',
  LAST_UPDATED_AT: 'Последнее обновление {date}',
  LOCALIZATION: 'Локализация',
  SELECT_AT_LEAST_ONE_LANGUAGE: 'Оставьте один язык как главный',
  THIS_COPY_BELONGS_TO: 'Эта копия v{version} принадлежит {email}',
  REQUEST: 'Запросить',
  PERKS_WORTH: 'привилегии на сумму ~$800',
  PAY_AMOUNT: 'Оплатить {amount}',
  PAGE_LEFT_BLANK: 'Страница пуста',
  PRIMARY_LANGUAGE: 'Основной язык',
  SUPPORTED_LANGUAGES: 'Поддерживаемые языки',
  SUCCESSFUL_PAYMENT_MESSAGE: 'Оплата прошла успешно.',
  PAYMENT_IS_PROCESSING_MESSAGE: 'Оплата обрабатывается.',
  PAYMENT_UNSUCCESSFUL_MESSAGE: 'Оплата не прошла. Проверьте способ оплаты.',
  SOMETHING_WENT_WRONG: 'Что-то пошло не так. Попробуйте снова.',
  INCLUDED_IN: 'Включен в',
  DETAILS: 'Подробности',
  SUBTITLE: 'Подзаголовок',
  PAGE_NOT_FOUND: 'Страница не найдена',
  THANK_YOU: 'Спасибо',
  START: 'Начать',
  PRODMAKE_PITCH_TITLE: 'Создавайте продукты быстрее с Продмэйк',
  PRODMAKE_PITCH_CTA: '{link} - редактор для по-настоящему глобальных продуктов',
  TRY_PRODMAKE: 'Начать сейчас',
  SCROLL_PURCHASE: '“Продажа” при скролле',
  ITEM_INCLUDED_IN_OFFERINGS: 'Предмет включен в',
  VISIBLE: 'Видимый',
  HIDDEN: 'Скрытый',
  PROPERTIES: 'Свойства',
  BANNER_CTA: 'Баннер CTA (призыв к действию)',
  PURCHASE_FOR_FULL_ACCESS: 'Получить полный доступ',
  ACCESS: 'Доступ',
  SAMPLE: 'Сэмпл',
  VISIBLE_OBFUSCATED: 'Видимо за шифром',
  ALL_LANGUAGES: 'Все языки',
  PURCHASED_ON: 'Приобретено',
  ITEM_NOT_PUBLIC: 'Еще не опубликовано',
  SUCCESSFUL_FREEBIE_MESSAGE: 'Доступ получен',
  ACCESSED_ON: 'Доступ получен',
  SETTLED: 'Проведено',
  UNIVERSAL: 'Универсальный',
  ALL: 'Все',
  TRANSLATE: 'Перевести',
  TRANSLATE_TO: 'Перевести на',
  UNIQUE: 'Уникальные',
  'N/A': 'N/A',
  WRITING: 'Письмо',
  TASKS: 'Задачи',
  PAGE_LIST: 'Страницы',
  PURCHASE_INFO: 'Информация о покупке',
  HERO: 'Баннер',
  SCROLL_PROGRESS: 'Прогресс прокрутки',
  HOME_SCROLL: 'Список страниц',
  NAV: 'Навигация',
  PRODUCT_PROPERTIES: 'Описание продукта',
  PRODUCT_AUTHOR: 'Информация об авторе',
  BLUEPRINT: 'Чертёж',
  BLOCKS: 'Блоки',
  READY_TO_USE: 'Готово к использованию',
  WEB_BOOK: 'Веб-книга',
  COVER_RIBBON: 'Лента на обложке',
  COVER_INTERACTION: 'Взаимодействие обложки',
  STRUCTURE: 'Структура',
  WIDTH: 'Ширина',
  ENABLED: 'Включено',
  DISABLED: 'Отключено',
  DISTRIBUTION: 'Дистрибуция',
  AUTHOR_BIO: 'Об авторе',
  EXPORT_EPUB: 'Экспорт в Epub',
  EXPORT_PDF: 'Экспорт PDF',
  VIEW_JSON: 'Просмотр JSON',
  CHOOSE_TYPE: 'Выберите тип',
  UNIVERSAL_BOOK: 'Универсальная книга',
  PLAYLIST_COURSE: 'Плейлист-курс',
  PERSONAL_WEBSITE: 'Личный веб-сайт',
  IMPORT: 'Импорт',
  BILLING: 'Биллинг',
  PAYOUT: 'Выплаты',
  PLANS: 'Тарифы',
  NOTIFICATIONS: 'Уведомления',
  PREFERENCES: 'Настройки',
  PROFILE: 'Профиль',
  CANCEL: 'Отмена',
  SAVE: 'Сохранить',
  ACCOUNT: 'Аккаунт',
  FULLNAME: 'Полное имя',
  PAYMENT_CARDS: 'Платежные карты',
  NO_PAYMENT_METHODS_FOUND: 'Способы оплаты не найдены',
  CARD_ADDED_SUCCESSFULLY: 'Карта успешно добавлена',
  ADD_CARD: 'Добавить карту',
  GRIND: 'Грайнд',
  YEAR_ONE: 'Год Первый',
  PRO: 'Профи',
  YEARLY: 'Годовой',
  MONTHLY: 'Месячный',
  CHOOSE_PLAN: 'Выберите тариф',
  PAYMENT_METHOD: 'Способ оплаты',
  CONFIRMATION: 'Подтверждение',
  SELECT: 'Выбрать',
  SELECT_PLAN: 'Выбрать «{planName}»',
  NEXT: 'Далее',
  YOUR_CURRENT_FREE_TRIAL_PLAN: 'Ваш текущий испытательный период «{planName} {planPeriod}» заканчивается {trialUntil}.',
  YOUR_CURRENT_PAID_PLAN: 'Ваш текущий тариф «{planName} {planPeriod}» оплачен до {paidUntil}.',
  CURRENT_PLAN: 'Текущий тариф',
  INBOX: 'Входящие',
  FINISH: 'Завершить',
  CHOOSE_PAYMENT_METHOD: 'Выберите способ оплаты',
  ACTIVATE_SELECTED_PLAN: 'Активировать выбранный тариф',
  BACK_TO_CARD_LIST: 'Вернуться к списку карт',
  LAST_PRODUCT_VIEWS: 'Последние просмотры продуктов',
  NO_VIEWS_YET: 'Просмотров пока не было',
  FREE_TRIAL_ENDS: 'Испытательный срок заканчивается {inDays}',
  WORKSPACE_NAME: 'Название рабочего пространства',
  NEW_WORKSPACE: 'Новое рабочее пространство',
  WORKSPACE_MEMBERS: 'Участники пространства',
  INVITE: 'Пригласить',
  WORKSPACE_INVITE: 'Пригласить в пространство',
  NO_MEMBERS_FOUND: 'Участники отсутствуют',
  UPGRADE_NOW: 'Обновить сейчас',
  NOVICE: 'Новичок',
  ESTABLISHED: 'Опытный',
  SEE_INVOICES: 'Счета',
  CANCEL_SUBSCRIPTION: 'Отменить подписку',
  BACK: 'Назад',
  MONTHLY_RATE: 'Месячный тариф',
  YEARLY_RATE: 'Годовой тариф',
  PLAN: 'Тариф',
  SUBSCRIPTION_ACTIVATED_1: 'Подписка была успешно оформлена. Оплачено до {date}.',
  SUBSCRIPTION_ACTIVATED_2: 'Транзакция на выписке вашей карты будет отмечена как PRODMAKE (или STRIPE пока транзакция обрабатывается).',
  SUBSCRIPTION_ACTIVATED_3: 'Вы получите потверждение на вашу электронную почту. Спасибо за использование Продмэйк!',
  SUCCESSFULLY_ACTIVATED: 'Успешно активировано',
  CONTINUE: 'Продолжить',
  MONTHLY_PLAN_RATE_PER_SEAT: '{rate} за участника в месяц',
  MONTHLY_PLAN_RATE: '{rate} в месяц',
  YEARLY_PLAN_RATE_PER_SEAT: '{rate} за участника в год',
  YEARLY_PLAN_RATE: '{rate} в год',
  TRIAL_PERIOD: 'Испытательный период',
  N_DAYS: '{count} дней',
  YOU_WONT_BE_CHARGED_NOW: 'Оплата не будет проведена',
  START_PLAN_TRIAL: 'Начать пробный период',
  NO_PURCHASES_YET: 'Покупок пока не было',
  UPCOMING_PAYOUTS: 'Предстоящие выплаты',
  UPDATE_PERSONAL_DETAILS: 'Обновить личные данные',
  UPDATE_WORKSPACE_DETAILS: 'Обновить информацию о рабочем пространстве',
  TRANSLATE_AUTOMATICALLY: 'Перевести автоматически',
  GET_NEW_PRODUCT_IDEAS: 'Получить новые идеи для продуктов',
  MAKE_YOUR_FIRST_SALE: 'Совершите вашу первую продажу',
  GET_YOUR_FIRST_PAYOUT: 'Получите вашу первую выплату',
  SET_UP_A_PROFILE: 'Настроить профиль',
  SET_UP_A_WORKSPACE: 'Настроить рабочее пространство',
  SET_UP_A_PAYMENT_METHOD: 'Настроить способ оплаты',
  SET_UP_PAYOUT: 'Настроить выплату',
  CREATE_YOUR_FIRST_PRODUCT: 'Создайте ваш первый продукт',
  PUBLISH_YOUR_FIRST_PRODUCT: 'Опубликуйте ваш первый продукт',
  ONBOARDED: 'Рабочее пространство настроено',
  YOU_DONT_HAVE_UPCOMING_PAYOUTS: 'У вас нет предстоящих выплат',
  IDEAS: 'Идеи',
  PARAPHRASE: 'Перефразировать',
  STATS: 'Показатели',
  CREATED: 'Создано',
  PROCESSING: 'Обработка',
  PROCESSED: 'Обработано',
  AI_STRUCT_TRANSLATION: 'Перевод',
  AI_STRUCT_REVISE: 'Парафраз',
  AI_STRUCT_CHECK_GRAMMAR: 'Проверка грамматики',
  AI_BOOK_GENERATION: 'Создание книги',
  AI_WRITE_DRAFT: 'Написать черновик',
  AI: 'ИИ',
  VIEW: 'Просмотр',
  ACCEPT: 'Принять',
  GENERATE: 'Генерировать',
  DONT_HAVE_AN_ACCOUNT: 'Нет аккаунта?',
  JOIN_PRODMAKE: 'Присоединиться к Продмэйк',
  ALREADY_HAVE_AN_ACCOUNT: 'Уже есть аккаунт?',
  INVITATION_CODE: 'Код приглашения',
  PASSWORD: 'Пароль',
  LOGGED_IN_AS: 'Вошли как {email}',
  SELECT_OR_CREATE: 'Выбрать или создать',
  NEW_TASK: 'Новая задача',
  SUBMIT: 'Отправить',
  FOR_WHOM: 'Для кого',
  COMPLETED_IN: 'Завершено в',
  ASSIGNEE: 'Исполнитель',
  COST: 'Стоимость',
  COST_INCLUDED: 'Бесплатно',
  PAGE_COUNT: '{count} страниц',
  DELETE: 'Удалить',
  HAS_NOT_BEEN_COMPLETED: 'Еще не завершено',
  REPEAT_TASK: 'Повторить задачу',
  SECOND_COUNT: '{count} секунд',
  TASK: 'Задача',
  INTO_IDEA: 'В идею',
  INTO_BOOK: 'В книгу',
  INTO_PRODUCT: 'В продукт',
  COLLECTION_COUNT: 'Коллекции ({count})',
  OFFERING_COUNT: 'Предложения ({count})',
  NEW_VERSION: 'Новая версия',
  SUBMIT_NEW_VERSION: 'Отправить на рассмотрение',
  IS_PUBLIC: 'Общий доступ',
  IS_IN_STORE: 'Представлено в магазине Продмэйк',
  ACCESS_FOR_PREVIOUS_CUSTOMERS: 'Доступно для клиентов, у которых есть предыдущие версии',
  LEARN_MORE: 'Подробнее',
  ACCESS_LINKS_SENT: 'Ссылки для доступа отправлены на вашу почту.',
  PROVIDE_FEEDBACK: 'Оставить отзыв',
  BACK_TO_ALL: 'Назад к списку',
  TRIAL_ENDED: 'Пробный период завершён',
  WORKSPACE_EXPLAIN_CAPTION: 'Здесь будут рабочие пространства. Чтобы начать создание продуктов {link}.',
  USAGE: 'Использование',
  OPEN: 'Открыть',
  SKIP: 'Пропустить',
  LAST_TRANSACTIONS: 'Последние транзакции',
  VIEW_ALL: 'Просмотреть все',
  START_TOUR: 'Начать тур',
  ACTIVATE_WORKSPACE: 'Активировать',
  MARK_AS_DRAFT: 'Отметить как черновик',
  DRAFT_PREVIEW_EARLY_ACCESS: 'Это предварительный просмотр черновика для раннего доступа. Продукт не в финальной форме.',
  TYPE: 'Тип',
  BLOCK_BASED: 'Блочный',
  TEXT_BASED: 'Текстовый',
  BRAINSTORM: 'Мозговой штурм',
  WITH_CORRECT_GRAMMAR: 'С правильной грамматикой',
  IN_DIFF_WORDS: 'Другими словами',
  IN_KINDER_TONE: 'Более мягким тоном',
  IN_PROFESSIONAL_TONE: 'Профессиональным тоном',
  IN_ASSERTIVE_TONE: 'Уверенным тоном',
  IN_FRIENDLY_TONE: 'Дружелюбным тоном',
  IN_EXPERT_TONE: 'Тоном эксперта',
  WITHOUT_ADJECTIVES: 'Без прилагательных',
  NO_FLUFF: 'Без лишнего',
  REVISE: 'Пересмотреть',
  CREATE_TASK: 'Создать задачу',
  IN_PROGRESS: 'В процессе',
  INTEGRATE_TRANSLATION: 'Интегрировать перевод',
  MECHANICAL_TURK: 'Механический турок',
  READY: 'Готово',
  CHOOSE_TASK_TYPE: 'Выберите тип задачи',
  INTEGRATE: 'Интегрировать',
  START_PLAN: 'Начать план',
  USED: 'Использовано',
  UNUSED: 'Не использовано',
  GO_TO_SOURCE: 'Перейти к исходнику',
  DRAFTS: 'Черновики',
  DUPLICATE: 'Дубликат',
  YOUR_TRIAL_PLAN_LAPSED: 'Ваш пробный план «{planName} {planPeriod}» завершился {trialUntil}. Пришло время активировать вашу подписку.',
  YOU_WILL_BE_IMMEDIATELY_CHARGED: 'С вашей карты немедленно произведут списание.',
  ACTIVATE_PLAN: 'Активировать план',
  SUBSCRIPTION_PLAN_IS_ACTIVATED: 'План подписки активирован',
  TRIAL_PLAN_IS_ACTIVATED: 'Пробный план активирован',
  TRIAL_PLAN_ACTIVATED_DETAIL: 'Ваша бесплатная пробная версия плана «{planName}» завершится {trialEnds}.',
  PAID_PLAN_ACTIVATED_DETAIL: 'План «{planName} ({planPeriod})» активирован.',
  PAID_PLAN_WILL_RENEW: 'Он будет продлен {paidUntil}.',
  AI_REQUESTS: 'Запросы к ИИ',
  ROYALTIES: 'Роялти',
  RESETS_MONTHLY: 'Сбрасывается каждый расчетный период',
  SOURCE: 'Источник',
  START_PRODUCT_TOUR: 'Продуктовый тур',
  WELCOME_TO_PRODMAKE: 'Добро пожаловать в Продмэйк!',
  WELCOME_TO_PRODMAKE_CAPTION: 'Все-в-одном редакторе для самостоятельного публикования.',
  SELECT_A_WORKSPACE_FIRST: 'Сначала выберите рабочее пространство',
  SELECT_A_WORKSPACE_FIRST_CAPTION: 'Давайте рассмотрим ваше рабочее пространство.',
  YOUR_WORKSPACE: 'Ваше рабочее пространство',
  YOUR_WORKSPACE_CAPTION: 'Здесь вы делаете всё, начиная с создания продукта и заканчивая публикацией новых версий, и анализом продаж.',
  WORKSPACE_NAVIGATION: 'Навигация по рабочему пространству',
  WORKSPACE_NAVIGATION_CAPTION: 'Глобальная навигация по пространству.',
  WORKSPACE_OVERVIEW: 'Обзор рабочего пространства',
  WORKSPACE_OVERVIEW_CAPTION: 'Здесь вы можете увидеть, как обстоят дела в вашем рабочем пространстве.',
  AT_GLANCE: 'На первый взгляд',
  AT_GLANCE_TOUR_CAPTION: 'Здесь ключевые показатели.',
  QUICK_ACTIONS: 'Быстрые действия',
  QUICK_ACTIONS_CAPTION: 'Здесь вы можете найти полезные действия.',
  PRODMAKE_EDITOR: 'Редактор Продмэйк',
  PRODMAKE_EDITOR_CAPTION: 'Давайте посмотрим, как вы управляете продуктом.',
  PRODUCT_PAGE: 'Страница продукта',
  PRODUCT_PAGE_CAPTION: 'Это страница продукта, где вы видите всё с постоянным предпросмотром в нескольких форматах.',
  NAVIGATING_PRODUCT: 'Навигация по продукту',
  NAVIGATING_PRODUCT_CAPTION: 'Тут вы переключаетесь между различными разделами продукта.',
  PRODUCT_PREVIEW_CAPTION: 'Здесь у вас предварительный просмотр продукта для версий в веб, Epub, PDF.',
  PREVIEW_ACTIONS_CAPTION: 'Давайте попробуем переключить это.',
  PRODUCT_PREVIEW_EPUB: 'Формат цифровой книги',
  PRODUCT_PREVIEW_EPUB_CAPTION: 'Epub подходит для этого примера (формат, используемый Amazon Kindle и другими читалками).',
  PRODUCT_DOWNLOAD_EPUB: 'Можно скачать в Epub и PDF',
  PRODUCT_DOWNLOAD_EPUB_CAPTION: 'Это займет некоторое время для рендеринга (поскольку это делается на вашем устройстве). Будет готово для скачивания в ближайшее время.',
  PRODUCT_TOUR_EXPORT: 'Экспортировать и использовать в другом месте',
  PRODUCT_TOUR_EXPORT_CAPTION: 'Epub был сгенерирован из контента, который у нас есть в редакторе. Его можно использовать на Kindle, Apple iBooks и других устройствах.',
  PRODUCT_PREVIEW_WEB_CAPTION: 'Теперь давайте сосредоточимся на пользовательском интерфейсе для редактирования контента.',
  PRODUCT_GENERAL_VIEW: 'Общие настройки',
  PRODUCT_GENERAL_VIEW_CAPTION: 'Здесь у нас общая информация о продукте, такая как заголовок, слоганы и поддержка языков.',
  PRODUCT_GENERAL_VIEW_2: 'Введенные данные сразу же отображаются в предварительном просмотре.',
  PRODUCT_EDITOR_CAPTION: 'Редактор - это то место, где вы создаете контент для вашего продукта. Он состоит из коллекций и элементов коллекций, например, Глав и Страниц.',
  CONTENT_STATS_TOUR_CAPTION: 'Здесь вы можете увидеть статистику контента.',
  COLLECTION_TOUR_CAPTION: 'Вот коллекция. У вас может быть столько, сколько вам нужно.',
  COLLECTION_VIEW_TOUR_CAPTION: 'Тут список элементов коллекции.',
  COLLECTION_ITEM_TOUR_CAPTION: 'Давайте рассмотрим элемент коллекции.',
  EDITOR_TOUR_CAPTION: 'Это редактор, где вы творите.',
  PRODUCT_VIEW_2_TOUR_CAPTION: 'Давайте вернемся к обзору продукта.',
  PRODUCT_OFFERINGS_TOUR_CAPTION: 'Давайте рассмотрим предложения.',
  OFFERINGS_TOUR_CAPTION: 'Здесь вы управляете предложениями. Цену можно связать с валютой и языком.',
  PRODUCT_ACCESS_TOUR_CAPTION: 'Давайте посмотрим, как вы можете контролировать доступ к предложениям.',
  ACCESS_TOUR_CAPTION: 'Здесь вы можете настраивать доступ к элементам коллекции для каждого предложения, будь то бесплатный образец, платный, или с затуманенной видимостью для каждого языка.',
  PRODUCT_VERSIONS_CAPTION: 'Давайте опубликуем новую версию.',
  PRODUCT_VERSIONS_LIST_CAPTION: 'Здесь вы увидите все версии.',
  PRODUCT_VERSIONS_NEW_TOUR_CAPTION: 'Давайте создадим новую версию.',
  PRODUCT_VERSION_IS_PUBLIC_TOUR_CAPTION: 'Давайте разрешим общественный доступ.',
  PRODUCT_VERSION_SUBMIT_TOUR_CAPTION: 'Давайте отправим.',
  NEW_PRODUCT_VERSION_TOUR_CAPTION: 'Вот новая версия продукта. Она жива, потому что мы разрешили публичный доступ.',
  NEW_PRODUCT_VERSION_OPENABLE_TOUR_CAPTION: 'Мы можем увидеть все форматы и детали.',
  NEW_PRODUCT_VERSION_OPENABLE_2_TOUR_CAPTION: 'Или мы можем открыть его и поделиться с миром.',
  NEW_PRODUCT_VERSION_OPENABLE_3_TOUR_CAPTION: 'Вот и все. Теперь ваш продукт может быть опубликован для всех.',
  WORKSPACE_IDEAS_TOUR_CAPTION: 'Идеи - это то место, где вы можете работать с черновиками.',
  WORKSPACE_IDEAS_ITEM_TOUR_CAPTION: 'Давайте рассмотрим одну из ваших идей.',
  EDITOR_2_TOUR_CAPTION: 'Это тот же текстовый редактор, в котором вы творите.',
  EDITOR_TOOLS_BUTTON_TOUR_CAPTION: 'Давайте переключим боковую панель нажав тут.',
  EDITOR_TOOLS_DETAILS_TOUR_CAPTION: 'Это раздел «Детали».',
  EDITOR_TOOLS_TASKS_LIST_TOUR_CAPTION: 'Давайте посмотрим, что есть на панели задач.',
  EDITOR_TOOLS_TASKS_PANEL_TOUR_CAPTION: 'Здесь содержится список связанных задач. Давайте создадим новую задачу.',
  TASK_MANAGER_TOUR_CAPTION: 'Это окно менеджера задач.',
  TASK_MANAGER_NEW_TOUR_CAPTION: 'Давайте настроим задачу, которую вы создали из боковой панели.',
  TASK_MANAGER_NEW_STEP_1_TOUR_CAPTION: 'Для этой демонстрации выберем «Ревизия».',
  TASK_MANAGER_NEW_STEP_2_TOUR_CAPTION: '«Экспертный тон» подойдет.',
  TASK_MANAGER_NEW_SUBMIT_TOUR_CAPTION: 'Теперь давайте отправим задачу на обработку.',
  TASK_MANAGER_NEW_PROPERTIES_TOUR_CAPTION: 'Задача создана. Вот ее свойства.',
  TASK_MANAGER_NEW_STATUS_TOUR_CAPTION: 'Вы всегда можете видеть статус задачи здесь.',
  TASK_MANAGER_NEW_ACTIONS: 'Действия с задачей',
  TASK_MANAGER_NEW_ACTIONS_TOUR_CAPTION: 'Мы можем интегрировать и/или повторять задачу, когда она выполнена.',
  ALWAYS_ACCESSIBLE: 'Всегда доступно',
  ALWAYS_ACCESSIBLE_TOUR_CAPTION: 'Здесь вы всегда можете получить доступ к менеджеру задач.',
  FINAL_TOUR_CAPTION: 'Вы закончили тур. Теперь хорошее время, чтобы настроить ваш аккаунт.',
  FINAL_TOUR_CHECK_OUT: 'Ознакомьтесь с руководствами {link}, чтобы на 100% использовать возможности Продмэйк. Спасибо, что пробовали тур по продукту.',
  IN_HELP_SECTION: 'в нашем разделе помощи',
  IF_YOU_HAVE_ANY_QS: 'Если у вас есть вопросы, свяжитесь с нами через team@prodmake.com.',
  YOUR_HIT_MAX_PRODUCTS_LIMIT: 'Вы достигли максимального количества товаров по вашему тарифному плану.',
  YOUR_HIT_MAX_MEMBERS_LIMIT: 'Вы достигли максимального количества участников по вашему тарифному плану.',
  YOUR_HIT_MAX_MEMBERS_LIMIT_CAPTION: 'Перейдите на тарифный план с большим лимитом.',
  SUBSCRIPTION_PLAN_WAS_CANCELED: 'Ваша подписка на «{planName} {planPeriod}» была отменена {canceledAt}. Вы можете использовать подписку на протяжении всего периода (до {untilDate}).',
  YOUR_CURRENT_PLAN_WILL_NOT_RENEW: 'Ваша подписка не будет продлена',
  YOUR_CURRENT_FREE_TRIAL_PLAN_CANCELED: 'Ваш бесплатный пробный период «{planName} {planPeriod}» заканчивается {trialUntil} и был отменен {canceledAt}.',
  YOUR_CURRENT_PAID_PLAN_CANCELED: 'Ваш текущий платный тариф «{planName} {planPeriod}» был отменен {canceledAt}. Вы можете пользоваться им на протяжении всего периода до {paidUntil}.',
  IN_REVIEW: 'На рассмотрении',
  APPROVED: 'Одобрено',
  REJECTED: 'Отклонено',
  REVIEW_STATUS: 'Статус рассмотрения',
  MAKER_CONTRACT: 'Условия и положения изготовителя',
  I_ACCEPT: 'Я принимаю Условия и положения',
  ACCEPTED_MAKER_TERMS_CAPTION: 'Вы приняли условия {date}.',
  ONLY_WORKSPACE_ADMIN_CAN_ACCEPT_TERMS_CAPTION: 'Только администратор может принимать условия.',
  ONLY_WORKSPACE_ADMIN_CAN_CANCEL_PLAN: 'Только администратор рабочей области может отменить подписку.',
  INVOICES: 'Счета',
  NO_INVOICES_FOUND: 'Счета не найдены',
  SUBSCRIPTION_CHARGE: 'Подписка',
  TRASH_IS_EMPTY: 'Корзина пуста',
  USER: 'Пользователь',
  ADMIN: 'Админ',
  ACTIVE: 'Активный',
  INACTIVE: 'Неактивный',
  YOU: 'Вы',
  START_FREE_TRIAL: 'Начать пробный период',
  FIRST_TIME_MAKER_WELCOME: 'Это ваше первое рабочее пространство',
  FIRST_TIME_MAKER_WELCOME_CAPTION: 'Рабочее пространство - это место, где вы превращаете свои идеи в бесплатные и платные продукты.',
  FIRST_TIME_MAKER_WELCOME_CAPTION2: 'Публикуйте продукты в виде веб-сайтов, экспортируйте свои рукописи в Epub и PDF.',
  FIRST_TIME_MAKER_WELCOME_CAPTION3: 'Пригласите свою команду или работайте в одиночку. Все в одном месте — в вашем рабочем пространстве.',
  FIRST_TIME_MAKER_START_TRIAL_CAPTION: 'Месячные тарифы от $20. Кредитка не требуется.',
  FIRST_TIME_MAKER_NOT_INTERESTED: 'Не интересуетесь в данный момент? {goToStoreLink} сейчас.',
  TRIAL_HAS_STARTED: 'Пробный период начался',
  ACCEPT_TERMS_TO_PUBLISH_PRODUCTS: 'Примите условия использования для публикации продуктов',
  TRY_PRODUCT_TOUR: 'Попробуйте продуктовый тур',
  TRY_PRODUCT_TO_SEE_IT_IN_ACTION: 'Чтобы понять как работает Продмэйк',
  WHAT_BEST_DESCRIBES_YOUR_WORK: 'Что лучше всего описывает вашу работу?',
  WELCOME_ONBOARDING_FINAL_MESSAGE: 'Отлично, спасибо — мы создали для вас рабочее пространство.',
  NOVELIST: 'Романист',
  SCI_FI_WRITER: 'Писатель-фантаст',
  NON_FICTION_WRITER: 'Писатель-нон-фикшн',
  POET: 'Поэт',
  BLOGGER: 'Блогер',
  COPYWRITER: 'Копирайтер',
  SCREENWRITER: 'Сценарист',
  COMIC_WRITER: 'Автор комиксов',
  LITERARY_AGENT: 'Литературный агент',
  ILLUSTRATOR: 'Иллюстратор',
  PHOTOGRAPHER: 'Фотограф',
  STUDENT: 'Студент',
  ACADEMIC_RESEARCHER: 'Академ. исследователь',
  ART_DIRECTOR: 'Художеств. директор',
  SOFTWARE_DEVELOPER: 'Разработчик',
  ENTREPRENEUR: 'Предприниматель',
  SERVICE_PROVIDER: 'Поставщик услуг',
  TEACHER: 'Учитель',
  OTHER: 'Другое',
  WELCOME_ONBOARDING_VIDEO_CAPTION: 'Посмотрите видео с пояснениями или нажмите «Начать работу».',
  GET_STARTED: 'Начать работу',
  MAKE: 'Создавайте',
  MAKE_CAPTION: 'Книги и продукты, богатые информацией.',
  PUBLISH: 'Публикуйте',
  PUBLISH_CAPTION: 'Как отдельные веб-сайты, Epub или PDF.',
  MONETIZE: 'Монетизируйте',
  MONETIZE_CAPTION: 'Предложения в различных валютах.',
  GO_TO_MARKET: 'Выходите на рынок',
  GO_TO_MARKET_CAPTION: 'С помощью редактора для быстрых циклов.',
  ACCESS_CONTROL: 'Контроль',
  ACCESS_CONTROL_CAPTION: 'Ограничьте доступ для каждого предложения.',
  AI_ASSISTED: 'С помощью ИИ',
  AI_ASSISTED_CAPTION: '«Мозговой штурм» с ИИ, глубина за вами.',
  WHAT_IS_YOUR_PRIMARY_GOAL: 'Какова ваша основная цель?',
  PUBLISH_A_BOOK: 'Опубликовать книгу',
  CREATE_A_WEBSITE: 'Создать веб-книгу',
  PRODUCE_FASTER: 'Создавать контент быстрее',
  MAKE_GLOBAL_PRODUCTS: 'Создавать глобальные продукты',
  SIMPLIFY_WORKFLOW: 'Упростить рабочий процесс',
  ADDITIONAL_INCOME: 'Дополнительный доход',
  DELEGATE_BORING_WORK: 'Делегировать скукоту',
  SAVE_TIME: 'Сэкономить время',
  SUCCESSFULLY_RESTORED: 'Успешно восстановлено',
  ALSO_AVAILABLE_ON: 'Также доступно на',
  LINK: 'Ссылка',
  THIRD_PARTY_LINKS: 'Ссылки на третие стороны',
  YOU_NOW_CAN_PUBLISH_PRODUCTS: 'Теперь вы можете публиковать продукты',
  YOU_NOW_CAN_PUBLISH_PRODUCTS_DETAIL: 'Самостоятельно и в магазине Продмэйк',
  TO_PUBLISH_ACCEPT_TERMS: 'Публикация отключена',
  TO_PUBLISH_ACCEPT_TERMS_DETAIL: 'Без принятия условий изготовителя',
  LETS_SET_UP_YOUR_PROFILE: 'Давайте настроим ваш профиль.',
  PITCH_TITLE: 'Создавайте продукты быстро.',
  PITCH_SUBTITLE: 'Делитесь своим опытом — публикуйте, экспортируйте, монетизируйте.',
  TRANSLATE_FROM: 'Перевести из',
  END: 'Конец',
  PRODUCT_IS_YET_TO_BE_RELEASED: 'Продукт еще не выпущен',
  PRODUCT_NOTIFY_ME_SUBMITTED: 'Вы будете уведомлены, когда продукт будет выпущен.',
  LEAVE_YOUR_EMAIL_TO_BE_NOTIFIED: 'Получите уведомление на ваш почтовый ящик, когда это произойдет.',
  NOTIFY_ME: 'Получить уведомление',
  ALREADY_SUBSCRIBED: 'Уже подписаны',
  WEEK_OVER_WEEK: 'Н/Н',
  MONTH_OVER_MONTH: 'М/М',
  QUARTER_OVER_QUARTER: 'К/К',
  YEAR_OVER_YEAR: 'Г/Г',
  YES: 'Да',
  NO: 'Нет',
  LAST_WEEK: 'Последняя неделя',
  LAST_MONTH: 'Последний месяц',
  LAST_QUARTER: 'Последний квартал',
  LAST_YEAR: 'Последний год',
  PHONE_NUMBER: 'Мобильный номер',
  DOB: 'Дата рождения',
  POSTAL_CODE: 'Почтовый индекс',
  FIRST_NAME: 'Имя',
  LAST_NAME: 'Фамилия',
  WISE_SERVICE: 'Wise',
  PAYPAL_SERVICE: 'PayPal',
  WIRE_TRANSFER: 'Банковский перевод',
  PAYOUT_METHOD: 'Метод выплаты',
  ACCOUNT_TYPE: 'Тип аккаунта',
  PERSONAL: 'Индивидуальный',
  BUSINESS: 'Бизнес',
  ADDRESS: 'Адрес',
  CITY: 'Город',
  COUNTRY: 'Страна',
  SELECT_COUNTRY: 'Выберите страну',
  NO_COUNTRY_FOUND: 'Страна не найдена',
  FOR_WIRE_TRANSFER_CONTACT: 'Для настройки банковских переводов свяжитесь с team@prodmake.com.',
  LEGAL_BUSINESS_NAME: 'Юридическое название компании',
  BUSINESS_TYPE: 'Тип бизнеса',
  SELECT_BUSINESS_TYPE: 'Выберите тип бизнеса',
  NOT_FOUND: 'Не найдено',
  CORPORATION: 'Корпорация',
  LLC: 'Общество с ограниченной ответственностью',
  PARTNERSHIP: 'Партнерство',
  NON_PROFIT: 'Благотворительная организация',
  SOLE_PROPRIETORSHIP: 'Индивидуальный предприниматель',
  WISE_EMAIL: 'Электронная почта Wise',
  PAYPAL_EMAIL: 'Электронная почта PayPal',
  PAYOUT_SETTINGS_UPDATED: 'Настройки выплаты обновлены',
  ALL_TIME: 'За все время',
  NO_PAYOUTS_YET: 'Выплат пока не было.',
  POSSIBLE_TO_PAYOUT_LATER: 'Выплата возможна после $10 на балансе.',
  AWAITING_APPROVAL: 'Ожидает одобрения',
  APPROVE: 'Одобрить',
  NEW_PAYOUT: 'Новая выплата',
  AMOUNT: 'Сумма',
  REPRESENTATIVE: 'Представитель',
  TIME_FOR_A_NEW_PAYOUT: 'Время для новой выплаты',
  VIEW_PAYOUTS: 'Все выплаты',
  UPDATED_FIELD: '{fieldName} обновлено до “{fieldValue}”',
  PAYOUT_WAS_CREATED: 'Выплата создана',
  PAYOUT_WAS_APPROVED: 'Выплата одобрена',
  PAYOUT_WAS_CANCELLED: 'Выплата отменена',
  CREATE: 'Создать',
  VERIFICATION: 'Верификация',
  VERIFY: 'Подтвердить',
  YOUR_ACCOUNT_IS_NOW_VERIFIED: 'Ваш аккаунт теперь подтвержден',
  ERROR_TRY_AGAIN: 'Ошибка. Попробуйте снова.',
  YOUR_ACCOUNT_IS_VERIFIED: 'Ваш аккаунт подтвержден',
  YOUR_ACCOUNT_IS_VERIFIED_CAPTION: 'Теперь у вашего аккаунта есть полный доступ к функциям Продмэйк.',
  YOUR_ACCOUNT_IS_NOT_VERIFIED: 'Ваш аккаунт не подтвержден',
  YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION: 'Публикация платных продуктов и организация выплат доступны только для подтвержденных пользователей, которые согласились с условиями издателя.',
  YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION_2: 'Ваш аккаунт ограничен редактированием.',
  SERVER_ERROR: 'Ошибка сервера',
  DOCUMENT_INVALID: 'недействительный документ',
  DOCUMENT_EXPIRED: 'истекший документ',
  DOCUMENT_TYPE_NOT_SUPPORTED: 'тип документа не поддерживается',
  CONSENT_DECLINED: 'согласие отклонено',
  ACCOUNT_VERIFICATION_FAIL_REASON: 'Аккаунт не может быть подтвержден из-за: {status}.',
  VERIFY_YOUR_ACCOUNT: 'Подтвердите ваш аккаунт',
  PUBLISHING_IS_FOR_VERIFIED: 'Публикация доступна для подтвержденных пользователей, которые согласились с условиями издателя.',
  PAYOUTS_DISABLED: 'Выплаты отключены',
  PAYOUTS_DISABLED_CAPTION: 'Подтвердите ваш аккаунт и примите условия пользования как издатель чтобы включить выплаты.',
  PRODUCT_HAS_NO_OFFERINGS: 'Продукт не имеет предложений',
  PENDING: 'В ожидании',
  JOIN: 'Присоединиться',
  DECLINE: 'Отклонить',
  ACCEPTED: 'Принято',
  DECLINED: 'Отклонено',
  YOU_WERE_INVITED_TO_WORKSPACE: '{byUser} приглашает вас в {workspace}.',
  DOMAIN_USED: 'Домен недоступен',
  TO_BE_VERIFIED: 'На проверке…',
  VERIFIED: 'Подтверждено',
  PRODUCT_DOMAIN_BY_ID_CAPTION: 'Продукт также доступен по ID',
  NO_CUSTOM_DOMAINS_SET: 'Для этого продукта не настроены пользовательские домены.',
  PRODUCT_IS_AVAILABLE_IN_OTHER_LANGUAGES: 'Продукт доступен на другом языке',
  BLOG: 'Блог',
  CONTINUE_WRITING: 'Продолжить писать',
  CONTINUE_WRITING_CAPTION: 'Расширить мысль с ИИ.',
  TEXT: 'Текст',
  EDITOR_TEXT: 'Текст',
  EDITOR_TEXT_CAPTION: 'Просто начните печатать.',
  TODO_LIST: 'Список задач',
  TODO_LIST_CAPTION: 'Список дел.',
  H1: 'Заголовок 1',
  H1_CAPTION: 'Большой заголовок раздела.',
  H2: 'Заголовок 2',
  H2_CAPTION: 'Средний заголовок раздела.',
  H3: 'Заголовок 3',
  H3_CAPTION: 'Маленький заголовок раздела.',
  BULLET_LIST: 'Список с маркерами',
  BULLET_LIST_CAPTION: 'Создать список с маркерами.',
  NUMBERED_LIST: 'Нумерованный список',
  NUMBERED_LIST_CAPTION: 'Создать нумерованный список.',
  QUOTE: 'Цитата',
  QUOTE_CAPTION: 'Зафиксировать цитату.',
  CODE: 'Код',
  CODE_CAPTION: 'Зафиксировать фрагмент кода.',
  IMAGE_CAPTION: 'Загрузите изображение.',
  PASTE_A_LINK: 'Вставьте ссылку',
  PUBLIC: 'Публичная',
  RELOAD: 'Обновить страницу',
  UNIVERSAL_BOOK_CAPTION: 'Современный взгляд на самостоятельно изданную книгу.',
  BLOG_CAPTION: 'Блог минималиста.',
  KNOWLEDGE_BASE: 'База знаний',
  KNOWLEDGE_BASE_CAPTION: 'Помогите вашим клиентам и заинтересованным сторонам.',
  PERSONAL_WEBSITE_CAPTION: 'Представьте себя миру.',
  NEW_PRODUCT: 'Новый продукт',
  PRODUCT_ROADMAP: 'Дорожная карта продукта',
  PRODUCT_ROADMAP_CAPTION: 'Видение, направление и прогресс вашего продукта.',
  GRID: 'Сетка',
  SCROLL_NAVIGATION: 'Прокрутка навигации',
  LINK_LIST: 'Список ссылок',
  PAGE: 'Страница',
}
