import React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'
import microdiff from 'microdiff'
import dayjs from 'shared/utils/dayjs'

import { useGlobalStore } from '../../../lib/global'

import Input from '../../Input'
import InputDatePicker from '../../InputDatepicker'
import InputPhoneNumber from '../../InputPhoneNumber'
import SelectCountry from '../../SelectCountry'
import SelectBusinessType from '../../SelectBusinessType'

import styles from '../index.module.scss'
import './styles.scss'

function PayoutPage(props) {
  const intl = useIntl()
  const [privateState, setPrivateState] = React.useState({})
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb
  }))

  const user = useLiveQuery(() => {
    if (!auth?.user?.id || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  const canSetPayout =
    user?.is_verified &&
    props.activeWorkspace.meta.data.has_accepted_maker_terms

  const onChange = (ev, withSubmit) => {
    if (ev.currentTarget) {
      const { name, value } = ev.currentTarget
      setPrivateState(prev => {
        const b = {...prev, [name]: value }
        if (withSubmit) onSubmit(b)
        return b
      })
    } else {
      const { name, value } = ev
      setPrivateState(prev => {
        const b = {...prev, [name]: value }
        if (withSubmit) onSubmit(b)
        return b
      })
    }
  }

  const onBlur = () => {
    if (user?.id && Object.keys(privateState).length) {
      onSubmit()
    }
  }

  const onSubmit = (b) => {
    if (!canSetPayout) {
      toast.error(intl.formatMessage({ id: 'ERROR_TRY_AGAIN' }))
      return
    }
    const changed = b ? b : privateState
    const changes = {
      updated_at: (new Date()).toISOString(),
      private_data: {
        ...user.private_data || {},
        ...changed
      }
    }
    accountDb.accounts.update(user?.id, changes)
    const diff = microdiff(user.private_data, changes.private_data)
    diff.forEach(item => {
      const key = item.path[0]
      const msg = (
        <FormattedMessage
          id='UPDATED_FIELD'
          values={{
            fieldName: intl.formatMessage({ id: key.toUpperCase() }),
            fieldValue: item.value ? intl.formatMessage({ id: item.value }) : ''
          }}
        />
      )
      toast.success(msg)
    })
  }

  const onChangeCountry = (value) => onChange({ name: 'country', value: value?.value || value }, true)
  const onChangePhoneNumber = (value) => onChange({ name: 'phone_number', value }, true)
  const onChangeDOB = (value) => onChange({ name: 'dob', value: dayjs(value).format('YYYY/MM/DD') }, true)
  const onChangeBusinessType = (value) => onChange({ value, name: 'business_type' }, true)
  const onChangeAccountType = (value) => onChange({ name: 'account_type', value }, true)
  const onChangePayoutMethod = (value) => onChange({ name: 'payout_method', value }, true)

  return (
    <div className={styles.wrapper} id='payout'>
      {!canSetPayout && (
        <div className='payout-limited-wrapper'>
          <div className='payout-limited'>
            <h3>
              <FormattedMessage id='PAYOUTS_DISABLED' />
            </h3>
            <p>
              <FormattedMessage id='PAYOUTS_DISABLED_CAPTION' />
            </p>
          </div>
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='PAYOUT_METHOD' />
        </div>
        <div className='select-button-group'>
          <button
            onClick={() => onChangePayoutMethod('WISE_SERVICE')}
            className={user?.private_data?.payout_method === 'WISE_SERVICE' ? 'is-active' : ''}
          >
            <FormattedMessage id='WISE_SERVICE' />
          </button>
          <button
            onClick={() => onChangePayoutMethod('PAYPAL_SERVICE')}
            className={user?.private_data?.payout_method === 'PAYPAL_SERVICE' ? 'is-active' : ''}
          >
            <FormattedMessage id='PAYPAL_SERVICE' />
          </button>
          <button
            onClick={() => onChangePayoutMethod('WIRE_TRANSFER')}
            className={user?.private_data?.payout_method === 'WIRE_TRANSFER' ? 'is-active' : ''}
          >
            <FormattedMessage id='WIRE_TRANSFER' />
          </button>
        </div>
      </div>
      <div className='payout-body'>
        <div className='payout-inputs-grid'>
          {user?.private_data?.payout_method === 'WISE_SERVICE' && (
            <>
              <div>
                <Input
                  type='email'
                  id='wise_email'
                  name='wise_email'
                  label={intl.formatMessage({ id: 'WISE_EMAIL' })}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={privateState.wise_email ?? user?.private_data?.wise_email}
                />
              </div>
            </>
          )}
          {user?.private_data?.payout_method === 'PAYPAL_SERVICE' && (
            <>
              <div>
                <Input
                  type='email'
                  id='paypal_email'
                  name='paypal_email'
                  label={intl.formatMessage({ id: 'PAYPAL_EMAIL' })}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={privateState.paypal_email ?? user?.private_data?.paypal_email}
                />
              </div>
            </>
          )}
          {user?.private_data?.payout_method === 'WIRE_TRANSFER' && (
            <>
              <p className='payout-method-caption'>
                <FormattedMessage id='FOR_WIRE_TRANSFER_CONTACT' />
              </p>
            </>
          )}
        </div>
      </div>

      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='ACCOUNT_TYPE' />
        </div>
        <div className='select-button-group'>
          <button
            onClick={() => onChangeAccountType('PERSONAL')}
            className={user?.private_data?.account_type === 'PERSONAL' ? 'is-active' : ''}
          >
            <FormattedMessage id='PERSONAL' />
          </button>
          <button
            onClick={() => onChangeAccountType('BUSINESS')}
            className={user?.private_data?.account_type === 'BUSINESS' ? 'is-active' : ''}
          >
            <FormattedMessage id='BUSINESS' />
          </button>
        </div>
      </div>
      <div className='payout-body'>
        <div className='payout-inputs-grid'>
          {user?.private_data?.account_type === 'BUSINESS' && (
            <>
              <div>
                <Input
                  type='text'
                  id='legal_business_name'
                  name='legal_business_name'
                  label={intl.formatMessage({ id: 'LEGAL_BUSINESS_NAME' })}
                  value={privateState.legal_business_name ?? user?.private_data?.legal_business_name}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </div>
              <div>
                <SelectBusinessType
                  type='text'
                  id='business_type'
                  name='business_type'
                  label={intl.formatMessage({ id: 'BUSINESS_TYPE' })}
                  value={privateState.business_type ?? user?.private_data?.business_type}
                  onChange={onChangeBusinessType}
                />
              </div>
            </>
          )}

          <div>
            <Input
              type='text'
              id='first_name'
              name='first_name'
              label={intl.formatMessage({ id: 'FIRST_NAME' })}
              onChange={onChange}
              onBlur={onBlur}
              value={privateState.first_name ?? user?.private_data?.first_name}
            />
          </div>
          <div>
            <Input
              type='text'
              id='last_name'
              name='last_name'
              label={intl.formatMessage({ id: 'LAST_NAME' })}
              onChange={onChange}
              onBlur={onBlur}
              value={privateState.last_name ?? user?.private_data?.last_name}
            />
          </div>
          <div>
            <Input
              type='text'
              id='address'
              name='address'
              label={intl.formatMessage({ id: 'ADDRESS' })}
              onChange={onChange}
              onBlur={onBlur}
              value={privateState.address ?? user?.private_data?.address}
            />
          </div>
          <div>
            <Input
              type='text'
              id='city'
              name='city'
              label={intl.formatMessage({ id: 'CITY' })}
              onChange={onChange}
              onBlur={onBlur}
              value={privateState.city ?? user?.private_data?.city}
            />
          </div>
          <div>
            <Input
              type='text'
              id='postal_code'
              name='postal_code'
              label={intl.formatMessage({ id: 'POSTAL_CODE' })}
              onChange={onChange}
              onBlur={onBlur}
              value={privateState.postal_code ?? user?.private_data?.postal_code}
            />
          </div>
          <div>
            <SelectCountry
              id='country'
              name='country'
              label={intl.formatMessage({ id: 'COUNTRY' })}
              onChange={onChangeCountry}
              value={privateState.country ?? user?.private_data?.country}
            />
          </div>
          <div>
            <InputPhoneNumber
              id='phone_number'
              name='phone_number'
              label={intl.formatMessage({ id: 'PHONE_NUMBER' })}
              onChange={onChangePhoneNumber}
              value={privateState.phone_number ?? user?.private_data?.phone_number}
            />
          </div>
          <div>
            <InputDatePicker
              id='dob'
              name='dob'
              label={intl.formatMessage({ id: 'DOB' })}
              onChange={onChangeDOB}
              onBlur={onBlur}
              value={privateState.dob ?? user?.private_data?.dob}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayoutPage
