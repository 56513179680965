import React from 'react'
import Cancel from './Cancel'
import Invoices from './Invoices'
import Plans from './Plans'
import './styles.scss'

export default function PlanSwitch(props) {
  const { user, location } = props

  return (
    <div className='plan-switch'>
      {(!user?.id || !location.query?.plansTab || location.query.plansTab === 'plans') && (
        <Plans {...props} />
      )}

      {(user?.id && location.query.plansTab === 'invoices') && (
        <Invoices {...props} />
      )}

      {(user?.id && location.query.plansTab === 'cancel') && (
        <Cancel {...props} />
      )}
    </div>
  )
}
