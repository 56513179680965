import React from 'react'
import Cookies from 'js-cookie'
import loadable from '@loadable/component'
import { useIntl } from 'react-intl'
import { Toaster } from 'sonner'
import { Router, globalHistory } from '@reach/router'
import { IntlProvider } from 'react-intl'
import { useLiveQuery } from 'dexie-react-hooks'
import messagesBy from 'shared/translations/messagesBy'
import dayjs from 'shared/utils/dayjs'
import ErrorBoundary from './components/ErrorBoundary'
import Tour from './components/Tour'
import { useGlobalStore } from './lib/global'
import { useWorkspace } from './hooks/useWorkspace'
import Loading from './components/Loading'
import AuthWindow from './components/AuthWindow'
import WelcomeScreen from './components/WelcomeScreen'
import NewWorkspaceForm from './components/NewWorkspaceForm'
import UserSettings from './components/UserSettings'
import WorkspaceSettings from './components/WorkspaceSettings'
import WorkspaceOnboarding from './components/WorkspaceOnboarding'
import WorkspaceTrialActivation from './components/WorkspaceTrialActivation'
import AuthProvider from './AuthProvider'
import NotFound from './views/NotFound'
import Nav from './components/Nav'
import TopbarMenu from './components/TopbarMenu'
import MinMenu from './components/MinMenu'
import './App.scss'

const TeamRoot = loadable(() => import('./views/TeamRoot'))
const BizRoot = loadable(() => import('./views/BizRoot'))
const AdminRoot = loadable(() => import('./views/AdminRoot'))

function App() {
  const intl = useIntl()
  const {
    auth,
    accountDb,
    location,
    isPadded,
    setLocation,
    hasFetchedUser,
    hasCreatedWorkspaces,
  } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb,
    location: s.location,
    isPadded: s.isPadded,
    setLocation: s.setLocation,
    hasFetchedUser: s.hasFetchedUser,
    hasCreatedWorkspaces: s.hasCreatedWorkspaces,
  }))

  React.useEffect(() => {
    setLocation(globalHistory.location)
    globalHistory.listen((window) => {
      setLocation(window.location)
    })
  }, [setLocation])

  const {
    workspaceList,
    activeWorkspace,
    activeDatabase
  } = useWorkspace()

  const user = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  const structs = useLiveQuery(() => {
    if (!activeDatabase) return []
    return activeDatabase.structs.toCollection().sortBy('created_at')
  }, [activeDatabase], [])

  React.useEffect(() => {
    window.intl = intl
  }, [intl])

  const common = {
    workspaceList,
    activeDatabase,
    activeWorkspace,
    structs,
    auth,
    user,
    location,
  }

  const hasInit = auth.isAuthenticated
    ? accountDb && hasCreatedWorkspaces
    : hasFetchedUser

  const minimalRoutes = [
    '/biz',
    '/admin',
  ]

  const isMinRoute = minimalRoutes.find(o => {
    if (auth.isAuthenticated && location.pathname === '/') return true
    return location.pathname === o || location.pathname.includes(o)
  })
  const isNavHidden =
    isMinRoute ||
    (
      auth.isAuthenticated && (
        location.pathname.includes('/join')
        || location.pathname.includes('/login')
      )
    )

  const className = [
    'App',
    isPadded ? 'is-padded' : '',
    !isMinRoute && isPadded ? 'with-nav' : '',
    auth?.isAuthenticated && !isMinRoute && isPadded ? 'with-nav' : '',
    window?.__TAURI__ ? 'is-desktop' : '',
  ]

  return (
    <>
      <Toaster richColors expand />
      <div className={className.join(' ')}>
        <AuthWindow {...common} />
        <Nav {...common} isHidden={isNavHidden} />
        {auth?.isAuthenticated && (<Tour location={location} />)}
        {isMinRoute && <TopbarMenu {...common} />}
        {isMinRoute && <MinMenu {...common} />}
        <UserSettings activeWorkspace={activeWorkspace} />
        <NewWorkspaceForm {...common} />
        <Loading isActive={!hasInit} />
        <WorkspaceSettings activeWorkspace={activeWorkspace} />
        <WorkspaceOnboarding activeWorkspace={activeWorkspace} location={location} />
        <WorkspaceTrialActivation activeWorkspace={activeWorkspace} location={location} />
        <Router primary={false}>
          {auth.isAuthenticated && (<WelcomeScreen {...common} path='/' />)}
          {auth.isAuthenticated && (<WelcomeScreen {...common} path='/login' />)}
          {auth.isAuthenticated && (<WelcomeScreen {...common} path='/join' />)}
          {!auth.isAuthenticated && (
            <TeamRoot path='/' {...common} />
          )}
          {!auth.isAuthenticated && (
            <TeamRoot path='/login' {...common} />
          )}
          {!auth.isAuthenticated && (
            <TeamRoot path='/join' {...common} />
          )}
          <AdminRoot path='/admin/*' {...common} />
          <BizRoot path='/biz/*' {...common} />
          <TeamRoot path='/:teamId/*' {...common} />
          <NotFound default />
        </Router>
      </div>
    </>
  )
}

function WithProviders(props) {
  const { locale, setLocale, setEditingLocale } = useGlobalStore(s => ({
    locale: s.locale,
    setLocale: s.setLocale,
    setEditingLocale: s.setEditingLocale,
  }))
  const messages = messagesBy[locale]

  React.useEffect(() => {
    const savedLocale = Cookies.get('locale')
    window.changeLocale = function(l) {
      setLocale(l)
      setEditingLocale(l)
      if (dayjs) {
        dayjs.locale(l)
      }
    }
    if (savedLocale) window.changeLocale(savedLocale)
  }, [])

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale='en'>
      <ErrorBoundary>
        <AuthProvider auth={{}}>
          <App />
        </AuthProvider>
      </ErrorBoundary>
    </IntlProvider>
  )
}

export default WithProviders
