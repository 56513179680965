import React from 'react'
import { useIntl } from 'react-intl'
import { navigate } from '@reach/router'
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride'
import { useGlobalStore } from '../../lib/global'
import styles from './index.module.scss'
import { getSteps } from './steps'

export default function Tour(props) {
  const intl = useIntl()
  const { tourState, setTourState } = useGlobalStore(s => s)

  const steps = React.useMemo(() => {
    return getSteps({
      intl,
      type: tourState.type,
      location: props.location,
      activeWorkspace: props.activeWorkspace
    })
  }, [intl, props.location, props.activeWorkspace, tourState.type])

  const pause = function() {
    setTourState(prev => ({
      ...prev,
      run: false
    }))
  }

  const resume = function() {
    setTourState(prev => ({
      ...prev,
      run: true
    }))
  }

  const onCallback = (data) => {
    const { status, type, action, index } = data
    const currStep = steps[index]

    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setTourState({ run: false, stepIndex: 0 })
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

      if (index === 1) {
        setTourState(prev => ({
          ...prev,
          run: true,
          stepIndex: nextStepIndex,
        }))
      } else if (index === 2 && action === ACTIONS.PREV) {
        setTourState(prev => ({
          ...prev,
          run: true,
          stepIndex: nextStepIndex,
        }))
      } else {
        // Update state to advance the tour
        setTourState(prev => ({
          ...prev,
          run: true,
          stepIndex: nextStepIndex,
        }))
      }
    }

    if (type === 'step:before') {
      // if (currStep.name === 'welcome') {
      //   navigate('/')
      // }
      // if (currStep.name === 'your-workspace') {
      //   const id = document.querySelector('#workspace-item-0')?.href?.split('/')?.[3]
      //   navigate(`/${id}`)
      //   setTourState(prev => ({
      //     ...prev,
      //     workspaceId: id,
      //   }))
      // }
    }

    if (type === 'step:after') {
      if (currStep.name === 'product-editor') {
        pause()
        document.querySelector('#go-to-editor a')?.click()
        setTimeout(resume, 300)
      }

      if (currStep.name === 'product-selection') {
        document.querySelector('.nav-list-item a')?.click()
      }

      if (currStep.name === 'product-view-2') {
        document.querySelector('.nav-list-item a')?.click()
      }

      if (currStep.name === 'product-preview-epub') {
        pause()
        document.querySelector('#view-epub-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-download-epub') {
        pause()
        document.querySelector('#download-epub-button')?.click()
        setTimeout(resume, 600)
      }

      if (currStep.name === 'product-preview-web') {
        pause()
        document.querySelector('#view-web-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'collection') {
        pause()
        document.querySelector('#collection-0 a')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'collection-item') {
        pause()
        document.querySelector('#collection-item-0 a')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'editor-tools-button') {
        pause()
        document.querySelector('#toggle-tools-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'editor-tools-tasks-link') {
        pause()
        document.querySelector('#editor-tools-tasks-link')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'editor-tools-tasks-panel') {
        pause()
        document.querySelector('.create-task-button')?.click()
        setTimeout(resume, 600)
      }

      if (currStep.name === 'task-manager-new-step-1') {
        pause()
        document.querySelector('#create-revise-task-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'task-manager-new-step-2') {
        pause()
        document.querySelector('#revise-IN_EXPERT_TONE-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'task-manager-new-submit') {
        pause()
        document.querySelector('.view-header-button.cta')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'task-manager-new-actions') {
        pause()
        document.querySelector('.task-manager-overlay')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-offerings') {
        pause()
        document.querySelector('#go-to-offerings a')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-access') {
        pause()
        document.querySelector('#go-to-access a')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-versions') {
        pause()
        document.querySelector('#go-to-versions a')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-versions-new') {
        pause()
        document.querySelector('#new-version-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-version-is-public') {
        pause()
        document.querySelector('#is_public_label')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'product-version-submit') {
        pause()
        document.querySelector('#submit-version-button')?.click()
        setTimeout(resume, 400)
      }

      if (currStep.name === 'workspace-ideas') {
        pause()
        document.querySelector('#go-to-ideas a')?.click()
        setTimeout(resume, 300)
      }

      if (currStep.name === 'workspace-ideas-item') {
        pause()
        document.querySelector('#ideas-list .list-item:first-child a')?.click()
        setTimeout(resume, 300)
      }

      if (currStep.name === 'workspace-tasks') {
        pause()
        navigate(`/${tourState.workspaceId}`)
        setTimeout(resume, 300)
      }
    }
  }

  return (
    <div className={styles.tourWrapper}>
      <Joyride
        {...tourState}
        steps={steps}
        callback={onCallback}
        showSkipButton
        hideBackButton
        hideCloseButton
        styles={{
          options: {
            arrowColor: getComputedStyle(document.body).getPropertyValue('--sidebar-background'),
            backgroundColor: getComputedStyle(document.body).getPropertyValue('--sidebar-background'),
            // overlayColor: 'hsla(0, 0%, 50%, 0.3)',
            // spotlightShadow: '0 0 15px hsla(0, 0, 0, 0.5)',
            primaryColor: getComputedStyle(document.body).getPropertyValue('--sidebar-text'),
            textColor: getComputedStyle(document.body).getPropertyValue('--text'),
          }
        }}
      />
    </div>
  )
}
