import * as React from 'react'
// import PhoneNumber from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'

export default function CustomPhoneNumber(props) {
  const id = props.id || ''
  const [phoneNumber, setPhoneNumber] = React.useState('')

  const onChange = (number) => {
    setPhoneNumber(number)
    if (typeof props.onChange === 'function') {
      props.onChange(number)
    }
  }

  React.useEffect(() => {
    if (props.value) setPhoneNumber(props.value)
  }, [props.value])

  return (
    <div className={`input-wrapper ${props.className || ''}`}>
      {props.label && (
        <label htmlFor={id} className='input-label'>
          {props.label}
        </label>
      )}
      <PhoneInput
        id={id}
        value={phoneNumber}
        onChange={onChange}
        name={props.name}
        country={props.country}
      />
    </div>
  )
}
