import en from './en'
import ru from './ru'
import ja from './ja'
import ko from './ko'
import kk from './kk'

const list = {
  en,
  ru,
  ja,
  // KO,
  // KK,
  // fr,
  // it,
  // tr,
  // de,
  // es,
}

const messages = {}
const abbrs_ = []
const globalLocaleList_ = {}

Object.keys(list).forEach(key => {
  const value = list[key]

  abbrs_.push(key)

  globalLocaleList_[key] = {
    abbr: key,
    locale: value.code,
    name: value.CURRENT_LANGUAGE
  }

  messages[key] = value
})

export const abbrs = [...abbrs_]
export const globalLocaleList = Object.values(globalLocaleList_)
export default messages
