import * as React from 'react'
import { Link } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import { useGlobalStore } from '../../../lib/global'

import All from './All'
import Invites from './Invites'
// import styles from './index.module.scss'
import styles from '../index.module.scss'

function MembersPage({ activeWorkspace }) {
  const { location } = useGlobalStore(s => ({
    location: s.location,
  }))
  return (
    <div className={styles.wrapper} id='members'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='WORKSPACE_MEMBERS' />
        </div>
        <div className={styles.headerToolbar}>
          <Link
            to={`${location.pathname}?wsettings=1&tab=members&membersTab=all`}
            className={[
              styles.headerToolbarLink,
              location.query?.wsettings === '1' && (!location.query?.membersTab || location.query?.membersTab === 'all') ? 'active' : ''
            ].join(' ')}
          >
            <FormattedMessage id='ALL' />
          </Link>
          <Link
            to={`${location.pathname}?wsettings=1&tab=members&membersTab=invite`}
            className={[
              styles.headerToolbarLink,
              location.query?.wsettings === '1' && location.query?.membersTab === 'invite' ? 'active' : ''
            ].join(' ')}
          >
            <FormattedMessage id='INVITE' />
          </Link>
        </div>
      </div>
      {(!location.query?.membersTab || location.query?.membersTab !== 'invite') && (
        <All location={location} activeWorkspace={activeWorkspace} />
      )}
      {location.query?.membersTab === 'invite' && (
        <Invites location={location} activeWorkspace={activeWorkspace} />
      )}
    </div>
  )
}

export default MembersPage
