import Emitter from '../emitter'
import { useGlobalStore } from '../global'
import { WS_URL } from '../config'

const RECONNECT_DELAY = 2000
export const SOCKET_URL = `${WS_URL}/sync`

let conns = {}
let ws

Emitter.on('WS_CONNECT', connect)

export async function connect({ db, ...props }) {
  // If there's already a connecting websocket, wait for it
  if (ws && ws.readyState === 0) {
    return new Promise((resolve) => {
      const checkReady = setInterval(() => {
        if (ws.readyState === 1) {
          clearInterval(checkReady)
          resolve(ws)
        }
      }, 100)
    })
  }
  
  // Return existing connection if healthy
  if (ws && ws.readyState === 1) return ws

  ws = db.ws = conns[db.name] = new WebSocket(SOCKET_URL)

  ws.onopen = function (event) {
    useGlobalStore.setState({ wsStatus: 'ONLINE' })
    console.log('Socket is open.')
    if (props.type === 'RECONNECTED') {
      Emitter.emit('WS_RECONNECTED')
    } else {
      Emitter.emit('WS_OPEN', event)
    }
    if (!ws.interval) {
      ws.interval = setInterval(function () {
        ws.send(JSON.stringify({ cmd: 'ping' }))
      }, 3000)
    }
  }

  ws.onmessage = function(event) {
    // console.log('Message:', event.data)
    Emitter.emit('WS_MESSAGE', event)
  }

  ws.onclose = function(event) {
    useGlobalStore.setState({ wsStatus: 'OFFLINE' })
    if (ws.interval) clearInterval(ws.interval)
    ws = null
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', event.reason)
    setTimeout(function() {
      useGlobalStore.setState({ wsStatus: 'CONNECTING' })
      connect({ db, type: 'RECONNECTED' })
    }, RECONNECT_DELAY)
    Emitter.emit('WS_CLOSE', event)
  }

  ws.onerror = function (event) {
    console.error('Socket encountered error: ', event.message, 'Closing socket')
    useGlobalStore.setState({ wsStatus: 'OFFLINE' })
    if (ws) ws.close()
    Emitter.emit('WS_ERROR', event)
  }

  ws.runWhenReady = fn => {
    if (ws?.readyState === 1) {
      fn()
    } else {
      setTimeout(() => {
        ws?.runWhenReady(fn)
      }, 500)
    }
  }

  return ws
}

export function getWs() {
  return ws
}
