import Dexie from 'dexie'
import { useGlobalStore } from '../../global'
import { registerSync, addons } from '../dexie'
import { SOCKET_URL, connect } from '../ws'

export class AccountDatabase extends Dexie {
  constructor(name, opts) {
    super(name, opts)
    this.version(19).stores({
      accounts: '$$id',
      balances: '$$id',
      payment_method: '$$id',
      profiles: '$$id, lang, [lang+account_id]',
      workspaces: '$$id, status',
      invites: '$$id, workspace_id, status',
      struct_invites: '$$id, struct_id, workspace_id',
      struct_access: '$$id, struct_id',
      shared_structs: '$$id, short_id, parent_id, workspace_id',
      subscriptions: '$$id',
      notifications: '$$id',
    })
  }
}

export const createAccountDatabase = () => {
  registerSync(AccountDatabase)
  const db = new AccountDatabase('account', { addons })
  db.startSync = async () => {
    db.isSyncing = true
    const ws = await connect({ db })

    ws.runWhenReady(async function() {
      await db.open()

      const state = useGlobalStore.getState()

      db.syncable.connect('websocket', SOCKET_URL, {
        c: 'account',
        token: state.auth?.token,
        lang: state.lang || 'en'
      }).catch(err => {
        console.error (`Failed to connect: ${err.stack || err}`)
      })

      db.syncable.on('statusChanged', function (status, url) {
        useGlobalStore.getState().setAccountDbStatus({
          status,
          text: Dexie.Syncable.StatusTexts[status]
        })
      })
    })
  }

  db.stopSync = async () => {
    await db.open()
    db.syncable.disconnect(SOCKET_URL)
    db.isSyncing = false
  }

  db.getSyncStatus = async () => {
    await db.open()
    const status = db.syncable.getStatus(SOCKET_URL)
    return status
  }

  return db
}
