import * as React from 'react'
import { v4 as uuid } from 'uuid'
import { nanoid } from 'nanoid'
import { FormattedMessage, useIntl } from 'react-intl'
import TextareaAutosize from 'react-textarea-autosize'
import isHotkey from 'is-hotkey'
import { useGlobalStore } from '../../lib/global'
// import iconsMap from 'shared/icons/map'
// import ImgIcon from 'shared/atoms/ImgIcon'
import Validator from 'validator'
import { useLiveQuery } from 'dexie-react-hooks'

import styles from './index.module.scss'

function InviteBase({
  location,
  activeWorkspace,
  withList = true,
  ...props
}) {
  const intl = useIntl()
  const [emails, setEmails] = React.useState('')
  const { auth, databases, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    databases: s.databases,
    accountDb: s.accountDb
  }))

  const db = databases?.[activeWorkspace?.id]

  const members = useLiveQuery(() => {
    if (!db) return []
    return db.members.toArray()
  }, [db], [])

  // const user = useLiveQuery(() => {
  //   if (!auth?.userId || !accountDb) return null
  //   return accountDb.accounts.where({ id: auth.userId }).first()
  // }, [auth, accountDb])

  const invites = useLiveQuery(() => {
    if (!db) return []
    return db.invites.toArray()
  }, [db])

  const onChange = React.useCallback(e => {
    setEmails(e.currentTarget.value)
  }, [])

  // TODO: add revoke
  // const setRole = React.useCallback(invite => e => {
  //   const { value } = e.currentTarget
  //   updateWorkspaceInvite(intl.locale, {
  //     id: member.account.id,
  //     workspace_id: activeWorkspace.id,
  //     role: value
  //   })
  // }, [activeWorkspace, invitesQuery.data])

  const onSubmit = React.useCallback(async (ev) => {
    ev.preventDefault()

    const canInviteMembers = members.length < activeWorkspace.meta?.data?.plan?.max_members
    if (!canInviteMembers) return

    try {
      const emailList = emails.split(',').map(o => o.trim()).filter(o => Validator.isEmail(o))
      const invites = emailList.map(email => ({
        email,
        id: uuid(),
        created_at: (new Date()).toISOString(),
        updated_at: (new Date()).toISOString(),
        invite_code: nanoid(),
        invited_by_id: auth?.user?.id,
        workspace_id: activeWorkspace?.id,
        status: 'PENDING',
        data: {}
      }))
      db.invites.bulkAdd(invites)
      setEmails('')
      if (props.onSubmit) props.onSubmit()
      // TODO: notify invites are sent successfully
    } catch (err) {
      console.log('err inviting to space', err)
    }
    return false
  }, [db, emails, activeWorkspace?.id])

  const onKeyDown = React.useCallback(async e => {
    if (isHotkey('mod+enter', e)) {
      onSubmit(e)
    }
  })

  if (!activeWorkspace) return null

  const canInviteMembers = members.length < activeWorkspace.meta?.data?.plan?.max_members
  if (!canInviteMembers) {
    return (
      <div className={styles.inviteBaseWrapper}>
        <div className={styles.inviteHeader}>
          <h3>
            {intl.formatMessage({ id: 'YOUR_HIT_MAX_MEMBERS_LIMIT' })}
          </h3>
        </div>
        <div>
          {intl.formatMessage({ id: 'YOUR_HIT_MAX_MEMBERS_LIMIT_CAPTION' })}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.inviteBaseWrapper}>
      <div className={styles.inviteHeader}>
        <h3>
          <FormattedMessage id='WORKSPACE_INVITE' />
        </h3>
      </div>
      <form className={styles.inviteForm} onSubmit={onSubmit}>
        <TextareaAutosize
          autoFocus
          placeholder='email@xevol.com, email1@xevol.com, email2@xevol.com'
          value={emails}
          onChange={onChange}
          onKeyDown={onKeyDown}
          minRows={3}
        />
        <button>
          {false && 'SENDING'}
          <FormattedMessage id='INVITE' />
        </button>
      </form>
      {withList && (
        <div className={styles.inviteList}>
          <div className={styles.section}>
            <div className={styles.sectionBody}>
              {invites?.map(invite => (
                <div className={styles.userRow} key={`${invite.email}-${invite.status}`}>
                  <div className={styles.userInfo}>
                    {/* <ImgIcon */}
                      {/* size='medium' */}
                      {/* type='circle' */}
                      {/* url={invite.data?.picture_url} */}
                      {/* caption={invite.email} */}
                    {/* /> */}
                    <div className={styles.userIDs}>
                      <strong>
                        {invite.data?.username}
                      </strong>
                      <p>
                        {invite.email}
                      </p>
                    </div>
                  </div>
                  <div className={styles.userAccess}>
                    <div className={styles.userStatus}>
                      <span><FormattedMessage id={invite.status} /></span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default InviteBase
