import React from 'react'
import { v4 as uuid } from 'uuid'
import { useLiveQuery } from 'dexie-react-hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGlobalStore } from '../../../lib/global'
import Input from '../../Input'
import validateInput from './validate'
import styles from '../index.module.scss'

const initialState = {
  fullname: null,
  bio: null,
  errors: {
    fullname: '',
    bio: ''
  },
  isChanged: false,
  isLoading: false
}

function SettingsProfileContainer(props) {
  const withButtons = props.withButtons ?? true
  const withBio = props.withBio ?? true
  const intl = useIntl()
  const [lang, setLang] = React.useState('')
  const [state, setState] = React.useState(initialState)
  const { auth, accountDb, } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb,
  }))
  const profiles = useLiveQuery(() => {
    if (!auth?.user?.id || !accountDb) return []
    return accountDb.profiles.toArray()
  }, [auth, accountDb])

  const onChange = e => {
    setState({
      ...state,
      isChanged: true,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  const onBlur = () => {
    if (!withButtons) {
      onSubmit()
    }
  }

  function isValid() {
    const { errors, isValid } = validateInput(state)

    if (!isValid) {
      setState({ ...state, errors })
    }

    return isValid
  }

  const onSubmit = async e => {
    if (e) e.preventDefault()

    if (isValid()) {
      setState({
        ...state,
        errors: {},
        isLoading: true
      })

      const payload = {}

      if (state.fullname) {
        payload.fullname = state.fullname
      }

      if (state.bio) {
        payload.bio = state.bio
      }

      const found = await accountDb.profiles.where({
        lang: lang || intl.locale,
        account_id: auth?.user?.id
      }).first()

      if (found) {
        accountDb.profiles.update(found.id, payload)
      } else {
        accountDb.profiles.add({
          id: uuid(),
          lang: lang || intl.locale,
          account_id: auth.user.id,
          ...payload
        })
      }
    }
  }

  const selectedProfile = React.useMemo(() => {
    if (!auth?.user) return {}
    const found = profiles?.find(o => o.lang === (lang || intl.locale) && o.account_id === auth?.user.id)
    return found || {}
  }, [profiles, lang, intl.locale, auth])

  const onChangeLang = React.useCallback(e => {
    setLang(e.target.value)
    setState(initialState)
    return false
  })

  const cancel = () => setState(initialState)

  const {
    fullname,
    bio,
    errors,
    isLoading,
    isChanged
  } = state

  return (
    <div className={styles.inner}>
      <form className={styles.form} onSubmit={onSubmit}>
        <select value={lang || intl.locale} onChange={onChangeLang} className={styles.langSelect}>
          <option value='en'>EN</option>
          <option value='ru'>RU</option>
          <option value='ja'>JA</option>
          <option value='kr'>KR</option>
          <option value='kk'>KK</option>
        </select>
        <div className={styles.fieldset}>
          <Input
            error={errors.fullname}
            placeholder=''
            onChange={onChange}
            value={fullname === null ? selectedProfile?.fullname : fullname}
            label={intl.formatMessage({ id: 'FULLNAME' })}
            onBlur={onBlur}
            type='text'
            id='fullname'
            name='fullname'
          />
        </div>
        {withBio && (
          <div className={styles.fieldset}>
            <Input
              error={errors.bio}
              placeholder=''
              onChange={onChange}
              value={bio === null ? selectedProfile?.bio : bio}
              label={intl.formatMessage({ id: 'BIO' })}
              type='textarea'
              onBlur={onBlur}
              id='bio'
              name='bio'
            />
          </div>
        )}
        {withButtons && (
          <div className={styles.footer}>
            <button className={`${styles.button} cta-button`} disabled={!isChanged || isLoading} type='submit'>
              <FormattedMessage id='SAVE' />
            </button>
            <button className={`${styles.button} cancelButton`} disabled={!isChanged} onClick={cancel}>
              <FormattedMessage id='CANCEL' />
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default SettingsProfileContainer
