// TODO: revampt into a proper ready to use template

export default {
  "nav": {
    "sections": [{
      "id": "nav-section-1",
      "links": [],
      "title": ""
    }]
  },
  "style": {
  },
  "site": {
    "webmanifest": "",
    "title": {
      "en": ""
    },
    "favicon": {
      "icon": "",
      "fav16": "",
      "fav32": "",
      "appleTouchIcon": ""
    },
    "picture": {},
    "description": {
      "en": ""
    },
    "tagline_1": {
      "en": ""
    },
    "tagline_2": {
      "en": ""
    },
    "tagline_3": {
      "en": ""
    },
  },
  "purchase": {
    "cta": {
      "title": {
        en: ""
      },
      "description": {
        en: ""
      },
      "selling_point_1": {
        icon: "",
        en: ""
      },
      "selling_point_2": {
        icon: "",
        en: ""
      },
      "selling_point_3": {
        icon: "",
        en: ""
      },
    },
    "price": "",
    "currency": ""
  },
  "collections": {
    "list": [],
    "items": []
  }
}
