import React from 'react'
import qs from 'qs'
import { FormattedMessage, useIntl } from 'react-intl'
import Cookies from 'js-cookie'
import { navigate } from '@reach/router'
import { useSupportedLocales } from '../hooks/useSupportedLocales'
import ArrowDownSVG from '../icons/remix/system/arrow-down-s-line.svg'
import { useClickOutside } from '../hooks/useClickOutside'
import './styles.scss'

export default function LanguageSelect(props) {
  const {
    struct,
    position = 'top from-right',
    short = false,
    isContained,
  } = props
  const intl = useIntl()
  const location = props.location
  const locale = props.locale || intl.locale
  const localeList = useSupportedLocales(struct)

  const ref = React.useRef()
  const [isShowing, setShowing] = React.useState(false)
  const showSelection = React.useCallback(() => {
    setShowing(true)
  }, [])

  const onClickOutside = React.useCallback(() => {
    setShowing(false)
  }, [])
  useClickOutside(ref, onClickOutside)

  const setLocale = React.useCallback(locale => {
    if (isContained) {
      if (typeof props.onLocaleChange === 'function') {
        props.onLocaleChange(locale)
      }
    } else {
      Cookies.set('locale', locale, {
        ...process.env.NODE_ENV === 'production' ? {
          domain: '.prodmake.com'
        } : {}
      })
      if (window.changeLocale && typeof window.changeLocale === 'function') {
        window.changeLocale(locale)
      }
    }
  }, [])

  const onSwitch = React.useCallback(locale => async ev => {
    setLocale(locale)
    if (location?.query?.locale) {
      navigate(`${location?.pathname}?${qs.stringify({
        ...location?.query || {},
        locale
      })}`)
    }
    return false
  })

  useClickOutside(ref, () => {
    setShowing(false)
  })

  const list = (
    <div className='selection-list'>
      {localeList.map((l) => {
        return (
          <div
            key={l.locale}
            onKeyPress={onSwitch(l.locale)}
            onClick={onSwitch(l.locale)}
            className={`language-styled-link ${locale === l.locale ? 'is-active' : ''}`}
          >
            <FormattedMessage id={l.locale?.toLowerCase()} />
            <small>
              {l.name}
            </small>
          </div>
        )
      })}
    </div>
  )

  return (
    <div className='select-wrapper'>
      <button className={`selection ${short ? 'short' : ''}`} onClick={showSelection} onKeyPress={showSelection}>
        {short && typeof locale === 'string' && (
          <span>
            {locale?.toUpperCase()}
          </span>
        )}
        {!short && (
          <span>
            <FormattedMessage id='CURRENT_LANGUAGE' />–{locale?.toUpperCase()}
          </span>
        )}
        <span>
          <ArrowDownSVG />
        </span>
      </button>
      <div ref={ref}>
        <div className={['selection-content', isShowing ? 'active' : '', position].join(' ')}>
          <div className='selection-block'>
            {list}
          </div>
        </div>
      </div>
    </div>
  )
}
