export const API_URL = process.env.API_URL || 'http://localhost:5006'
export const WS_URL = process.env.WS_URL || 'ws://localhost:5050'
export const YJS_URL = process.env.YJS_URL || 'ws://localhost:5034'

export const REACT_APP_BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER || '99'
export const REACT_APP_BUILD_DATE = process.env.REACT_APP_BUILD_DATE || '2023-08-18'

export const HELP_STRUCT_ID = process.env.NODE_ENV === 'production'
  ? 'bfc73dd2-7fbc-4d68-b199-f518a4ef11ca'
  : '61bcdc6d-f6c5-4125-bc96-dedaaac38f2a'

export const IFRAME_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://render.prodmake.com'
  : 'http://localhost:3013'
