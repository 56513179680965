import Dexie from 'dexie'
import Cookie from 'js-cookie'
import create from 'zustand'
import { toast } from 'sonner'
import produce from 'immer'
import queryString from 'query-string'
import { navigate } from '@reach/router'
import qs from 'query-string'
import { v4 as uuid } from 'uuid'
import { nanoid } from 'nanoid'
import objectPath from 'object-path'
import cloneDeep from 'lodash.clonedeep'
import { arrayMove } from '@dnd-kit/sortable'
import deepmerge from 'deepmerge'
import { isPlainObject } from 'is-plain-object'
import debounce from 'lodash.debounce'
import microdiff from 'microdiff'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import tmpl from '../../lib/db/models/tmpl'
import { restoreUserSession } from './restoreUserSession'
import { createAccountDatabase } from '../db'
import trace from '../utils/trace'

let registerSw = function() { /* noop */ }
// registerSw = require('shared/sw.install').registerSw

async function cleanDexie() {
  return new Promise((resolve, reject) => {
    Dexie.getDatabaseNames(function (names, cb) {
      names.forEach(function (name) {
        var db = new Dexie(name)
        db.delete().then(function() {
          trace('Database successfully deleted: ', name)
        }).catch(function (err) {
          console.error('Could not delete database: ', name, err)
          reject(err)
        }).finally(function() {
          trace('Done. Now executing callback if passed.')
          resolve()
          if (typeof cb === 'function') {
            cb()
          }
        })
      })
    })
  })
}

const defaultAuth = {
  activeWorkspace: null,
  auth: {
    isAuthenticated: false,
    token: '',
    user: {},
    userId: ''
  }
}

const storedPadded = (
  localStorage.getItem('isPadded')
)
const isPadded = (
  storedPadded ? storedPadded === '1' : true
)

const storedMinimalView = (
  localStorage.getItem('isMinimalView')
)
const isMinimalView = (
  storedMinimalView ? storedMinimalView === '1' : false
)

const defaultFields = {
  ...defaultAuth,
  location: {
    pathname: '',
  },
  hasFetchedUser: false,
  hasCreatedWorkspaces: false,
  accountDb: null,
  workspaces: [],
  databases: {},
  lang: 'en',
  locale: 'en',
  editingLocale: 'en',
  theme: '',
  tmp: {},
  tmpStruct: {},
  isPadded,
  isMinimalView,
  isExpanded: localStorage.getItem('isExpanded') === '1',
  isCommanderActive: false,
  isZen: false,
  serviceWorkerStatus: 'INIT',
  wsStatus: 'OFFLINE',
  currentEditor: null,

  tourState: {
    type: 'full',
    stepIndex: 0,
    run: false,
    continuous: true,
  },
}

function getProductIdFromLocation(location) {
  const splat = location.pathname.split('/')
  if (splat[2] !== 'product') return
  return splat[3]
}

export const useGlobalStore = create((set, get) => ({
  ...defaultFields,

  playTour: () => {
    return set(produce(state => {
      state.tourState.run = true
    }))
  },

  stopTour: () => {
    return set(produce(state => {
      state.tourState.run = false
    }))
  },

  setTourState: (val) => {
    if (typeof val === 'function') {
      const newState = val(useGlobalStore.getState().tourState)
      return set(produce(state => {
        Object.keys(newState).forEach((key) => {
          state.tourState[key] = newState[key]
        })
      }))
    }

    return set(produce(state => {
      Object.keys(val).forEach((key) => {
        state.tourState[key] = val[key]
      })
    }))
  },

  initStruct: async (props) => {
    const {
      setTmp,
      setTmpStruct,
      databases,
      activeWorkspace,
      location
    } = useGlobalStore.getState()

    const activeDatabase = databases?.[activeWorkspace?.id]

    // items
    const structItems = await activeDatabase?.struct_items?.toArray()
    if (structItems) {
      const mapped = {}
      structItems?.forEach((item) => {
        mapped[item.id] = item
      })
      setTmp({
        ...useGlobalStore.getState().tmp,
        ...mapped
      })
    }

    // domains
    const structDomains = await activeDatabase?.struct_domains?.toArray()
    if (structDomains) {
      const mapped = {}
      structDomains?.forEach((domain) => {
        mapped[domain.id] = domain
      })
      setTmp({
        ...useGlobalStore.getState().tmp,
        ...mapped
      })
    }

    // offerings
    const structOfferings = await activeDatabase?.struct_offerings?.toArray()
    if (structOfferings) {
      const mapped = {}
      structOfferings?.forEach((offering) => {
        mapped[offering.id] = offering
      })
      setTmp({
        ...useGlobalStore.getState().tmp,
        ...mapped
      })
    }

    activeDatabase.on('changes', function (changes) {
      changes.forEach(async function (change) {
        // if (change.type === 1) { console.log('An object was created: ' + JSON.stringify(change.obj)); }
        // if (change.type === 2) { console.log('An object with key ' + change.key + ' was updated with modifications: ' + JSON.stringify(change.mods)); }
        // if (change.type === 3) { console.log('An object was deleted: ' + JSON.stringify(change.oldObj)); }

        if (change.source && change.table === 'structs') {
          if (change.obj.short_id === location.productId) { // has source = remote change
            const { tmpStruct } = useGlobalStore.getState()
            // TODO: diff between local and remote chunk

            const isLocal = change.mods.updated_at === tmpStruct.updated_at || change.obj.updated_at === tmpStruct.updated_at
            if (!isLocal) {
              const diff = microdiff(change.obj, tmpStruct)
              if (change.obj && diff.length > 0) {
                const latest = await activeDatabase?.structs?.get({ short_id: location.productId })
                if (tmpStruct.updated_at !== latest.updated_at) {
                  setTmpStruct(latest)
                }
              }
            }
          } else {
            // console.log('struct local change')
          }
        }

        // update handler for collections
        if (change.source && change.table && change.obj?.id) {
          const original = await activeDatabase[change.table].get(change.obj?.id)
          const { tmp } = useGlobalStore.getState()
          const one = tmp[change.obj.id] || original
          if (!one) return
          const isUpdate = Boolean(change.mods?.updated_at)
          const isInsert = Boolean(!change.mods?.updated_at && change.obj?.id)
          const isLocal = !change.source // TODO: keep in mind if not work: change.mods?.updated_at === one.updated_at || change.obj.updated_at === one.updated_at
          if (!isLocal) {
            if (isUpdate) {
              const diff = microdiff(change.obj, one)
              if (change.obj && diff.length > 0) {
                const latest = await activeDatabase?.[change.table]?.get(change.obj.id)
                if (one.updated_at !== latest.updated_at) {
                  setTmp({
                    ...tmp,
                    [change.obj.id]: change.obj
                  })
                }
              }
            }
            if (isInsert) {
              setTmp({
                ...tmp,
                [change.obj.id]: change.obj
              })
            }
          }
        }
      })
    })
  },

  restoreFromJSON: async entities => {
    const { struct, struct_domains, struct_offerings, struct_items } = entities
    const {
      tmpStruct,
      setTmp,
      updateStruct,
      databases,
      activeWorkspace,
      initStruct
    } = useGlobalStore.getState()

    const db = databases?.[activeWorkspace?.id]
    const updatedIdsMap = {}

    struct_items.forEach(async (item) => {
      const id = uuid()
      updatedIdsMap[item.id] = id
      const newItem = {
        ...item,
        slug: nanoid(),
        struct_id: tmpStruct.id,
        id,
        created_at: (new Date()).toISOString(),
      }
      await db.struct_items.add(newItem)
    })

    struct_domains.forEach(async (domain) => {
      const newDomain = {
        ...domain,
        struct_id: tmpStruct.id,
        created_at: (new Date()).toISOString(),
        id: uuid()
      }
      await db.struct_domains.add(newDomain)
    })

    struct_offerings.forEach(async (offering) => {
      const newOffering = {
        ...offering,
        struct_id: tmpStruct.id,
        created_at: (new Date()).toISOString(),
        short_id: nanoid(),
        id: uuid()
      }

      // reassign items to offerings
      Object.keys(newOffering.data.includes_items).forEach(itemId => {
        const newItemId = updatedIdsMap[itemId]
        newOffering.data.includes_items[newItemId] = !!newOffering.data.includes_items[itemId]
        delete newOffering.data.includes_items[itemId]
      })

      await db.struct_offerings.add(newOffering)
    })

    struct.data.collections.items = struct.data.collections.items.map((i) => {
      return {
        ...i,
        struct_item_id: updatedIdsMap[i.struct_item_id]
      }
    })

    updateStruct({ data: struct.data })

    initStruct(tmpStruct.short_id)
  },

  setTmpStruct: struct => {
    const { tmpStruct } = useGlobalStore.getState()
    if (tmpStruct) {
      const diff = microdiff(tmpStruct, struct)
      if (diff.length === 0) return
    }
    return set(produce(state => {
      state.tmpStruct = struct
    }))
  },

  setTmp: struct => {
    const { tmp } = useGlobalStore.getState()
    if (tmp) {
      const diff = microdiff(tmp, struct)
      if (diff.length === 0) return
    }
    return set(produce(state => {
      state.tmp = struct
    }))
  },

  setCurrentEditor: (editor) => {
    return set(produce(state => {
      state.currentEditor = editor
    }))
  },

  setDatabases: (mapped) => {
    return set(produce(state => {
      state.databases = mapped
    }))
  },

  setLocale: (locale) => {
    Cookie.set('locale', locale, {
      ...process.env.NODE_ENV === 'production' ? {
        domain: '.prodmake.com'
      } : {}
    })
    // date-picker
    if (locale === 'en') {
      setDefaultLocale(locale)
    } else {
      const dateFnLocale = require(`date-fns/locale/${locale}`)
      if (dateFnLocale?.default) {
        registerLocale(locale, dateFnLocale.default)
        setDefaultLocale(locale)
      }
    }
    return set(produce(state => {
      state.locale = locale
    }))
  },

  setEditingLocale: (locale) => {
    return set(produce(state => {
      state.editingLocale = locale
    }))
  },

  setLocation: (location) => {
    return set(produce(state => {
      state.location = {
        ...location,
        query: qs.parse(location.search),
        productId: getProductIdFromLocation(location)
      }
    }))
  },

  setWorkspaces: (workspaces) => {
    return set(produce(state => {
      state.workspaces = workspaces
    }))
  },

  setHasFetchedUser: (val) => {
    return set(produce(state => {
      state.hasFetchedUser = val
    }))
  },

  setHasCreatedWorkspaces: (val) => {
    return set(produce(state => {
      state.hasCreatedWorkspaces = val
    }))
  },

  setAccountDatabase: (dbInstance) => {
    return set(produce(state => {
      state.accountDb = dbInstance
    }))
  },

  setAccountDbStatus: (payload = {}) => {
    return set(produce(state => {
      state.accountDbStatus = payload
    }))
  },

  setDbStatus: (payload = {}) => {
    return set(produce(state => {
      state.dbStatus = payload
    }))
  },

  setActiveWorkspace: activeWorkspace => {
    return set(() => ({ activeWorkspace }))
  },

  login: ({ token, user }) => {
    const auth = {
      user,
      token,
      userId: user.id,
      isAuthenticated: true
    }

    Cookie.set('auth_token', token, {
      ...process.env.NODE_ENV === 'production' ? {
        domain: '.prodmake.com'
      } : {}
    })

    return set(produce(state => {
      state.auth = auth
    }))
  },
  loginLocally: ({ privKey }) => {
    restoreUserSession({ privKey })
  },
  logout: () => {
    Cookie.remove('auth_token', {
      ...process.env.NODE_ENV === 'production' ? { domain: '.prodmake.com' } : {}
    })

    return set(produce(state => {
      state.auth = defaultAuth
    }))
  },
  cleanUserStore: async () => {
    const got = get()
    if (got.accountDb) await got.accountDb.stopSync()

    const dbs = Object.values(got.databases)
    if (dbs?.length) {
      for (const db of dbs) {
        await db.stopSync()
      }
    }

    await cleanDexie()

    return set(produce(state => {
      Object.keys(defaultAuth).forEach(key => {
        state[key] = defaultAuth[key]
      })
    }))
  },
  updateUserProfile: (lang, changes) => {
    return set(produce(state => {
      if (!state.user) {
        state.user = {}
      }

      state.user.updated_at = (new Date()).toISOString()

      const old = {}
      const idx = state.user.profiles.findIndex(o => o.lang === lang)
      if (idx === -1) {
        state.user.profiles.push({
          lang,
          ...changes
        })
      } else {
        Object.keys(changes).forEach(key => {
          old[key] = state.user.profiles[idx][key]
          state.user.profiles[idx][key] = changes[key]
        })
      }
    }))
  },
  setWorkspaceMembers: (workspaceId, members) => {
    return set(produce(state => {
      state.workspaces[workspaceId] = state.workspaces[workspaceId] || {}
      state.workspaces[workspaceId].members = members
    }))
  },
  updateWorkspaceMember: (lang, changes) => {
    return set(produce(state => {
      if (!state.workspaces[changes.workspace_id]) return

      const memberIdx = state.workspaces[changes.workspace_id].members.findIndex(member =>
        member.account.id === changes.id
      )

      if (memberIdx === -1) return

      const ts = (new Date()).toISOString()
      const old = {}
      state.workspaces[changes.workspace_id].members[memberIdx].updated_at = ts

      Object.keys(changes).forEach(key => {
        old[key] = state.workspaces[changes.workspace_id].members[memberIdx][key]
        state.workspaces[changes.workspace_id].members[memberIdx][key] = changes[key]
      })
    }))
  },

  setExpanded: (val) => {
    return set(produce(state => {
      state.isExpanded = val
    }))
  },

  setPadded: (val) => {
    return set(produce(state => {
      state.isPadded = val
    }))
  },

  togglePadded: () => {
    return set(produce(state => {
      state.isPadded = state.isPadded ? false : true
    }))
  },

  toggleMinimalView: () => {
    return set(produce(state => {
      state.isMinimalView = state.isMinimalView ? false : true
      if (state.isMinimalView) {
        state.isPadded = false
      }
    }))
  },

  setCommanderActive: (active = true) => {
    return set(produce(state => {
      state.isCommanderActive = active
    }))
  },

  toggleCommander: (val) => {
    return set(produce(state => {
      state.isCommanderActive = !state.isCommanderActive
    }))
  },

  toggleZen: (val) => {
    return set(produce(state => {
      state.isZen = !state.isZen
    }))
  },

  registerServiceWorker: () => {
    registerSw(event => {
      set(produce(state => {
        state.serviceWorkerStatus = 'REGISTERED'
      }))
    })
    return set(produce(state => {
      state.serviceWorkerStatus = 'REGISTERING'
    }))
  },

  updateDbCollection: debounce(async (collection, id, changes) => {
    const {
      databases,
      activeWorkspace,
    } = useGlobalStore.getState()
    const db = databases?.[activeWorkspace?.id]
    await db[collection].update(id, changes)
  }, 500),

  removeDbCollection: debounce((collection, id) => {
    const {
      databases,
      activeWorkspace,
    } = useGlobalStore.getState()
    const db = databases?.[activeWorkspace?.id]
    db[collection].where({ id }).delete()
  }, 200),

  addTask: async ({
    type,
    data,
    private_data,
    priority
  }) => {
    const {
      auth,
      databases,
      activeWorkspace,
    } = useGlobalStore.getState()

    const db = databases?.[activeWorkspace?.id]

    // TODO: here we have to add a lot of metadata for server to handle everything
    // correctly

    const newTask = {
      id: uuid(),
      created_at: (new Date()).toISOString(),
      account_id: auth.user?.id,
      workspace_id: activeWorkspace?.id,
      type,
      priority,
      data
    }

    await db.tasks.add(newTask)
  },

  deleteStruct: (structId) => {
    const {
      removeDbCollection,
      setTmpStruct,
      tmpStruct,
    } = useGlobalStore.getState()

    if (tmpStruct.id === structId) {
      setTmpStruct({})
    }

    removeDbCollection('structs', structId)
  },

  updateStructGeneralWithDemoData: (locale) => {
    const tmpl = { // TODO: by locale
      title: 'My product name',
      description: 'Product that helps you do your best',
      tagline_1: 'Tagline that makes you think',
      tagline_2: 'True statements only',
      tagline_3: 'For thinkers',
    }

    const {
      updateDbCollection,
      setTmpStruct,
      tmpStruct,
    } = useGlobalStore.getState()

    const struct = cloneDeep(tmpStruct)
    const ts = (new Date()).toISOString()

    const changes = {
      [`data.site.title.${locale}`]: tmpl.title,
      [`data.site.description.${locale}`]: tmpl.description,
      [`data.site.tagline_1.${locale}`]: tmpl.tagline_1,
      [`data.site.tagline_2.${locale}`]: tmpl.tagline_2,
      [`data.site.tagline_3.${locale}`]: tmpl.tagline_3,
    }

    // do local change
    objectPath.set(struct, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(struct, key, changes[key])
    }
    setTmpStruct(struct)

    // do db change
    updateDbCollection('structs', struct.id, {
      updated_at: ts,
      ...changes,
    })

    return struct
  },

  updateStructVersion: (id, changes) => {
    const { updateDbCollection } = useGlobalStore.getState()
    const ts = (new Date()).toISOString()

    // do db change
    updateDbCollection('struct_versions', id, {
      updated_at: ts,
      ...changes,
    })
  },

  updateStructItem: async (structItemId, changes) => {
    const { setTmp, updateDbCollection, databases, activeWorkspace } = useGlobalStore.getState()
    const db = databases?.[activeWorkspace?.id]
    const original = await db.struct_items.get(structItemId)
    const item = cloneDeep(original)
    const ts = (new Date()).toISOString()

    // do local change
    objectPath.set(item, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(item, key, changes[key])
    }

    setTmp({
      ...useGlobalStore.getState().tmp,
      [item.id]: item
    })

    // do db change
    updateDbCollection('struct_items', item.id, {
      updated_at: ts,
      data: item.data
    })
  },

  updateRefs: async ({ selectedList, item, value }) => {
    const {
      databases,
      activeWorkspace,
      updateStructById
    } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]

    const removedCollectionIds = []

    /* removal */
    // 1. find removed
    value.forEach((one) => {
      const stillHasIt = selectedList.find(o => {
        return o.collectionId === one.collectionId
      })
      if (!stillHasIt) {
        removedCollectionIds.push(one)
      }
    })

    // 2. run remove operation
    for (const removedItem of removedCollectionIds) {
      const struct = await activeDatabase.structs.get({ id: removedItem.structId })
      const items = []

      // filter relevant items
      struct?.data?.collections?.items?.forEach((refItem) => {
        if (refItem.struct_item_id !== item.id) {
          items.push(refItem)
        }
      })

      // set relevant items
      const changes = {
        updated_at: (new Date()).toISOString(),
        'data.collections.items': Object.values(items)
      }
      updateStructById(removedItem.structId, changes, false)
      activeDatabase.structs.update(removedItem.structId, changes)
    }

    /* recreate links */
    for (const selection of selectedList) {
      const struct = await activeDatabase.structs.get({ id: selection.structId })
      const items = {}

      struct?.data?.collections?.items?.forEach((refItem) => {
        items[refItem.struct_item_id] = refItem
      })

      items[item.id] = {
        collection: selection.collectionId,
        struct_item_id: item.id,
        options: {}
      }

      // set items
      const changes = {
        updated_at: (new Date()).toISOString(),
        'data.collections.items': Object.values(items)
      }
      updateStructById(selection.structId, changes, false)
      activeDatabase.structs.update(selection.structId, changes)
    }
  },

  updateStructById: async (structId, changes, withDbChange) => {
    const {
      setTmp,
      tmpStruct,
      setTmpStruct,
      databases,
      activeWorkspace
    } = useGlobalStore.getState()
    const db = databases?.[activeWorkspace?.id]
    const _struct = await db.structs.get({ id: structId })
    const struct = cloneDeep(_struct)
    const ts = (new Date()).toISOString()

    // do local change
    objectPath.set(struct, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(struct, key, changes[key])
    }

    if (tmpStruct.id === struct.id) {
      setTmpStruct(struct)
    } else {
      setTmp({
        ...useGlobalStore.getState().tmp,
        [struct.id]: struct
      })
    }

    if (withDbChange) {
      // do db change
      await db.structs.update(structId, {
        updated_at: ts,
        ...changes
      })
    }

    return struct
  },


  updateStruct: (changes) => {
    const {
      updateDbCollection,
      setTmpStruct,
      tmpStruct,
    } = useGlobalStore.getState()

    const struct = cloneDeep(tmpStruct)
    const ts = (new Date()).toISOString()

    // do local change
    objectPath.set(struct, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(struct, key, changes[key])
    }
    setTmpStruct(struct)

    // do db change
    updateDbCollection('structs', struct.id, {
      updated_at: ts,
      ...changes,
    })

    return struct
  },

  updateCollectionItem: async (itemId, {
    name,
    value,
    checked,
    type
  }) => {
    const {
      tmp,
      setTmp,
      updateDbCollection,
      databases,
      activeWorkspace,
    } = useGlobalStore.getState()

    const db = databases?.[activeWorkspace?.id]
    const original = tmp[itemId] || await db.struct_items.get(itemId)
    const item = cloneDeep(original)
    const ts = (new Date()).toISOString()
    const val = type === 'checkbox' ? checked : value

    // do local change
    objectPath.set(item, 'updated_at', ts)
    objectPath.set(item, name, val)
    setTmp({
      ...useGlobalStore.getState().tmp,
      [itemId]: item
    })

    // do db change
    updateDbCollection('struct_items', itemId, {
      updated_at: ts,
      [name]: val
    })
  },

  updateCollectionRefItem: async (refItemId, {
    name,
    value,
    checked,
    type
  }) => {
    const {
      tmp,
      tmpStruct,
      setTmp,
      updateStruct,
    } = useGlobalStore.getState()

    const struct = cloneDeep(tmpStruct)
    const ts = (new Date()).toISOString()
    const val = type === 'checkbox' ? checked : value

    const items = [...struct.data.collections.items]
    const selectedIndex = items.findIndex(o => o.struct_item_id === refItemId)

    // do local change
    if (selectedIndex !== -1) {
      objectPath.set(struct, 'updated_at', ts)
      const key = `data.collections.items.${selectedIndex}.${name}`
      objectPath.set(struct, key, val)
      setTmp({
        ...useGlobalStore.getState().tmp,
        [tmpStruct]: struct
      })

      // do db change
      updateStruct({
        [key]: val
      })
    }
  },

  onChange: (ev) => {
    const { updateStruct } = useGlobalStore.getState()
    const {
      name,
      value,
      checked,
      type
    } = ev.currentTarget
    const val = type === 'checkbox' ? checked : value
    updateStruct({ [name]: val })
  },

  goToStructItem: async itemId => {
    const { databases, activeWorkspace } = useGlobalStore.getState()
    const db = databases?.[activeWorkspace?.id]
    if (!db) return
    const item = await db.struct_items.get(itemId)
    if (item.struct_id) {
      const struct = await db.structs.get(item.struct_id)
      if (!struct) return window.alert('error')
      const collectionId = struct.data.collections?.items?.find(o => o.struct_item_id === itemId)?.collection
      if (!collectionId) return window.alert('error')
      const url = `/${activeWorkspace.slug}/product/${struct.short_id}/content?collectionId=${collectionId}&viewItemId=${itemId}`
      navigate(url)
    } else {
      const url = `/${activeWorkspace.slug}/ideas?itemId=${itemId}`
      navigate(url)
    }
  },

  /* STRUCTS START
   * ******************** */
  createStruct: async (payload) => {
    const { editingLocale, auth, databases, activeWorkspace } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]
    if (!activeDatabase) return

    const ts = (new Date()).toISOString()
    const _data = {
      ...tmpl,
      blueprint: null
    }

    const data = deepmerge(_data, payload.data, {
      isMergeableObject: isPlainObject,
    })

    const struct = {
      id: payload.id || uuid(),
      short_id: payload.short_id || nanoid(),
      created_at: ts,
      updated_at: ts,
      data,
      account_id: auth.user?.id,
      lang: payload.lang || editingLocale,
      workspace_id: activeWorkspace.id,
      is_deleted: false,
      is_private: false,
      is_public: false,
      is_trashed: false,
    }

    await activeDatabase.structs.add(struct)

    return struct
  },

  /* TASKS START
   * ******************** */
  createTask: async (payload) => {
    const { databases, activeWorkspace } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]

    const newTask = {
      id: uuid(),
      created_at: (new Date()).toISOString(),
      struct_item_id: payload.struct_item_id,
      status: payload.status || 'CREATED',
      priority: payload.priority,
      type: payload.type,
      input: payload.input,
      output: {},
    }

    await activeDatabase.tasks.add(newTask)

    return newTask
  },
  updateTask: async (taskId, changes) => {
    const {
      updateDbCollection,
      setTmp,
      tmp,
      databases,
      activeWorkspace
    } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]

    const original = tmp[taskId] || await activeDatabase.tasks.get(taskId)
    const task = cloneDeep(original)
    const ts = (new Date()).toISOString()

    // do local change
    objectPath.set(task, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(task, key, changes[key])
    }
    setTmp({
      ...useGlobalStore.getState().tmp,
      [task.id]: task
    })

    // do db change
    updateDbCollection('tasks', task.id, {
      updated_at: ts,
      ...changes,
    })

    return task
  },
  trashTask: (taskId) => {
    const { databases, activeWorkspace } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]
    activeDatabase.tasks.delete(taskId)
  },
   /* TASKS END
  ******************** */

  /* IDEAS START
   * ******************** */
  createIdea: async (payload) => {
    const {
      auth,
      databases,
      activeWorkspace
    } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]

    const newStructItem = {
      id: uuid(),
      slug: nanoid(),
      created_at: (new Date()).toISOString(),
      struct_id: null,
      account_id: auth.user?.id,
      workspace_id: activeWorkspace?.id,
      ...payload
    }

    await activeDatabase.struct_items.add(newStructItem)

    return newStructItem
  },
  updateIdea: async (itemId, changes) => {
    const {
      updateDbCollection,
      setTmp,
      tmp,
      databases,
      activeWorkspace
    } = useGlobalStore.getState()
    const activeDatabase = databases?.[activeWorkspace?.id]

    const original = tmp[itemId] || await activeDatabase.struct_items.get(itemId)
    const item = cloneDeep(original)
    const ts = (new Date()).toISOString()

    // do local change
    objectPath.set(item, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(item, key, changes[key])
    }
    setTmp({
      ...useGlobalStore.getState().tmp,
      [item.id]: item
    })

    // do db change
    updateDbCollection('struct_items', item.id, {
      updated_at: ts,
      ...changes,
    })

    return item
  },
   /* IDEAS END
  ******************** */

  updateCollection: async (collection, id, changes) => {
    const {
      updateDbCollection,
      setTmp,
      tmp,
      tmpStruct,
      setTmpStruct,
      databases,
      activeWorkspace,
    } = useGlobalStore.getState()

    const db = databases?.[activeWorkspace?.id]
    const original = await db[collection].get(id)
    const item = cloneDeep(tmp[id]) || original
    const ts = (new Date()).toISOString()

    // do local change
    objectPath.set(item, 'updated_at', ts)
    for (const key of Object.keys(changes)) {
      objectPath.set(item, key, changes[key])
    }
    setTmp({
      ...tmp,
      [id]: item
    })

    // do db change
    updateDbCollection(collection, item.id, {
      updated_at: ts,
      ...changes,
    })

    return item
  },

  onAddCollection: () => {
    const { editingLocale, tmpStruct, updateStruct } = useGlobalStore.getState()
    const newCollection = {
      id: uuid(),
      slug: nanoid(),
      type: 'collection',
      items: [],
      title: {
        [editingLocale]: ''
      },
      options: {
        is_active: false,
        show_date: false
      }
    }
    const struct = cloneDeep(tmpStruct)
    updateStruct({
      'data.collections.list': [
        ...struct.data.collections.list,
        newCollection
      ]
    })
    return newCollection
  },

  onChangeCollection: ({ ev, collection }) => {
    const {
      name,
      value,
      checked,
      type
    } = ev.currentTarget
    const {
      editingLocale,
      tmpStruct,
      updateStruct,
    } = useGlobalStore.getState()
    const struct = cloneDeep(tmpStruct)
    const items = [...struct.data.collections.list]
    const selectedIndex = items.findIndex(o => o.id === collection.id)
    items.map((o, index) => {
      if (index !== selectedIndex) return o
      if (name === 'title') {
        o.title = o.title || {}
        o.title[editingLocale] = value
      } else {
        const val = type === 'checkbox' ? checked : value
        objectPath.set(o, name, val)
      }
      return o
    })

    updateStruct({
      'data.collections.list': items
    })
  },

  onRemoveCollection: (ev) => {
    const { tmpStruct, updateStruct } = useGlobalStore.getState()
    const struct = cloneDeep(tmpStruct)
    const qs = queryString.parse(window.location.search)
    const collectionId = qs.collectionId
    const selectedCollection = struct.data?.collections?.list?.find(o => o?.id === collectionId)
    let list = [...struct.data.collections.list || []]
    let items = [...struct.data.collections.items || []]
    const selectedIndex = list.findIndex(o => o.id === selectedCollection.id)
    if (selectedIndex !== -1) {
      list.splice(selectedIndex, 1)
    }

    // will delete everything
    list = list.filter(o => o.collection !== selectedCollection.id)
    items = items.filter(o => o.collection !== selectedCollection.id)

    updateStruct({
      'data.collections.list': list,
      'data.collections.items': items
    })

    navigate(window.location.pathname)
  },

  onReorderCollections: (oldIndex, newIndex) => {
    const { tmpStruct, updateStruct } = useGlobalStore.getState()
    const list = [...tmpStruct.data?.collections?.list || []]
    updateStruct({
      'data.collections.list': arrayMove(list, oldIndex, newIndex)
    })
  },

  onReorderCollectionItems: (collectionId, oldIndex, newIndex) => {
    const { tmpStruct, updateStruct } = useGlobalStore.getState()
    const items = [...tmpStruct.data?.collections?.items || []].filter(o => o.collection === collectionId)
    updateStruct({
      'data.collections.items': arrayMove(items, oldIndex, newIndex)
    })
  },

  onAddCollectionItem: async (ev) => {
    const {
      auth,
      editingLocale,
      tmpStruct,
      tmp,
      setTmp,
      updateStruct,
      databases,
      activeWorkspace,
    } = useGlobalStore.getState()

    const struct = cloneDeep(tmpStruct)
    const qs = queryString.parse(window.location.search)
    const collectionId = qs.collectionId
    const selectedCollection = struct.data?.collections?.list?.find(o => o?.id === collectionId)

    const newStructItem = {
      id: uuid(),
      slug: nanoid(),
      created_at: (new Date()).toISOString(),
      struct_id: struct.id,
      account_id: auth.user?.id,
      workspace_id: activeWorkspace?.id,
      data: {
        title: {
          [editingLocale]: ''
        },
        content: {
          [editingLocale]: ''
        },
      }
    }

    // add record
    const db = databases?.[activeWorkspace?.id]
    const created = await db.struct_items.add(newStructItem)

    if (!created) {
      toast.error('Error')
    } else {
      // add reference
      const newReferenceItem = {
        collection: selectedCollection.id,
        struct_item_id: newStructItem.id,
        options: {}
      }
      updateStruct({
        'data.collections.items': [
          ...struct.data.collections.items,
          newReferenceItem
        ]
      })

      setTmp({
        ...tmp,
        [newStructItem.id]: newStructItem
      })

      navigate(`${window.location.pathname}?collectionId=${selectedCollection.id}&viewItemId=${newStructItem.id}`)
    }

    return newStructItem
  }
}))

useGlobalStore.subscribe((state) => ({
  auth: state.auth,
}), async (state) => {
  // make sure account syncs
  const { auth, accountDb } = state
  if (accountDb) {
    const isSyncing = accountDb.isSyncing
    trace('is account db syncing', accountDb.isSyncing)
    if (auth.isAuthenticated) {
      if (!isSyncing) {
        accountDb.startSync()
        trace('start syncing account')
      }
    } else {
      if (!auth?.isAuthenticated && !isSyncing) {
        if (accountDb?.ws) {
          if (accountDb.ws.readyState !== accountDb.ws.CLOSE) {
            accountDb.ws.close()
          }
        }
        useGlobalStore.getState().setAccountDatabase(null)
        window.indexedDB.deleteDatabase('account')
        trace('stopped syncing account')
      }
    }
  }

  if (!accountDb) {
    // SKETCHY
    // if (state.hasFetchedUser && !auth?.isAuthenticated) {
    //   window.indexedDB.deleteDatabase('account')
    // }
    trace(accountDb ? 'has account db' : 'has not account db')

    if (auth.isAuthenticated) {
      const db = createAccountDatabase()
      trace('created account db', db)
      useGlobalStore.getState().setAccountDatabase(db)
      trace('set account db')
    }
  }
})
