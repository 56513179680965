export function getWorkspaceSteps({ intl, locale }) {
  return [
    {
      name: 'workspace-navigation',
      target: '#workspace-navigation',
      title: intl.formatMessage({ id: 'WORKSPACE_NAVIGATION' }),
      content: intl.formatMessage({ id: 'WORKSPACE_NAVIGATION_CAPTION' }),
      locale,
      placement: 'right',
    },

    {
      name: 'overview',
      target: 'body',
      title: intl.formatMessage({ id: 'WORKSPACE_OVERVIEW' }),
      content: intl.formatMessage({ id: 'WORKSPACE_OVERVIEW_CAPTION' }),
      locale,
      placement: 'bottom',
    },

    {
      name: 'overview-commerce',
      target: '#commerce-group',
      title: intl.formatMessage({ id: 'AT_GLANCE' }),
      content: intl.formatMessage({ id: 'AT_GLANCE_TOUR_CAPTION' }),
      locale,
      placement: 'bottom',
    },
    {
      name: 'overview-tips',
      target: '#tips-group',
      title: intl.formatMessage({ id: 'QUICK_ACTIONS' }),
      content: intl.formatMessage({ id: 'QUICK_ACTIONS_CAPTION' }),
      locale,
      placement: 'bottom',
    },

    {
      name: 'product-selection',
      target: '.nav-list-item',
      title: intl.formatMessage({ id: 'PRODMAKE_EDITOR' }),
      content: intl.formatMessage({ id: 'PRODMAKE_EDITOR_CAPTION' }),
      locale,
      placement: 'bottom',
    },

    {
      name: 'product-view',
      target: 'body',
      title: intl.formatMessage({ id: 'PRODUCT_PAGE' }),
      content: intl.formatMessage({ id: 'PRODUCT_PAGE_CAPTION' }),
      locale,
      placement: 'center',
    },
    {
      name: 'product-navigation',
      target: '#product-navigation',
      title: intl.formatMessage({ id: 'NAVIGATING_PRODUCT' }),
      content: intl.formatMessage({ id: 'NAVIGATING_PRODUCT_CAPTION' }),
      locale,
      placement: 'right',
    },
  ]
}

