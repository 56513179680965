import React from 'react'
import { useIntl } from 'react-intl'
import * as config from 'shared/config'
import PlanSwitch from '../PlanSwitch'
import { useGlobalStore } from '../../lib/global'
import PaymentMethodSelect from './PaymentMethodSelect'
import Confirmation from './Confirmation'

import styles from './index.module.scss'

const defaultState = {
  currentStep: 1,
  activeSteps: {},
  selectedPlan: null,
  selectedPaymentMethod: null,
  selectedPeriod: null,
  isSubmitting: false,
  isError: false,
  isSuccess: false,
  subscription: null
}

export default function WorkspacePlanSwitch(props) {
  const intl = useIntl()
  const { auth } = useGlobalStore(s => ({ auth: s.auth }))
  const [state, setState] = React.useState(defaultState)

  const onGoToStep = React.useCallback((step) => ev => {
    setState(prev => ({
      ...prev,
      currentStep: step,
      activeSteps: {
        ...prev.activeSteps,
        [step]: true
      }
    }))
  })

  const onPlanSelect = React.useCallback((plan, period) => {
    setState(prev => ({
      ...prev,
      selectedPlan: plan,
      selectedPeriod: period,
      currentStep: 2,
      activeSteps: {
        ...prev.activeSteps,
        3: true
      }
    }))
  })

  const onPaymentMethodSelect = React.useCallback((paymentMethod) => {
    setState(prev => ({
      ...prev,
      selectedPaymentMethod: paymentMethod,
    }))
  })

  const onReset = React.useCallback(() => {
    setState(defaultState)
  })

  const onFinish = React.useCallback(async () => {
    setState(prev => ({
      ...prev,
      isSubmitting: true,
    }))

    try {
      const res = await fetch(`${config.API_URL}/v1/workspaces/${props.activeWorkspace.id}/activate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        },
        body: JSON.stringify({
          locale: intl.locale,
          payment_method: state.selectedPaymentMethod,
          period: state.selectedPeriod,
          plan: state.selectedPlan,
        }),
      })
      const data = await res.json()
      setState(prev => ({
        ...prev,
        isProcessed: true,
        isSubmitting: false,
        isSuccess: data.success,
        subscription: data.subscription,
      }))
    } catch (err) {
      console.log(err)
      setState(prev => ({
        ...prev,
        isProcessed: true,
        isSubmitting: false,
        isError: true,
      }))
    }
  })

  React.useEffect(() => {
    return () => {
      setState(defaultState)
    }
  }, [])

  // TODO: check if subscription is active
  // TODO: check if it’s expired
  // if (props.activeWorkspace?.meta?.data?.subscription) return null

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.body}>
          {state.currentStep === 1 && (
            <PlanSwitch
              {...props}
              onPlanSelect={onPlanSelect}
            />
          )}
          {state.currentStep === 2 && (
            <PaymentMethodSelect
              {...props}
              state={state}
              onGoToStep={onGoToStep}
              onPaymentMethodSelect={onPaymentMethodSelect}
            />
          )}
          {state.currentStep === 3 && (
            <Confirmation
              {...props}
              state={state}
              onReset={onReset}
              onGoToStep={onGoToStep}
              onFinish={onFinish}
            />
          )}
        </div>
      </div>
    </div>
  )
}
