import React from 'react'
import { Link, navigate } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import Logout from '../../components/Logout'
import { API_URL } from '../../lib/config'
import { useGlobalStore } from '../../lib/global'
// import GithubSVG from '../../icons/remix/logos/github-fill.svg'
// import AppleSVG from '../../icons/remix/logos/apple-fill.svg'
import styles from './index.module.scss'

export default function Login() {
  const { auth, login } = useGlobalStore(s => s)

  const onLogin = React.useCallback(async (email, password) => {
    try {
      const res = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          identifier: email,
          password
        })
      })

      const data = await res.json()
      login({
        user: data.user,
        token: data.token
      })
      navigate('/')
    } catch (err) {
      console.log(err)
    }
  }, [])

  const onSubmit = React.useCallback(async (ev) => {
    ev.preventDefault()
    const email = ev.target.elements.email.value
    const password = ev.target.elements.password.value
    try {
      if (email && password) {
        onLogin(email, password)
      }
    } catch (err) {
      console.log(err)
    }
    return false
  }, [])

  return (
    <div className={styles.loginFormWrapper}>
      <div className={styles.loginFormHeader}>
        {!auth?.isAuthenticated && (
          <h1 className={styles.title}>
            <FormattedMessage id='LOGIN' />
          </h1>
        )}
      </div>
      <div className={styles.loginFormBody}>
        {!auth?.isAuthenticated && (
          <div>
            <form onSubmit={onSubmit} className={styles.form}>
              <div className={styles.formField}>
                <label htmlFor='email'>
                  <FormattedMessage id='EMAIL' />
                </label>
                <input
                  id='email'
                  type='email'
                  placeholder=''
                  name='email'
                  autoFocus
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor='password'>
                  <FormattedMessage id='PASSWORD' />
                </label>
                <input
                  id='password'
                  type='password'
                  placeholder=''
                  name='password'
                />
              </div>
              <button type='submit'>
                <FormattedMessage id='CONTINUE' />
              </button>
            </form>
            <div className={styles.loginActions}>
              <p>
                <FormattedMessage id='DONT_HAVE_AN_ACCOUNT' />
              </p>
              <Link to='/join'>
                <FormattedMessage id='JOIN_PRODMAKE' />
              </Link>
            </div>
          </div>
        )}
        {auth?.isAuthenticated && (
          <div>
            logged in as {auth.user.email}
            <Logout />
          </div>
        )}
      </div>
    </div>
  )
}
