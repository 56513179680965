import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useLiveQuery } from 'dexie-react-hooks'
import { loadStripe } from '@stripe/stripe-js'
import * as config from 'shared/config'
import { toast } from 'sonner'
import { usePreviousValue } from 'shared/hooks/usePreviousValue'
import { useGlobalStore } from '../../lib/global'
import './styles.scss'

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY)

export default function StripeVerification() {
  const intl = useIntl()
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb
  }))
  const user = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  const prevVerificationStatus = usePreviousValue(user?.private_data?.verification_status)

  React.useEffect(() => {
    const currStatus = user?.private_data?.verification_status
    if (prevVerificationStatus !== currStatus && currStatus && prevVerificationStatus) {
      setTimeout(() => {
        toast(intl.formatMessage({ id: currStatus }))
      }, 1000)
    }
  }, [prevVerificationStatus, user?.private_data?.verification_status])

  const onSubmit = React.useCallback(async () => {
    const { client_secret } = await fetch(`${config.API_URL}/v1/verification/create-verification-session`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      },
      body: JSON.stringify({ locale: intl.locale }),
    }).then(r => r.json())

    const stripe = await stripePromise.then(r => r)
    await stripe.verifyIdentity(client_secret)
  })

  return (
    <div className='id-verification-wrapper'>
      {!user?.is_verified && (
        <div>
          <h3>
            <FormattedMessage id='YOUR_ACCOUNT_IS_NOT_VERIFIED' />
          </h3>
          <p>
            <FormattedMessage id='YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION' />
          </p>
          <p>
            <FormattedMessage id='YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION_2' />
          </p>
          {user?.private_data?.verification_status && (
            <p>
              <FormattedMessage
                id='ACCOUNT_VERIFICATION_FAIL_REASON'
                values={{
                  status: intl.formatMessage({ id: user?.private_data?.verification_status })
                }}
              />
            </p>
          )}
          <div className=''>
            <button onClick={onSubmit} className='cta-button'>
              <FormattedMessage id='VERIFY' />
            </button>
          </div>
        </div>
      )}

      {user?.is_verified && (
        <div>
          <h3><FormattedMessage id='YOUR_ACCOUNT_IS_VERIFIED' /></h3>
          <p>
            <FormattedMessage id='YOUR_ACCOUNT_IS_VERIFIED_CAPTION' />
          </p>
        </div>
      )}
    </div>
  )
}
