import React from 'react'
import { v4 as uuid } from 'uuid'
import { nanoid } from 'nanoid'
import { useIntl } from 'react-intl'
import { useLiveQuery } from 'dexie-react-hooks'
import { useGlobalStore } from '../../../lib/global'
import ImageSelect from '../../ImageSelect'
import styles from './IconForm.module.scss'

const WorkspaceIconForm = props => {
  const intl = useIntl()
  const {
    auth,
    databases,
    activeWorkspace
  } = useGlobalStore(s => ({
    auth: s.auth,
    databases: s.databases,
    activeWorkspace: s.activeWorkspace,
  }))
  const db = databases?.[activeWorkspace.id]

  const infos = useLiveQuery(() => {
    if (!auth?.user?.id || !db) return []
    return db.infos.toArray()
  }, [auth, db], [])

  const info = infos?.find(o => o.lang === 'en') || infos?.[0]

  const updateWorkspaceIcon = React.useCallback(async (payload) => {
    if (!payload) return
    if (info) {
      db.infos.update(info.id, {
        updated_at: (new Date()).toISOString(),
        picture_url: payload.picture_url
      })
    } else {
      db.infos.add({
        id: uuid(),
        short_id: nanoid(),
        created_at: (new Date()).toISOString(),
        updated_at: (new Date()).toISOString(),
        lang: 'en',
        picture_url: payload.picture_url
      })
    }
  }, [db, info])

  return (
    <div className={styles.avatarSelectWrapper}>
      <ImageSelect
        src={info?.picture_url || ''}
        params={{ workspaceId: activeWorkspace?.id }}
        onUploadFinish={updateWorkspaceIcon}
        withEditor
      />
    </div>
  )
}

export default WorkspaceIconForm
