import { API_URL } from '../lib/config'
import { useGlobalStore } from '../lib/global'

export default async function customFetch(url, options) {
  const state = useGlobalStore.getState()
  const token = state.auth.token
  if (options.authOnly && !state.auth.isAuthenticated) return

  const res = await fetch(`${API_URL}${url}`, {
    ...options,
    cache: 'no-store',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  const json = await res.json()
  return json
}
