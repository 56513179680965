import dexieObservable from "dexie-observable";
import dexieSyncable from "dexie-syncable";
import Emitter from "../emitter";
import { getWs } from "./ws";

export const addons = [dexieObservable, dexieSyncable];

function sync(
  context,
  url,
  options,
  baseRevision,
  syncedRevision,
  changes,
  partial,
  applyRemoteChanges,
  onChangesAccepted,
  onSuccess,
  onError
) {
  // The following vars are needed because we must know which callback to ack when server sends it's ack to us.
  const acceptCallbacks = {};
  let requestId = 0;
  let isActive = false;

  // Connect the WebSocket to given url:
  const ws = getWs();

  // When initiated, send our changes to the server.
  function init() {
    // Initiate this socket connection by sending our id. If we dont have a id yet,
    // server will call back with a new client identity that we should use in future WebSocket connections.

    ws.send(
      JSON.stringify({
        c: options.c,
        cmd: options.cmd,
        type: "id",
        id: ws?.id || null,
        lang: options.lang,
        token: options.token,
        workspace_id: options.workspace_id
      })
    );

    // Send our changes:
    sendChanges(changes, baseRevision, partial, onChangesAccepted);

    // Subscribe to server changes:
    ws.send(
      JSON.stringify({
        c: options.c,
        token: options.token,
        cmd: options.cmd,
        type: "subscribe",
        workspace_id: options.workspace_id,
        syncedRevision
      })
    );

    isActive = true;
  }

  if (ws) {
    ws.runWhenReady(init);
  }

  // Inform framework and make it reconnect
  Emitter.on("WS_RECONNECTED", () => {
    onError("", 1000);
  });

  // sendChanges() method:
  function sendChanges(changes, baseRevision, partial, onChangesAccepted) {
    ++requestId;
    acceptCallbacks[requestId.toString()] = onChangesAccepted;

    // In this example, the server expects the following JSON format of the request:
    //  {
    //      type: "changes"
    //      baseRevision: baseRevision,
    //      changes: changes,
    //      partial: partial,
    //      requestId: id
    //  }
    //  To make the sample simplified, we assume the server has the exact same specification of how changes are structured.
    //  In real world, you would have to pre-process the changes array to fit the server specification.
    //  However, this example shows how to deal with the WebSocket to fullfill the API.

    const payload = {
      c: options.c,
      token: options.token,
      cmd: options.cmd,
      workspace_id: options.workspace_id,
      type: "changes",
      changes,
      partial,
      baseRevision,
      requestId
    };
    ws.send(JSON.stringify(payload));
  }

  // isFirstRound: Will need to call onSuccess() only when we are in sync the first time.
  // onSuccess() will unblock Dexie to be used by application code.
  // If for example app code writes: db.friends.where('shoeSize').above(40).toArray(callback), the execution of that query
  // will not run until we have called onSuccess(). This is because we want application code to get results that are as
  // accurate as possible. Specifically when connected the first time and the entire DB is being synced down to the browser,
  // it is important that queries starts running first when db is in sync.
  let isFirstRound = true;

  // When message arrive from the server, deal with the message accordingly:
  Emitter.on("WS_MESSAGE", onMessage);
  async function onMessage(event) {
    if (!isActive) return;

    try {
      // Assume we have a server that should send JSON messages of the following format:
      // {
      //     type: 'id', "changes", "ack" or "error"
      //     id: unique value for our database client node to persist in the context. (Only applicable if type='id')
      //     message: Error message (Only applicable if type="error")
      //     requestId: ID of change request that is acked by the server (Only applicable if type="ack" or "error")
      //     changes: changes from server (Only applicable if type="changes")
      //     lastRevision: last revision of changes sent (applicable if type="changes")
      //     partial: true if server has additionalChanges to send. False if these changes were the last known. (applicable if type="changes")
      // }
      if (event.data instanceof Blob) return;
      const requestFromServer = JSON.parse(event.data);

      if (
        requestFromServer.type === "changes" &&
        requestFromServer.c === options.c
      ) {
        if (
          requestFromServer.w_id &&
          requestFromServer.w_id !== options.workspace_id
        ) {
          return;
        }

        try {
          await applyRemoteChanges(
            requestFromServer.changes,
            requestFromServer.currentRevision,
            requestFromServer.partial
          );
        } catch (err) {
          console.error("Error applying remote changes:", err);
          // Force a full sync if we hit an error
          ws.send(
            JSON.stringify({
              c: options.c,
              token: options.token,
              cmd: options.cmd,
              type: "subscribe",
              workspace_id: options.workspace_id,
              syncedRevision: null // null revision triggers full sync
            })
          );
        }
        if (isFirstRound && !requestFromServer.partial) {
          // Since this is the first sync round and server sais we've got all changes - now is the time to call onsuccess()
          onSuccess({
            // Specify a react function that will react on additional client changes
            react(changes, baseRevision, partial, onChangesAccepted) {
              sendChanges(changes, baseRevision, partial, onChangesAccepted);
            },
            // Specify a disconnect function that will close our socket so that we dont continue to monitor changes.
            disconnect() {
              isActive = false;
            }
          });
          isFirstRound = false;
        }
      }

      if (
        requestFromServer.type === "ack" &&
        requestFromServer.c === options.c
      ) {
        const requestId = requestFromServer.requestId;
        const acceptCallback = acceptCallbacks[requestId.toString()];
        if (acceptCallback) acceptCallback();
        // Tell framework that server has acknowledged the changes sent.
        delete acceptCallbacks[requestId.toString()];
      }

      if (requestFromServer.type === "id" && !ws?.id) {
        ws.id = requestFromServer.id;
      }

      if (requestFromServer.type === "error") {
        // const requestId = requestFromServer.requestId
        ws.close();
        onError(requestFromServer.message, Infinity);
        // Don't reconnect - an error in application level means we have done something wrong.
      }
    } catch (e) {
      console.log("infinity issue", e);
      ws.close();
      onError(e, Infinity);
      // Something went crazy. Server sends invalid format or our code is buggy. Dont reconnect - it would continue failing.
    }
  }
}

export function registerSync(db) {
  return db.Syncable.registerSyncProtocol("websocket", { sync });
}
