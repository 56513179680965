import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from '@reach/router'
import KeyboardSVG from 'shared/icons/remix/device/keyboard-line.svg'
import LockSVG from 'shared/icons/remix/system/lock-2-line.svg'
import OpenAISVG from 'shared/icons/remix/logos/openai-fill.svg'
import PagesSVG from 'shared/icons/remix/document/pages-line.svg'
import MoneySVG from 'shared/icons/remix/finance/money-dollar-circle-line.svg'
import StoreSVG from 'shared/icons/remix/buildings/store-2-line.svg'
import AvatarForm from '../../components/UserSettings/Profile/AvatarForm'
import ProfileForm from '../../components/UserSettings/Profile/ProfileForm'
import WorkspaceTitle from '../../components/WorkspaceTitle'
import { useWorkspace } from '../../hooks/useWorkspace'
import { useGlobalStore } from '../../lib/global'

const BEST_DESCRIBES_LIST = [
  'NOVELIST',
  'SCI_FI_WRITER',
  'NON_FICTION_WRITER',
  'POET',
  'BLOGGER',
  'COPYWRITER',
  'SCREENWRITER',
  'COMIC_WRITER',
  'EDITOR',
  'PUBLISHER',
  'LITERARY_AGENT',
  'ILLUSTRATOR',
  'PHOTOGRAPHER',
  'STUDENT',
  'ACADEMIC_RESEARCHER',
  'ART_DIRECTOR',
  'SOFTWARE_DEVELOPER',
  'ENTREPRENEUR',
  'SERVICE_PROVIDER',
  'TEACHER',
  'OTHER', // Allows for those who don’t feel represented by the other options
]

const PRIMARY_GOALS = [
  'PUBLISH_A_BOOK',
  'CREATE_A_WEBSITE',
  'PRODUCE_FASTER',
  'MAKE_GLOBAL_PRODUCTS',
  'SIMPLIFY_WORKFLOW',
  'ADDITIONAL_INCOME',
  'DELEGATE_BORING_WORK',
  'SAVE_TIME',
  'OTHER',
]

export default function OnboardingScreen(props) {
  const intl = useIntl()
  const { workspaceList, isLoading } = useWorkspace()
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb,
  }))

  const [state, setState] = React.useState({
    currentStep: 0,
  })

  const onChange = React.useCallback((e) => {
    const { name, value } = e.currentTarget
    setState((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onFinishOnboarding = React.useCallback(async () => {
    accountDb.accounts.update(auth.user.id, {
      updated_at: (new Date()).toISOString(),
      data: {
        has_onboarded: true,
        best_describes: state.best_describes,
        best_describes_custom: state.best_describes_custom,
        primary_goal: state.primary_goal,
        primary_goal_custom: state.primary_goal_custom
      }
    })
  })

  const goToStep = React.useCallback((step) => {
    if (step === 4) {
      if (props.invites?.length > 0) {
        // user has been invited, so let’s finish onboarding
        onFinishOnboarding()
        return
      }
    }
    setState((prev) => ({
      ...prev,
      currentStep: step
    }))
  })

  const onSelectBestDescribes = React.useCallback((option) => {
    setState((prev) => ({
      ...prev,
      best_describes: option
    }))
  })

  const onSelectPrimaryGoal = React.useCallback((option) => {
    setState((prev) => ({
      ...prev,
      primary_goal: option
    }))
  })

  return (
    <div className='welcomeScreenBlock'>
      {state.currentStep === 0 && (
        <div className='welcomeScreenBlockBody'>
          <h3>
            <FormattedMessage id='WELCOME_TO_PRODMAKE' />
          </h3>
          <div className='points-grid'>
            <div>
              <div className='points-grid-icon'>
                <KeyboardSVG />
              </div>
              <h3><FormattedMessage id='MAKE' /></h3>
              <p><FormattedMessage id='MAKE_CAPTION' /></p>
            </div>
            <div>
              <div className='points-grid-icon'>
                <PagesSVG />
              </div>
              <h3><FormattedMessage id='PUBLISH' /></h3>
              <p><FormattedMessage id='PUBLISH_CAPTION' /></p>
            </div>
            <div>
              <div className='points-grid-icon'>
                <MoneySVG />
              </div>
              <h3><FormattedMessage id='MONETIZE' /></h3>
              <p><FormattedMessage id='MONETIZE_CAPTION' /></p>
            </div>
            <div>
              <div className='points-grid-icon'>
                <LockSVG />
              </div>
              <h3><FormattedMessage id='ACCESS_CONTROL' /></h3>
              <p><FormattedMessage id='ACCESS_CONTROL_CAPTION' /></p>
            </div>
            <div>
              <div className='points-grid-icon'>
                <OpenAISVG />
              </div>
              <h3><FormattedMessage id='AI_ASSISTED' /></h3>
              <p><FormattedMessage id='AI_ASSISTED_CAPTION' /></p>
            </div>
            <div>
              <div className='points-grid-icon'>
                <StoreSVG />
              </div>
              <h3><FormattedMessage id='GO_TO_MARKET' /></h3>
              <p><FormattedMessage id='GO_TO_MARKET_CAPTION' /></p>
            </div>
          </div>
          <div className='welcomeScreenBlockNavigation'>
            <span />
            <button onClick={() => goToStep(1)} className='cta-button'>
              <FormattedMessage id='START' />
            </button>
          </div>
        </div>
      )}
      {state.currentStep === 1 && (
        <div className='welcomeScreenBlockBody'>
          <h3>
            <FormattedMessage id='WHAT_BEST_DESCRIBES_YOUR_WORK' />
          </h3>
          <div className='grid-selection'>
            {BEST_DESCRIBES_LIST.map((option) => (
              <button onClick={() => onSelectBestDescribes(option)} className={state.best_describes === option ? 'is-active' : ''}>
                <FormattedMessage id={option} />
              </button>
            ))}
          </div>
          {state.best_describes === 'OTHER' && (
            <div className='custom-answer-input'>
              <input
                type='text'
                name='best_describes_custom'
                placeholder={intl.formatMessage({ id: 'WHAT_BEST_DESCRIBES_YOUR_WORK' })}
                onChange={onChange}
                autoFocus
              />
            </div>
          )}
          <div className='welcomeScreenBlockNavigation'>
            <button onClick={() => goToStep(0)}>
              <FormattedMessage id='BACK' />
            </button>
            <button onClick={() => goToStep(2)} disabled={!state.best_describes && !state.best_describes_custom} className='cta-button'>
              <FormattedMessage id='NEXT' />
            </button>
          </div>
        </div>
      )}
      {state.currentStep === 2 && (
        <div className='welcomeScreenBlockBody'>
          <h3>
            <FormattedMessage id='WHAT_IS_YOUR_PRIMARY_GOAL' />
          </h3>
          <div className='grid-selection'>
            {PRIMARY_GOALS.map((option) => (
              <button onClick={() => onSelectPrimaryGoal(option)} className={state.primary_goal === option ? 'is-active' : ''}>
                <FormattedMessage id={option} />
              </button>
            ))}
          </div>
          {state.primary_goal === 'OTHER' && (
            <div className='custom-answer-input'>
              <input
                type='text'
                name='primary_goal_custom'
                placeholder={intl.formatMessage({ id: 'WHAT_IS_YOUR_PRIMARY_GOAL' })}
                onChange={onChange}
                autoFocus
              />
            </div>
          )}
          <div className='welcomeScreenBlockNavigation'>
            <button onClick={() => goToStep(1)}>
              <FormattedMessage id='BACK' />
            </button>
            <button onClick={() => goToStep(3)} disabled={!state.primary_goal && !state.primary_goal_custom} className='cta-button'>
              <FormattedMessage id='NEXT' />
            </button>
          </div>
        </div>
      )}
      {state.currentStep === 3 && (
        <div className='welcomeScreenBlockBody'>
          <p className='text-align-center'>
            <FormattedMessage id='LETS_SET_UP_YOUR_PROFILE' />
          </p>
          <div className={`welcomeScreenProfile`}>
            <AvatarForm {...props} />
            <ProfileForm {...props} withButtons={false} withBio={false} />
          </div>
          <div className='welcomeScreenBlockNavigation'>
            <button onClick={() => goToStep(2)}>
              <FormattedMessage id='BACK' />
            </button>
            <button onClick={() => goToStep(4)} disabled={!state.primary_goal && !state.primary_goal_custom} className='cta-button'>
              <FormattedMessage id='NEXT' />
            </button>
          </div>
        </div>
      )}
      {state.currentStep === 4 && (
        <div className='welcomeScreenBlockBody'>
          <p>
            <FormattedMessage id='WELCOME_ONBOARDING_FINAL_MESSAGE' />
          </p>
          <p>
            <Link to={`/${workspaceList[0].slug}?novice=1`} onClick={() => onFinishOnboarding()}>
              <div className={'selectWorkspaceListItem'}>
                <WorkspaceTitle workspace={workspaceList[0]} isEnlarged />
              </div>
            </Link>
          </p>
        </div>
      )}
    </div>
  )
}
