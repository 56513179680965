import React from 'react'
import styles from './NotFound.module.scss'

const NotFound = (props) => {
  return (
    <div className={styles.notFound}>
      <h2>/{props['*']}</h2>
      <p>Page not found</p>
    </div>
  )
}

export default NotFound
