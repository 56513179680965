import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLiveQuery } from 'dexie-react-hooks'
import { useGlobalStore } from '../../../lib/global'
import './styles.scss'

export default function Usage(props) {
  const { databases } = useGlobalStore(s => ({ databases: s.databases }))
  const activePlan = props.activeWorkspace?.meta?.data?.plan
  const activeUsage = props.activeWorkspace?.meta?.data?.usage
  const db = databases[props.activeWorkspace?.id]

  const members = useLiveQuery(() => {
    if (!db) return []
    return db.members.toArray()
  }, [db], [])

  const liveProducts = useLiveQuery(() => {
    if (!db) return []
    return db.structs.filter(o => !o.is_deleted && !o.is_trashed).toArray()
  }, [db], [])

  return (
    <div className='usage-list'>
      <div>
        <h4>
          <FormattedMessage id='PRODUCTS' />
        </h4>
        <div>
          {liveProducts.length} <span className='limit-text'>/ {activePlan?.max_products}</span>
        </div>
      </div>
      <div>
        <h4>
          <FormattedMessage id='WORKSPACE_MEMBERS' />
        </h4>
        <div>
          {members.length} <span className='limit-text'>/ {activePlan?.max_members}</span>
        </div>
      </div>
      <div>
        <h4>
          <FormattedMessage id='ROYALTIES' />
        </h4>
        <div>
          {100 - activePlan?.fee_rate}%
        </div>
      </div>
      <div>
        <h4>
          <FormattedMessage id='AI_REQUESTS' />
          <small>(<FormattedMessage id='RESETS_MONTHLY' />)</small>
        </h4>
        <div>
          {activeUsage?.ai_requests || 0} <span className='limit-text'>/ {activePlan?.max_ai_requests ?? 300}</span>
        </div>
      </div>
    </div>
  )
}
