import React from 'react'
import qs from 'query-string'
import { FormattedMessage, useIntl } from 'react-intl'
import * as config from 'shared/config'
import { navigate } from '@reach/router'
import PlanSwitch from '../PlanSwitch'
import { useGlobalStore } from '../../lib/global'
import { useWorkspaceSubscription } from '../../hooks/useWorkspaceSubscription'
import styles from './index.module.scss'

import PaymentMethodSelect from './PaymentMethodSelect'
import Confirmation from './Confirmation'

const defaultState = {
  currentStep: 1,
  activeSteps: {
    '1': true,
    '2': true
  },
  selectedPlan: null,
  selectedPaymentMethod: null,
  selectedPeriod: null,
  isSubmitting: false,
  isSuccess: false,
}

export default function WorkspaceOnboarding(props) {
  const intl = useIntl()
  const { auth, location } = useGlobalStore(s => s)
  const [state, setState] = React.useState(defaultState)

  const onGoToStep = React.useCallback((step) => ev => {
    setState(prev => ({
      ...prev,
      currentStep: step,
      activeSteps: {
        ...prev.activeSteps,
        [step]: true
      }
    }))
  })

  const onPlanSelect = React.useCallback((plan, period) => {
    setState(prev => ({
      ...prev,
      selectedPlan: plan,
      selectedPeriod: period,
      currentStep: 2,
      activeSteps: {
        ...prev.activeSteps,
        2: true
      }
    }))
  })

  const onPaymentMethodSelect = React.useCallback((paymentMethod) => {
    setState(prev => ({
      ...prev,
      selectedPaymentMethod: paymentMethod,
    }))
  })

  const onReset = React.useCallback(() => {
    setState(defaultState)
  })

  const onClose = React.useCallback(() => {
    navigate(`${props.location.pathname}?${qs.stringify({
      ...props.location.query || {},
      activate: undefined
    })}`)
  })

  const onFinish = React.useCallback(async () => {
    setState(prev => ({
      ...prev,
      isSubmitting: true,
    }))

    try {
      // finalize, mark workspace onboarded
      const res = await fetch(`${config.API_URL}/v1/workspaces/${props.activeWorkspace.id}/activate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        },
        body: JSON.stringify({
          locale: intl.locale,
          payment_method: state.selectedPaymentMethod,
          period: state.selectedPeriod,
          plan: state.selectedPlan,
        }),
      })
      const json = await res.json()

      setState(prev => ({
        ...prev,
        isSuccess: json.success
      }))

      navigate(`${location.pathname}?activated=1`)

      // kick congrats screen
      // option 1: welcome tour
    } catch (err) {
      console.log(err)
    }
  })

  React.useEffect(() => {
    return () => {
      onReset()
    }
  }, [props.activeWorkspace?.id, props.location.query?.activate])

  const {
    isActivated,
    trialEnds,
    isTrialLapsed,
    isOnTrial,
    maxProducts,
  } = useWorkspaceSubscription({ activeWorkspace: props.activeWorkspace })

  if (!props.activeWorkspace?.id || props.activeWorkspace.id === 'demo') return null
  const isActivatedWorkspace = !!props.activeWorkspace?.meta?.data?.plan

  const hasLoaded = typeof props.activeWorkspace?.meta?.data !== 'undefined'
  if (!hasLoaded) return null

  if (!isTrialLapsed) {
    if (isActivatedWorkspace) {
      // for activated, only show this screen if requested
      if (props.location.query?.activate !== '1') return null
      if (state.isSuccess) return null
    } else {
      // for not activated workspace we have to force plan selection
    }
  }

  // TODO: check if subscription is active
  // TODO: check if it’s expired
  // if (props.activeWorkspace?.meta?.data?.subscription) return null

  return (
    <>
      <div className={styles.onboardingOverlay} onClick={onClose} />
      <div className={styles.onboardingWrapper}>
        <div className={styles.onboardingContent}>
          <div className={styles.onboardingHead}>
            <div className={styles.onboardingProgress}>
              <button
                onClick={onGoToStep(1)}
                disabled={!state.activeSteps[1]}
                className={state.currentStep === 1 ? 'is-active' : ''}
              >
                <FormattedMessage id='CHOOSE_PLAN' />
              </button>
              <button
                onClick={onGoToStep(2)}
                disabled={!state.activeSteps[2]}
                className={state.currentStep === 2 ? 'is-active' : ''}
              >
                <FormattedMessage id='PAYMENT_METHOD' />
              </button>
              <button
                onClick={onGoToStep(3)}
                disabled={!state.activeSteps[3]}
                className={state.currentStep === 3 ? 'is-active' : ''}
              >
                <FormattedMessage id='CONFIRMATION' />
              </button>
            </div>
          </div>
          <div className={styles.onboardingBody}>
            {state.currentStep === 1 && (
              <PlanSwitch
                {...props}
                onPlanSelect={onPlanSelect}
              />
            )}
            {state.currentStep === 2 && (
              <PaymentMethodSelect
                {...props}
                state={state}
                onGoToStep={onGoToStep}
                onPaymentMethodSelect={onPaymentMethodSelect}
              />
            )}
            {state.currentStep === 3 && (
              <Confirmation
                {...props}
                state={state}
                onFinish={onFinish}
                isActivatedWorkspace={isActivatedWorkspace}
              />
            )}
          </div>
          <div className={styles.onboardingFooter}>
            <div>
              Prodmake LLC
            </div>
            <div>
              2023
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
