import React from 'react'
import { Link } from '@reach/router'
import LogoSVG from 'shared/icons/prodmake-icon.svg'
import styles from './index.module.scss'

export default function TopbarMenu(props) {
  const hasUserOnboarded = props?.user?.data?.has_onboarded
  if (!hasUserOnboarded) return null

  return (
    <div className={styles.topbarMenu}>
      <Link to='/' className='logo-link topbar-logo-link'>
        <LogoSVG />
      </Link>
    </div>
  )
}
