import Cookies from 'js-cookie'
import getUserByToken from 'shared/methods/getUserByToken'
import trace from 'shared/utils/trace'
import { useGlobalStore } from './useGlobalStore'

function getToken() {
  // const ls = localStorage.getItem('auth_token')
  const c = Cookies.get('auth_token')
  return c
}

export async function restoreUserSession({ privKey: _privKey } = {}) {
  const state = useGlobalStore.getState()
  const privKey = _privKey || state.privKey // keep key in memory via zustand

  // initial load without sw means we’re online
  const token = getToken()

  if (!privKey && token) {
    try {
      const { auth, user } = await getUserByToken(token)
      if (auth) {
        state.login(auth)
      }
      trace('online auth success.', auth, user)

    } catch (err) {
      trace('online auth fail.', err)
    }
  }

  // if (privKey) {
  //   trace('priv-key based')
  //   await state.accountDb.open()
  //   const users = await state.accountDb.accounts.toCollection().toArray()
  //   const user = users[0]

  //   if (token && user?.id) {
  //     restored.auth = {
  //       isAuthenticated: true,
  //       token,
  //       user
  //     }
  //     setToken(token)
  //     useGlobalStore.setState(restored)
  //   }
  // }

  state.setHasFetchedUser(true)
}
