import React from 'react'
import { FormattedMessage } from 'react-intl'
import SelectedPlan from '../../SelectedPlan'
import SelectedPaymentMethod from '../../SelectedPaymentMethod'

export default function WorkspaceOnboardingConfirmation(props) {
  return (
    <div className='workspace-plan-step'>
      <div className='workspace-plan-step-head'>
        <h2>
          <FormattedMessage id='CONFIRMATION' />
        </h2>
      </div>
      <div className='workspace-plan-step-body'>
        <SelectedPlan {...props} />
        <SelectedPaymentMethod {...props} />
      </div>
      <div className='workspace-plan-step-footer'>
        <span className='cta-button-caption'>
          {!props.isActivatedWorkspace && <FormattedMessage id='YOU_WONT_BE_CHARGED_NOW' />}
          {props.isActivatedWorkspace && <FormattedMessage id='YOU_WILL_BE_IMMEDIATELY_CHARGED' />}
        </span>
        <div>
          <button
            className='cta-button'
            onClick={props.onFinish}
            disabled={props.state.isSubmitting || props.state.isSuccess}
          >
            {props.state.isSubmitting && <FormattedMessage id='LOADING' />}
            {!props.state.isSubmitting && props.isActivatedWorkspace && <FormattedMessage id='ACTIVATE_PLAN' />}
            {!props.state.isSubmitting && !props.isActivatedWorkspace && <FormattedMessage id='START_PLAN_TRIAL' />}
          </button>
        </div>
      </div>
    </div>
  )
}
