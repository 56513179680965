import React from 'react'
import { FormattedMessage } from 'react-intl'
import BackSVG from 'shared/icons/feather/arrow-left.svg'
import NextSVG from 'shared/icons/feather/arrow-right.svg'
import BillingSetup from '../../BillingSetup'

export default function PaymentMethodSelect({
  state,
  onPaymentMethodSelect,
  onGoToStep
}) {
  return (
    <div className='workspace-plan-step'>
      <div className='workspace-plan-step-head'>
        <div>
          <button
            onClick={onGoToStep(state.currentStep - 1)}
            className='back-button'
          >
            <BackSVG />
            <FormattedMessage id='BACK' />
          </button>
          <h2 className='title'>
            <FormattedMessage id='CHOOSE_PAYMENT_METHOD' />
          </h2>
        </div>
        <div>
          <button
            onClick={onGoToStep(3)}
            className='next-button'
            disabled={!state.selectedPaymentMethod}
          >
            <FormattedMessage id='NEXT' />
            <NextSVG />
          </button>
        </div>
      </div>
      <div className='workspace-plan-step-body'>
        <BillingSetup
          state={state}
          mode='select'
          onPaymentMethodSelect={onPaymentMethodSelect}
        />
      </div>
    </div>
  )
}
