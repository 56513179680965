import produce from 'immer'
import Cookie from 'js-cookie'
import create from 'zustand'

const defaultFields = {
  boomerang: 0,
  auth: {
    user: null,
    token: '',
    loggedOut: null
  },
}

const store = create(set => ({
  ...defaultFields,
  setAuth: auth => set(produce(state => {
    if (auth.user) {
      state.auth = auth
    } else {
      Cookie.remove('auth_token', { path: '/' })
      state.auth = defaultFields.auth
      state.auth.loggedOut = Math.random()
    }
  }))
}))

export default store
