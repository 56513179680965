export default {
  code: 'en',
  CURRENT_LANGUAGE: 'English',

  en: 'English',
  ja: 'Japanese',
  ko: 'Korean',
  fr: 'French',
  ru: 'Russian',
  de: 'German',
  kk: 'Kazakh',

  // ui
  FULLSCREEN_ENTER: 'Full screen',
  FULLSCREEN_EXIT: 'Exit full screen',
  CLOSE: 'Close',
  ADD: 'Add',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  REMOVE: 'Remove',
  NAME: 'Name',
  NO_NAVIGATION_SET: 'Navigation is empty.',
  NO_COMMERCE_SET: 'Commerce is off.',

  SETTINGS: 'Settings',
  DASHBOARD: 'Dashboard',
  PRICE: 'Price',
  WELCOME: 'Welcome',
  WELCOME_BACK: 'Welcome back',

  // context
  OVERVIEW: 'Overview',
  EDITOR: 'Editor',
  CONTENT: 'Content',
  GENERAL: 'General',
  NAVIGATION: 'Navigation',
  DESIGN: 'Design',
  STYLE: 'Style',
  COVER: 'Cover',
  MARKETING: 'Marketing',
  AUDIENCE: 'Audience',
  SOCIAL: 'Social Networks',
  COMMERCE: 'Commerce',
  OFFERINGS: 'Offerings',
  ADVANCED: 'Advanced',
  COLLECTIONS: 'Collections',
  DOMAINS: 'Domains',
  EXPORT: 'Export',
  LIVE: 'LIVE',
  VERSIONS: 'Versions',
  ANALYTICS: 'Analytics',
  COMING_SOON: 'Coming Soon',
  MARKETS: 'Markets',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  SHOW_PLAIN_COVER: 'Show plain cover',
  SHOW_COMING_SOON: 'Show "Coming Soon"',
  TAGLINES: 'Taglines',
  TAGLINE: 'Tagline',
  DANGER_ZONE: 'Danger Zone',
  TRASH: 'Trash',
  RESTORE: 'Restore',
  DELETE_FOREVER: 'Delete forever',
  MENU: 'Menu',
  TERMS: 'Terms',
  REFUND_POLICY: 'Refund Policy',
  PRIVACY_POLICY: 'Privacy Policy',
  DOWNLOADS: 'Downloads',
  FEEDBACK: 'Feedback',
  HELP: 'Help',
  UNTITLED: 'Untitled',
  PRODUCTS: 'Products',
  LOGIN: 'Log in',
  LOGOUT: 'Log out',
  PAYOUTS: 'Payouts',
  CUSTOMERS: 'Customers',
  THEMES: 'Themes',
  LOADING: 'Loading',
  PRODMAKE: 'Prodmake',

  MADE_WITH: 'Made with',
  LANGUAGES: 'Languages',
  LANGUAGE: 'Language',

  ALREADY_PURCHASED: 'Already have access',
  GET_FULL_ACCESS: 'Get full access',

  USD: 'US Dollar',
  BALANCE: 'Balance',
  TOTAL_BALANCE: 'Total Balance',
  PROFIT: 'Profit',
  REVENUE: 'Revenue',
  TURNOVER: 'Turnover',
  TRANSACTIONS: 'Transactions',
  DATE: 'Date',
  CURRENCY: 'Currency',
  TRANSACTION_CURRENCY: 'Transaction Currency',
  MONEY_IN: 'Money In',
  MONEY_OUT: 'Money Out',
  FEE: 'Fee',
  STATUS: 'Status',
  EMAIL: 'Email',
  PRODUCT: 'Product',
  VERSION: 'Version',
  OFFERING: 'Offering',
  WITHDRAW: 'Withdraw',
  UPGRADE_TO_PRO: 'Upgrade to Pro',
  QUICK_ACCESS: 'Quick Access',
  GETTING_STARTED: 'Getting Started',
  GETTING_TRACTION: 'Gaining Traction',
  SUGGESTED_FOR_YOU: 'Suggested for You',

  WORKSPACE_SETTINGS: 'Workspace Settings',
  CREATE_WORKSPACE: 'Create Workspace',
  JOIN_WORKSPACE: 'Join Workspace',
  GO_TO_STORE: 'Go to Store',

  RESTORE_PURCHASE: 'Restore Purchase',
  PRODUCT_VIEWS: 'Product Views',
  SALES: 'Sales',
  CONVERSION_RATE: 'Conversion Rate',
  PRODMAKE_PRODUCT_TITLE: 'Prodmake Product',
  PRODMAKE_DESCRIPTION: 'Make products with Prodmake',
  SECURE_ACCESS_LINK: 'Secure access link to your email',
  ALREADY_PURCHASED_SUBMITTED: 'Successfully submitted. Check your mail.',
  ERROR: 'Error',
  MONEY_BACK_GUARANTEE_POINT: 'Money-back guarantee (5 days)',
  INSTANT_ACCESS_POINT: 'Instant access: web, .pdf, .epub',
  FUTURE_UPDATES_POINT: 'Future updates',
  PARTNER_PERKS_POINT: 'Partner perks worth $800',
  LAST_UPDATED_POINT: 'Last updated',
  GET_FOR_FREE_SUBMITTED: 'You now have access to the free version.',
  GET_FOR_FREE_NOW: 'Get for free now',
  WIP: 'Work in Progress',
  POWERED_BY_PRODMAKE: 'Powered by Prodmake',
  RAW_HTML: 'HTML',
  EPUB: 'Epub',
  SHORT_ID: 'Short ID',
  NEW: 'New',
  NO_PRODUCT_PUBLISHED: 'Product has not been published',
  PREVIEW: 'Preview',
  VIEWING: 'Viewing',
  OPEN_LIVE: 'Open in a new window',
  BACK_TO_WEB: 'Back to full version',
  NO_ITEMS_TO_DISPLAY_RAW: 'Nothing to show',
  BACK_TO_HOMEPAGE: 'Back to homepage',
  ACCESS_NOW_FOR_FREE: 'Try for free',
  GET_ACCESS_NOW: 'Get access',
  BE_FIRST_TO_ACCESS: 'Be the first to access',
  CHANGE_STRUCT_COVER: 'Change cover',
  CHANGE_LOGO: 'Change logo',
  CHANGE_AVATAR: 'Change avatar',
  CATEGORY: 'Category',
  MANUALS: 'Manuals',
  BOOKS: 'Books',
  GUIDES: 'Guides',
  PAGES: 'Pages',
  LAST_RELEASE: 'Last release',
  AGE_RATING: 'Age Rating',

  SERIF: 'Serif',
  SANS_SERIF: 'Sans-Serif',
  MONOSPACE: 'Monospace',
  FULL_WIDTH: 'Full Width',
  NARROW: 'Narrow',
  SIMPLE: 'Simple',
  BLOCK: 'Block',
  MINIMAL: 'Minimal',
  FONTS: 'Fonts',
  CHAPTERS: 'Chapters',
  FOOTER: 'Footer',
  REQUEST_ACCESS: 'Request Access',
  NOW: 'Now',
  BUY: 'Buy',
  STAY_TUNED: 'Stay Tuned',
  VIEWING_LIMITED_VERSION: 'You are viewing a limited version.',
  VIEWING_LIMITED_VERSION_OBFUSCATED: 'You are viewing a demo version.',
  TO_SEE_CONTENT: 'For full access',
  ABOUT_AUTHOR: 'About the Author',
  RENDERING: 'Rendering',
  SERIES_NAME: 'Series Name',
  BOOK_NUMBER: 'Book Number',
  PUBLISHER: 'Publisher',
  DEDICATION: 'Dedication',
  MESSAGE: 'Message',
  EBOOK_ISBN: 'Ebook ISBN',
  OPTIONAL: 'Optional',
  AUTHOR: 'Author',
  TITLE_PAGE: 'Title Page',
  WEB: 'Web',
  EBOOK: 'Ebook',
  PDF: 'PDF',
  SHOW_PUBLICATION_DATE: 'Show Publication Date',
  SHOW_RELEASE_DATE: 'Show Release Date',
  SHOW_VERSION: 'Show Version',
  DOWNLOAD: 'Download',
  REGENERATE: 'Regenerate',
  SHOW_AUTHOR_INFO: 'Show Author Info',
  SHOW_WORKSPACE_INFO: 'Show Workspace Info',
  BIO: 'Bio',
  PICTURE_URL: 'Picture URL',
  OVERRIDE: 'Override',
  CONTRIBUTORS: 'Contributors',
  IMAGE: 'Image',
  SHOW: 'Show',
  COLLECTION: 'Collection',
  LINKS: 'Links',
  REMOVE_SECTION: 'Remove Section',
  INCLUSION_AND_ACCESS: 'Includes',
  FULL_ACCESS: 'Full Access',
  DELETE_ITEM: 'Delete',
  COLLECTION_TITLE: 'Collection Title',
  SLUG: 'Slug',
  REMOVE_COLLECTION: 'Remove Collection',
  INCLUDES_COLLECTION_ITEMS: 'Includes {count}/{total} items from the collection: ',
  WITH_FULL_ACCESS: 'With Full Access',
  VISIBLE_ITEMS: '{count}/{total} with full access',
  OFFERING_AVAILABLE_FOR_FREE: 'This offering will be available for free.',
  CURRENCY_AMOUNT_TERMS: 'Minimum amount in {name} currency is {symbol}{min_amount}, maximum is {symbol}{max_amount}.',
  PREVIEW_VERSION: 'Preview version',
  TOC: 'Table of Contents',
  DOMAIN_SETUP_MESSAGE: 'You’ll have to point your domain to the *.prodmake.com using CNAME record.',
  VALIDATE: 'Validate',
  REMOVE_DOMAIN: 'Remove',
  WORDS: 'Word Count',
  ORIGINALLY_PUBLISHED: 'Originally published',
  LAST_UPDATED: 'Last updated',
  ORIGINALLY_PUBLISHED_AT: 'Originally published on {date}',
  LAST_UPDATED_AT: 'Last updated at {date}',
  LOCALIZATION: 'Localization',
  SELECT_AT_LEAST_ONE_LANGUAGE: 'Select at least one language',
  THIS_COPY_BELONGS_TO: 'This copy v{version} belongs to {email}',
  REQUEST: 'Request',
  PERKS_WORTH: 'perks worth ~$800',
  PAY_AMOUNT: 'Pay {amount}',
  PAGE_LEFT_BLANK: 'This page is left blank',
  PRIMARY_LANGUAGE: 'Primary language',
  SUPPORTED_LANGUAGES: 'Supported languages',
  SUCCESSFUL_PAYMENT_MESSAGE: 'Payment was successful.',
  PAYMENT_IS_PROCESSING_MESSAGE: 'Payment is being processed.',
  PAYMENT_UNSUCCESSFUL_MESSAGE: 'Payment was unsuccessful. Check the payment method.',
  SOMETHING_WENT_WRONG: 'Something went wrong. Try again.',
  INCLUDED_IN: 'Included in',
  DETAILS: 'Details',
  SUBTITLE: 'Subtitle',
  PAGE_NOT_FOUND: 'Page not found',
  THANK_YOU: 'Thank you',
  START: 'Start',
  PRODMAKE_PITCH_TITLE: 'Make products with Prodmake',
  PRODMAKE_PITCH_CTA: '{link} is an editor for truly global products',
  TRY_PRODMAKE: 'Start now',
  SCROLL_PURCHASE: '“Purchase” on scroll',
  ITEM_INCLUDED_IN_OFFERINGS: 'Item included in: ',
  VISIBLE: 'Visible',
  HIDDEN: 'Hidden',
  PROPERTIES: 'Properties',
  BANNER_CTA: 'Banner CTA (call-to-action)',
  PURCHASE_FOR_FULL_ACCESS: 'Purchase for full access',
  ACCESS: 'Access',
  SAMPLE: 'Sample',
  VISIBLE_OBFUSCATED: 'Visible obfuscated',
  ALL_LANGUAGES: 'All languages',
  PURCHASED_ON: 'Purchased on',
  ITEM_NOT_PUBLIC: 'Item hasn’t been published yet',
  SUCCESSFUL_FREEBIE_MESSAGE: 'Access was granted',
  ACCESSED_ON: 'Accessed on',
  SETTLED: 'Settled',
  UNIVERSAL: 'Universal',
  ALL: 'All',
  TRANSLATE: 'Translate',
  TRANSLATE_TO: 'Translate to',
  UNIQUE: 'Unique',
  'N/A': 'N/A',
  WRITING: 'Writing',
  TASKS: 'Tasks',
  PAGE_LIST: 'Pages',
  PURCHASE_INFO: 'Purchase Info',
  HERO: 'Hero (Banner)',
  SCROLL_PROGRESS: 'Scroll Progress',
  HOME_SCROLL: 'Home Scroll',
  NAV: 'Nav',
  PRODUCT_PROPERTIES: 'Product Properties',
  PRODUCT_AUTHOR: 'Author Info',
  BLUEPRINT: 'Blueprint',
  BLOCKS: 'Blocks',
  READY_TO_USE: 'Ready-to-use',
  WEB_BOOK: 'Web-Book',
  COVER_RIBBON: 'Cover Ribbon',
  COVER_INTERACTION: 'Cover Interaction',
  STRUCTURE: 'Structure',
  WIDTH: 'Width',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  DISTRIBUTION: 'Distribution',
  AUTHOR_BIO: 'About author',
  EXPORT_EPUB: 'Export as Epub',
  EXPORT_PDF: 'Export as PDF',
  VIEW_JSON: 'View as JSON',
  CHOOSE_TYPE: 'Choose type',
  UNIVERSAL_BOOK: 'Universal book',
  PLAYLIST_COURSE: 'Playlist course',
  PERSONAL_WEBSITE: 'Personal website',
  IMPORT: 'Import',
  BILLING: 'Billing',
  PAYOUT: 'Payout',
  PLANS: 'Plans',
  NOTIFICATIONS: 'Notifications',
  PREFERENCES: 'Preferences',
  PROFILE: 'Profile',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  ACCOUNT: 'Account',
  FULLNAME: 'Full name',
  PAYMENT_CARDS: 'Payment cards',
  NO_PAYMENT_METHODS_FOUND: 'No payment methods found',
  CARD_ADDED_SUCCESSFULLY: 'Card added successfully',
  ADD_CARD: 'Add card',
  GRIND: 'Grind',
  YEAR_ONE: 'Year One',
  PRO: 'Pro',
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  CHOOSE_PLAN: 'Choose plan',
  PAYMENT_METHOD: 'Payment method',
  CONFIRMATION: 'Confirmation',
  SELECT: 'Select',
  SELECT_PLAN: 'Select “{planName}”',
  NEXT: 'Next',
  YOUR_CURRENT_FREE_TRIAL_PLAN: 'Your current free trial plan of “{planName} {planPeriod}” ends on {trialUntil}.',
  YOUR_CURRENT_PAID_PLAN: 'Your current subscription plan of “{planName} {planPeriod}” renews on {paidUntil}.',
  CURRENT_PLAN: 'Current plan',
  INBOX: 'Inbox',
  FINISH: 'Finish',
  CHOOSE_PAYMENT_METHOD: 'Choose payment method',
  ACTIVATE_SELECTED_PLAN: 'Activate selected plan',
  BACK_TO_CARD_LIST: 'Back to card list',
  LAST_PRODUCT_VIEWS: 'Last product views',
  NO_VIEWS_YET: 'No views yet',
  FREE_TRIAL_ENDS: 'Free trial ends {inDays}',
  WORKSPACE_NAME: 'Workspace name',
  NEW_WORKSPACE: 'New workspace',
  WORKSPACE_MEMBERS: 'Workspace members',
  INVITE: 'Invite',
  WORKSPACE_INVITE: 'Invite to workspace',
  NO_MEMBERS_FOUND: 'No members found',
  UPGRADE_NOW: 'Upgrade now',
  NOVICE: 'Novice',
  ESTABLISHED: 'Established',
  SEE_INVOICES: 'See invoices',
  CANCEL_SUBSCRIPTION: 'Cancel subscription',
  BACK: 'Back',
  MONTHLY_RATE: 'Monthly rate',
  YEARLY_RATE: 'Yearly rate',
  PLAN: 'Plan',
  SUBSCRIPTION_ACTIVATED_1: 'Subscription has been successfully activated. It’s paid until {date}.',
  SUBSCRIPTION_ACTIVATED_2: 'Credit card payments will appear on your statement as PRODMAKE (or STRIPE while charge is pending).',
  SUBSCRIPTION_ACTIVATED_3: 'You’ll receive a confirmation via email. Thank you for choosing Prodmake!',
  SUCCESSFULLY_ACTIVATED: 'Successfully activated',
  CONTINUE: 'Continue',
  MONTHLY_PLAN_RATE_PER_SEAT: '{rate} per user per month',
  MONTHLY_PLAN_RATE: '{rate} per month',
  YEARLY_PLAN_RATE_PER_SEAT: '{rate} per user per year',
  YEARLY_PLAN_RATE: '{rate} per year',
  TRIAL_PERIOD: 'Trial period',
  N_DAYS: '{count} days',
  YOU_WONT_BE_CHARGED_NOW: 'You will not be charged now',
  START_PLAN_TRIAL: 'Start trial now',
  NO_PURCHASES_YET: 'No purchases yet',
  UPCOMING_PAYOUTS: 'Upcoming payouts',
  UPDATE_PERSONAL_DETAILS: 'Update personal details',
  UPDATE_WORKSPACE_DETAILS: 'Update workspace details',
  TRANSLATE_AUTOMATICALLY: 'Translate automatically',
  GET_NEW_PRODUCT_IDEAS: 'Get new product ideas',
  MAKE_YOUR_FIRST_SALE: 'Make your first sale',
  GET_YOUR_FIRST_PAYOUT: 'Get your first payout',
  SET_UP_A_PROFILE: 'Set up a profile',
  SET_UP_A_WORKSPACE: 'Set up a workspace',
  SET_UP_A_PAYMENT_METHOD: 'Set up a payment method',
  SET_UP_PAYOUT: 'Set up payout',
  CREATE_YOUR_FIRST_PRODUCT: 'Create your first product',
  PUBLISH_YOUR_FIRST_PRODUCT: 'Publish you first product',
  ONBOARDED: 'Onboarded workspace',
  YOU_DONT_HAVE_UPCOMING_PAYOUTS: 'You don’t have any upcoming payouts',
  LAST_TRANSACTIONS: 'Last transactions',
  VIEW_ALL: 'View all',
  IDEAS: 'Ideas',
  PARAPHRASE: 'Paraphrase',
  STATS: 'Stats',
  CREATED: 'Created',
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  AI_STRUCT_TRANSLATION: 'Translate',
  AI_STRUCT_REVISE: 'Revise',
  AI_STRUCT_CHECK_GRAMMAR: 'Correct Grammar',
  AI_BOOK_GENERATION: 'Book Generation',
  AI_WRITE_DRAFT: 'Write Draft',
  AI: 'AI',
  VIEW: 'View',
  ACCEPT: 'Accept',
  GENERATE: 'Generate',
  DONT_HAVE_AN_ACCOUNT: 'Don’t have an account?',
  JOIN_PRODMAKE: 'Join Prodmake',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  INVITATION_CODE: 'Invitation code',
  PASSWORD: 'Password',
  LOGGED_IN_AS: 'Logged in as {email}',
  SELECT_OR_CREATE: 'Select or create',
  NEW_TASK: 'New Task',
  SUBMIT: 'Submit',
  FOR_WHOM: 'For whom',
  COMPLETED_IN: 'Completed in',
  ASSIGNEE: 'Assignee',
  COST: 'Cost',
  COST_INCLUDED: 'Included',
  PAGE_COUNT: '{count} pages',
  DELETE: 'Delete',
  HAS_NOT_BEEN_COMPLETED: 'Has not been completed yet',
  REPEAT_TASK: 'Repeat task',
  SECOND_COUNT: '{count} seconds',
  TASK: 'Task',
  INTO_IDEA: 'Into idea',
  INTO_BOOK: 'Into book',
  INTO_PRODUCT: 'Into product',
  COLLECTION_COUNT: 'Collections ({count})',
  OFFERING_COUNT: 'Offerings ({count})',
  NEW_VERSION: 'New version',
  SUBMIT_NEW_VERSION: 'Submit for review',
  IS_PUBLIC: 'Public access',
  IS_IN_STORE: 'Displayed in Prodmake store',
  ACCESS_FOR_PREVIOUS_CUSTOMERS: 'Accessible by customers who own previous versions',
  LEARN_MORE: 'Learn more',
  ACCESS_LINKS_SENT: 'Access links have been sent to your email.',
  TRIAL_ENDED: 'Trial Ended',
  BACK_TO_ALL: 'Back to all',
  WORKSPACE_EXPLAIN_CAPTION: 'Here is where you’ll see your workspaces. To start making products {link}.',
  PROVIDE_FEEDBACK: 'Provide feedback',
  USAGE: 'Usage',
  SELECT_CATEGORY: 'Select category',
  SELECT_AGE_RATING: 'Select age rating',
  OPEN: 'Open',
  SKIP: 'Skip',
  ACTIVATE_WORKSPACE: 'Activate workspace',
  MARK_AS_DRAFT: 'Mark as Draft',
  DRAFT_PREVIEW_EARLY_ACCESS: 'This is a draft preview for early access. The product is not in final form.',
  TYPE: 'Type',
  BLOCK_BASED: 'Block-based',
  TEXT_BASED: 'Text-based',
  BRAINSTORM: 'Brainstorm',
  WITH_CORRECT_GRAMMAR: 'With correct grammar',
  IN_DIFF_WORDS: 'In different words',
  IN_KINDER_TONE: 'In kinder tone',
  IN_PROFESSIONAL_TONE: 'In professional tone',
  IN_ASSERTIVE_TONE: 'In assertive tone',
  IN_FRIENDLY_TONE: 'In friendly tone',
  IN_EXPERT_TONE: 'In expert tone',
  WITHOUT_ADJECTIVES: 'Without adjectives',
  NO_FLUFF: 'No fluff',
  REVISE: 'Revise',
  CREATE_TASK: 'Create task',
  IN_PROGRESS: 'In-progress',
  INTEGRATE_TRANSLATION: 'Integrate translation',
  MECHANICAL_TURK: 'Mechanical Turk',
  READY: 'Ready',
  CHOOSE_TASK_TYPE: 'Choose task type',
  INTEGRATE: 'Integrate',
  START_PLAN: 'Start plan',
  USED: 'Used',
  UNUSED: 'Unused',
  GO_TO_SOURCE: 'Go to source',
  DRAFTS: 'Drafts',
  DUPLICATE: 'Duplicate',
  YOUR_TRIAL_PLAN_LAPSED: 'Your free trial plan of “{planName} {planPeriod}” has ended on {trialUntil}. Now’s time to activate your subscription.',
  YOU_WILL_BE_IMMEDIATELY_CHARGED: 'You’ll be immediately charged.',
  ACTIVATE_PLAN: 'Activate plan',
  SUBSCRIPTION_PLAN_IS_ACTIVATED: 'Subscription plan is active',
  TRIAL_PLAN_IS_ACTIVATED: 'Trial plan is active',
  TRIAL_PLAN_ACTIVATED_DETAIL: 'Your free trial of “{planName}” plan will end {trialEnds}.',
  PAID_PLAN_ACTIVATED_DETAIL: 'Your “{planName} ({planPeriod})” plan is activated.',
  PAID_PLAN_WILL_RENEW: 'It’ll renew on {paidUntil}.',
  AI_REQUESTS: 'AI requests',
  ROYALTIES: 'Royalties',
  RESETS_MONTHLY: 'Resets on every billing period',
  SOURCE: 'Source',
  START_PRODUCT_TOUR: 'Start Product Tour',
  WELCOME_TO_PRODMAKE: 'Welcome to Prodmake!',
  WELCOME_TO_PRODMAKE_CAPTION: 'The all-in-one editor for self-publishing.',
  SELECT_A_WORKSPACE_FIRST: 'Select a workspace first',
  SELECT_A_WORKSPACE_FIRST_CAPTION: 'Let’s check out your workspace.',
  YOUR_WORKSPACE: 'Your Workspace',
  YOUR_WORKSPACE_CAPTION: 'This is your workspace where you do everything from creating a product and working on a new chapter to publishing new versions, exporting to Epub and PDF, and analyzing sales.',
  WORKSPACE_NAVIGATION: 'Workspace Navigation',
  WORKSPACE_NAVIGATION_CAPTION: 'This is how you get around.',
  WORKSPACE_OVERVIEW: 'Workspace Overview',
  WORKSPACE_OVERVIEW_CAPTION: 'Here you can see how your workspace is doing.',
  AT_GLANCE: 'At a Glance',
  AT_GLANCE_TOUR_CAPTION: 'Here you have key indicators of your business.',
  QUICK_ACTIONS: 'Quick Actions',
  QUICK_ACTIONS_CAPTION: 'Here you can find helpful actions.',
  PRODMAKE_EDITOR: 'Prodmake Editor',
  PRODMAKE_EDITOR_CAPTION: 'Let’s see how you manage a product.',
  PRODUCT_PAGE: 'Product Page',
  PRODUCT_PAGE_CAPTION: 'This is a product page where you see everything with an always-on preview in multiple formats.',
  NAVIGATING_PRODUCT: 'Navigating the Product',
  NAVIGATING_PRODUCT_CAPTION: 'This is how you switch between different sections of a product.',
  PRODUCT_PREVIEW_CAPTION: 'Here you have a product preview for Web, Epub, PDF.',
  PREVIEW_ACTIONS_CAPTION: 'Let’s try toggling this.',
  PRODUCT_PREVIEW_EPUB: 'Digital Book format',
  PRODUCT_PREVIEW_EPUB_CAPTION: 'Epub works for this example (a format used by Amazon Kindle and other readers).',
  PRODUCT_DOWNLOAD_EPUB: 'Downloadable in Epub and PDF',
  PRODUCT_DOWNLOAD_EPUB_CAPTION: 'It takes a moment to render (as it’s done on your device). Should be ready for download momentarily.',
  PRODUCT_TOUR_EXPORT: 'Export and use elsewhere',
  PRODUCT_TOUR_EXPORT_CAPTION: 'Epub was generated from the content we have in the editor. It can be used on Kindle, Apple iBooks and other devices.',
  PRODUCT_PREVIEW_WEB_CAPTION: 'Now let’s focus on the UI for editing the contents.',
  PRODUCT_GENERAL_VIEW: 'General Settings',
  PRODUCT_GENERAL_VIEW_CAPTION: 'Here we have general product information like title, taglines and language support.',
  PRODUCT_GENERAL_VIEW_2: 'Entered data is in the preview right away.',
  PRODUCT_EDITOR_CAPTION: 'Editor is how you build content for your product. It consists of collections and collections items, e.g. Chapters and Pages.',
  CONTENT_STATS_TOUR_CAPTION: 'Here you can see stats for the content.',
  COLLECTION_TOUR_CAPTION: 'Here is a collection. You can have as many as you need.',
  COLLECTION_VIEW_TOUR_CAPTION: 'Here is a list of collection items.',
  COLLECTION_ITEM_TOUR_CAPTION: 'Let’s see a collection item.',
  EDITOR_TOUR_CAPTION: 'This is an editor where you do the magic.',
  PRODUCT_VIEW_2_TOUR_CAPTION: 'Let’s return to product view.',
  PRODUCT_OFFERINGS_TOUR_CAPTION: 'Let’s see the offerings.',
  OFFERINGS_TOUR_CAPTION: 'Here you control the offerings. Price can be mapped to currency and language.',
  PRODUCT_ACCESS_TOUR_CAPTION: 'Let’s see how you can control access to the offerings.',
  ACCESS_TOUR_CAPTION: 'Here you can map access to collection items for each offering, be it a free sample, paid, or with obfuscated visibility for each language.',
  PRODUCT_VERSIONS_CAPTION: 'Let’s publish a new version.',
  PRODUCT_VERSIONS_LIST_CAPTION: 'Here you will see all of the versions.',
  PRODUCT_VERSIONS_NEW_TOUR_CAPTION: 'Let’s create a new version.',
  PRODUCT_VERSION_IS_PUBLIC_TOUR_CAPTION: 'Let’s enable public access.',
  PRODUCT_VERSION_SUBMIT_TOUR_CAPTION: 'Let’s submit.',
  NEW_PRODUCT_VERSION_TOUR_CAPTION: 'Here’s a new product version. It’s live because we allowed public access.',
  NEW_PRODUCT_VERSION_OPENABLE_TOUR_CAPTION: 'We can see all the formats and details.',
  NEW_PRODUCT_VERSION_OPENABLE_2_TOUR_CAPTION: 'Or we can open it and share with the world.',
  NEW_PRODUCT_VERSION_OPENABLE_3_TOUR_CAPTION: 'That’s it. Your product can now be published for everyone to see.',
  WORKSPACE_IDEAS_TOUR_CAPTION: 'Now let’s see what else we got here. Ideas is where you can work on pages directly.',
  WORKSPACE_IDEAS_ITEM_TOUR_CAPTION: 'Let’s check one of your ideas.',
  EDITOR_2_TOUR_CAPTION: 'This is the same text editor where you do the magic.',
  EDITOR_TOOLS_BUTTON_TOUR_CAPTION: 'Let’s toggle the sidebar we have here.',
  EDITOR_TOOLS_DETAILS_TOUR_CAPTION: 'This is a Details section.',
  EDITOR_TOOLS_TASKS_LIST_TOUR_CAPTION: 'Let’s see what Tasks panel has.',
  EDITOR_TOOLS_TASKS_PANEL_TOUR_CAPTION: 'This contains a list of associated tasks. Let’s create a new task to see what it can do.',
  TASK_MANAGER_TOUR_CAPTION: 'This is a Task Manager window.',
  TASK_MANAGER_NEW_TOUR_CAPTION: 'Let’s configure the task you created from the sidebar.',
  TASK_MANAGER_NEW_STEP_1_TOUR_CAPTION: 'Let’s choose Revision for this demo.',
  TASK_MANAGER_NEW_STEP_2_TOUR_CAPTION: '“In Expert Tone” will work.',
  TASK_MANAGER_NEW_SUBMIT_TOUR_CAPTION: 'Now let’s submit task for processing.',
  TASK_MANAGER_NEW_PROPERTIES_TOUR_CAPTION: 'The task is now created. Here are its properties.',
  TASK_MANAGER_NEW_STATUS_TOUR_CAPTION: 'You can always see the task’s status here.',
  TASK_MANAGER_NEW_ACTIONS: 'Task Actions',
  TASK_MANAGER_NEW_ACTIONS_TOUR_CAPTION: 'We can integrate and/or repeat task when it’s done.',
  ALWAYS_ACCESSIBLE: 'Always accesible',
  ALWAYS_ACCESSIBLE_TOUR_CAPTION: 'This is where you can always access the Task Manager.',
  FINAL_TOUR_CAPTION: 'You’re done with the tour. Now’s a good time to set up your account.',
  FINAL_TOUR_CHECK_OUT: 'Check out guides {link} to get 100% out of Prodmake. Thank you for trying the product tour.',
  IN_HELP_SECTION: 'in our Help section',
  IF_YOU_HAVE_ANY_QS: 'If you have any questions, reach out via team@prodmake.com.',
  YOUR_HIT_MAX_PRODUCTS_LIMIT: 'You hit your plan’s limit of max products.',
  YOUR_HIT_MAX_MEMBERS_LIMIT: 'You hit max members limit on your plan.',
  YOUR_HIT_MAX_MEMBERS_LIMIT_CAPTION: 'Upgrade to a plan with a higher limit.',
  SUBSCRIPTION_PLAN_WAS_CANCELED: 'Your subscription to “{planName} {planPeriod}” was canceled on {canceledAt}. You can use subscription for the full length of a paid or trial period (until {untilDate}).',
  YOUR_CURRENT_PLAN_WILL_NOT_RENEW: 'Your subscription will not be renewed',
  YOUR_CURRENT_FREE_TRIAL_PLAN_CANCELED: 'Your current free trial plan of “{planName} {planPeriod}” ends on {trialUntil} and it was canceled on {canceledAt}.',
  YOUR_CURRENT_PAID_PLAN_CANCELED: 'Your current subscription plan of “{planName} {planPeriod}” was canceled on {canceledAt}. You can use for a full length of period until {paidUntil}.',
  IN_REVIEW: 'In Review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  REVIEW_STATUS: 'Review Status',
  MAKER_CONTRACT: 'Maker’s Terms & Conditions',
  I_ACCEPT: 'I Accept The Terms & Conditions',
  ACCEPTED_MAKER_TERMS_CAPTION: 'You accepted terms on {date}.',
  ONLY_WORKSPACE_ADMIN_CAN_ACCEPT_TERMS_CAPTION: 'Only workspace admin can accept terms.',
  ONLY_WORKSPACE_ADMIN_CAN_CANCEL_PLAN: 'Only workspace admin can cancel subscription plan.',
  INVOICES: 'Invoices',
  NO_INVOICES_FOUND: 'No invoices found',
  SUBSCRIPTION_CHARGE: 'Subscription',
  TRASH_IS_EMPTY: 'Trash is empty',
  USER: 'User',
  ADMIN: 'Admin',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  YOU: 'You',
  START_FREE_TRIAL: 'Start free trial',
  FIRST_TIME_MAKER_WELCOME: 'This is your first workspace',
  FIRST_TIME_MAKER_WELCOME_CAPTION: 'Workspace is where you turn your ideas into free and paid products.',
  FIRST_TIME_MAKER_WELCOME_CAPTION2: 'Publish products as websites, export your manuscripts to Epub, and PDF.',
  FIRST_TIME_MAKER_WELCOME_CAPTION3: 'Invite your team or work alone. All from a single place — your workspace.',
  FIRST_TIME_MAKER_START_TRIAL_CAPTION: 'Monthly plans from $20. No credit card required.',
  FIRST_TIME_MAKER_NOT_INTERESTED: 'Not interested right this moment? {goToStoreLink} now.',
  TRIAL_HAS_STARTED: 'Trial has now started.',
  ACCEPT_TERMS_TO_PUBLISH_PRODUCTS: 'Accept terms of service publish products',
  TRY_PRODUCT_TOUR: 'Try Product Tour',
  TRY_PRODUCT_TO_SEE_IT_IN_ACTION: 'To see Prodmake in action',
  WHAT_BEST_DESCRIBES_YOUR_WORK: 'What best describes your work?',
  WELCOME_ONBOARDING_FINAL_MESSAGE: 'Great, thank you — we created a workspace for you. Let’s show you around.',
  NOVELIST: 'Novelist',
  SCI_FI_WRITER: 'Sci-Fi Writer',
  NON_FICTION_WRITER: 'Non-Fiction Writer',
  POET: 'Poet',
  BLOGGER: 'Blogger',
  COPYWRITER: 'Copywriter',
  SCREENWRITER: 'Screenwriter',
  COMIC_WRITER: 'Comic Writer',
  LITERARY_AGENT: 'Literary Agent',
  ILLUSTRATOR: 'Illustrator',
  PHOTOGRAPHER: 'Photographer',
  STUDENT: 'Student',
  ACADEMIC_RESEARCHER: 'Academic Researcher',
  ART_DIRECTOR: 'Art Director',
  SOFTWARE_DEVELOPER: 'Software Developer',
  ENTREPRENEUR: 'Entrepreneur',
  SERVICE_PROVIDER: 'Service Provider',
  TEACHER: 'Teacher',
  OTHER: 'Other',
  WELCOME_ONBOARDING_VIDEO_CAPTION: 'See the explaining video or click to “Get started”.',
  GET_STARTED: 'Get started',
  MAKE: 'Make',
  MAKE_CAPTION: 'Books and information-rich products.',
  PUBLISH: 'Publish',
  PUBLISH_CAPTION: 'As standalone websites, Epub or PDF.',
  MONETIZE: 'Monetize',
  MONETIZE_CAPTION: 'Add multiple offerings in multiple currencies.',
  GO_TO_MARKET: 'Go-to-market Fast',
  GO_TO_MARKET_CAPTION: 'All-in-one workspace to iterate quickly.',
  ACCESS_CONTROL: 'Access Control',
  ACCESS_CONTROL_CAPTION: 'Limit access for each offering.',
  AI_ASSISTED: 'AI-Assisted',
  AI_ASSISTED_CAPTION: '“Brainstorm” with AI, focus on depth.',
  WHAT_IS_YOUR_PRIMARY_GOAL: 'What is your primary goal?',
  PUBLISH_A_BOOK: 'Publish a Book',
  CREATE_A_WEBSITE: 'Create a web-book',
  PRODUCE_FASTER: 'Produce content faster',
  MAKE_GLOBAL_PRODUCTS: 'Create Global Products',
  SIMPLIFY_WORKFLOW: 'Simplify My Workflow',
  ADDITIONAL_INCOME: 'Earn Additional income',
  DELEGATE_BORING_WORK: 'Delegate Boring Tasks',
  SAVE_TIME: 'Save time',
  SUCCESSFULLY_RESTORED: 'Successfully restored',
  ALSO_AVAILABLE_ON: 'Also available on',
  LINK: 'Link',
  THIRD_PARTY_LINKS: 'Third-party Links',
  YOU_NOW_CAN_PUBLISH_PRODUCTS: 'You can now publish products',
  YOU_NOW_CAN_PUBLISH_PRODUCTS_DETAIL: 'On the web and on the Prodmake Store',
  TO_PUBLISH_ACCEPT_TERMS: 'No publishing enabled',
  TO_PUBLISH_ACCEPT_TERMS_DETAIL: 'Without accepting Maker’s Terms',
  LETS_SET_UP_YOUR_PROFILE: 'Let’s set up your profile.',
  PITCH_TITLE: 'Make actionable products fast.',
  PITCH_SUBTITLE: 'Share your expertise — publish, export, monetize.',
  TRANSLATE_FROM: 'Translate from',
  END: 'End',
  PRODUCT_IS_YET_TO_BE_RELEASED: 'Product is yet to be released',
  PRODUCT_NOTIFY_ME_SUBMITTED: 'You’ll be notified when product is released.',
  LEAVE_YOUR_EMAIL_TO_BE_NOTIFIED: 'Get a notification to your inbox when it does.',
  NOTIFY_ME: 'Notify me',
  ALREADY_SUBSCRIBED: 'Already subscribed',
  WEEK_OVER_WEEK: 'W/W',
  MONTH_OVER_MONTH: 'M/M',
  QUARTER_OVER_QUARTER: 'Q/Q',
  YEAR_OVER_YEAR: 'Y/Y',
  YES: 'Yes',
  NO: 'No',
  LAST_WEEK: 'Last week',
  LAST_MONTH: 'Last month',
  LAST_QUARTER: 'Last quarter',
  LAST_YEAR: 'Last year',
  PHONE_NUMBER: 'Phone number',
  DOB: 'Date of Birth',
  POSTAL_CODE: 'Postal Code',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  WISE_SERVICE: 'Wise',
  PAYPAL_SERVICE: 'PayPal',
  WIRE_TRANSFER: 'Wire',
  PAYOUT_METHOD: 'Payout Method',
  ACCOUNT_TYPE: 'Account Type',
  PERSONAL: 'Personal',
  BUSINESS: 'Business',
  ADDRESS: 'Address',
  CITY: 'City',
  COUNTRY: 'Country',
  SELECT_COUNTRY: 'Select country',
  NO_COUNTRY_FOUND: 'No country found',
  FOR_WIRE_TRANSFER_CONTACT: 'For setting up wire transfers contact team@prodmake.com.',
  LEGAL_BUSINESS_NAME: 'Legal business name',
  BUSINESS_TYPE: 'Business type',
  SELECT_BUSINESS_TYPE: 'Select business type',
  NOT_FOUND: 'Not found',
  CORPORATION: 'Corporation',
  LLC: 'Limited Liability Company',
  PARTNERSHIP: 'Partnership',
  NON_PROFIT: 'Non-profit',
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  WISE_EMAIL: 'Wise Email',
  PAYPAL_EMAIL: 'PayPal Email',
  PAYOUT_SETTINGS_UPDATED: 'Payout settings updated',
  ALL_TIME: 'All-time',
  NO_PAYOUTS_YET: 'No payouts yet.',
  POSSIBLE_TO_PAYOUT_LATER: 'Pay out requires at least $10 in balance.',
  AWAITING_APPROVAL: 'Awaiting approval',
  APPROVE: 'Approve',
  NEW_PAYOUT: 'New payout',
  AMOUNT: 'Amount',
  REPRESENTATIVE: 'Representative',
  TIME_FOR_A_NEW_PAYOUT: 'Time for a new payout',
  VIEW_PAYOUTS: 'View payouts',
  UPDATED_FIELD: '{fieldName} is updated to “{fieldValue}”',
  PAYOUT_WAS_CREATED: 'Payout was created',
  PAYOUT_WAS_APPROVED: 'Payout was approved',
  PAYOUT_WAS_CANCELLED: 'Payout was cancelled',
  CREATE: 'Create',
  VERIFICATION: 'Verification',
  VERIFY: 'Verify',
  YOUR_ACCOUNT_IS_NOW_VERIFIED: 'Your account is now verified',
  ERROR_TRY_AGAIN: 'Error. Try again.',
  YOUR_ACCOUNT_IS_VERIFIED: 'Your account is verified',
  YOUR_ACCOUNT_IS_VERIFIED_CAPTION: 'Your account has now full access to the features of Prodmake.',
  YOUR_ACCOUNT_IS_NOT_VERIFIED: 'Your account is not verified',
  YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION: 'Publishing paid products and arranging pay outs is only for verified users who agreed to Maker’s terms.',
  YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION_2: 'Your account is limited to editing.',
  SERVER_ERROR: 'Server error',
  DOCUMENT_INVALID: 'an invalid document',
  DOCUMENT_EXPIRED: 'an expired document',
  DOCUMENT_TYPE_NOT_SUPPORTED: 'a document type not supported',
  CONSENT_DECLINED: 'a declined consent',
  ACCOUNT_VERIFICATION_FAIL_REASON: 'Account couldn’t be verified due to {status}.',
  VERIFY_YOUR_ACCOUNT: 'Verify your account',
  PUBLISHING_IS_FOR_VERIFIED: 'Publishing is enabled for verified users who agreed to Maker’s terms.',
  PAYOUTS_DISABLED: 'Payouts disabled',
  PAYOUTS_DISABLED_CAPTION: 'Verify your account and accept maker terms to enable payouts.',
  PRODUCT_HAS_NO_OFFERINGS: 'Product has no offerings',
  PENDING: 'Pending',
  JOIN: 'Join',
  DECLINE: 'Decline',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  YOU_WERE_INVITED_TO_WORKSPACE: '{byUser} invites you to {workspace}.',
  DOMAIN_USED: 'Domain unavailable',
  TO_BE_VERIFIED: 'Verifying…',
  VERIFIED: 'Verified',
  PRODUCT_DOMAIN_BY_ID_CAPTION: 'Product is reachable by ID as well',
  NO_CUSTOM_DOMAINS_SET: 'This product is not set up to use any custom domains.',
  PRODUCT_IS_AVAILABLE_IN_OTHER_LANGUAGES: 'Product is available in other languages.',
  BLOG: 'Blog',
  CONTINUE_WRITING: 'Continue writing',
  CONTINUE_WRITING_CAPTION: 'Expand your thoughts with AI.',
  TEXT: 'Text',
  EDITOR_TEXT: 'Text',
  EDITOR_TEXT_CAPTION: 'Just start typing.',
  TODO_LIST: 'To-Do List',
  TODO_LIST_CAPTION: 'List of to-do items.',
  H1: 'Heading 1',
  H1_CAPTION: 'Big section heading.',
  H2: 'Heading 2',
  H2_CAPTION: 'Medium section heading.',
  H3: 'Heading 3',
  H3_CAPTION: 'Small section heading.',
  BULLET_LIST: 'Bullet List',
  BULLET_LIST_CAPTION: 'Create a simple bullet list.',
  NUMBERED_LIST: 'Numbered List',
  NUMBERED_LIST_CAPTION: 'Create a list with numbering.',
  QUOTE: 'Quote',
  QUOTE_CAPTION: 'Capture a quote.',
  CODE: 'Code',
  CODE_CAPTION: 'Capture a code snippet.',
  IMAGE_CAPTION: 'Upload an image from your computer.',
  PASTE_A_LINK: 'Paste a link',
  PUBLIC: 'Public',
  RELOAD: 'Reload',
  UNIVERSAL_BOOK_CAPTION: 'A modern take on a self-published book.',
  BLOG_CAPTION: 'A minimalist’s blog.',
  KNOWLEDGE_BASE: 'Knowledge Base',
  KNOWLEDGE_BASE_CAPTION: 'Help your customers and stakeholders.',
  PERSONAL_WEBSITE_CAPTION: 'Present yourself to the world.',
  NEW_PRODUCT: 'New product',
  PRODUCT_ROADMAP: 'Product Roadmap',
  PRODUCT_ROADMAP_CAPTION: 'The vision, direction, and progress of your product.',
  GRID: 'Grid',
  SCROLL_NAVIGATION: 'Scroll Navigation',
  LINK_LIST: 'Link List',
  PAGE: 'Page',
  FEATURES: 'Features',
}
