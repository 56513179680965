import { API_URL } from '../config'

export default async function getUserByToken(token) {
  if (!token) return {}
  const res = await fetch(`${API_URL}/auth/by-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token })
  })
  const user = await res.json()
  if (!user) return {}

  return {
    user,
    auth: { token, user }
  }
}
