import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo })
  }

  reload() {
    window.location.reload()
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className={styles.wrapper}>
          <div className={styles.message}>
            <h2>
              <FormattedMessage id='ERROR' />
            </h2>
            <p>
              <button className={`cta-button ${styles.reloadButton}`} onClick={this.reload}>
                <FormattedMessage id='RELOAD' />
              </button>
            </p>
            <details>
              <summary>
                <FormattedMessage id='DETAILS' />
              </summary>
              <pre>
                <code>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </code>
              </pre>
            </details>
          </div>
        </div>
      )
    }

    // Normally, just render children
    return this.props.children
  }
}

ErrorBoundary.getDerivedStateFromError = (error) => ({ error: error.toString() })

export default ErrorBoundary
