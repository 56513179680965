import * as React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { Link, navigate } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import UserPicture from '../UserPicture'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useGlobalStore } from '../../lib/global'
import styles from './index.module.scss'

function UserSwitcherComponent() {
  const ref = React.useRef()
  const [isActive, setActive] = React.useState(false)
  const {
    auth,
    accountDb,
    logout,
    cleanUserStore,
    isPadded,
    isZen,
    activeWorkspace,
    location,
    wsStatus,
  } = useGlobalStore(s => ({
    auth: s.auth,
    logout: s.logout,
    cleanUserStore: s.cleanUserStore,
    accountDb: s.accountDb,
    isPadded: s.isPadded,
    isZen: s.isZen,
    activeWorkspace: s.activeWorkspace,
    updateUser: s.updateUser,
    location: s.location,
    wsStatus: s.wsStatus,
  }))
  const staleUsers = useLiveQuery(() => {
    if (!accountDb) return []
    return accountDb.accounts.toArray()
  }, [accountDb], [])
  const user = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb], {})
  const profile = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.profiles.where({ lang: 'en' }).first()
  }, [auth, accountDb])

  const toggle = React.useCallback(e => {
    e.preventDefault()
    setActive(prev => !prev)
  })

  const close = React.useCallback(e => {
    setActive(false)
  }, [])
  useClickOutside(ref, close)

  const lock = React.useCallback(e => {
    // TODO
  }, [])

  const onLogout = React.useCallback(async (e) => {
    e.preventDefault()
    close()
    logout()
    await cleanUserStore()
    navigate('/')
    setTimeout(() => {
      window.location.reload()
    }, 100)
    return false
  }, [])

  const isDashboard = location.pathname.includes('overview')

  return (
    <div
      ref={ref}
      className={`${styles.userSwitcher} ${!isZen ? 'isActive' : ''} ${isPadded && activeWorkspace ? 'padded' : ''}`}
    >
      <button onClick={toggle} className={styles.userSwitcherButton}>
        <UserPicture user={user} />
        {auth?.isAuthenticated && (
          <span className={`${styles.onlineIndicator} ${wsStatus}`} />
        )}
      </button>
      <div
        className={[
          styles.userSwitcherContent,
          isActive ? 'active' : '',
          isDashboard ? 'dashboard' : ''
        ].join(' ')}
      >
        <div className={styles.userSwitcherBlock}>
          {!auth?.isAuthenticated && (
            <div className={styles.userSwitcherSection}>
              {staleUsers.map((user) => {
                return (
                  <Link
                    key={`su-${user.id}`}
                    to={'login-local'}
                    className={`${styles.styledLink} public with-icon`}
                    onClick={close}
                  >
                    <img src={user.picture_url} />
                    Continue
                  </Link>
                )
              })}
              <Link
                to={'/'}
                onClick={close}
                className={`${styles.styledLink}`}
              >
                <FormattedMessage id='LOGIN' defaultMessage='Login' />
              </Link>
              <Link
                to={'/join'}
                onClick={close}
                className={`${styles.styledLink}`}
              >
                <FormattedMessage id='JOIN' defaultMessage='Join' />
              </Link>
            </div>
          )}
          {auth?.isAuthenticated && (
            <div className={styles.userSwitcherSection}>
              {profile?.fullname && (
                <span className={`${styles.styledText} header`}>
                  {profile?.fullname}
                </span>
              )}
              <span className={styles.styledText}>
                {auth?.user?.email}
              </span>
              <div className={styles.separator} />
              <Link
                to={`/`}
                className={`${styles.styledLink}`}
                onClick={close}
              >
                <FormattedMessage id='DASHBOARD' defaultMessage='Dashboard' />
              </Link>
              <a
                href='https://prodmake.com/?ref=app'
                className={`${styles.styledLink}`}
              >
                <FormattedMessage id='GO_TO_STORE' />
              </a>
              <div className={styles.separator} />
              <Link
                to={`${location.pathname}?settings=1`}
                className={`${styles.styledLink}`}
                onClick={close}
              >
                <FormattedMessage id='SETTINGS' defaultMessage='Settings' />
              </Link>
              <div className={styles.separator} />
              {false && <span>PENDING: {0}</span>}
              {false && <div className={styles.separator} />}
              {false && <a
                href='#'
                onClick={lock}
              >
                Lock
              </a>}
              {/* <Link */}
              {/*   to={'purchases'} */}
              {/*   onClick={close} */}
              {/*   className={`${styles.styledLink}`} */}
              {/* > */}
              {/*   Purchases */}
              {/* </Link> */}
              <Link
                to={'logout'}
                onClick={onLogout}
                className={`${styles.styledLink}`}
              >
                <FormattedMessage id='LOGOUT' defaultMessage='Log out' />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserSwitcherComponent
