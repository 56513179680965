import React from 'react'
import ReactDOM from 'react-dom'
import tinycolor from 'tinycolor2'
import { FormattedMessage } from 'react-intl'
import PaletteSVG from '../icons/remix/design/palette-line.svg'
import { useClickOutside } from '../hooks/useClickOutside'
import './styles.scss'

const themeList = [
  'aldarkose',
  'seoul256light',
  'palenight',
  'soyuz',
  'soyuz.dark',
  'sonicpi',
  'apollo',
  'cobalt',
  'zenburn',
  'nightowl',
  'noir',
  'obsidian',
  'op-1',
  'gotham',
  // 'commodore',

  // 'palenightlight',

  // 'lotus',
  // 'murata',
  // 'muzieca',
  // 'ninetynine',
  // 'nord',

  // 'marble',
  // 'coal',
  // 'snow',
  // 'solarised.light',

  // 'tape',

  // 'orca',
  // 'pawbin',
  // 'pico8',
  // 'polivoks',
  // 'rainonwires',
  // 'roguelight',
  // 'sk',
  // 'solarised.dark',
  // 'teenage',

  // 'teletext',
  // 'vacuui',

  // 'haxe',
  // 'isotope',
  // 'boysenberry', 'battlestation',
  // 'laundry',
]

function extract(xml) {
  const svg = new DOMParser().parseFromString(xml, 'text/xml')
  try {
    const theme = {
      'background': svg.getElementById('background').getAttribute('fill'),
      'text': svg.getElementById('text').getAttribute('fill'),
      'sidebar-background': svg.getElementById('sidebar-background').getAttribute('fill'),
      'sidebar-text': svg.getElementById('sidebar-text').getAttribute('fill'),
      'accent': svg.getElementById('accent').getAttribute('fill'),
      'text-on-accent': svg.getElementById('text-on-accent').getAttribute('fill'),
      'action': svg.getElementById('action').getAttribute('fill'),
    }
    Object.keys(theme).forEach(key => {
      const val = theme[key]
      const color = tinycolor(val)
      theme[`${key}_is_light`] = color.isLight()
      if (key === 'background') {
        const alt = tinycolor(theme['background'])
        theme.background_darker = alt.darken(3).toHslString()
        theme.background_darker_1 = alt.darken(6).toHslString()
        theme.background_darker_2 = alt.darken(9).toHslString()
        theme.background_lighter = alt.lighten(3).toHslString()
        theme.background_lighter_1 = alt.lighten(6).toHslString()
        theme.background_lighter_2 = alt.lighten(9).toHslString()
        theme.background_alt = alt.darken(3).toHslString()
        theme.background_alt_1 = alt.darken(3).toHslString()
        theme.background_alt_2 = alt.darken(9).toHslString()
      }
      if (color.isLight()) {
        theme[`${key}_a3`] = color.darken(3).toHslString()
        theme[`${key}_a5`] = color.darken(5).toHslString()
        theme[`${key}_a10`] = color.darken(10).toHslString()
        theme[`${key}_a20`] = color.darken(20).toHslString()
        theme[`${key}_a30`] = color.darken(30).toHslString()
      } else {
        theme[`${key}_a3`] = color.lighten(3).toHslString()
        theme[`${key}_a5`] = color.lighten(5).toHslString()
        theme[`${key}_a10`] = color.lighten(10).toHslString()
        theme[`${key}_a20`] = color.lighten(20).toHslString()
        theme[`${key}_a30`] = color.lighten(30).toHslString()
      }
      theme[`${key}_l5`] = color.lighten(5).toHslString()
      theme[`${key}_l10`] = color.lighten(10).toHslString()
      theme[`${key}_d5`] = color.darken(5).toHslString()
      theme[`${key}_d10`] = color.darken(10).toHslString()
      theme[`${key}_b5`] = color.brighten(5).toHslString()
      theme[`${key}_b10`] = color.brighten(10).toHslString()
      theme[`${key}_ds10`] = color.desaturate(10).toHslString()

      const monoList = color.monochromatic()
      monoList.forEach((m, index) => {
        const hsl = m.toHslString()
        theme[`${key}_m_${index}`] = hsl
      })

      const triadList = color.triad()
      triadList.forEach((t, index) => {
        const hsl = t.toHslString()
        theme[`${key}_t_${index}`] = hsl
      })
    })
    return theme
  } catch (err) {
    console.warn('Theme', 'Incomplete SVG Theme', err)
  }
}

export default function Theme({ isContained = false, short = false }) {
  const ref = React.useRef()
  const [isActive, setActive] = React.useState(false)
  const [activeTheme, setActiveTheme] = React.useState('')
  let rootEl = typeof document !== 'undefined' ? document.documentElement : ''
  let rendererEl = typeof document !== 'undefined' ? document.querySelector('.renderer-root') : ''
  const key = isContained ? 'renderer-theme' : 'theme'
  const portalEl = isContained ? rendererEl : rootEl

  const restoreTheme = React.useCallback(() => {
    const theme = localStorage.getItem(key)
    if (theme) {
      const parsed = JSON.parse(theme)
      setTheme(parsed.name, parsed.colors)
    }
  }, [key, rootEl, rendererEl])

  const setTheme = React.useCallback((themeName, theme) => {
    if (typeof document !== 'undefined' && theme) {
      Object.keys(theme).forEach(key => {
        if (isContained) {
          const el = document.querySelector('.renderer-root')
          el?.style?.setProperty(`--${key}`, theme[key])
        } else {
          rootEl?.style?.setProperty(`--${key}`, theme[key])
        }
      })
    }

    const str = JSON.stringify({
      name: themeName,
      colors: theme
    })

    localStorage.setItem(key, str)
    setActiveTheme(themeName)
  }, [key, rootEl, rendererEl])

  const onSwitch = React.useCallback((themeName, src) => async ev => {
    const res = await fetch(src)
    const svg = await res.text()
    const theme = extract(svg)
    setTheme(themeName, theme)
  }, [])

  useClickOutside(ref, () => {
    setActive(false)
  })

  React.useEffect(() => {
    try {
      restoreTheme()
    } catch (err) {
      console.log('err restoring theme', err)
    }
  }, [])

  const globalList = (
    <div className={`themes ${isActive ? 'is-active' : ''}`} ref={ref}>
      <div className={`themeList ${isActive ? 'is-active' : ''}`}>
        {themeList.map(theme => {
          const src = `/themes/${theme}.svg`
          return (
            <div
              key={theme}
              onKeyPress={onSwitch(theme, src)}
              onClick={onSwitch(theme, src)}
              className={activeTheme === theme ? 'is-active' : ''}
            >
              <img src={src} alt={theme} />
            </div>
          )
        })}
      </div>
    </div>
  )

  return (
    <div id='themes' className='flex'>
      <button onClick={() => setActive(!isActive)} className='action-button with-icon'>
        <PaletteSVG /> {!short && (<FormattedMessage id='THEMES' defaultMessage='Themes' />)}
      </button>
      {typeof document !== 'undefined' && portalEl && ReactDOM.createPortal(globalList, portalEl)}
    </div>
  )
}
