import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.scss'

export default function LoadingScreen({ isActive = false }) {
  return (
    <div className={`${styles.loadingScreen} ${!isActive ? 'is-hidden' : ''}`}>
      <img src='/apple-touch-icon.png' alt='Prodmake logo' />
      <h3>
        <FormattedMessage id='PRODMAKE' defaultMessage='Prodmake' />
      </h3>
      <p><FormattedMessage id='LOADING' defaultMessage='Loading' />…</p>
    </div>
  )
}
