import Cookies from 'js-cookie'
import { restoreUserSession } from './restoreUserSession'

export function init(useGlobalStore) {
  window.onload = () => {
    const state = useGlobalStore.getState()
    if (!state?.auth?.isAuthenticated) {
      restoreUserSession()
    }
  }

  useGlobalStore.subscribe(s => s.isPadded, function (state) {
    localStorage.setItem('isPadded', state.isPadded ? '1' : '0')
    if (!process.env.IS_ELECTRON) {
      Cookies.set('isPadded', state.isPadded ? '1' : '0')
    }
  })
}
