import React from 'react'
import Card from './Card'
import './styles.scss'

function getExpiry(m, y) {
  const expMonth = m.toString().length === 1 ? `0${m}` : m
  return `${expMonth}/${y}`
}

export default function CreditCard(props) {
  const {
    name,
    isActive,
    issuer,
    last4,
    expMonth,
    expYear,
    onClick,
    isSelected
  } = props

  return (
    <div
      className={`credit-card-wrapper ${onClick ? 'is-button' : ''} ${isSelected ? 'is-selected' : ''}`}
      onClick={onClick}
      onKeyDown={onClick}
      role='button'
      tabIndex={0}
    >
      <Card
        preview
        disabled={!isActive}
        focused={false}
        expiry={getExpiry(expMonth, expYear)}
        name={name}
        issuer={issuer}
        number={`•••• •••• •••• ${last4}`}
        last4={last4}
      />
    </div>
  )
}
