import React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGlobalStore } from '../../../lib/global'
import Input from '../../Input'
import styles from '../index.module.scss'
import validateInput from './validate'

const initialState = {
  username: null,
  email: null,

  errors: {},
  isChanged: false,
  isLoading: false
}

function SettingsAccountContainer() {
  const intl = useIntl()
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb
  }))

  const user = useLiveQuery(() => {
    if (!auth?.user?.id || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  const [state, setState_] = React.useState(initialState)
  const setState = React.useCallback((change) => {
    setState_(prev => ({
      ...prev,
      ...change
    }))
  })

  const onChange = React.useCallback(e => {
    setState({
      isChanged: true,
      [e.currentTarget.name]: e.currentTarget.value
    })
  })

  const isValid = React.useCallback(() => {
    const { errors, isValid } = validateInput(state)

    if (!isValid) {
      setState({ errors })
    }

    return isValid
  })

  const onSubmit = React.useCallback(async e => {
    e.preventDefault()

    if (isValid()) {
      setState({
        errors: {},
        isLoading: true
      })

      const { username, email } = state

      try {
        accountDb.accounts.update(user.id, {
          updated_at: (new Date()).toISOString(),
          ...username === null ? {} : { username },
          ...email === null ? {} : { email },
          data: {
            ...user.data || {},
            has_setup_profile: true
          }
        })
        setState({
          ...initialState,
        })
      } catch (err) {
        setState({
          errors: {
            form: err
          },
          isLoading: false
        })
      }
    }
  })

  const cancel = React.useCallback(() => {
    if (state.isChanged) setState(initialState)
  })

  const {
    username,
    email,
    errors,
    isLoading,
    isChanged
  } = state

  return (
    <div className={styles.wrapper} id='account'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='ACCOUNT' />
        </div>
      </div>
      <div className={`${styles.body} account`}>
        <div className={styles.inner}>
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.fieldset}>
              <Input
                error={errors.username}
                placeholder=''
                onChange={onChange}
                value={username === null ? user?.username : username}
                label={intl.formatMessage({ id: 'USERNAME' })}
                type='text'
                name='username'
                id='username'
              />
            </div>
            <div className={styles.fieldset}>
              <Input
                error={errors.email}
                onChange={onChange}
                value={email === null ? user?.email : email}
                label={intl.formatMessage({ id: 'EMAIL' })}
                type='text'
                name='email'
                id='email'
              />
            </div>
            <div className={styles.footer}>
              <button className={`${styles.button} cta-button`} disabled={!isChanged || isLoading} type='submit'>
                <FormattedMessage id='SAVE' />
              </button>
              <button className={`${styles.button} cancelButton`} disabled={!isChanged} onClick={cancel}>
                <FormattedMessage id='CANCEL' />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SettingsAccountContainer
