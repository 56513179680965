import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import HSLToHex from '../utils/HSLToHex'

function getOptions(theme = {}) {
  return {
    disableLink: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#767676',
        color: '#767676',
        fontWeight: 400,
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        // '::placeholder': {
        //   color: ''
        // },
        ':-webkit-autofill': {
          color: '#fce883'
        }
      },
      invalid: {
        iconColor: '#ff4141',
        color: '#ff4141'
      }
    }
  }
}

function CardSection(props) {
  return (
    <div className='card-element-wrapper'>
      <CardElement options={getOptions(props.theme)} />
    </div>
  )
}

export default CardSection

