import { v4 as uuid } from 'uuid'
import { nanoid } from 'nanoid'
// import objectPath from 'object-path'
// import produce from 'immer'
// import microdiff from 'microdiff'
import deepmerge from 'deepmerge'
import { isPlainObject } from 'is-plain-object'
// import twf from './twf'

import tmpl from './tmpl'
import { useGlobalStore } from '../../global'

export async function addStruct(payload, opts = {}) {
  if (!payload.workspace_id) return

  const { databases, auth } = useGlobalStore.getState()
  const db = databases?.[payload.workspace_id]
  if (!db) return

  const ts = (new Date()).toISOString()
  const struct = {
    id: uuid(),
    short_id: nanoid(),
    created_at: ts,
    updated_at: ts,
    data: {
      ...tmpl,
      blueprint: null
    },
    account_id: auth.user?.id,
    lang: payload.lang,
    workspace_id: payload.workspace_id,
    is_deleted: false,
    is_private: false,
    is_public: false,
    is_trashed: false,
  }

  db.structs.add(struct)

  return struct
}

export async function updateStruct(id, changes) {
  const { databases, activeWorkspace } = useGlobalStore.getState()
  const db = databases?.[activeWorkspace?.id]
  if (!db) return

  const ts = (new Date()).toISOString()
  const found = await db.structs.where({ id }).first()

  const merged = deepmerge(found, changes, {
    isMergeableObject: isPlainObject,
  })
  await db.structs.update(id, {
    ...merged,
    updated_at: ts,
  })
}

export async function trashStruct(id) {
  const { databases, activeWorkspace } = useGlobalStore.getState()
  const db = databases?.[activeWorkspace?.id]
  if (!db) return

  const ts = (new Date()).toISOString()
  const changes = {
    updated_at: ts,
    is_trashed: true
  }
  await db.structs.update(id, changes)
  const struct = await db.structs.where({ id }).first()
  return struct
}
