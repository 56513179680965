import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import S3Upload from 'react-s3-uploader/s3upload'
import { FormattedMessage } from 'react-intl'
import { useGlobalStore } from '../../lib/global'
import { API_URL } from '../../lib/config'
import './styles.scss'

function getConfig(params = {}, type) {
  if (params.workspaceId) {
    return {
      caption: 'CHANGE_LOGO',
      signingUrl: '/v1/s3/sign/workspace-icon',
      queryParams: {
        ...params
      }
    }
  }

  if (params.structId) {
    return {
      caption: 'CHANGE_STRUCT_COVER',
      signingUrl: '/v1/s3/sign/struct-cover',
      queryParams: {
        ...params
      }
    }
  }

  // default is user pic
  return {
    caption: 'CHANGE_AVATAR',
    signingUrl: '/v1/s3/sign/avatar',
    queryParams: {}
  }
}

export default function ImageSelect(props) {
  const {
    src = '',
    params = {},
    withEditor = false,
    onUploadFinish: onFinish = (url) => console.log
  } = props
  const { auth } = useGlobalStore(s => ({
    auth: s.auth,
  }))

  const editorRef = React.useRef()
  const [state, setState] = React.useState({
    file: null,
    scale: 1,
    rotate: 0,
    progressPercentage: null
  })
  const { signingUrl, caption, queryParams = {} } = getConfig(params)

  const onUploadProgress = React.useCallback(percentage => {
    setState(prevState => ({
      ...prevState,
      progressPercentage: `${percentage}%`
    }))
  }, [])

  const onUploadError = React.useCallback(e => {
    console.log('error', e) // eslint-disable-line
  }, [])

  const onUploadFinish = React.useCallback(async res => {
    try {
      if (onFinish) onFinish({ picture_url: res.url })
    } catch (err) {
      console.log(err)
    }

    setState(prevState => ({
      ...prevState,
      isLoading: false,
      progressPercentage: null
    }))
  }, [onFinish])

  const onDrop = acceptedFiles => {
    const file = acceptedFiles[0]
    if (file) {
      setState(prevState => ({
        ...prevState,
        file
      }))
      if (!withEditor) {
        const reader = new FileReader();
        reader.addEventListener('load', ev => {
          const blob = reader.result
          blob.name = file.name
          upload(blob)
        });
        reader.readAsArrayBuffer(file);
      }
    }
  }

  const handleScale = React.useCallback(e => {
    const scale = parseFloat(e.target.value)
    setState({ ...state, scale })
  })

  const handleRotate = React.useCallback(e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      rotate: state.rotate + 90
    }))
  })

  const handleSave = React.useCallback(() => {
    if (editorRef.current) {
      const scaled = editorRef.current.getImageScaledToCanvas()
      scaled.toBlob(blob => {
        blob.name = state.file?.name || 'untitled'
        upload(blob)
      }, 'image/jpeg', 0.7)
    }
  })

  const upload = React.useCallback((blob) => {
    reset()
    setState(prevState => ({
      ...prevState,
      isLoading: true
    }))

    const options = Object.assign({
      files: [blob],

      server: API_URL,
      signingUrl,
      signingUrlMethod: 'POST',
      signingUrlQueryParams: queryParams,
      signingUrlHeaders: {
        Authorization: `Bearer ${auth.token || localStorage.auth_token}`
      },
      uploadRequestHeaders: {
        'Cache-Control': 'max-age=31536000'
      },

      accept: 'image/*',

      contentDisposition: 'auto',

      onProgress: onUploadProgress,
      onFinishS3Put: onUploadFinish,
      onError: onUploadError
    })

    new S3Upload(options) // eslint-disable-line
  })

  const reset = React.useCallback(() => {
    setState({
      file: null,
      rotate: 0,
      scale: 1
    })
  })

  const cancel = React.useCallback(e => {
    e.preventDefault()
    reset()
  })

  const save = React.useCallback(e => {
    e.preventDefault()
    handleSave()
  })

  const {
    file,
    isLoading,
    scale,
    rotate,
    progressPercentage
  } = state

  const cancelButton = file && <div onClick={cancel} /> // eslint-disable-line

  const form = (
    <div>
      <div className='select-image'>
        <label
          className={`avatar-caption ${src ? '' : 'is-ready'}`}
          htmlFor='avatar_image'
        >
          <input
            id='avatar_image'
            type='file'
            onChange={(ev) => onDrop(ev.target.files)}
            name='avatar_image'
            accept='image/*'
          />
          <FormattedMessage id={caption} />
        </label>
        <div className='select-image-wrapper'>
          {src && <img src={src} alt='' />}
        </div>
        {isLoading && (
          <div className='select-image-loader'>
            {progressPercentage}
          </div>
        )}
      </div>
      {cancelButton}
      {file && withEditor &&
        <div className='image-editor-wrapper' width='100%'>
          <AvatarEditor
            ref={editorRef}
            image={file}
            width={400}
            height={400}
            border={20}
            color={[0, 0, 0, 0.5]}
            style={{
              cursor: 'move',
              width: '100%',
              height: '100%'
            }}
            scale={scale}
            rotate={rotate}
            onSave={handleSave}
          />
          <div className='image-editor-controls'>
            <input
              className='image-editor-slider'
              name='scale'
              type='range'
              onChange={handleScale}
              min='1'
              max='8'
              step='0.05'
              defaultValue={scale}
            />
          </div>
          <div className='image-editor-actions'>
            <button
              onClick={handleRotate}
            >
              <FormattedMessage id='ROTATE' defaultMessage='Rotate' />
            </button>
            <button
              onClick={cancel}
            >
              <FormattedMessage id='CANCEL' defaultMessage='Cancel' />
            </button>
            <button
              className='main cta-button'
              onClick={save}
            >
              <FormattedMessage id='SAVE' defaultMessage='Save' />
            </button>
          </div>
        </div>}
    </div>
  )

  return (
    <div>
      {form}
    </div>
  )
}
