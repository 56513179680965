import React from 'react'
import { FormattedMessage } from 'react-intl'
import CreditCardMini from 'shared/CreditCardMini'

export default function SelectedPaymentMethod({ state }) {
  return (
    <div className='flex-field'>
      <h4>
        <FormattedMessage id='PAYMENT_METHOD' />
      </h4>
      <CreditCardMini
        issuer={state.selectedPaymentMethod?.data?.card?.brand}
        last4={state.selectedPaymentMethod?.data?.card?.last4}
        expMonth={state.selectedPaymentMethod?.data?.card?.exp_month}
        expYear={state.selectedPaymentMethod?.data?.card?.exp_year}
      />
    </div>
  )
}
