import React from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { FormattedMessage } from 'react-intl'
import CardSection from './CardSection'
import './styles.scss'

export default function SetupForm(props) {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setLoading(true)

    const result = await stripe.confirmCardSetup(props.secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    })

    const key = 'setupIntent'

    if (result.error) {
      if (props.onError) {
        props.onError(result.error)
      } else {
        window.alert(result.error.message)
      }
    } else {
      // The payment has been processed!
      if (result[key].status === 'succeeded') {
        if (props.onSuccess) {
          props.onSuccess(result[key])
        } else {
          console.log('success', result)
        }
      }
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className='add-payment-card-form'>
      <div className='form-card'>
        <CardSection theme={props.theme} />
      </div>
      <div className='form-footer'>
        <button className='purchase-button' disabled={loading}>
          {loading && <FormattedMessage id='LOADING' />}
          {!loading && props.caption}
        </button>
      </div>
    </form>
  )
}
