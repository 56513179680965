import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export default function validateInput(data) {
  const errors = {}

  if (data.name !== null && Validator.isEmpty(data.name)) {
    errors.name = 'Required'
  }

  if (data.slug !== null && Validator.isEmpty(data.slug)) {
    errors.slug = 'Required'
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
