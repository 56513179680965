import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from '@reach/router'
import dayjs from 'shared/utils/dayjs'
import Tumbler, { useTumbler } from 'shared/Tumbler'
import { getCurrencySymbol } from 'shared/helpers/getCurrencySymbol'
import { basicCopy, plans } from './data'

export default function Plans(props) {
  const intl = useIntl()
  const { location } = props
  const { view, next } = useTumbler('MONTHLY')

  const onPlanSelect = React.useCallback(plan => (ev) => {
    if (typeof props.onPlanSelect === 'function') {
      props.onPlanSelect(plan, view)
    }
    return false
  })

  const activePlan = props.activeWorkspace?.meta?.data?.plan
  const activeSubscription = props.activeWorkspace?.meta?.data?.subscription
  const freeTrialEndDate = props.activeWorkspace?.meta?.data?.subscription?.free_trial_end_date

  const isOnTrial = (freeTrialEndDate && dayjs().isBefore(freeTrialEndDate))
  const isTrialLapsed = freeTrialEndDate && !dayjs().isBefore(freeTrialEndDate)
  const canceled_at = activeSubscription?.canceled_at

  if (!props.activeWorkspace?.id) return null

  return (
    <>
      {activePlan?.name && (
        <div className='plan-head'>
          {isTrialLapsed && (
            <div className='plan-current-caption'>
              <FormattedMessage
                id='YOUR_TRIAL_PLAN_LAPSED'
                values={{
                  planName: intl.formatMessage({ id: activePlan?.name }),
                  planPeriod: intl.formatMessage({ id: activePlan?.period }),
                  trialUntil: dayjs(activeSubscription?.free_trial_end_date).locale(intl.locale).format('MMM D, YYYY')
                }}
              />
            </div>
          )}
          {!isTrialLapsed && (
            <div className='plan-current-caption'>
              {activeSubscription?.free_trial_end_date && !canceled_at && (
                <FormattedMessage
                  id='YOUR_CURRENT_FREE_TRIAL_PLAN'
                  values={{
                    planName: intl.formatMessage({ id: activePlan?.name }),
                    planPeriod: intl.formatMessage({ id: activePlan?.period }),
                    trialUntil: dayjs(activeSubscription?.free_trial_end_date).locale(intl.locale).format('MMM D, YYYY')
                  }}
                />
              )}
              {activeSubscription?.paid_until && !canceled_at && (
                <FormattedMessage
                  id='YOUR_CURRENT_PAID_PLAN'
                  values={{
                    planName: intl.formatMessage({ id: activePlan?.name }),
                    planPeriod: intl.formatMessage({ id: activePlan?.period }),
                    paidUntil: dayjs(activeSubscription?.paid_until).locale(intl.locale).format('MMM D, YYYY')
                  }}
                />
              )}
              {activeSubscription?.free_trial_end_date && canceled_at && (
                <FormattedMessage
                  id='YOUR_CURRENT_FREE_TRIAL_PLAN_CANCELED'
                  values={{
                    planName: intl.formatMessage({ id: activePlan?.name }),
                    planPeriod: intl.formatMessage({ id: activePlan?.period }),
                    trialUntil: dayjs(activeSubscription?.free_trial_end_date).locale(intl.locale).format('MMM D, YYYY'),
                    canceledAt: dayjs(canceled_at).locale(intl.locale).format('MMM D, YYYY'),
                  }}
                />
              )}
              {activeSubscription?.paid_until && canceled_at && (
                <FormattedMessage
                  id='YOUR_CURRENT_PAID_PLAN_CANCELED'
                  values={{
                    planName: intl.formatMessage({ id: activePlan?.name }),
                    planPeriod: intl.formatMessage({ id: activePlan?.period }),
                    paidUntil: dayjs(activeSubscription?.paid_until).locale(intl.locale).format('MMM D, YYYY'),
                    canceledAt: dayjs(canceled_at).locale(intl.locale).format('MMM D, YYYY'),
                  }}
                />
              )}
              {props.user?.id && (
                <>
                  <Link to={`${location.pathname}?wsettings=1&tab=plans&plansTab=invoices`}>
                    <FormattedMessage id='SEE_INVOICES' />
                  </Link>
                  {!canceled_at && (
                    <Link to={`${location.pathname}?wsettings=1&tab=plans&plansTab=cancel`}>
                      <FormattedMessage id='CANCEL_SUBSCRIPTION' />
                    </Link>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <div className='plan-list'>
        <div className='plan-list-head'>
          <h2>
            <FormattedMessage id='PLANS' />
          </h2>
          <Tumbler
            items={[
              'MONTHLY',
              'YEARLY'
            ]}
            view={view}
            next={next}
          />
        </div>
        <div className='plan-list-body'>
          <div className='plan-list-copy' dangerouslySetInnerHTML={{ __html: basicCopy[intl.locale] }} />

          <div className='plan-list-items'>
            {plans.map((plan) => {
              const isCurrentPlan = plan.name === activePlan?.name && activePlan?.period === view
              return (
                <div key={plan.name} className='plan-list-item'>
                  <h2>
                    <FormattedMessage id={plan.name || ''} />
                  </h2>
                  <div className='plan-list-item-price'>
                    {view === 'MONTHLY' && (
                      <React.Fragment>
                        {getCurrencySymbol(plan.currency_code)}{plan.monthly_price_per_seat / 100}
                      </React.Fragment>
                    )}
                    {view === 'YEARLY' && (
                      <React.Fragment>
                        {getCurrencySymbol(plan.currency_code)}{plan.yearly_price_per_seat / 100}
                      </React.Fragment>
                    )}
                    <small>
                      <FormattedMessage id={view} /> {view === 'YEARLY' && <span>(-20%)</span>}
                    </small>
                  </div>

                  <div
                    className='plan-list-item-body'
                    dangerouslySetInnerHTML={{ __html: plan.features[intl.locale] }}
                  />

                  <div className='plan-list-item-footer'>
                    <div className='plan-list-item-cta'>
                      {(isOnTrial || isTrialLapsed) && (
                        <button onClick={onPlanSelect(plan)}>
                          <FormattedMessage
                            id='SELECT_PLAN'
                            values={{
                              planName: intl.formatMessage({ id: plan.name || '' })
                            }}
                          />
                        </button>
                      )}
                      {!isOnTrial && !isTrialLapsed && (
                        <button onClick={onPlanSelect(plan)} disabled={isCurrentPlan}>
                          {isCurrentPlan && (
                            <FormattedMessage id='CURRENT_PLAN' />
                          )}
                          {!isCurrentPlan && (
                            <FormattedMessage
                              id='SELECT_PLAN'
                              values={{
                                planName: intl.formatMessage({ id: plan.name || '' })
                              }}
                            />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
