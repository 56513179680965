import Themes from 'shared/Themes'
import LanguageSelect from 'shared/LanguageSelect'
import { Link } from '@reach/router'
import QuestionSVG from '../../icons/remix/system/question-line.svg'
import './styles.scss'

export default function UIControls(props) {
  const hasUserOnboarded = props?.user?.data?.has_onboarded
  const isCentered = !hasUserOnboarded || props.location.pathname === '/'

  return (
    <div className={`ui-controls ${props.fixed ? 'is-fixed' : ''} ${isCentered ? 'is-centered' : ''}`}>
      <LanguageSelect position='top from-left' />
      <Themes short />
      {props.activeWorkspace?.slug && (
        <Link
          className='help-link'
          to={`/${props.activeWorkspace?.slug}/help`}
        >
          <QuestionSVG />
        </Link>
      )}
    </div>
  )
}
