import React from 'react'
import { Link, navigate } from '@reach/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { useGlobalStore } from '../../lib/global'
import XSVG from '../../icons/remix/system/close-fill.svg'
import styles from './index.module.scss'
import PlansSettings from './Plans'

import General from './General'
import Members from './Members'
import Usage from './Usage'

const SettingsComponent = (props) => {
  const intl = useIntl()
  const { activeWorkspace } = props
  const { auth, location } = useGlobalStore(s => ({
    auth: s.auth,
    location: s.location,
  }))
  const isActive = location.query?.wsettings === '1'

  const onClose = React.useCallback(() => {
    navigate(location.pathname)
  }, [location.pathname])

  if (!isActive || !activeWorkspace || !auth.isAuthenticated) return null

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'WORKSPACE_SETTINGS' })}
        </title>
      </Helmet>

      <div
        className={`${styles.settingsOverlay} ${isActive ? 'active' : ''}`}
        onClick={onClose}
      />

      <div className={`${styles.settings} ${isActive ? 'active' : ''}`}>
        <button className={`${styles.closeButton} internal`} onClick={onClose}>
          <XSVG />
        </button>
        <div className='horizontal-menu is-active'>
          <div className='horizontal-menu-block'>
            <div className='horizontal-menu-block-title'>
              <span>{activeWorkspace?.info?.name}</span>
            </div>
          </div>
          <div className='horizontal-menu-block'>
            <Link
              to={`${location.pathname}?wsettings=1&tab=general`}
              className={[
                'horizontal-menu-link',
                location.query?.wsettings === '1' && (!location.query?.tab || location.query?.tab === 'general') ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='GENERAL' />
            </Link>
            <Link
              to={`${location.pathname}?wsettings=1&tab=members`}
              className={[
                'horizontal-menu-link',
                location.query?.wsettings === '1' && location.query?.tab === 'members' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='WORKSPACE_MEMBERS' />
            </Link>
            <Link
              to={`${location.pathname}?wsettings=1&tab=plans`}
              className={[
                'horizontal-menu-link',
                location.query?.wsettings === '1' && location.query?.tab === 'plans' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='PLANS' />
            </Link>
            <Link
              to={`${location.pathname}?wsettings=1&tab=usage`}
              className={[
                'horizontal-menu-link',
                location.query?.wsettings === '1' && location.query?.tab === 'usage' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='USAGE' />
            </Link>
          </div>
        </div>
        <div className={styles.settingsBody}>
          <div className={styles.pageWrapper}>
            {(!location.query?.tab || location.query.tab === 'general') && (
              <General {...props} />
            )}
            {(location.query.tab === 'members') && (
              <Members {...props} />
            )}
            {(location.query.tab === 'usage') && (
              <Usage {...props} />
            )}
            {(location.query.tab === 'plans') && (
              <PlansSettings {...props} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsComponent
