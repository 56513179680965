import * as React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import WorkspacePlanSwitch from '../../WorkspacePlanSwitch'
import { useGlobalStore } from '../../../lib/global'
import styles from '../index.module.scss'

export default function PlansPage(props) {
  const { auth, accountDb, location } = useGlobalStore(s => ({
    auth: s.auth,
    location: s.location,
    accountDb: s.accountDb
  }))

  const user = useLiveQuery(() => {
    if (!auth?.user?.id || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  return (
    <div className={styles.wrapper} id='plan'>
      <div className={`${styles.body} plans`}>
        <WorkspacePlanSwitch
          {...props}
          auth={auth}
          location={location}
          user={user}
          />
      </div>
    </div>
  )
}
