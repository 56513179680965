import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export default function validateInput(data) {
  const errors = {}

  if (data.username !== null && Validator.isEmpty(data.username)) {
    errors.username = 'Required'
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
