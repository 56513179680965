import * as React from 'react'
import { nanoid } from 'nanoid'
import { v4 as uuid } from 'uuid'
import { FormattedMessage, useIntl } from 'react-intl'
import Input from '../../Input'
// import { notifyWorkspaceArchived } from 'shared/notifications/workspace'
import { useGlobalStore } from '../../../lib/global'
import validateInput from './validate'
import styles from '../index.module.scss'
import WorkspaceIconForm from './IconForm'

const initialState = {
  name: null,
  slug: null,
  errors: {
    name: '',
    slug: ''
  },
  isChanged: false,
  isLoading: false
}

function WorkspacePage({ activeWorkspace }) {
  const intl = useIntl()
  const [state, setState] = React.useState(initialState)
  const { databases, accountDb, location } = useGlobalStore(s => ({
    databases: s.databases,
    accountDb: s.accountDb,
    location: s.location,
  }))
  const db = databases?.[activeWorkspace?.id]

  const onChange = e => {
    setState({
      ...state,
      isChanged: true,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  const isValid = React.useCallback((state) => {
    const { errors, isValid } = validateInput(state)
    if (!isValid) {
      setState({ errors })
    }
    return isValid
  }, [])

  const onSubmit = async e => {
    e.preventDefault()

    if (isValid(state)) {
      setState({
        ...state,
        errors: {},
        isLoading: true
      })

      const { name, slug } = state

      if (!db) return

      if (activeWorkspace?.info?.id) {
        db.infos.update(activeWorkspace?.info.id, {
          updated_at: (new Date()).toISOString(),
          ...name === null ? {} : { name },
          ...slug === null ? {} : { slug }
        })
      } else {
        const payload = {
          id: uuid(),
          short_id: nanoid(),
          workspace_id: activeWorkspace.id,
          created_at: (new Date()).toISOString(),
          updated_at: (new Date()).toISOString(),
          lang: location.payload.lang,
          ...name === null ? {} : { name },
          ...slug === null ? {} : { slug }
        }
        db.infos.add(payload)
      }

      if (!activeWorkspace?.meta?.data?.has_setup_workspace) {
        db.metas.update(activeWorkspace.meta?.id, {
          updated_at: (new Date()).toISOString(),
          'data.has_setup_workspace': true
        })
      }
    }
  }

  const archiveWorkspace = React.useCallback(() => {
    accountDb.workspaces.update(activeWorkspace.id, {
      updated_at: (new Date()).toISOString(),
      status: 'ARCHIVED'
    })
    // navigate to home
    // dispatch({
    //   type: 'HOME',
    //   payload: {
    //     lang: location.payload.lang
    //   }
    // })
    // notifyWorkspaceArchived(activeWorkspace)
  }, [activeWorkspace, accountDb?.workspaces])

  const cancel = () => setState(initialState)

  return (
    <div className={styles.wrapper} id='general'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='WORKSPACE_SETTINGS' />
        </div>
      </div>
      <div className={`${styles.body} workspace`}>
        <WorkspaceIconForm activeWorkspace={activeWorkspace} />
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.fieldset}>
            <Input
              error={state.errors.name}
              placeholder=''
              onChange={onChange}
              value={state.name === null ? activeWorkspace?.info?.name : state.name}
              label={intl.formatMessage({ id: 'WORKSPACE_NAME' })}
              type='text'
              id='name'
              name='name'
            />
          </div>
          {false && <div className={styles.fieldset}>
            <Input
              error={state.errors.slug}
              placeholder=''
              onChange={onChange}
              value={state.slug === null ? activeWorkspace?.slug : state.slug}
              label={intl.formatMessage({ id: 'WORKSPACE_SLUG' })}
              type='text'
              id='slug'
              name='slug'
            />
          </div>}
          <div className={styles.footer}>
            <button className={`${styles.button} saveButton`} disabled={!state.isChanged || state.isLoading}>
              <FormattedMessage id='SAVE' />
            </button>
            <button className={`${styles.button} cancelButton`} disabled={!state.isChanged} onClick={cancel}>
              <FormattedMessage id='CANCEL' />
            </button>
          </div>
        </form>
      </div>
      {activeWorkspace?.membership?.role === 'ADMIN' && activeWorkspace?.membership?.status === 'ACTIVE' && (
        <div className={styles.body}>
          <div>
            <FormattedMessage id='DANGER_ZONE' />
          </div>
          <div>
            <FormattedMessage id='WORKSPACE_ARCHIVE_WARNING' defaultMessage='Workspace with all documents will no longer be available to you.' />
          </div>
          <button onClick={archiveWorkspace}>
            <FormattedMessage id='ARCHIVE_WORKSPACE' />
            Archive workspace
          </button>
        </div>
      )}
    </div>
  )
}

export default WorkspacePage
