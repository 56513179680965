import React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import ImageSelect from '../../ImageSelect'
import { useGlobalStore } from '../../../lib/global'
import styles from '../index.module.scss'

const SettingsProfileAvatarForm = () => {
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb,
  }))

  const user = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  const updateProfilePic = React.useCallback(async (payload) => {
    if (!payload) return
    accountDb.accounts.update(auth.user?.id, {
      picture_url: payload.picture_url
    })
  }, [auth.user?.id, accountDb])

  return (
    <div className={styles.avatarSelectWrapper}>
      <ImageSelect
        src={user?.picture_url || ''}
        onUploadFinish={updateProfilePic}
        withEditor
      />
    </div>
  )
}

export default SettingsProfileAvatarForm
