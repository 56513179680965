import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/ru'
import 'dayjs/locale/ja'
// import 'dayjs/locale/ko'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

export default dayjs
