export default {
  code: 'ja',
  CURRENT_LANGUAGE: '日本語',

  en: '英語',
  ja: '日本語',
  ko: '韓国語',
  fr: 'フランス語',
  ru: 'ロシア語',
  de: 'ドイツ語',
  kk: 'カザフ語',

  // ui
  FULLSCREEN_ENTER: '全画面表示',
  FULLSCREEN_EXIT: '全画面解除',
  CLOSE: '閉じる',
  ADD: '追加',
  ENABLE: '有効',
  DISABLE: '無効',
  REMOVE: '削除',
  NAME: '名前',
  NO_NAVIGATION_SET: 'ナビゲーションが設定されていません。',
  NO_COMMERCE_SET: 'コマースがオフです。',

  SETTINGS: '設定',
  DASHBOARD: 'ダッシュボード',
  PRICE: '価格',
  WELCOME: 'ようこそ',
  WELCOME_BACK: 'おかえりなさい',

  // context
  OVERVIEW: '概要',
  EDITOR: 'エディター',
  CONTENT: 'コンテンツ',
  GENERAL: '一般',
  NAVIGATION: 'ナビゲーション',
  DESIGN: 'デザイン',
  STYLE: 'スタイル',
  COVER: 'カバー',
  MARKETING: 'マーケティング',
  AUDIENCE: '観客',
  SOCIAL: 'ソーシャルメディア',
  COMMERCE: 'コマース',
  OFFERINGS: 'オファー',
  ADVANCED: '高度な設定',
  COLLECTIONS: 'コレクション',
  DOMAINS: 'ドメイン',
  EXPORT: 'エクスポート',
  LIVE: 'ライブ',
  VERSIONS: 'バージョン',
  ANALYTICS: '分析',
  COMING_SOON: '近日公開',
  MARKETS: '市場',
  TITLE: 'タイトル',
  DESCRIPTION: '説明',
  SHOW_PLAIN_COVER: 'シンプルなカバーを表示',
  SHOW_COMING_SOON: '近日公開を表示',
  TAGLINES: 'キャッチフレーズ',
  TAGLINE: 'キャッチフレーズ',
  DANGER_ZONE: '危険ゾーン',
  TRASH: 'ゴミ箱',
  RESTORE: '復元',
  DELETE_FOREVER: '永久に削除',
  MENU: 'メニュー',
  TERMS: '利用規約',
  REFUND_POLICY: '返金ポリシー',
  PRIVACY_POLICY: 'プライバシーポリシー',
  DOWNLOADS: 'ダウンロード',
  FEEDBACK: 'フィードバック',
  HELP: 'ヘルプ',
  UNTITLED: 'タイトルなし',
  PRODUCTS: '製品',
  LOGIN: 'ログイン',
  LOGOUT: 'ログアウト',
  PAYOUTS: '支払い',
  CUSTOMERS: '顧客',
  THEMES: 'テーマ',
  LOADING: '読み込み中',
  PRODMAKE: 'プロダクトメイク',

  MADE_WITH: '作成者:',
  LANGUAGES: '言語',
  LANGUAGE: '言語',

  ALREADY_PURCHASED: '既に購入済み',
  GET_FULL_ACCESS: 'フルアクセスを取得',

  USD: '米ドル',
  BALANCE: '残高',
  TOTAL_BALANCE: '合計残高',
  PROFIT: '利益',
  REVENUE: '収益',
  TURNOVER: '売上高',
  TRANSACTIONS: '取引',
  DATE: '日付',
  CURRENCY: '通貨',
  TRANSACTION_CURRENCY: '取引通貨',
  MONEY_IN: '入金',
  MONEY_OUT: '出金',
  FEE: '手数料',
  STATUS: 'ステータス',
  EMAIL: 'メール',
  PRODUCT: '製品',
  VERSION: 'バージョン',
  OFFERING: '提供',
  WITHDRAW: '引き出す',
  UPGRADE_TO_PRO: 'Proにアップグレード',
  QUICK_ACCESS: 'クイックアクセス',
  GETTING_STARTED: 'スタートガイド',
  GETTING_TRACTION: '成長の開始',
  SUGGESTED_FOR_YOU: 'おすすめ',

  WORKSPACE_SETTINGS: 'ワークスペースの設定',
  CREATE_WORKSPACE: 'ワークスペースを作成',
  JOIN_WORKSPACE: 'ワークスペースに参加',
  GO_TO_STORE: 'ストアへ行く',

  RESTORE_PURCHASE: '購入の復元',
  PRODUCT_VIEWS: '商品の閲覧',
  SALES: '販売',
  CONVERSION_RATE: 'コンバージョン率',
  PRODMAKE_PRODUCT_TITLE: 'Prodmake商品',
  PRODMAKE_DESCRIPTION: 'Prodmakeで商品を作成',
  SECURE_ACCESS_LINK: 'メールに安全なアクセスリンク',
  ALREADY_PURCHASED_SUBMITTED: '正常に受け付けられました。メールを確認してください。',
  ERROR: 'エラー',
  MONEY_BACK_GUARANTEE_POINT: '5日間の返金保証',
  INSTANT_ACCESS_POINT: 'ウェブ、.pdf、.epubバージョンへの即時アクセス',
  FUTURE_UPDATES_POINT: '今後のアップデート',
  PARTNER_PERKS_POINT: 'パートナーからの特典は$800の価値',
  LAST_UPDATED_POINT: '最後の更新',
  GET_FOR_FREE_SUBMITTED: '無料版へのアクセスができるようになりました。',
  GET_FOR_FREE_NOW: '今すぐ無料で入手',
  WIP: '作業中',
  POWERED_BY_PRODMAKE: 'プロドメイク製',
  RAW_HTML: 'HTML',
  EPUB: 'Epub',
  SHORT_ID: '短いID',
  NEW: '新しい',
  NO_PRODUCT_PUBLISHED: '製品は公開されていません',
  PREVIEW: 'プレビュー',
  VIEWING: '表示中',
  OPEN_LIVE: '新しいウィンドウで開く',
  BACK_TO_WEB: 'ウェブに戻る',
  NO_ITEMS_TO_DISPLAY_RAW: '表示する項目がありません raw',
  BACK_TO_HOMEPAGE: 'ホームページに戻る',
  ACCESS_NOW_FOR_FREE: '無料でアクセス',
  GET_ACCESS_NOW: '今すぐアクセス',
  BE_FIRST_TO_ACCESS: '最初にアクセスする',
  CHANGE_STRUCT_COVER: 'カバーを変更する',
  CHANGE_LOGO: 'ロゴを変更する',
  CHANGE_AVATAR: 'アバターを変更する',
  CATEGORY: 'カテゴリー',
  MANUALS: 'マニュアル',
  BOOKS: '本',
  GUIDES: 'ガイドブック',
  PAGES: 'ページ',
  LAST_RELEASE: '最新リリース',
  AGE_RATING: '年齢制限',

  SERIF: 'セリフ',
  SANS_SERIF: 'サンセリフ',
  MONOSPACE: 'モノスペース',
  FULL_WIDTH: '全角',
  NARROW: '狭い',
  SIMPLE: 'シンプル',
  BLOCK: 'ブロック',
  MINIMAL: 'ミニマル',
  FONTS: 'フォント',
  CHAPTERS: '章',
  FOOTER: 'フッター',
  REQUEST_ACCESS: 'アクセスをリクエスト',
  NOW: '今',
  BUY: '購入',
  STAY_TUNED: '登録',
  VIEWING_LIMITED_VERSION: '限定版を閲覧中です。',
  VIEWING_LIMITED_VERSION_OBFUSCATED: 'テキストが難読化された限定版を閲覧中です。',
  TO_SEE_CONTENT: '完全なアクセスのため',
  ABOUT_AUTHOR: '著者について',
  RENDERING: 'レンダリング',
  SERIES_NAME: 'シリーズ名',
  BOOK_NUMBER: '書籍番号',
  PUBLISHER: '出版社',
  DEDICATION: '献呈',
  MESSAGE: 'メッセージ',
  EBOOK_ISBN: '書籍のISBN',
  OPTIONAL: '任意',
  AUTHOR: '著者',
  TITLE_PAGE: 'タイトルページ',
  WEB: 'ウェブ',
  EBOOK: '電子書籍',
  PDF: 'PDF',
  SHOW_PUBLICATION_DATE: '出版日を表示',
  SHOW_RELEASE_DATE: 'リリース日を表示',
  SHOW_VERSION: 'バージョンを表示',
  DOWNLOAD: 'ダウンロード',
  REGENERATE: '再生成',
  SHOW_AUTHOR_INFO: '著者情報を表示',
  SHOW_WORKSPACE_INFO: 'ワークスペース情報を表示',
  BIO: 'バイオ',
  PICTURE_URL: '画像URL',
  OVERRIDE: '上書き',
  CONTRIBUTORS: '寄稿者',
  IMAGE: '画像',
  SHOW: '表示',
  COLLECTION: 'コレクション',
  LINKS: 'リンク',
  REMOVE_SECTION: 'セクションを削除',
  INCLUSION_AND_ACCESS: '含むとアクセス',
  FULL_ACCESS: 'フルアクセス',
  DELETE_ITEM: '削除',
  COLLECTION_TITLE: 'コレクションのタイトル',
  SLUG: 'スラグ',
  REMOVE_COLLECTION: 'コレクションを削除',
  INCLUDES_COLLECTION_ITEMS: 'コレクションからのアイテム{count}/{total}を含む:',
  WITH_FULL_ACCESS: 'フルアクセスで',
  VISIBLE_ITEMS: '{count}/{total}がフルアクセスで表示',
  OFFERING_AVAILABLE_FOR_FREE: 'このオファーは無料で利用できます。',
  CURRENCY_AMOUNT_TERMS: '{name}の最小金額は{symbol}{min_amount}、最大は{symbol}{max_amount}です。',
  PREVIEW_VERSION: 'プレビューバージョン',
  TOC: '目次',
  DOMAIN_SETUP_MESSAGE: 'CNAMEレコードを使用して、ドメインを*.prodmake.comに向ける必要があります。',
  VALIDATE: '検証',
  REMOVE_DOMAIN: 'ドメインを削除',
  WORDS: '単語数',
  ORIGINALLY_PUBLISHED: '元々公開された',
  LAST_UPDATED: '最後に更新された',
  ORIGINALLY_PUBLISHED_AT: '{date}に最初に公開されました',
  LAST_UPDATED_AT: '{date}に最後に更新されました',
  LOCALIZATION: 'ローカリゼーション',
  SELECT_AT_LEAST_ONE_LANGUAGE: '少なくとも1つの言語を主要として残してください',
  THIS_COPY_BELONGS_TO: 'このコピー v{version} は {email} のものです',
  REQUEST: 'リクエスト',
  PERKS_WORTH: '特典総額 ~$800',
  PAY_AMOUNT: '{amount} を支払う',
  PAGE_LEFT_BLANK: 'このページは空白です。',
  PRIMARY_LANGUAGE: '主要な言語',
  SUPPORTED_LANGUAGES: 'サポートされている言語',
  SUCCESSFUL_PAYMENT_MESSAGE: '支払いが成功しました。',
  PAYMENT_IS_PROCESSING_MESSAGE: '支払いが処理中です。',
  PAYMENT_UNSUCCESSFUL_MESSAGE: '支払いが失敗しました。支払い方法を確認してください。',
  SOMETHING_WENT_WRONG: '何か問題が発生しました。再試行してください。',
  INCLUDED_IN: '以下に含まれる',
  DETAILS: '詳細',
  SUBTITLE: 'サブタイトル',
  PAGE_NOT_FOUND: 'ページが見つかりません',
  THANK_YOU: 'ありがとう',
  START: 'スタート',
  PRODMAKE_PITCH_TITLE: 'Prodmakeで製品を作成する',
  PRODMAKE_PITCH_CTA: '{link}は本当にグローバルな製品のためのエディタです',
  TRY_PRODMAKE: '今すぐ始める',
  SCROLL_PURCHASE: 'スクロール時の「購入」',
  ITEM_INCLUDED_IN_OFFERINGS: 'オファーにアイテムが含まれています',
  VISIBLE: '表示',
  HIDDEN: '非表示',
  PROPERTIES: 'プロパティ',
  BANNER_CTA: 'バナーCTA (call-to-action)',
  PURCHASE_FOR_FULL_ACCESS: '完全アクセスのために購入する',
  ACCESS: 'アクセス',
  SAMPLE: 'サンプル',
  VISIBLE_OBFUSCATED: '表示されるが難読化された',
  ALL_LANGUAGES: 'すべての言語',
  PURCHASED_ON: '購入日',
  ITEM_NOT_PUBLIC: 'アイテムはまだ公開されていません',
  SUCCESSFUL_FREEBIE_MESSAGE: 'アクセスが許可されました',
  ACCESSED_ON: 'アクセス日',
  SETTLED: '決済済み',
  UNIVERSAL: 'ユニバーサル',
  ALL: 'すべて',
  TRANSLATE: '翻訳',
  TRANSLATE_TO: '翻訳する',
  UNIQUE: 'ユニーク',
  'N/A': 'N/A',
  WRITING: 'ライティング',
  TASKS: 'タスク',
  PAGE_LIST: 'ページ一覧',
  PURCHASE_INFO: '購入情報',
  HERO: 'ヒーロー (バナー)',
  SCROLL_PROGRESS: 'スクロール進行度',
  HOME_SCROLL: 'ホームスクロール',
  NAV: 'ナビゲーション',
  PRODUCT_PROPERTIES: '商品の特性',
  PRODUCT_AUTHOR: '著者情報',
  BLUEPRINT: '設計図',
  BLOCKS: 'ブロック',
  READY_TO_USE: '使用準備完了',
  WEB_BOOK: 'ウェブブック',
  COVER_RIBBON: 'カバーリボン',
  COVER_INTERACTION: 'カバーのインタラクション',
  STRUCTURE: '構造',
  WIDTH: '幅',
  ENABLED: '有効',
  DISABLED: '無効',
  DISTRIBUTION: '配布',
  AUTHOR_BIO: '著者について',
  EXPORT_EPUB: 'Epubとしてエクスポート',
  EXPORT_PDF: 'PDFとしてエクスポート',
  VIEW_JSON: 'JSONとして表示',
  CHOOSE_TYPE: 'タイプを選択',
  UNIVERSAL_BOOK: 'ユニバーサルブック',
  PLAYLIST_COURSE: 'プレイリストコース',
  PERSONAL_WEBSITE: '個人のウェブサイト',
  IMPORT: 'インポート',
  BILLING: '課金',
  PAYOUT: '支払い',
  PLANS: 'プラン',
  NOTIFICATIONS: '通知',
  PREFERENCES: '設定',
  PROFILE: 'プロフィール',
  CANCEL: 'キャンセル',
  SAVE: '保存',
  ACCOUNT: 'アカウント',
  FULLNAME: '氏名',
  PAYMENT_CARDS: '支払いカード',
  NO_PAYMENT_METHODS_FOUND: '支払い方法が見つかりません',
  CARD_ADDED_SUCCESSFULLY: 'カードが正常に追加されました',
  ADD_CARD: 'カードを追加',
  GRIND: 'グラインド',
  YEAR_ONE: '1年目',
  PRO: 'プロフェッショナル',
  YEARLY: '年間プラン',
  MONTHLY: '月間プラン',
  CHOOSE_PLAN: 'プランを選ぶ',
  PAYMENT_METHOD: '支払い方法',
  CONFIRMATION: '確認',
  SELECT: '選択',
  SELECT_PLAN: '「{planName}」を選ぶ',
  NEXT: '次へ',
  YOUR_CURRENT_FREE_TRIAL_PLAN: '現在の無料試用期間「{planName} {planPeriod}」は{trialUntil}に終了します。',
  YOUR_CURRENT_PAID_PLAN: '現在の有料プラン「{planName} {planPeriod}」は{paidUntil}に更新されます。',
  CURRENT_PLAN: '現在のプラン',
  INBOX: '受信トレイ',
  FINISH: '終了',
  CHOOSE_PAYMENT_METHOD: '支払い方法を選んでください',
  ACTIVATE_SELECTED_PLAN: '選択したプランを有効化',
  BACK_TO_CARD_LIST: 'カードリストに戻る',
  LAST_PRODUCT_VIEWS: '最後に閲覧した商品',
  NO_VIEWS_YET: '未閲覧',
  FREE_TRIAL_ENDS: '無料トライアルが{inDays}日で終了',
  WORKSPACE_NAME: 'ワークスペース名',
  NEW_WORKSPACE: '新しいワークスペース',
  WORKSPACE_MEMBERS: 'ワークスペースのメンバー',
  INVITE: '招待',
  WORKSPACE_INVITE: 'ワークスペースに招待',
  NO_MEMBERS_FOUND: 'メンバーが見つかりません',
  UPGRADE_NOW: '今すぐアップグレード',
  NOVICE: '初心者',
  ESTABLISHED: '熟練者',
  SEE_INVOICES: '請求書を見る',
  CANCEL_SUBSCRIPTION: 'サブスクリプションをキャンセル',
  BACK: '戻る',
  MONTHLY_RATE: '月額料金',
  YEARLY_RATE: '年間料金',
  PLAN: 'プラン',
  SUBSCRIPTION_ACTIVATED_1: 'サブスクリプションが正常に有効にされました。{date}まで支払い済みです。',
  SUBSCRIPTION_ACTIVATED_2: 'クレジットカードの支払いは、明細書にPRODMAKE（またはSTRIPE：保留中の請求の場合）として表示されます。',
  SUBSCRIPTION_ACTIVATED_3: '確認のメールを送信します。Prodmakeを選んでいただきありがとうございます！',
  SUCCESSFULLY_ACTIVATED: '正常に有効化されました',
  CONTINUE: '続ける',
  MONTHLY_PLAN_RATE_PER_SEAT: '{rate} ユーザーごと/月',
  MONTHLY_PLAN_RATE: '{rate} /月',
  YEARLY_PLAN_RATE_PER_SEAT: '{rate} ユーザーごと/年',
  YEARLY_PLAN_RATE: '{rate} /年',
  TRIAL_PERIOD: '試用期間',
  N_DAYS: '{count}日間',
  YOU_WONT_BE_CHARGED_NOW: '今は課金されません',
  START_PLAN_TRIAL: '今すぐ試用を開始',
  NO_PURCHASES_YET: 'まだ購入がありません',
  UPCOMING_PAYOUTS: '今後の支払い',
  UPDATE_PERSONAL_DETAILS: '個人情報を更新',
  UPDATE_WORKSPACE_DETAILS: 'ワークスペースの詳細を更新',
  TRANSLATE_AUTOMATICALLY: '自動的に翻訳',
  GET_NEW_PRODUCT_IDEAS: '新しい商品アイデアを取得',
  MAKE_YOUR_FIRST_SALE: '初めての販売を行う',
  GET_YOUR_FIRST_PAYOUT: '初めての支払いを受ける',
  SET_UP_A_PROFILE: 'プロフィールを設定',
  SET_UP_A_WORKSPACE: 'ワークスペースを設定',
  SET_UP_A_PAYMENT_METHOD: '支払い方法を設定',
  SET_UP_PAYOUT: '支払いを設定',
  CREATE_YOUR_FIRST_PRODUCT: '初めての商品を作成',
  PUBLISH_YOUR_FIRST_PRODUCT: '初めての商品を公開',
  ONBOARDED: 'オンボード済みのワークスペース',
  YOU_DONT_HAVE_UPCOMING_PAYOUTS: '今後の支払いはありません',
  IDEAS: 'アイデア',
  PARAPHRASE: 'パラフレーズ',
  STATS: '統計',
  CREATED: '作成済み',
  PROCESSING: '処理中',
  PROCESSED: '処理済み',
  AI_STRUCT_TRANSLATION: '翻訳',
  AI_STRUCT_REVISE: '言い換え',
  AI_STRUCT_CHECK_GRAMMAR: '文法を修正',
  AI_BOOK_GENERATION: '書籍生成',
  AI_WRITE_DRAFT: '下書きを書く',
  AI: 'AI',
  VIEW: '表示',
  ACCEPT: '承諾',
  GENERATE: '生成',
  DONT_HAVE_AN_ACCOUNT: 'アカウントをお持ちではありませんか？',
  JOIN_PRODMAKE: 'Prodmakeに参加',
  ALREADY_HAVE_AN_ACCOUNT: '既にアカウントをお持ちですか？',
  INVITATION_CODE: '招待コード',
  PASSWORD: 'パスワード',
  LOGGED_IN_AS: '{email}としてログイン',
  SELECT_OR_CREATE: '選択または作成',
  NEW_TASK: '新しいタスク',
  SUBMIT: '提出',
  FOR_WHOM: '誰のために',
  COMPLETED_IN: '完了',
  ASSIGNEE: '担当者',
  COST: 'コスト',
  COST_INCLUDED: 'プランに含まれるコスト',
  PAGE_COUNT: '{count}ページ',
  DELETE: '削除',
  HAS_NOT_BEEN_COMPLETED: 'まだ完了していません',
  REPEAT_TASK: 'タスクを繰り返す',
  SECOND_COUNT: '{count} 秒',
  TASK: 'タスク',
  INTO_IDEA: 'アイデアへ',
  INTO_BOOK: '本へ',
  INTO_PRODUCT: '製品へ',
  COLLECTION_COUNT: 'コレクション数 ({count})',
  OFFERING_COUNT: 'オファー数 ({count})',
  NEW_VERSION: '新しいバージョン',
  SUBMIT_NEW_VERSION: 'レビューに提出する',
  IS_PUBLIC: '公開アクセス',
  IS_IN_STORE: 'Prodmakeストアに表示されています',
  ACCESS_FOR_PREVIOUS_CUSTOMERS: '以前のバージョンを所有する顧客にアクセス可能',
  LEARN_MORE: '詳細',
  ACCESS_LINKS_SENT: 'アクセスリンクがあなたのメールに送信されました。',
  TRIAL_ENDED: '試用期間が終了しました',
  BACK_TO_ALL: 'すべてに戻る',
  WORKSPACE_EXPLAIN_CAPTION: 'ここでは、ワークスペースが表示されます。製品を作成するには {link}。',
  PROVIDE_FEEDBACK: 'フィードバックを提供する',
  USAGE: '使用',
  OPEN: '開く',
  SKIP: 'スキップ',
  FINALIZE: '最終',
  LAST_TRANSACTIONS: '最後の取引',
  VIEW_ALL: 'すべて表示',
  START_TOUR: 'ツアーを開始',
  ACTIVATE_WORKSPACE: 'ワークスペースをアクティベート',
  MARK_AS_DRAFT: '下書きとしてマーク',
  DRAFT_PREVIEW_EARLY_ACCESS: 'これはドラフトプレビューで、早期アクセス用です。製品は最終形態ではありません。',
  TYPE: 'タイプ',
  BLOCK_BASED: 'ブロックベース',
  TEXT_BASED: 'テキストベース',
  BRAINSTORM: 'ブレインストーミング',
  WITH_CORRECT_GRAMMAR: '正しい文法で',
  IN_DIFF_WORDS: '異なる言葉で',
  IN_KINDER_TONE: '優しいトーンで',
  IN_PROFESSIONAL_TONE: 'プロフェッショナルなトーンで',
  IN_ASSERTIVE_TONE: '断定的なトーンで',
  IN_FRIENDLY_TONE: 'フレンドリーなトーンで',
  IN_EXPERT_TONE: 'エキスパートトーンで',
  WITHOUT_ADJECTIVES: '形容詞なしで',
  NO_FLUFF: '無駄なし',
  REVISE: '修正',
  CREATE_TASK: 'タスクを作成',
  IN_PROGRESS: '進行中',
  INTEGRATE_TRANSLATION: '翻訳を統合',
  MECHANICAL_TURK: 'メカニカルターク',
  READY: '準備完了',
  CHOOSE_TASK_TYPE: 'タスクタイプを選択',
  INTEGRATE: '統合',
  START_PLAN: 'プランを開始',
  USED: '使用済み',
  UNUSED: '未使用',
  GO_TO_SOURCE: 'ソースへ移動',
  DRAFTS: 'ドラフト',
  DUPLICATE: '複製',
  YOUR_TRIAL_PLAN_LAPSED: '「{planName} {planPeriod}」の無料トライアルプランは{trialUntil}に終了しました。今がサブスクリプションをアクティベートする時です。',
  YOU_WILL_BE_IMMEDIATELY_CHARGED: 'すぐに課金されます。',
  ACTIVATE_PLAN: 'プランをアクティベート',
  SUBSCRIPTION_PLAN_IS_ACTIVATED: 'サブスクリプションプランがアクティブ化されています',
  TRIAL_PLAN_IS_ACTIVATED: 'トライアルプランがアクティブ化されています',
  TRIAL_PLAN_ACTIVATED_DETAIL: '「{planName}」プランの無料トライアルは{trialEnds}に終了します。',
  PAID_PLAN_ACTIVATED_DETAIL: 'あなたの「{planName} ({planPeriod})」プランがアクティブ化されました。',
  PAID_PLAN_WILL_RENEW: '{paidUntil}に更新されます。',
  AI_REQUESTS: 'AIリクエスト',
  ROYALTIES: 'ロイヤルティ',
  RESETS_MONTHLY: '毎課金期間にリセットされます',
  SOURCE: 'ソース',
  START_PRODUCT_TOUR: '製品ツアー',
  WELCOME_TO_PRODMAKE: 'Prodmakeへようこそ！',
  WELCOME_TO_PRODMAKE_CAPTION: 'セルフパブリッシングのためのオールインワンエディター。',
  SELECT_A_WORKSPACE_FIRST: '最初にワークスペースを選択してください',
  SELECT_A_WORKSPACE_FIRST_CAPTION: 'あなたのワークスペースをチェックしましょう。',
  YOUR_WORKSPACE: 'あなたのワークスペース',
  YOUR_WORKSPACE_CAPTION: 'ここがあなたのワークスペースで、製品の作成、新しいチャプターの作業、新しいバージョンの公開、EpubやPDFへのエクスポート、販売の分析からすべてを行います。',
  WORKSPACE_NAVIGATION: 'ワークスペースナビゲーション',
  WORKSPACE_NAVIGATION_CAPTION: 'これが移動する方法です。',
  WORKSPACE_OVERVIEW: 'ワークスペースの概要',
  WORKSPACE_OVERVIEW_CAPTION: 'ここでワークスペースのパフォーマンスを確認できます。',
  AT_GLANCE: '一目で',
  AT_GLANCE_TOUR_CAPTION: 'ここには、ビジネスのキーインディケーターがあります。',
  QUICK_ACTIONS: 'クイックアクション',
  QUICK_ACTIONS_CAPTION: 'ここには便利なアクションがあります。',
  PRODMAKE_EDITOR: 'Prodmakeエディター',
  PRODMAKE_EDITOR_CAPTION: '製品の管理方法を見てみましょう。',
  PRODUCT_PAGE: '製品ページ',
  PRODUCT_PAGE_CAPTION: 'これは製品ページで、常にオンのプレビューで複数のフォーマットで全てを見ることができます。',
  NAVIGATING_PRODUCT: '製品のナビゲート',
  NAVIGATING_PRODUCT_CAPTION: '製品の異なるセクションを切り替える方法です。',
  PRODUCT_PREVIEW_CAPTION: 'ここでWeb、Epub、PDFの製品プレビューがあります。',
  PREVIEW_ACTIONS_CAPTION: 'これをトグルしてみましょう。',
  PRODUCT_PREVIEW_EPUB: 'デジタルブックフォーマット',
  PRODUCT_PREVIEW_EPUB_CAPTION: 'Epubはこの例に適しています（Amazon Kindleや他のリーダーで使用されるフォーマット）。',
  PRODUCT_DOWNLOAD_EPUB: 'EpubとPDFでダウンロード可能',
  PRODUCT_DOWNLOAD_EPUB_CAPTION: 'レンダリングには時間がかかります（あなたのデバイスで実行されるため）。ダウンロードの準備ができるのはもう少し後です。',
  PRODUCT_TOUR_EXPORT: 'エクスポートして他で使用',
  PRODUCT_TOUR_EXPORT_CAPTION: 'エディターにあるコンテンツからEpubが生成されました。Kindle、Apple iBooks、および他のデバイスで使用できます。',
  PRODUCT_PREVIEW_WEB_CAPTION: '今度はコンテンツを編集するためのUIに焦点を当てましょう。',
  PRODUCT_GENERAL_VIEW: '一般設定',
  PRODUCT_GENERAL_VIEW_CAPTION: 'ここには、タイトル、タグライン、言語サポートなどの一般的な製品情報があります。',
  PRODUCT_GENERAL_VIEW_2: '入力したデータはすぐにプレビューに表示されます。',
  PRODUCT_EDITOR_CAPTION: 'エディターは製品のコンテンツを作成する場所です。コレクションとコレクションアイテム、例えば、チャプターとページで構成されています。',
  CONTENT_STATS_TOUR_CAPTION: 'ここでコンテンツの統計を見ることができます。',
  COLLECTION_TOUR_CAPTION: 'これがコレクションです。必要な数だけ持つことができます。',
  COLLECTION_VIEW_TOUR_CAPTION: 'これがコレクションアイテムのリストです。',
  COLLECTION_ITEM_TOUR_CAPTION: 'コレクションアイテムを見てみましょう。',
  EDITOR_TOUR_CAPTION: 'ここがマジックをするエディターです。',
  PRODUCT_VIEW_2_TOUR_CAPTION: '製品ビューに戻りましょう。',
  PRODUCT_OFFERINGS_TOUR_CAPTION: 'オファリングを見てみましょう。',
  OFFERINGS_TOUR_CAPTION: 'ここでオファリングをコントロールします。価格は通貨と言語にマッピングできます。',
  PRODUCT_ACCESS_TOUR_CAPTION: 'オファリングへのアクセスをコントロールする方法を見てみましょう。',
  ACCESS_TOUR_CAPTION: 'ここで、無料サンプル、有料、または各言語でのオブスキュアな可視性を持つコレクションアイテムへのアクセスをオファリングごとにマッピングできます。',
  PRODUCT_VERSIONS_CAPTION: '新しいバージョンを公開しましょう。',
  PRODUCT_VERSIONS_LIST_CAPTION: 'ここにはすべてのバージョンが表示されます。',
  PRODUCT_VERSIONS_NEW_TOUR_CAPTION: '新しいバージョンを作成しましょう。',
  PRODUCT_VERSION_IS_PUBLIC_TOUR_CAPTION: '公開アクセスを有効にしましょう。',
  PRODUCT_VERSION_SUBMIT_TOUR_CAPTION: '提出しましょう。',
  NEW_PRODUCT_VERSION_TOUR_CAPTION: '新しい製品バージョンです。私たちは公開アクセスを許可したので、ライブです。',
  NEW_PRODUCT_VERSION_OPENABLE_TOUR_CAPTION: 'すべてのフォーマットと詳細を見ることができます。',
  NEW_PRODUCT_VERSION_OPENABLE_2_TOUR_CAPTION: 'または、それを開いて世界と共有することができます。',
  NEW_PRODUCT_VERSION_OPENABLE_3_TOUR_CAPTION: 'それでおしまいです。製品はこれでみんなに公開できます。',
  WORKSPACE_IDEAS_TOUR_CAPTION: 'さて、ここで他に何があるか見てみましょう。アイデアは、ページを直接作業する場所です。',
  WORKSPACE_IDEAS_ITEM_TOUR_CAPTION: 'あなたのアイデアの1つをチェックしましょう。',
  EDITOR_2_TOUR_CAPTION: 'これはマジックをする同じテキストエディターです。',
  EDITOR_TOOLS_BUTTON_TOUR_CAPTION: 'ここにあるサイドバーをトグルしてみましょう。',
  EDITOR_TOOLS_DETAILS_TOUR_CAPTION: 'これが詳細セクションです。',
  EDITOR_TOOLS_TASKS_LIST_TOUR_CAPTION: 'タスクパネルに何があるか見てみましょう。',
  EDITOR_TOOLS_TASKS_PANEL_TOUR_CAPTION: 'これには関連するタスクのリストが含まれています。何をできるかを見るために新しいタスクを作成してみましょう。',
  TASK_MANAGER_TOUR_CAPTION: 'これがタスクマネージャーウィンドウです。',
  TASK_MANAGER_NEW_TOUR_CAPTION: 'サイドバーから作成したタスクを構成してみましょう。',
  TASK_MANAGER_NEW_STEP_1_TOUR_CAPTION: 'このデモのために「リビジョン」を選びましょう。',
  TASK_MANAGER_NEW_STEP_2_TOUR_CAPTION: '「専門家のトーン」で動作します。',
  TASK_MANAGER_NEW_SUBMIT_TOUR_CAPTION: 'タスクを処理するために提出しましょう。',
  TASK_MANAGER_NEW_PROPERTIES_TOUR_CAPTION: 'タスクが作成されました。これがそのプロパティです。',
  TASK_MANAGER_NEW_STATUS_TOUR_CAPTION: 'タスクのステータスはここで常に確認できます。',
  TASK_MANAGER_NEW_ACTIONS: 'タスクアクション',
  TASK_MANAGER_NEW_ACTIONS_TOUR_CAPTION: 'タスクが終わったら統合および/または繰り返すことができます。',
  ALWAYS_ACCESSIBLE: '常にアクセス可能',
  ALWAYS_ACCESSIBLE_TOUR_CAPTION: 'これは常にタスクマネージャーにアクセスできる場所です。',
  FINAL_TOUR_CAPTION: 'ツアーが終了しました。今はアカウントを設定するのに良い時期です。',
  FINAL_TOUR_CHECK_OUT: 'Prodmakeを100%使いこなすためにガイド{link}をチェックしてください。製品ツアーをお試しいただきありがとうございます。',
  IN_HELP_SECTION: 'ヘルプセクションで',
  IF_YOU_HAVE_ANY_QS: 'ご質問がありましたら、team@prodmake.comまでご連絡ください。',
  YOUR_HIT_MAX_PRODUCTS_LIMIT: 'プランの最大製品数の制限に達しました。',
  YOUR_HIT_MAX_MEMBERS_LIMIT: 'プランの最大メンバー数の制限に達しました。',
  YOUR_HIT_MAX_MEMBERS_LIMIT_CAPTION: '上限の多いプランにアップグレードしてください。',
  SUBSCRIPTION_PLAN_WAS_CANCELED: '“{planName} {planPeriod}”へのご登録は、{canceledAt}にキャンセルされました。お支払い済みまたはトライアル期間の全期間（{untilDate}まで）サブスクリプションをご利用いただけます。',
  YOUR_CURRENT_PLAN_WILL_NOT_RENEW: 'サブスクリプションは更新されません',
  YOUR_CURRENT_FREE_TRIAL_PLAN_CANCELED: '「{planName} {planPeriod}」の現在の無料トライアルプランは、{trialUntil}に終了し、{canceledAt}にキャンセルされました。',
  YOUR_CURRENT_PAID_PLAN_CANCELED: '「{planName} {planPeriod}」の現在のサブスクリプションプランは、{canceledAt}にキャンセルされました。{paidUntil}までの全期間を利用することができます。',
  IN_REVIEW: '審査中',
  APPROVED: '承認済み',
  REJECTED: '拒否されました',
  REVIEW_STATUS: '審査状況',
  MAKER_CONTRACT: 'メーカーの利用規約',
  I_ACCEPT: '利用規約に同意する',
  ACCEPTED_MAKER_TERMS_CAPTION: 'あなたは{date}に規約を承認しました。',
  ONLY_WORKSPACE_ADMIN_CAN_ACCEPT_TERMS_CAPTION: 'ワークスペースの管理者のみが規約を受け入れることができます。',
  ONLY_WORKSPACE_ADMIN_CAN_CANCEL_PLAN: 'サブスクリプションプランをキャンセルできるのは、ワークスペースの管理者のみです。',
  INVOICES: '請求書',
  NO_INVOICES_FOUND: '請求書が見つかりません',
  SUBSCRIPTION_CHARGE: 'サブスクリプション料金',
  TRASH_IS_EMPTY: 'ゴミ箱は空です',
  USER: 'ユーザー',
  ADMIN: '管理者',
  ACTIVE: 'アクティブ',
  INACTIVE: '非アクティブ',
  YOU: 'あなた',
  START_FREE_TRIAL: '無料トライアルを開始',
  FIRST_TIME_MAKER_WELCOME: 'これが初めてのワークスペースです',
  FIRST_TIME_MAKER_WELCOME_CAPTION: 'ワークスペースは、アイデアを無料および有料製品に変える場所です。',
  FIRST_TIME_MAKER_WELCOME_CAPTION2: '製品をウェブサイトとして公開し、原稿をEpubおよびPDFにエクスポートします。',
  FIRST_TIME_MAKER_WELCOME_CAPTION3: 'チームを招待するか、一人で作業します。すべて単一の場所 — あなたのワークスペースから。',
  FIRST_TIME_MAKER_START_TRIAL_CAPTION: '月額プランは20ドルから。クレジットカードは必要ありません。',
  FIRST_TIME_MAKER_NOT_INTERESTED: '今は興味がありませんか？{goToStoreLink} 今すぐ。',
  TRIAL_HAS_STARTED: 'トライアルが開始されました。',
  ACCEPT_TERMS_TO_PUBLISH_PRODUCTS: '製品を公開するには利用規約に同意してください',
  TRY_PRODUCT_TOUR: '製品ツアーを試す',
  TRY_PRODUCT_TO_SEE_IT_IN_ACTION: 'Prodmakeの動作を確認するには',
  WHAT_BEST_DESCRIBES_YOUR_WORK: 'あなたの仕事を最もよく説明するものは？',
  WELCOME_ONBOARDING_FINAL_MESSAGE: '素晴らしい、ありがとうございます — あなたのためにワークスペースを作成しました。案内させていただきましょう。',

  NOVELIST: '小説家',
  SCI_FI_WRITER: 'SF作家',
  NON_FICTION_WRITER: 'ノンフィクション作家',
  POET: '詩人',
  BLOGGER: 'ブロガー',
  COPYWRITER: 'コピーライター',
  SCREENWRITER: '脚本家',
  COMIC_WRITER: 'コミック作家',
  LITERARY_AGENT: '文学エージェント',
  ILLUSTRATOR: 'イラストレーター',
  PHOTOGRAPHER: '写真家',
  STUDENT: '学生',
  ACADEMIC_RESEARCHER: '学術研究者',
  ART_DIRECTOR: 'アートディレクター',
  SOFTWARE_DEVELOPER: 'ソフトウェア開発者',
  ENTREPRENEUR: '起業家',
  SERVICE_PROVIDER: 'サービス提供者',
  TEACHER: '教師',
  OTHER: 'その他',
  WELCOME_ONBOARDING_VIDEO_CAPTION: '説明ビデオを見るか、「開始する」をクリックしてください。',
  GET_STARTED: '開始する',
  MAKE: '作成する',
  MAKE_CAPTION: '情報豊富な本や製品。',
  PUBLISH: '公開する',
  PUBLISH_CAPTION: '単独のウェブサイト、Epub、またはPDFとして。',
  MONETIZE: '収益化する',
  MONETIZE_CAPTION: 'さまざまな通貨での提供。',
  GO_TO_MARKET: '市場への参入',
  GO_TO_MARKET_CAPTION: '迅速な反復のためのオールインワンエディタ。',
  ACCESS_CONTROL: 'アクセス制御',
  ACCESS_CONTROL_CAPTION: '各提供に対するアクセスを制限する。',
  AI_ASSISTED: 'AIサポート',
  AI_ASSISTED_CAPTION: 'AIと「ブレインストーミング」、深い焦点を持つ。',
  WHAT_IS_YOUR_PRIMARY_GOAL: 'あなたの主な目標は何ですか？',
  PUBLISH_A_BOOK: '本を出版する',
  CREATE_A_WEBSITE: 'ウェブブックを作成する',
  PRODUCE_FASTER: 'コンテンツをより速く制作する',
  MAKE_GLOBAL_PRODUCTS: 'グローバルな製品を作成する',
  SIMPLIFY_WORKFLOW: 'ワークフローを単純化する',
  ADDITIONAL_INCOME: '追加の収入を得る',
  DELEGATE_BORING_WORK: '退屈な作業を委託する',
  SAVE_TIME: '時間を節約する',
  SUCCESSFULLY_RESTORED: '正常に復元されました',
  ALSO_AVAILABLE_ON: 'また、次でも利用可能',
  LINK: 'リンク',
  THIRD_PARTY_LINKS: 'サードパーティのリンク',
  YOU_NOW_CAN_PUBLISH_PRODUCTS: 'あなたは今、製品を公開することができます',
  YOU_NOW_CAN_PUBLISH_PRODUCTS_DETAIL: 'ウェブとProdmakeストアで',
  TO_PUBLISH_ACCEPT_TERMS: '公開が有効ではありません',
  TO_PUBLISH_ACCEPT_TERMS_DETAIL: 'メーカーの条件を受け入れないと',
  LETS_SET_UP_YOUR_PROFILE: 'プロフィールを設定しましょう。',
  PITCH_TITLE: '迅速に実行可能な製品を作成。',
  PITCH_SUBTITLE: 'あなたの専門知識を共有 — 公開、エクスポート、収益化。',
  TRANSLATE_FROM: '翻訳元から',
  END: '終了',
  PRODUCT_IS_YET_TO_BE_RELEASED: '商品のリリースはまだです',
  PRODUCT_NOTIFY_ME_SUBMITTED: '商品がリリースされると、お知らせします。',
  LEAVE_YOUR_EMAIL_TO_BE_NOTIFIED: 'リリース時に通知を受け取るために、メールアドレスを残してください。',
  NOTIFY_ME: '通知して',
  ALREADY_SUBSCRIBED: 'すでに登録済み',
  WEEK_OVER_WEEK: '週次比',
  MONTH_OVER_MONTH: '月次比',
  QUARTER_OVER_QUARTER: '四半期比',
  YEAR_OVER_YEAR: '年次比',
  YES: 'はい',
  NO: 'いいえ',
  LAST_WEEK: '先週',
  LAST_MONTH: '先月',
  LAST_QUARTER: '先四半期',
  LAST_YEAR: '昨年',
  PHONE_NUMBER: '電話番号',
  DOB: '生年月日',
  POSTAL_CODE: '郵便番号',
  FIRST_NAME: '名前',
  LAST_NAME: '姓',
  WISE_SERVICE: 'Wise',
  PAYPAL_SERVICE: 'ペイパル',
  WIRE_TRANSFER: 'ワイヤートランスファー',
  PAYOUT_METHOD: '支払い方法',
  ACCOUNT_TYPE: 'アカウントタイプ',
  PERSONAL: '個人',
  BUSINESS: 'ビジネス',
  ADDRESS: '住所',
  CITY: '市',
  COUNTRY: '国',
  SELECT_COUNTRY: '国を選択',
  NO_COUNTRY_FOUND: '国が見つかりません',
  FOR_WIRE_TRANSFER_CONTACT: '電信送金の設定については、team@prodmake.comまでご連絡ください。',
  LEGAL_BUSINESS_NAME: '法的事業名',
  BUSINESS_TYPE: 'ビジネスタイプ',
  SELECT_BUSINESS_TYPE: 'ビジネスタイプを選択',
  NOT_FOUND: '見つかりません',
  CORPORATION: '株式会社',
  LLC: '有限責任会社',
  PARTNERSHIP: 'パートナーシップ',
  NON_PROFIT: '非営利団体',
  SOLE_PROPRIETORSHIP: '個人事業主',
  WISE_EMAIL: 'Wiseメール',
  PAYPAL_EMAIL: 'PayPalメール',
  PAYOUT_SETTINGS_UPDATED: '支払い設定が更新されました',
  ALL_TIME: '全期間',
  NO_PAYOUTS_YET: 'まだ支払いはありません。',
  POSSIBLE_TO_PAYOUT_LATER: '支払いには、少なくとも$10の残高が必要です。',
  AWAITING_APPROVAL: '承認待ち',
  APPROVE: '承認する',
  NEW_PAYOUT: '新しい支払い',
  AMOUNT: '金額',
  REPRESENTATIVE: '代表',
  TIME_FOR_A_NEW_PAYOUT: '新しい支払いの時間',
  VIEW_PAYOUTS: '支払いを見る',
  UPDATED_FIELD: '{fieldName} が “{fieldValue}” に更新されました',
  PAYOUT_WAS_CREATED: '支払いが作成されました',
  PAYOUT_WAS_APPROVED: '支払いが承認されました',
  PAYOUT_WAS_CANCELLED: '支払いがキャンセルされました',
  CREATE: '作成',
  VERIFICATION: '検証',
  VERIFY: '確認する',
  YOUR_ACCOUNT_IS_NOW_VERIFIED: 'あなたのアカウントは現在確認済みです',
  ERROR_TRY_AGAIN: 'エラー。再試行してください。',
  YOUR_ACCOUNT_IS_VERIFIED: 'あなたのアカウントは確認済みです',
  YOUR_ACCOUNT_IS_VERIFIED_CAPTION: 'あなたのアカウントは、Prodmakeの機能に完全にアクセスできるようになりました。',
  YOUR_ACCOUNT_IS_NOT_VERIFIED: 'あなたのアカウントは確認されていません',
  YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION: '有料製品の公開および支払いの整理は、Makerの条件に同意した確認済みのユーザーのみが行うことができます。',
  YOUR_ACCOUNT_IS_NOT_VERIFIED_CAPTION_2: 'あなたのアカウントは編集に制限されています。',
  SERVER_ERROR: 'サーバーエラー',
  DOCUMENT_INVALID: '無効な文書',
  DOCUMENT_EXPIRED: '期限切れの文書',
  DOCUMENT_TYPE_NOT_SUPPORTED: 'サポートされていない文書タイプ',
  CONSENT_DECLINED: '同意が拒否されました',
  ACCOUNT_VERIFICATION_FAIL_REASON: '次の理由でアカウントを確認できませんでした {status}。',
  VERIFY_YOUR_ACCOUNT: 'アカウントを確認してください',
  PUBLISHING_IS_FOR_VERIFIED: '公開は、Makerの条件に同意した確認済みのユーザーに対して有効です。',
  PAYOUTS_DISABLED: '支払いが無効化されています',
  PAYOUTS_DISABLED_CAPTION: 'アカウントを確認し、メーカーの利用条件を受け入れて支払いを有効にしてください。',
  PRODUCT_HAS_NO_OFFERINGS: '製品のオファーがありません',
  PENDING: '保留中',
  JOIN: '参加する',
  DECLINE: '辞退する',
  ACCEPTED: '受け入れられました',
  DECLINED: '辞退しました',
  YOU_WERE_INVITED_TO_WORKSPACE: '{byUser}から{workspace}に招待されました。',
  DOMAIN_USED: 'ドメインは使用不可能',
  TO_BE_VERIFIED: '確認待ち',
  VERIFIED: '確認済み',
  PRODUCT_DOMAIN_BY_ID_CAPTION: 'IDでも商品にアクセスできます',
  NO_CUSTOM_DOMAINS_SET: 'この製品はカスタムドメインを使用するように設定されていません。',
  PRODUCT_IS_AVAILABLE_IN_OTHER_LANGUAGES: 'この商品は他の言語でも利用可能です。',
  BLOG: 'ブログ',
  CONTINUE_WRITING: '続けて書く',
  CONTINUE_WRITING_CAPTION: 'AIを使用して考えを広げる。',
  TEXT: 'テキスト',
  EDITOR_TEXT: 'テキスト',
  EDITOR_TEXT_CAPTION: 'タイピングを開始してください。',
  TODO_LIST: 'To-Do リスト',
  TODO_LIST_CAPTION: 'やることリスト。',
  H1: 'ヘディング 1',
  H1_CAPTION: '大きなセクションヘディング。',
  H2: 'ヘディング 2',
  H2_CAPTION: '中程度のセクションヘディング。',
  H3: 'ヘディング 3',
  H3_CAPTION: '小さいセクションヘディング。',
  BULLET_LIST: '箇条書きリスト',
  BULLET_LIST_CAPTION: 'シンプルな箇条書きリストを作成する。',
  NUMBERED_LIST: '番号付きリスト',
  NUMBERED_LIST_CAPTION: '番号付きのリストを作成する。',
  QUOTE: '引用',
  QUOTE_CAPTION: '引用をキャプチャする。',
  CODE: 'コード',
  CODE_CAPTION: 'コードスニペットをキャプチャする。',
  IMAGE_CAPTION: '画像アップロード',
  PASTE_A_LINK: 'リンクを挿入',
  PUBLIC: '公開',
  RELOAD: 'ページを更新する',
  UNIVERSAL_BOOK_CAPTION: '自費出版書籍の現代的な取り組み。',
  BLOG_CAPTION: 'ミニマリストのブログ。',
  KNOWLEDGE_BASE: 'ナレッジベース',
  KNOWLEDGE_BASE_CAPTION: 'お客様とステークホルダーをサポート。',
  PERSONAL_WEBSITE_CAPTION: '世界に自分を紹介。',
  NEW_PRODUCT: '新製品',
  PRODUCT_ROADMAP: '製品ロードマップ',
  PRODUCT_ROADMAP_CAPTION: '製品のビジョン、方向性、進捗。',
  GRID: 'グリッド',
  SCROLL_NAVIGATION: 'スクロールナビゲーション',
  LINK_LIST: 'Link List',
  PAGE: 'Page',
}
