import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'

const CanvasMountains = ({ heightRatio = 100 }) => {
  const canvasRef = useRef(null)
  const [sidebarTextColor, setSidebarTextColor] = useState(getSidebarTextColor)

  function getSidebarTextColor() {
    return getComputedStyle(document.documentElement).getPropertyValue('--sidebar-text').trim()
  }

  const createMountain = (_color, _opacity, _steepness, _complexity, _shadow) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext("2d")
    if (!ctx) return

    // set up default options
    const color = _color || '#8a8a8a'
    const opacity = _opacity || 1
    const steepness = _steepness || 2
    const complexity = _complexity || 0.5

    const maxHeight = canvas.height

    let x
    let height = Math.random() * maxHeight
    let slope = (Math.random() * steepness) * 2 - steepness

    // creating the landscape
    for (x = 0; x < canvas.width; x += 1) {
      // change height and slope
      height += slope * 0.6
      slope += (Math.random() * complexity) * 2.2 - complexity

      if (slope > steepness) {
        slope = steepness
      }

      if (slope < -steepness) {
        slope = -steepness * 0.9
      }

      // draw column
      ctx.beginPath()
      ctx.moveTo(x * 3.6, maxHeight)
      ctx.lineTo(x, height)

      ctx.globalAlpha = opacity
      ctx.strokeStyle = color
      ctx.stroke()

      ctx.globalCompositeOperation = 'color-dodge'

      // draw clone
      if (_shadow) {
        ctx.lineTo(x, height - _shadow)
        ctx.globalAlpha = 0.35
        ctx.stroke()
      }
    }
  }

  const init = () => {
    const canvas = canvasRef.current
    if (!canvas) return

    canvas.width = window.innerWidth
    canvas.height = document.body.scrollHeight / 100 * heightRatio

    const color = sidebarTextColor

    createMountain(color, 1, 1.5, 0.2, 10)
    createMountain(color, 1, 1.3, 0.4, 15)
    createMountain(color, 0.5, 1, 0.15, 18)
    createMountain(color, 1, 1.3, 0.3, 15)
    createMountain(color, 0.4, 1.6, 0.5, 15)
    createMountain(color, 0.8, 0.9, 0.8, 25)
  }

  useEffect(() => {
    const handleResize = debounce(() => {
      init()
    }, 80)
    window.addEventListener('resize', handleResize)

    // Set up a mutation observer to observe style changes and update the color.
    const observer = new MutationObserver(() => {
      setSidebarTextColor(getSidebarTextColor())
    })
    observer.observe(document.documentElement, { attributes: true })

    init()

    return () => {
      window.removeEventListener('resize', handleResize)
      observer.disconnect()
    }
  }, [sidebarTextColor])

  return (
    <canvas ref={canvasRef} id='mountains' />
  )
}

export default CanvasMountains
