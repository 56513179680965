import React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { createWorkspaceDatabase } from '../lib/db'
import { useGlobalStore } from '../lib/global'
import trace from '../lib/utils/trace'

export function useWorkspace() {
  const {
    auth = {},
    location,
    locale,
    accountDb,
    databases,
    workspaces,
    setDatabases,
    setWorkspaces,
    setHasCreatedWorkspaces,
    setActiveWorkspace
  } = useGlobalStore(s => ({
    auth: s.auth,
    location: s.location,
    locale: s.locale,
    accountDb: s.accountDb,
    databases: s.databases,
    workspaces: s.workspaces,
    setDatabases: s.setDatabases,
    setWorkspaces: s.setWorkspaces,
    setHasCreatedWorkspaces: s.setHasCreatedWorkspaces,
    setActiveWorkspace: s.setActiveWorkspace,
  }))

  const [isLoading, setLoading] = React.useState(true)
  const [workspaceList, setWorkspaceList] = React.useState([])
  const [activeWorkspace, setWorkspace] = React.useState({})
  const activeDatabase = databases?.[activeWorkspace?.id]

  const slug = location.pathname.split('/')[1]

  const userWorkspaces = useLiveQuery(() => {
    if (!accountDb || !auth.isAuthenticated) return []
    return accountDb.workspaces.where({ status: 'ACTIVE' }).reverse().sortBy('created_at')
  }, [auth.isAuthenticated, accountDb], [])

  React.useEffect(() => {
    const dbs = { ...databases }
    trace('user workspaces', userWorkspaces)
    for (const w of userWorkspaces) {
      if (w.id && !dbs[w.id]?.id) {
        dbs[w.id] = createWorkspaceDatabase(w)
        if (dbs[w.id]) {
          dbs[w.id].startSync(() => {
            setLoading(false)
          })
        }
      }
    }
    if (Object.keys(userWorkspaces).length === 0) {
      setLoading(false)
    }
    setHasCreatedWorkspaces(true)
    setDatabases(dbs)
  }, [userWorkspaces])

  useLiveQuery(async () => {
    if (accountDb && auth.isAuthenticated) {
      const dbs = { ...databases }
      const workspaces = await accountDb.workspaces.where({ status: 'ACTIVE' }).sortBy('created_at')
      try {
        const list = await Promise.resolve((async function() {
          const tmp = []
          await Promise.all(workspaces.map(async w => {
            const one = { ...w }
            const db = dbs[w.id]
            if (db) {
              const infos = await db.infos.toArray()
              const localized = infos?.find(o => o.lang === locale)
              const first = infos?.[0]
              one.info = localized || first

              const metas = await db.metas.toArray()
              one.meta = metas?.[0]
            }
            tmp.push(one)
          }))
          return tmp
        })())
        setWorkspaceList(list)
        setWorkspaces(list)
      } catch (err) {
        trace('err', err)
        setWorkspaceList([])
      }
    }
  }, [auth.isAuthenticated, databases, accountDb])

  React.useEffect(() => {
    let workspace = null
    if (slug && workspaces?.length) {
      workspace = workspaces.find(o => o.slug === slug)
    }
    setWorkspace(workspace)
    setActiveWorkspace(workspace)
  }, [workspaces, slug, location, setActiveWorkspace])

  return {
    isLoading,
    activeWorkspace: auth.isAuthenticated ? activeWorkspace : { id: 'demo' },
    activeDatabase,
    workspaceList: [...workspaceList].sort((a, b) => {
      if (a.info?.name < b.info?.name) return -1
      if (a.info?.name > b.info?.name) return 1
      return 0
    }),
  }
}
