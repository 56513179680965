import React from 'react'
import { Link } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import ArrowDownSVG from '../../icons/remix/system/arrow-down-s-line.svg'
import { useGlobalStore } from '../../lib/global'
import { useClickOutside } from '../../hooks/useClickOutside'
import WorkspaceTitle from '../WorkspaceTitle'
import styles from './index.module.scss'

export default function WorkspaceSelect() {
  const ref = React.useRef()
  const [isShowing, setShowing] = React.useState(false)
  const { auth = {}, workspaces, location, activeWorkspace } = useGlobalStore(s => ({
    auth: s.auth,
    workspaces: s.workspaces,
    location: s.location,
    activeWorkspace: s.activeWorkspace
  }))

  const showSelection = React.useCallback(() => {
    setShowing(true)
  }, [])

  const onClickOutside = React.useCallback(() => {
    setShowing(false)
  }, [])
  useClickOutside(ref, onClickOutside)

  return (
    <div className={`${styles.workspaceSelectWrapper} global-workspace-select`}>
      <div className={styles.selectWrapper}>
        <div className={styles.selection} onClick={showSelection}>
          <WorkspaceTitle
            withEllipsis
            workspace={activeWorkspace}
            user={auth?.user}
          />
          <ArrowDownSVG />
        </div>
        <div
          ref={ref}
          className={`${styles.selectionContent} ${isShowing ? 'active' : ''}`}
        >
          <div className={styles.selectionBlock}>
            <div className={styles.workspaceList}>
              <div className={styles.workspaceListItem}>
                <Link
                  to='/'
                  onClick={onClickOutside}
                  className={[
                    styles.styledLink,
                    'workspaceListItem',
                    location.pathname === '/' ? 'active' : ''
                  ].join(' ')}
                >
                  <WorkspaceTitle user={auth?.user} />
                </Link>
              </div>
              {auth.isAuthenticated && workspaces?.map((workspace) => (
                <div key={workspace.id} className={styles.workspaceListItem}>
                  <Link
                    to={`/${workspace.slug}`}
                    onClick={onClickOutside}
                    className={[
                      styles.styledLink,
                      'workspaceListItem',
                      workspace?.id === activeWorkspace?.id ? 'active' : ''
                    ].join(' ')}
                  >
                    <WorkspaceTitle
                      workspace={workspace}
                      user={auth?.user}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {auth?.user?.id && (
            <>
              <div className={styles.separator} />
              <div className={styles.selectionBlock}>
                <div className={styles.optionList}>
                  <Link
                    to={`${location.pathname}?wsettings=1`}
                    className={`${styles.styledLink} mute`}
                    onClick={onClickOutside}
                  >
                    <FormattedMessage id='WORKSPACE_SETTINGS' defaultMessage='Workspace settings' />
                  </Link>
                </div>
              </div>
              {/* <div className={styles.separator} /> */}
              {/* <div className={styles.selectionBlock}> */}
              {/*   <div className={styles.optionList}> */}
              {/*     <Link */}
              {/*       to={`/new-workspace`} */}
              {/*       className={`${styles.styledLink} mute`} */}
              {/*       onClick={onClickOutside} */}
              {/*     > */}
              {/*       <FormattedMessage id='CREATE_WORKSPACE' defaultMessage='Create workspace' /> */}
              {/*     </Link> */}
              {/*     <Link */}
              {/*       to={`/join-workspace`} */}
              {/*       className={`${styles.styledLink} mute`} */}
              {/*       onClick={onClickOutside} */}
              {/*     > */}
              {/*       <FormattedMessage id='JOIN_WORKSPACE' defaultMessage='Join workspace' /> */}
              {/*     </Link> */}
              {/*   </div> */}
              {/* </div> */}
              <div className={styles.separator} />
              <div className={styles.selectionBlock}>
                <div className={styles.optionList}>
                  <a
                    href='https://prodmake.com'
                    className={`${styles.styledLink} mute`}
                  >
                    <FormattedMessage id='GO_TO_STORE' defaultMessage='Go to Store' />
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
