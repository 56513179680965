import { nanoid } from 'nanoid'
import { v4 as uuid } from 'uuid'
import { useGlobalStore } from '../../global'
import { createWorkspaceDatabase } from '../../db'

export async function createWorkspace(name, lang) {
  const workspaceId = uuid()
  const ts = (new Date()).toISOString()
  const state = useGlobalStore.getState()

  const workspace = {
    id: workspaceId,
    slug: nanoid(),
    short_id: nanoid(),
    created_at: ts,
    updated_at: ts,
    deleted_at: null,
    data: {},
    role: 'ADMIN',
    status: 'ACTIVE'
  }
  state.accountDb.workspaces.add(workspace)

  const dbs = useGlobalStore.getState().databases
  let db

  if (dbs[workspaceId]) {
    db = dbs[workspaceId]
  } else {
    db = createWorkspaceDatabase({ id: workspaceId })
  }

  await db.startSync()

  useGlobalStore.setState({
    databases: {
      ...dbs,
      [workspaceId]: db
    }
  })

  const firstInfo = {
    id: uuid(),
    short_id: nanoid(),
    created_at: ts,
    updated_at: ts,
    picture_url: '',
    workspace_id: workspaceId,
    lang,
    name,
  }

  // infos
  db.infos.add(firstInfo)

  // metas
  db.metas.add({
    id: uuid(),
    short_id: nanoid(),
    created_at: ts,
    updated_at: ts,
    workspace_id: workspaceId,
    data: {
    }
  })
}

// export interface Workspace {
//   created_at: string;
//   updated_at: string;
//   deleted_at?: string;

//   id?: string;
//   short_id: string;

//   name: string;
//   slug: string;
//   picture_url?: string;

//   status?: string;
//   data: object;
// }

// export interface WorkspaceInfo {
//   created_at: string;
//   updated_at: string;
//   deleted_at?: string;
//   id?: string;
//   short_id: string;
//   name: string;
//   description: string;
//   picture_url?: string;
//   data: object;
// }

// export interface WorkspaceMember {
//   created_at: string;
//   updated_at: string;
//   deleted_at?: string;

//   id?: string;
//   short_id: string;

//   username: string;
//   email: string;
//   picture_url?: string;

//   account_id: string;
//   role: string;
//   picture_url?: string;
//   workspace_id?: string;

//   status?: string;
//   data: object;
// }

// export interface WorkspaceInvite {
//   created_at: string;
//   updated_at: string;
//   deleted_at?: string;

//   id?: string;
//   invite_code: string;
//   invited_by_id: string;
//   workspace_id: string;

//   email: string;
//   status: string;
//   data: object;
// }

// export interface WorkspaceStructInvite {
//   created_at: string;
//   updated_at: string;
//   deleted_at?: string;

//   id?: string;
//   invite_code: string;
//   invited_by_id: string;
//   workspace_id: string;
//   struct_id: string;

//   email: string;
//   status: string;
//   data: object;
// }

// export interface WorkspaceStructAccess {
//   created_at: string;
//   updated_at: string;
//   deleted_at?: string;
//   id?: string;
//   account_id: string;
//   struct_id: string;
//   status?: string;
//   invite_id?: string;
//   data: object;
// }
