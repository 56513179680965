export default {
  code: 'kk',

  CURRENT_LANGUAGE: 'Қазақша',
  en: 'Ағылшын',
  ja: 'Жапон',
  ko: 'Корей',
  fr: 'Француз',
  ru: 'Орыс',
  de: 'Неміс',
  kk: 'Қазақша',

  // ui
  FULLSCREEN_ENTER: 'Толық экранға өту',
  FULLSCREEN_EXIT: 'Толық экраннан шығу',
  CLOSE: 'Жабу',
  ADD: 'Қосу',
  ENABLE: 'Қосу',
  DISABLE: 'Өшіру',
  REMOVE: 'Аластату',
  NAME: 'Атау',
  NO_NAVIGATION_SET: 'Навигация орнатылмаған',
  NO_COMMERCE_SET: 'Сауда орнатылмаған',

  SETTINGS: 'Баптаулар',
  DASHBOARD: 'Панель',
  PRICE: 'Баға',
  WELCOME: 'Қош келдіңіз',
  WELCOME_BACK: 'Қайта қош келдіңіз',

  OVERVIEW: 'Шолу',
  EDITOR: 'Түзетуші',
  CONTENT: 'Мазмұн',
  GENERAL: 'Жалпы',
  NAVIGATION: 'Навигация',
  DESIGN: 'Дизайн',
  STYLE: 'Стиль',
  COVER: 'Мұқаба',
  MARKETING: 'Маркетинг',
  AUDIENCE: 'Аудитория',
  SOCIAL: 'Әлеуметтік желі',
  COMMERCE: 'Сауда',
  OFFERINGS: 'Төлем үлгерімдері',
  ADVANCED: 'Кеңейтілген',
  COLLECTIONS: 'Коллекциялар',
  DOMAINS: 'Домендер',
  EXPORT: 'Экспорт',
  LIVE: 'Тікелей',
  VERSIONS: 'Версиялар',
  ANALYTICS: 'Аналитика',
  COMING_SOON: 'Жақында',
  MARKETS: 'Базарлар',
  TITLE: 'Тақырып',
  DESCRIPTION: 'Сипаттама',
  SHOW_PLAIN_COVER: 'Қарапайым мұқабаны көрсету',
  SHOW_COMING_SOON: '“Жақында” дегенді көрсету',
  TAGLINES: 'Девиздер',
  TAGLINE: 'Девиз',
  DANGER_ZONE: 'Қаупті аймақ',
  TRASH: 'Себет',
  RESTORE: 'Қалпына келтіру',
  DELETE_FOREVER: 'Мәңгі жою',
  MENU: 'Мәзір',
  TERMS: 'Шарттар',
  REFUND_POLICY: 'Ақа қайтару саясаты',
  PRIVACY_POLICY: 'Құпиялылық саясаты',
  DOWNLOADS: 'Жүктемелер',
  FEEDBACK: 'Пікір',
  HELP: 'Көмек',
  UNTITLED: 'Атаусыз',
  PRODUCTS: 'Өнімдер',
  LOGIN: 'Кіру',
  LOGOUT: 'Шығу',
  PAYOUTS: 'Төлемдер',
  CUSTOMERS: 'Тұтынушылар',
  THEMES: 'Темалар',
  LOADING: 'Жүктелуде',
  PRODMAKE: 'Продмэйк',

  MADE_WITH: 'Жасалған',
  LANGUAGES: 'Тілдер',
  LANGUAGE: 'Тіл',

  ALREADY_PURCHASED: 'Алдаған сатылды',
  GET_FULL_ACCESS: 'Толық қатынас алу',

  USD: 'АҚШ доллары',
  BALANCE: 'Баланс',
  TOTAL_BALANCE: 'Жалпы баланс',
  PROFIT: 'Пайда',
  REVENUE: 'Омыр',
  TURNOVER: 'Омыр',
  TRANSACTIONS: 'Транзакциялар',
  DATE: 'Күн-ай',
  CURRENCY: 'Валюта',
  TRANSACTION_CURRENCY: 'Транзакция валютасы',
  MONEY_IN: 'Ақша кіріс',
  MONEY_OUT: 'Ақша шығыс',
  FEE: 'Комиссия',
  STATUS: 'Статус',
  EMAIL: 'Электронды пошта',
  PRODUCT: 'Өнім',
  VERSION: 'Нұсқа',
  OFFERING: 'Ұсыныс',
  WITHDRAW: 'Ақшаны шығару',
  UPGRADE_TO_PRO: 'Pro-ге жаңарту',
  QUICK_ACCESS: 'Жылдам қатынас',
  GETTING_STARTED: 'Бастау',
  GETTING_TRACTION: 'Өсуді бастау',
  SUGGESTED_FOR_YOU: 'Сізге ұсынылған',

  WORKSPACE_SETTINGS: 'Жұмыс аясының параметрлері',
  CREATE_WORKSPACE: 'Жұмыс аясын жасау',
  JOIN_WORKSPACE: 'Жұмыс аясына қосылу',
  GO_TO_STORE: 'Дүкенге бару',

  RESTORE_PURCHASE: 'Сатып алуды қалпына келтіру',
  PRODUCT_VIEWS: 'Өнімді қарау',
  SALES: 'Сату',
  CONVERSION_RATE: 'Айналу ставкасы',
  PRODMAKE_PRODUCT_TITLE: 'Продмэйк өнімі',
  PRODMAKE_DESCRIPTION: 'Продмэйк пен өнім жасаңыз',
  SECURE_ACCESS_LINK: 'Электронды поштаңызға қатынас сілтемесі',
  ALREADY_PURCHASED_SUBMITTED: 'Сәтті жіберілді. Поштаңызды тексеріңіз.',
  ERROR: 'Қате',
  MONEY_BACK_GUARANTEE_POINT: '14 күн ішінде ақшаны қайтару кепілдігі',
  INSTANT_ACCESS_POINT: 'Веб, .pdf, .epub нұсқаларына тікели жол',
  FUTURE_UPDATES_POINT: 'Болашақ жаңартулар',
  PARTNER_PERKS_POINT: 'Серіктестерден $800 құнды привилегия',
  LAST_UPDATED_POINT: 'Соңғы жаңартылған',
  GET_FOR_FREE_SUBMITTED: 'Қазір сізде тегін нұсқаға қатынас бар.',
  GET_FOR_FREE_NOW: 'Қазір тегін алыңыз',
  WIP: 'Жұмыс нұсқасы',
  POWERED_BY_PRODMAKE: 'Продмэйк жасаған',
  RAW_HTML: 'HTML',
  EPUB: 'Epub',
  SHORT_ID: 'Қысқа ID',
  NEW: 'Жаңа',
  NO_PRODUCT_PUBLISHED: 'Өнім жарияланған жоқ',
  PREVIEW: 'Алдын ала қарау',
  VIEWING: 'Қарауда',
  OPEN_LIVE: 'Жаңа терезеде ашу',
  BACK_TO_WEB: 'Толық нұсқаға оралу',
  NO_ITEMS_TO_DISPLAY_RAW: 'Көрсету үшін зат жоқ',
  BACK_TO_HOMEPAGE: 'Бас бетке оралу',
  ACCESS_NOW_FOR_FREE: 'Тегін қол жетімді',
  GET_ACCESS_NOW: 'Қатынасты сұрау',
  BE_FIRST_TO_ACCESS: 'Қатынасқа бірінші болу',
  CHANGE_STRUCT_COVER: 'Мұқабаны өзгерту',
  CHANGE_LOGO: 'Логотипті өзгерту',
  CHANGE_AVATAR: 'Аватарды өзгерту',
  CATEGORY: 'Санат',
  MANUALS: 'Нұсқаулықтар',
  BOOKS: 'Кітаптар',
  GUIDES: 'Нұсқаулықтар',
  PAGES: 'Беттер',
  LAST_RELEASE: 'Соңғысы',
  AGE_RATING: 'Жас рейтингі',

  SERIF: 'Засечкалы',
  SANS_SERIF: 'Засечкасыз',
  MONOSPACE: 'Моноширин',
  FULL_WIDTH: 'Толық ен',
  NARROW: 'Тар',
  SIMPLE: 'Кәдімгі',
  BLOCK: 'Блок',
  MINIMAL: 'Минималды',
  FONTS: 'Қаріптер',
  CHAPTERS: 'Тарақтар',
  FOOTER: 'Төменгі тақырыбы',
  REQUEST_ACCESS: 'Қатынасты сұрау',
  NOW: 'Қазір',
  BUY: 'Сатып алу',
  STAY_TUNED: 'Бізбен болыңыз',
  VIEWING_LIMITED_VERSION: 'Сіз шектелген нұсқаны қараудасыз.',
  VIEWING_LIMITED_VERSION_OBFUSCATED: 'Сіз демо-нұсқаны қараудасыз.',
  TO_SEE_CONTENT: 'Толық қатынас үшін',
  ABOUT_AUTHOR: 'Автор туралы',
  RENDERING: 'Рендерлеу',
  SERIES_NAME: 'Серия атауы',
  BOOK_NUMBER: 'Кітап нөмірі',
  PUBLISHER: 'Баспашы',
  DEDICATION: 'Арман',
  MESSAGE: 'Хабар',
  EBOOK_ISBN: 'Кітаптың ISBN',
  OPTIONAL: 'Міндетті емес',
  AUTHOR: 'Автор',
  TITLE_PAGE: 'Титулды бет',
  WEB: 'Веб',
  EBOOK: 'Электронды кітап',
  PDF: 'PDF',
  SHOW_PUBLICATION_DATE: 'Баспа күнін көрсету',
  SHOW_RELEASE_DATE: 'Жарияланған күнін көрсету',
  SHOW_VERSION: 'Версиясын көрсету',
  DOWNLOAD: 'Жүктеу',
  REGENERATE: 'Қайта жасау',
  SHOW_AUTHOR_INFO: 'Автор туралы ақпаратты көрсету',
  SHOW_WORKSPACE_INFO: 'Жұмыс орны туралы ақпаратты көрсету',
  BIO: 'Биография',
  PICTURE_URL: 'Сурет сілтемесі',
  OVERRIDE: 'Өзгерту',
  CONTRIBUTORS: 'Үлес қосушылар',
  IMAGE: 'Сурет',
  SHOW: 'Көрсету',
  COLLECTION: 'Коллекция',
  LINKS: 'Сілтемелер',
  REMOVE_SECTION: 'Бөлімді жою',
  INCLUSION_AND_ACCESS: 'Қамтип қосу және қатынасу',
  FULL_ACCESS: 'Толық қатынас',
  DELETE_ITEM: 'Жою',
  COLLECTION_TITLE: 'Коллекцияның атауы',
  SLUG: 'Слаг',
  REMOVE_COLLECTION: 'Коллекцияны жою',
  INCLUDES_COLLECTION_ITEMS: 'Коллекциядағы материалдарды қамтип қосу: {count}/{total}',
  WITH_FULL_ACCESS: 'Толық қатынас бар',
  VISIBLE_ITEMS: 'Толық қатынас бар {count}/{total}',
  OFFERING_AVAILABLE_FOR_FREE: 'Бұл ұсыныс тегін қол жетімді.',
  CURRENCY_AMOUNT_TERMS: '{name} валютасындағы ең кіші сома {symbol}{min_amount}, ең үлкені {symbol}{max_amount}.',
  PREVIEW_VERSION: 'Алдын ала көрсету нұсқасы',
  TOC: 'Мазмұны',
  DOMAIN_SETUP_MESSAGE: 'Сізге CNAME жазбасын пайдаланып, доменіңізді *.prodmake.com дегенге бағытуыңыз керек.',
  VALIDATE: 'Тексеру',
  REMOVE_DOMAIN: 'Доменді жою',
  WORDS: 'Сөздер саны',
  ORIGINALLY_PUBLISHED: 'Түпнұсқа шығарылған',
  LAST_UPDATED: 'Соңғы жаңартылған',
  ORIGINALLY_PUBLISHED_AT: '{date} күні түпнұсқа шығарылған',
  LAST_UPDATED_AT: '{date} күні соңғы жаңартылған',
  LOCALIZATION: 'Локализация',
  SELECT_AT_LEAST_ONE_LANGUAGE: 'Кем дегенде бір тілді негізгі ретте қалдырыңыз',
  THIS_COPY_BELONGS_TO: 'Бұл көшірме v{version} {email}-ге тән',
  REQUEST: 'Сұрау',
  PERKS_WORTH: 'привилегияларының құны ~$800',
  PAY_AMOUNT: '{amount} төлеу',
  PAGE_LEFT_BLANK: 'Бұл бет бос қалды.',
  PRIMARY_LANGUAGE: 'Бас тіл',
  SUPPORTED_LANGUAGES: 'Қолдау көрсетілген тілдер',
  SUCCESSFUL_PAYMENT_MESSAGE: 'Төлем сәтті өтті.',
  PAYMENT_IS_PROCESSING_MESSAGE: 'Төлем өңделуде.',
  PAYMENT_UNSUCCESSFUL_MESSAGE: 'Төлем сәтсіз өтті. Төлем әдісін тексеріңіз.',
  SOMETHING_WENT_WRONG: 'Бірдеме бір ақауды өтті. Қайта байқаңыз.',
  INCLUDED_IN: 'Кірістірілген',
  DETAILS: 'Егжей-тегжейлер',
  SUBTITLE: 'Бала тақырыбы',
  PAGE_NOT_FOUND: 'Парақ табылмады',
  THANK_YOU: 'Рақмет сізге',
  START: 'Бастау',
  PRODMAKE_PITCH_TITLE: 'Prodmake арқылы өнімдер жасау',
  PRODMAKE_PITCH_CTA: '{link} - бұл қатысушылардың сапасын арттыру үшін бастаушы',
  TRY_PRODMAKE: 'Қазір бастау',
  SCROLL_PURCHASE: 'Скролда “Сатып алу”',
  ITEM_INCLUDED_IN_OFFERINGS: 'Ұсынымға зат кірістірілген ',
  VISIBLE: 'Көрінеді',
  HIDDEN: 'Жасырын',
  PROPERTIES: 'Қасиеттер',
  BANNER_CTA: 'Баннер CTA (әрекетке шақыру)',
  PURCHASE_FOR_FULL_ACCESS: 'Толық кіру үшін сатып алу',
  ACCESS: 'Кіру',
  SAMPLE: 'Мысал',
  VISIBLE_OBFUSCATED: 'Көрінеді, жатталған',
  ALL_LANGUAGES: 'Барлық тілдер',
  PURCHASED_ON: 'Сатып алынған күн',
  ITEM_NOT_PUBLIC: 'Элемент әлі жарияланған жоқ',
  SUCCESSFUL_FREEBIE_MESSAGE: 'Кіру рұқсат берілді',
  ACCESSED_ON: 'Кіру күні',
  SETTLED: 'Төлеңдеген',
  UNIVERSAL: 'Универсалды',
  ALL: 'Барлық',
  TRANSLATE: 'Аударма',
  TRANSLATE_TO: 'Аударуға',
  UNIQUE: 'Бірегей',
  'N/A': 'N/A',
  WRITING: 'Жазба',
  TASKS: 'Тапсырмалар',
  PAGE_LIST: 'Беттер тізімі',
  PURCHASE_INFO: 'Сатып алу туралы мәлімет',
  HERO: 'Hero (Баннер)',
  SCROLL_PROGRESS: 'Айдару прогрессі',
  HOME_SCROLL: 'Басты бет айдаруы',
  NAV: 'Навигация',
  PRODUCT_PROPERTIES: 'Өнім қасиеттері',
  PRODUCT_AUTHOR: 'Автор туралы',
  BLUEPRINT: 'Жоспар',
  BLOCKS: 'Блоктар',
  READY_TO_USE: 'Қолдануға дайын',
  WEB_BOOK: 'Веб-кітап',
  COVER_RIBBON: 'Мұқаба лентасы',
  COVER_INTERACTION: 'Мұқаба әрекеті',
  STRUCTURE: 'Құрылым',
  WIDTH: 'Ені',
  ENABLED: 'Іске қосылған',
  DISABLED: 'Өшірілген',
  DISTRIBUTION: 'Тарату',
  AUTHOR_BIO: 'Автор туралы',
  EXPORT_EPUB: 'Epub ретінде экспорттау',
  EXPORT_PDF: 'PDF ретінде экспорттау',
  VIEW_JSON: 'JSON ретінде көру',
  CHOOSE_TYPE: 'Түрін таңдау',
  UNIVERSAL_BOOK: 'Ұлттық кітап',
  PLAYLIST_COURSE: 'Плейлист курсы',
  PERSONAL_WEBSITE: 'Жеке веб-сайт',
  IMPORT: 'Импорт',
  BILLING: 'Есептелу',
  PAYOUT: 'Төлем',
  PLANS: 'Жоспарлар',
  NOTIFICATIONS: 'Хабарландырулар',
  PREFERENCES: 'Баптаулар',
  PROFILE: 'Профиль',
  CANCEL: 'Болдырмау',
  SAVE: 'Сақтау',
  ACCOUNT: 'Тіркелгі',
  FULLNAME: 'Толық аты-жөні',
  PAYMENT_CARDS: 'Төлем карточкалары',
  NO_PAYMENT_METHODS_FOUND: 'Төлем әдістері табылмады',
  CARD_ADDED_SUCCESSFULLY: 'Карточка сәтті қосылды',
  ADD_CARD: 'Карточка қосу',
  GRIND: 'Грайнд',
  YEAR_ONE: 'Бірінші жыл',
  PRO: 'Профессионал',
  YEARLY: 'Жылдық жоспар',
  MONTHLY: 'Айлық жоспар',
  CHOOSE_PLAN: 'Жоспар таңдау',
  PAYMENT_METHOD: 'Төлем тәсілі',
  CONFIRMATION: 'Растау',
  SELECT: 'Таңдау',
  SELECT_PLAN: '«{planName}» таңдау',
  NEXT: 'Келесі',
  YOUR_CURRENT_FREE_TRIAL_PLAN: 'Сіздің ағымдағы тегін сынама уақыты «{planName} {planPeriod}» {trialUntil} дейін аяқталады.',
  YOUR_CURRENT_PAID_PLAN: 'Сіздің ағымдағы төленген жоспарыңыз «{planName} {planPeriod}» {paidUntil} дейін жаңартылады.',
  CURRENT_PLAN: 'Ағымда',
  INBOX: 'Кіріс',
  FINISH: 'Аяқтау',
  CHOOSE_PAYMENT_METHOD: 'Төлем тәсілін таңдаңыз',
  ACTIVATE_SELECTED_PLAN: 'Таңдалған жоспарды белсендіру',
  BACK_TO_CARD_LIST: 'Карталар тізіміне оралу',
  LAST_PRODUCT_VIEWS: 'Соңғы өнімдерді қарау',
  NO_VIEWS_YET: 'Қараулар әзірге дейін жоқ',
  FREE_TRIAL_ENDS: 'Тегін сынама {inDays} күннен кейін аяқталады',
  WORKSPACE_NAME: 'Жұмыс орнының атауы',
  NEW_WORKSPACE: 'Жаңа жұмыс орны',
  WORKSPACE_MEMBERS: 'Жұмыс орны мүшелері',
  INVITE: 'Шақыру',
  WORKSPACE_INVITE: 'Жұмыс орнына шақыру',
  NO_MEMBERS_FOUND: 'Мүшелер табылмады',
  UPGRADE_NOW: 'Қазір жаңарту',
  NOVICE: 'Баслаушы',
  ESTABLISHED: 'Тәжірибелі',
  SEE_INVOICES: 'Шоттарды қарау',
  CANCEL_SUBSCRIPTION: 'Жазылымды болдырмау',
  BACK: 'Артқа',
  MONTHLY_RATE: 'Ай сайынғы ақы',
  YEARLY_RATE: 'Жыл сайын ақы',
  PLAN: 'Жоспар',
  SUBSCRIPTION_ACTIVATED_1: 'Жазылым сәтті белсендірілді. Төленген {date} дейін.',
  SUBSCRIPTION_ACTIVATED_2: 'Кредит карта төлемдері PRODMAKE (немесе STRIPE аяқталмағанда) деп есептеледі.',
  SUBSCRIPTION_ACTIVATED_3: 'Сізге растау электрондық пошта арқылы жіберіледі. Prodmake таңдағаныңыз үшін рақмет!',
  SUCCESSFULLY_ACTIVATED: 'Сәтті белсендірілді',
  CONTINUE: 'Жалғастыру',
  MONTHLY_PLAN_RATE_PER_SEAT: 'Ай сайын {rate} пайдаланушы болып',
  YEARLY_PLAN_RATE_PER_SEAT: 'Жыл сайын {rate} пайдаланушы болып',
  TRIAL_PERIOD: 'Сынама мерзімі',
  N_DAYS: '{count} күн',
  YOU_WONT_BE_CHARGED_NOW: 'Сізді енді ақы алынбайды',
  START_PLAN_TRIAL: 'Сынаманы қазір бастау',
  NO_PURCHASES_YET: 'Әзірге дейін сатып алу жоқ',
  UPCOMING_PAYOUTS: 'Келесі төлемдер',
  UPDATE_PERSONAL_DETAILS: 'Жеке мәліметтерді жаңарту',
  UPDATE_WORKSPACE_DETAILS: 'Жұмыс орнының мәліметтерін жаңарту',
  TRANSLATE_AUTOMATICALLY: 'Автоматты түрде аудару',
  GET_NEW_PRODUCT_IDEAS: 'Жаңа өнім идеяларын алу',
  MAKE_YOUR_FIRST_SALE: 'Бірінші сатуыңызды жасаңыз',
  GET_YOUR_FIRST_PAYOUT: 'Бірінші төлеміңізді алыңыз',
  SET_UP_A_PROFILE: 'Профиль орнату',
  SET_UP_A_WORKSPACE: 'Жұмыс орнын орнату',
  SET_UP_A_PAYMENT_METHOD: 'Төлем әдісін орнату',
  SET_UP_PAYOUT: 'Төлемді орнату',
  CREATE_YOUR_FIRST_PRODUCT: 'Бірінші өніміңізді жасаңыз',
  PUBLISH_YOUR_FIRST_PRODUCT: 'Бірінші өніміңізді жариялаңыз',
  ONBOARDED: 'Жұмыс орнына кірістірілген',
  YOU_DONT_HAVE_UPCOMING_PAYOUTS: 'Сізде келесі төлемдер жоқ',
  IDEAS: 'Идеялар',
  PARAPHRASE: 'Перефраза',
  STATS: 'Көрсеткіштер',
  CREATED: 'Жасалды',
  PROCESSING: 'Өңделуде',
  PROCESSED: 'Өңделген',
  AI_STRUCT_TRANSLATION: 'Аудару',
  AI_STRUCT_REVISE: 'Парафраз',
  AI_STRUCT_CHECK_GRAMMAR: 'Грамматикалық қателерді тексеру',
  AI_BOOK_GENERATION: 'Кітап жасау',
  AI_WRITE_DRAFT: 'Тақырыбын жазу',
  AI: 'ЖС',
  VIEW: 'Көру',
  ACCEPT: 'Қабылдау',
  GENERATE: 'Жасау',
  DONT_HAVE_AN_ACCOUNT: 'Тіркелгіңіз жоқ па?',
  JOIN_PRODMAKE: 'Prodmake-ке қосылу',
  ALREADY_HAVE_AN_ACCOUNT: 'Тіркелгіңіз бар ма?',
  INVITATION_CODE: 'Шақыру коды',
  PASSWORD: 'Құпия сөз',
  LOGGED_IN_AS: '{email} ретінде кірді',
  SELECT_OR_CREATE: 'Таңдау немесе жасау',
  NEW_TASK: 'Жаңа тапсырма',
  SUBMIT: 'Жіберу',
  FOR_WHOM: 'Кімге',
  COMPLETED_IN: 'Аяқталды',
  ASSIGNEE: 'Тапсырма беруші',
  COST: 'Баға',
  COST_INCLUDED: 'Жоспарыңызға кірісті баға',
  PAGE_COUNT: '{count} бет',
  DELETE: 'Жою',
  HAS_NOT_BEEN_COMPLETED: 'Әзірше аяқталған жоқ',
  REPEAT_TASK: 'Тапсырысты қайталау',
  SECOND_COUNT: '{count} секунд',
  TASK: 'Тапсырыс',
}
