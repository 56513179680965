import React from 'react'
import './styles.scss'

export default function CreditCardMini(props) {
  const {
    issuer = '',
    last4 = '',
    expMonth = '',
    expYear = '',
    onClick,
    isSelected
  } = props

  return (
    <div
      className={`credit-card-mini-wrapper ${onClick ? 'is-button' : ''} ${isSelected ? 'is-selected' : ''}`}
      onClick={onClick}
      onKeyDown={onClick}
      role='button'
      tabIndex={0}
    >
      <div className='line'>
        <div className='number'>
          <div className='opaque'>**** **** ****</div><div className='last4'>{last4}</div>
        </div>
      </div>
      <div className='expiry'>
        {expMonth}/{expYear?.toString().substr(2, 2)}
      </div>
      <div className='issuer'>
        {issuer}
      </div>
    </div>
  )
}
