import React from 'react'
import Select from 'react-select'

const getStyles = (size = 'normal') => ({
  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'var(--background_a3)',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--sidebar-text)',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? 'var(--accent)' : 'var(--background_a3)',
    color: state.isFocused ? 'var(--sidebar-background)' : 'var(--text)',
    fontSize: size === 'normal' ? '15px' : '14px',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--accent)',
    padding: '7px',
    '&:hover': {
      color: 'var(--sidebar-text)',
    }
  }),
  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'var(--text_d5)',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '36px',
    backgroundColor: 'var(--background_a5)',
    border: '1px solid transparent',
    padding: size === 'normal' ? '0px 0' : '0 0',
    fontSize: size === 'normal' ? '15px' : '14px',
    lineHeight: '1',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      border: '1px solid var(--sidebar-text)',
    }
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'var(--background_a3)',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--sidebar-text)',
    // borderColor: state.isFocused ? 'grey' : 'red',
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'var(--sidebar-text)',
    color: 'var(--sidebar-background)',
    padding: '2px 4px',
    borderRadius: '4px',
    // borderColor: state.isFocused ? 'grey' : 'red',
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--sidebar-background)',
    fontSize: size === 'normal' ? '14px' : '13px',
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles
  })
})

export default function CustomSelect(props) {
  return (
    <Select
      styles={getStyles(props.size)}
      {...props}
    />
  )
}
