import React from 'react'
import qs from 'qs'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'
import dayjs from 'shared/utils/dayjs'
import * as config from 'shared/config'
import { navigate } from '@reach/router'
import { useGlobalStore } from '../../lib/global'
import styles from './index.module.scss'

const defaultState = {
  isSubmitting: false,
  isSuccess: false,
}

export default function WorkspaceOnboarding(props) {
  const intl = useIntl()
  const { auth, location, playTour } = useGlobalStore(s => s)
  const [state, setState] = React.useState(defaultState)

  const onStartTrial = React.useCallback(async () => {
    setState(prev => ({
      ...prev,
      isSubmitting: true,
    }))

    try {
      const res = await fetch(`${config.API_URL}/v1/workspaces/${props.activeWorkspace.id}/start-trial`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        },
        body: JSON.stringify({ locale: intl.locale }),
      })

      const json = await res.json()
      setState(prev => ({
        ...prev,
        isSuccess: json.success
      }))

      toast.success(intl.formatMessage({ id: 'TRIAL_HAS_STARTED' }), {
        description: <FormattedMessage
          id='TRIAL_PLAN_ACTIVATED_DETAIL'
          values={{
            planName: intl.formatMessage({ id: json.subscription.plan.name }),
            trialEnds: dayjs(new Date(json.subscription.free_trial_end_date)).fromNow(),
          }}
        />
      })

      navigate(`${location.pathname}?${qs.stringify({
        ...location.query || {},
        maker_terms: '1'
      })}`)
    } catch (err) {
      console.log(err)
    }
  })

  if (!props.activeWorkspace?.id) return null

  const isFirstTimeMaker =
    props.activeWorkspace?.id &&
    props.activeWorkspace?.meta?.data?.plan &&
    !props.activeWorkspace?.meta?.data?.subscription?.canceled_at &&
    !props.activeWorkspace?.meta?.data?.subscription?.free_trial_end_date &&
    !props.activeWorkspace?.meta?.data?.subscription?.paid_until

  if (!isFirstTimeMaker) return null

  return (
    <>
      <div className={styles.trialActivationOverlay} />
      <div className={styles.trialActivationWrapper}>
        <div className={styles.trialActivationContent}>
          <div className={styles.trialActivationBody}>
            <div>
              <h2>
                <FormattedMessage id='FIRST_TIME_MAKER_WELCOME' />
              </h2>
              {/* <p> */}
              {/*   <FormattedMessage id='FIRST_TIME_MAKER_WELCOME_CAPTION' /> */}
              {/* </p> */}
              {/* <p> */}
              {/*   <FormattedMessage id='FIRST_TIME_MAKER_WELCOME_CAPTION2' /> */}
              {/* </p> */}
              <p>
                <FormattedMessage id='FIRST_TIME_MAKER_WELCOME_CAPTION3' />
              </p>
              <div>
                <button className='cta-button' onClick={onStartTrial} disabled={state.isSubmitting}>
                  {state.isSubmitting && <FormattedMessage id='LOADING' />}
                  {!state.isSubmitting && <FormattedMessage id='START_FREE_TRIAL' />}
                </button>
                <span className='cta-button-caption'>
                  <FormattedMessage id='FIRST_TIME_MAKER_START_TRIAL_CAPTION' />
                </span>
              </div>
            </div>
          </div>
          <div className={styles.trialActivationFooter}>
            <div>
              Prodmake LLC
            </div>
            <div>
              2023
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
