import React from 'react'
import { FormattedMessage } from 'react-intl'
import dayjs from 'shared/utils/dayjs'
import BackSVG from 'shared/icons/feather/arrow-left.svg'
import SelectedPlan from '../../SelectedPlan'
import SelectedPaymentMethod from '../../SelectedPaymentMethod'

export default function WorkspacePlanSwitchConfirmation(props) {
  const { state, onGoToStep } = props

  return (
    <div className='workspace-plan-step'>
      {(!props.state.isProcessed || props.state.isError) && (
        <div className='workspace-plan-step-head'>
          <button
            className='back-button'
            onClick={onGoToStep(state.currentStep - 1)}
          >
            <BackSVG />
            <FormattedMessage id='BACK' />
          </button>
          <h2 className='title'>
            <FormattedMessage id='CONFIRMATION' />
          </h2>
        </div>
      )}

      {props.state.isProcessed && props.state.isSuccess && (
        <>
          <div className='workspace-plan-step-head'>
            <h2><FormattedMessage id='SUCCESSFULLY_ACTIVATED' /></h2>
          </div>
          <div className='workspace-plan-step-body'>
            <p>
              <FormattedMessage
                id='SUBSCRIPTION_ACTIVATED_1'
                values={{
                  date: dayjs(props.state.subscription?.paid_until).format('MMMM D, YYYY')
                }}
              />
            </p>
            <p>
              <FormattedMessage id='SUBSCRIPTION_ACTIVATED_2' />
            </p>
            <p>
              <FormattedMessage id='SUBSCRIPTION_ACTIVATED_3' />
            </p>
          </div>
          <div className='workspace-plan-step-footer'>
            <button className='cta-button' onClick={props.onReset}>
              <FormattedMessage id='CONTINUE' />
            </button>
          </div>
        </>
      )}
      {!props.state.isProcessed && (
        <div className='workspace-plan-step-body'>
          <SelectedPlan {...props} />
          <SelectedPaymentMethod {...props} />
        </div>
      )}

      {(!props.state.isProcessed || props.state.isError) && (
        <div className='workspace-plan-step-footer'>
          <span>
            {props.state.isError && (
              <div>
                <FormattedMessage id='THERE_WAS_AN_ERROR' />
              </div>
            )}
          </span>
          <button
            className='cta-button'
            onClick={props.onFinish}
          >
            {props.state.isSubmitting && (
              <FormattedMessage id='LOADING' />
            )}
            {!props.state.isSubmitting && (
              <FormattedMessage id='ACTIVATE_SELECTED_PLAN' />
            )}
          </button>
        </div>
      )}
    </div>
  )
}
