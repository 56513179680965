import React from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import StripeElementsSetup from 'shared/StripeElements/Setup'
import CreditCard from 'shared/CreditCard'
import PlusSVG from 'shared/icons/feather/plus-circle.svg'
import BackSVG from 'shared/icons/feather/arrow-left.svg'
import localStyles from './index.module.scss'
import { useGlobalStore } from '../../lib/global'

export default function BillingSetup(props) {
  const [view, setView] = React.useState('card-list')
  const mode = props.mode === 'select' ? 'select' : 'manage'
  const intl = useIntl()
  const { auth, accountDb } = useGlobalStore(s => ({
    auth: s.auth,
    accountDb: s.accountDb
  }))

  const user = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.accounts.toCollection().first()
  }, [auth, accountDb])

  const paymentMethods = useLiveQuery(() => {
    if (!auth?.userId || !accountDb) return null
    return accountDb.payment_method.toArray()
  }, [auth, accountDb])

  const [cardAdded, setCardAdded] = React.useState(false)

  const onSuccess = async res => {
    setCardAdded(true)
    setTimeout(() => {
      setView('card-list')
    }, 1000)
  }

  const onPaymentMethodSelect = React.useCallback((paymentMethod) => ev => {
    if (mode === 'select' && typeof props.onPaymentMethodSelect === 'function') {
      props.onPaymentMethodSelect(paymentMethod)
    }
  })

  const goAddCard = React.useCallback(() => {
    setView('add-card')
  })

  const goCardList = React.useCallback(() => {
    setView('card-list')
    setCardAdded(false)
  })

  React.useEffect(() => {
    if (!props.state?.selectedPaymentMethod?.id && paymentMethods?.length === 1) {
      onPaymentMethodSelect(paymentMethods[0])()
    }
  }, [props.state?.selectedPaymentMethod?.id, onPaymentMethodSelect, paymentMethods])

  if (!auth) return null

  return (
    <div className={localStyles.billingSetup}>
      {view === 'card-list' && user && (
        <div className={localStyles.cardList}>
          <div className={localStyles.cardListHeader}>
            <h4>
              <FormattedMessage id='PAYMENT_CARDS' />
            </h4>
            <button
              onClick={goAddCard}
              alt={intl.formatMessage({ id: 'ADD_CARD' })}
            >
              <PlusSVG />
            </button>
          </div>
          <div className={localStyles.cardListBody}>
            {!paymentMethods?.length && (
              <div className={localStyles.placeholder}>
                <FormattedMessage id='NO_PAYMENT_METHODS_FOUND' />
              </div>
            )}
            {
              paymentMethods?.length > 0 &&
              paymentMethods.map((node) => (
                <CreditCard
                  name={user?.fullname || user?.username}
                  isActive={node.data.status === 'active'}
                  issuer={node.data.card.brand}
                  last4={node.data.card.last4}
                  expMonth={node.data.card.exp_month}
                  expYear={node.data.card.exp_year}
                  onClick={mode === 'select' ? onPaymentMethodSelect(node) : null}
                  isSelected={props.state?.selectedPaymentMethod?.id === node.id}
                />
              ))
            }
          </div>
        </div>
      )}
      {view === 'add-card' && (
        <div className={localStyles.addCardWrapper}>
          <div className={localStyles.addCardWrapperHead}>
            <button onClick={goCardList} className='back-button'>
              <BackSVG />
              <FormattedMessage id='BACK' />
            </button>
            <h4>
              <FormattedMessage id='ADD_CARD' />
            </h4>
          </div>
          <div className={localStyles.addCardWrapperBody}>
            {cardAdded && (
              <div className='add-payment-card-form'>
                <FormattedMessage id='CARD_ADDED_SUCCESSFULLY' />
              </div>
            )}
            {!cardAdded && (
              <StripeElementsSetup
                caption={intl.formatMessage({ id: 'ADD' })}
                onSuccess={onSuccess}
                onError={(err) => console.log(err)}
                auth={auth}
                user={user}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

