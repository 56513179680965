import React from 'react'
import { Link } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import { useGlobalStore } from '../../lib/global'
import { useWorkspace } from '../../hooks/useWorkspace'
import WorkspaceTitle from '../../components/WorkspaceTitle'

export default function SelectScreen(props) {
  const { workspaceList, isLoading } = useWorkspace()
  const { accountDb } = useGlobalStore(s => ({ accountDb: s.accountDb }))

  const invites = props.invites

  const onDeclineInvite = React.useCallback((invite) => {
    accountDb.invites.update(invite.id, {
      updated_at: (new Date()).toISOString(),
      status: 'DECLINED'
    })

    setTimeout(() => {
      accountDb.invites.delete(invite.id)
    }, 900)
  }, [accountDb])

  const onAcceptInvite = React.useCallback((invite) => {
    accountDb.invites.update(invite.id, {
      updated_at: (new Date()).toISOString(),
      status: 'ACCEPTED'
    })
  }, [accountDb])

  return (
    <>
      <div className={'welcomeScreenBlock'}>
        <div className={'welcomeScreenBlockBody'}>
          {isLoading && (
            <p>
              <FormattedMessage id='LOADING' />…
            </p>
          )}
          {!isLoading && workspaceList.length === 0 && (
            <p></p>
          )}
          {!isLoading && workspaceList.length > 0 && (
            <div className={'selectWorkspaceList'}>
              {workspaceList?.map((w, index) => (
                <Link key={w.id} to={`/${w.slug}`} id={`workspace-item-${index}`}>
                  <div className={'selectWorkspaceListItem'}>
                    <WorkspaceTitle workspace={w} isEnlarged />
                  </div>
                </Link>
              ))}
            </div>
          )}
          {workspaceList.length < 3 && (
            <div className={'workspaceActions'}>
              <Link to={'?newWorkspace=1'}>
                <FormattedMessage id='CREATE_WORKSPACE' />
              </Link>
            </div>
          )}
        </div>
      </div>
      {invites.length > 0 && (
        <div className={'welcomeScreenBlock'}>
          <div className={'welcomeScreenBlockBody'}>
            <div className={'workspaceActions'}>
              {invites?.map((invite, index) => (
                <div key={invite.id}>
                  <p className='invite-caption'>
                    <FormattedMessage
                      id='YOU_WERE_INVITED_TO_WORKSPACE'
                      values={{
                        byUser: (
                          <span className='invite-field-caption'>
                            {invite.invited_by.picture_url && (
                              <img src={invite.invited_by.picture_url} alt='' />
                            )}
                            {invite.invited_by.username || invite.invited_by.email}
                          </span>
                        ),
                        workspace: (
                          <span className='invite-field-caption'>
                            {invite.workspace.picture_url && (
                              <img src={invite.workspace.picture_url} />
                            )}
                            {invite.workspace.name}
                          </span>
                        )
                      }}
                    />
                  </p>
                  <div>
                    <button onClick={() => onAcceptInvite(invite)} className='cta-button'>
                      <FormattedMessage id='JOIN' />
                    </button>
                    <button onClick={() => onDeclineInvite(invite)}>
                      <FormattedMessage id='DECLINE' />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
