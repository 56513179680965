import React from 'react'
import { Link, navigate } from '@reach/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { useGlobalStore } from '../../lib/global'
import XSVG from '../../icons/remix/system/close-fill.svg'
import styles from './index.module.scss'
import menuStyles from '../menu.module.scss'
import { REACT_APP_BUILD_NUMBER, REACT_APP_BUILD_DATE } from '../../lib/config'

import AccountSettings from './Account'
import ProfileSettings from './Profile'
import PreferencesSettings from './Preferences'
import NotificationsSettings from './Notifications'
import BillingSettings from './Billing'
import VerificationSettings from './Verification'
import PayoutSettings from './Payout'

const SettingsComponent = (props) => {
  const intl = useIntl()
  const { auth, location } = useGlobalStore(s => ({
    auth: s.auth,
    location: s.location,
  }))
  const isActive = location.query?.settings === '1'
  const onClose = React.useCallback((ev) => {
    navigate(location.pathname)
  }, [location.pathname])

  if (!isActive || !auth.isAuthenticated) return null

  return (
    <>
      <Helmet titleTemplate=''>
        <title>
          {intl.formatMessage({ id: 'SETTINGS' })}
        </title>
      </Helmet>

      <div
        onClick={onClose}
        className={`${styles.settingsOverlay} ${isActive ? 'active' : ''}`}
      />

      <div className={`${styles.settings} ${isActive ? 'active' : ''}`}>
        <button className={`${styles.closeButton} internal`} onClick={onClose}>
          <XSVG />
        </button>
        <div className={`${menuStyles.menu} is-active`}>
          <div className={`${menuStyles.menuBlock}`}>
            <div className={`${menuStyles.menuBlockTitle}`}>
              <FormattedMessage id='ACCOUNT' />
            </div>
            <Link
              to={`${location.pathname}?settings=1&tab=account`}
              className={[
                menuStyles.menuLink,
                location.query?.settings === '1' && (!location.query?.tab || location.query?.tab === 'account') ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='GENERAL' />
            </Link>
            <Link
              to={`${location.pathname}?settings=1&tab=profile`}
              className={[
                menuStyles.menuLink,
                location.query?.settings === '1' && location.query?.tab === 'profile' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='PROFILE' />
            </Link>
            {/* <Link */}
            {/*   to={`${location.pathname}?settings=1&tab=preferences`} */}
            {/*   className={[ */}
            {/*     menuStyles.menuLink, */}
            {/*     location.query?.settings === '1' && location.query?.tab === 'preferences' ? 'active' : '' */}
            {/*   ].join(' ')} */}
            {/* > */}
            {/*   <FormattedMessage id='PREFERENCES' /> */}
            {/* </Link> */}
            {/* <Link */}
            {/*   to={`${location.pathname}?settings=1&tab=notifications`} */}
            {/*   className={[ */}
            {/*     menuStyles.menuLink, */}
            {/*     location.query?.settings === '1' && location.query?.tab === 'notifications' ? 'active' : '' */}
            {/*   ].join(' ')} */}
            {/* > */}
            {/*   <FormattedMessage id='NOTIFICATIONS' /> */}
            {/* </Link> */}
            <Link
              to={`${location.pathname}?settings=1&tab=billing`}
              className={[
                menuStyles.menuLink,
                location.query?.settings === '1' && location.query?.tab === 'billing' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='BILLING' />
            </Link>
            <Link
              to={`${location.pathname}?settings=1&tab=verification`}
              className={[
                menuStyles.menuLink,
                location.query?.settings === '1' && location.query?.tab === 'verification' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='VERIFICATION' />
            </Link>
            <Link
              to={`${location.pathname}?settings=1&tab=payout`}
              className={[
                menuStyles.menuLink,
                location.query?.settings === '1' && location.query?.tab === 'payout' ? 'active' : ''
              ].join(' ')}
            >
              <FormattedMessage id='PAYOUT' />
            </Link>
            <p className='app-build-info'>
              v{REACT_APP_BUILD_NUMBER} ({REACT_APP_BUILD_DATE})
            </p>
          </div>
        </div>
        <div className={styles.settingsBody}>
          <div className={styles.pageWrapper}>
            {(!location.query?.tab || location.query.tab === 'account') && (
              <AccountSettings />
            )}
            {(location.query.tab === 'profile') && (
              <ProfileSettings />
            )}
            {(location.query.tab === 'preferences') && (
              <PreferencesSettings />
            )}
            {(location.query.tab === 'notifications') && (
              <NotificationsSettings />
            )}
            {(location.query.tab === 'billing') && (
              <BillingSettings />
            )}
            {(location.query.tab === 'payout') && (
              <PayoutSettings {...props} />
            )}
            {(location.query.tab === 'verification') && (
              <VerificationSettings auth={auth} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsComponent
