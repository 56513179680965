export function getTasksSteps({ intl, locale }) {
  return [
    {
      name: 'task-manager',
      target: '.task-manager',
      content: intl.formatMessage({ id: 'TASK_MANAGER_TOUR_CAPTION' }),
      locale,
      placement: 'center',
    },
    {
      name: 'task-manager-new',
      target: '.task-manager .task-list-item:first-child',
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'task-manager-new-step-1',
      target: '#create-revise-task-button',
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_STEP_1_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'task-manager-new-step-2',
      target: '#revise-IN_EXPERT_TONE-button',
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_STEP_2_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'task-manager-new-submit',
      target: '.view-header-button.cta',
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_SUBMIT_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'task-manager-new-properties',
      target: '.item-view-horizontal-properties',
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_PROPERTIES_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'task-manager-new-status',
      target: '#task-property-status',
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_STATUS_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'task-manager-new-actions',
      target: '.action-buttons',
      title: intl.formatMessage({ id: 'TASK_MANAGER_NEW_ACTIONS' }),
      content: intl.formatMessage({ id: 'TASK_MANAGER_NEW_ACTIONS_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },

    {
      name: 'workspace-tasks',
      target: '#go-to-tasks',
      title: intl.formatMessage({ id: 'ALWAYS_ACCESSIBLE' }),
      content: intl.formatMessage({ id: 'ALWAYS_ACCESSIBLE_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
  ]
}

