export function getIdeasSteps({ intl, locale }) {
  return [
    {
      name: 'workspace-ideas',
      target: '#go-to-ideas',
      content: intl.formatMessage({ id: 'WORKSPACE_IDEAS_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'workspace-ideas-item',
      target: '#ideas-list .list-item:first-child',
      content: intl.formatMessage({ id: 'WORKSPACE_IDEAS_ITEM_TOUR_CAPTION' }),
      locale,
      placement: 'right',
    },
    {
      name: 'editor-2',
      target: '#editor-panel .input-wrapper.h1',
      content: intl.formatMessage({ id: 'EDITOR_2_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'editor-tools-button',
      target: '#toggle-tools-button',
      content: intl.formatMessage({ id: 'EDITOR_TOOLS_BUTTON_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'editor-tools-details',
      target: '#editor-tools-details-panel',
      content: intl.formatMessage({ id: 'EDITOR_TOOLS_DETAILS_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'editor-tools-tasks-link',
      target: '#editor-tools-tasks-link',
      content: intl.formatMessage({ id: 'EDITOR_TOOLS_TASKS_LIST_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
    {
      name: 'editor-tools-tasks-panel',
      target: '.create-task-button',
      content: intl.formatMessage({ id: 'EDITOR_TOOLS_TASKS_PANEL_TOUR_CAPTION' }),
      locale,
      placement: 'left',
    },
  ]
}
