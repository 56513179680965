export default {
  code: 'ko',

  CURRENT_LANGUAGE: '한국인',
  ru: '러시아어',
  fr: '프랑스어',
  ja: '일본어',
  en: '영어',
  ko: '한국인',
  kk: '카자흐어',

  // ui
  FULLSCREEN_ENTER: '전체 화면',
  FULLSCREEN_EXIT: '전체 화면 해제',
  CLOSE: '닫기',
  ADD: '추가',
  ENABLE: '활성화',
  DISABLE: '비활성화',
  REMOVE: '제거',
  NAME: '이름',
  NO_NAVIGATION_SET: '내비게이션 설정 없음',
  NO_COMMERCE_SET: '커머스가 꺼져 있음',

  SETTINGS: '설정',
  DASHBOARD: '대시보드',
  PRICE: '가격',
  WELCOME: '환영합니다',
  WELCOME_BACK: '다시 오신 것을 환영합니다',

  // context
  OVERVIEW: '개요',
  EDITOR: '편집기',
  CONTENT: '컨텐츠',
  GENERAL: '일반',
  NAVIGATION: '내비게이션',
  DESIGN: '디자인',
  STYLE: '스타일',
  COVER: '커버',
  MARKETING: '마케팅',
  AUDIENCE: '대상',
  SOCIAL: '소셜 미디어',
  COMMERCE: '커머스',
  OFFERINGS: '제안',
  ADVANCED: '고급 설정',
  COLLECTIONS: '컬렉션',
  DOMAINS: '도메인',
  EXPORT: '내보내기',
  LIVE: '라이브',
  VERSIONS: '버전',
  ANALYTICS: '분석',
  COMING_SOON: '곧 출시',
  MARKETS: '시장',
  TITLE: '제목',
  DESCRIPTION: '설명',
  SHOW_PLAIN_COVER: '간단한 커버 보기',
  SHOW_COMING_SOON: '곧 출시 보기',
  TAGLINES: '태그라인',
  TAGLINE: '태그라인',
  DANGER_ZONE: '위험 구역',
  TRASH: '휴지통',
  RESTORE: '복원',
  DELETE_FOREVER: '영구 삭제',
  MENU: '메뉴',
  TERMS: '이용 약관',
  REFUND_POLICY: '환불 정책',
  PRIVACY_POLICY: '개인정보처리방침',
  DOWNLOADS: '다운로드',
  FEEDBACK: '피드백',
  HELP: '도움말',
  UNTITLED: '제목 없음',
  PRODUCTS: '제품',
  LOGIN: '로그인',
  LOGOUT: '로그아웃',
  PAYOUTS: '지급',
  CUSTOMERS: '고객',
  THEMES: '테마',
  LOADING: '로딩 중',
  PRODMAKE: '프로드메이크',

  MADE_WITH: '만든 툴:',
  LANGUAGES: '언어',
  LANGUAGE: '언어',

  ALREADY_PURCHASED: '이미 구매함',
  GET_FULL_ACCESS: '전체 액세스 받기',

  USD: '미국 달러',
  BALANCE: '잔액',
  TOTAL_BALANCE: '총 잔액',
  PROFIT: '이익',
  REVENUE: '매출',
  TURNOVER: '매출액',
  TRANSACTIONS: '거래',
  DATE: '날짜',
  CURRENCY: '화폐',
  TRANSACTION_CURRENCY: '거래 화폐',
  MONEY_IN: '입금',
  MONEY_OUT: '출금',
  FEE: '수수료',
  STATUS: '상태',
  EMAIL: '이메일',
  PRODUCT: '제품',
  VERSION: '버전',
  OFFERING: '제안',
  WITHDRAW: '인출',
  UPGRADE_TO_PRO: 'Pro로 업그레이드',
  QUICK_ACCESS: '빠른 접근',
  GETTING_STARTED: '시작하기',
  GETTING_TRACTION: '성장 시작',
  SUGGESTED_FOR_YOU: '추천',

  WORKSPACE_SETTINGS: '워크스페이스 설정',
  CREATE_WORKSPACE: '워크스페이스 생성',
  JOIN_WORKSPACE: '워크스페이스 참여',
  GO_TO_STORE: '상점으로 가기',

  RESTORE_PURCHASE: '구매 복원',
  PRODUCT_VIEWS: '제품 보기',
  SALES: '판매',
  CONVERSION_RATE: '전환율',
  PRODMAKE_PRODUCT_TITLE: 'Prodmake 제품',
  PRODMAKE_DESCRIPTION: 'Prodmake로 제품 만들기',
  SECURE_ACCESS_LINK: '이메일로 안전한 접속 링크',
  ALREADY_PURCHASED_SUBMITTED: '성공적으로 접수되었습니다. 이메일을 확인해 주세요.',
  ERROR: '오류',
  MONEY_BACK_GUARANTEE_POINT: '14일 환불 보장',
  INSTANT_ACCESS_POINT: '웹, .pdf, .epub 버전에 즉시 액세스',
  FUTURE_UPDATES_POINT: '미래의 업데이트',
  PARTNER_PERKS_POINT: '파트너로부터의 혜택은 $800의 가치',
  LAST_UPDATED_POINT: '마지막 업데이트',
  GET_FOR_FREE_SUBMITTED: '무료 버전에 액세스 할 수 있습니다.',
  GET_FOR_FREE_NOW: '지금 무료로 받아보세요',
  WIP: '작업 중',
  POWERED_BY_PRODMAKE: '프로드메이크 제작',
  RAW_HTML: 'HTML',
  EPUB: 'Epub',
  SHORT_ID: '짧은 ID',
  NEW: '새로운',
  NO_PRODUCT_PUBLISHED: '제품이 게시되지 않았습니다',
  PREVIEW: '미리보기',
  VIEWING: '보기',
  OPEN_LIVE: '새 창에서 열기',
  BACK_TO_WEB: '웹으로 돌아가기',
  NO_ITEMS_TO_DISPLAY_RAW: '표시할 항목이 없습니다 raw',
  BACK_TO_HOMEPAGE: '홈페이지로 돌아가기',
  ACCESS_NOW_FOR_FREE: '무료로 접근하기',
  GET_ACCESS_NOW: '지금 접근하기',
  BE_FIRST_TO_ACCESS: '첫 번째로 접근하기',
  CHANGE_STRUCT_COVER: '표지 변경하기',
  CHANGE_LOGO: '로고 변경하기',
  CHANGE_AVATAR: '아바타 변경하기',
  CATEGORY: '카테고리',
  MANUALS: '매뉴얼',
  BOOKS: '책',
  GUIDES: '가이드북',
  PAGES: '페이지',
  LAST_RELEASE: '최신 릴리즈',
  AGE_RATING: '연령 등급',

  SERIF: '세리프',
  SANS_SERIF: '산스-세리프',
  MONOSPACE: '고정폭',
  FULL_WIDTH: '전체 폭',
  NARROW: '좁은',
  SIMPLE: '단순한',
  BLOCK: '블록',
  MINIMAL: '최소한의',
  FONTS: '폰트',
  CHAPTERS: '장',
  FOOTER: '푸터',
  REQUEST_ACCESS: '접근 요청',
  NOW: '지금',
  BUY: '구매',
  STAY_TUNED: '구독하기',
  VIEWING_LIMITED_VERSION: '제한된 버전을 보고 있습니다.',
  VIEWING_LIMITED_VERSION_OBFUSCATED: '텍스트가 왜곡된 제한된 버전을 보고 있습니다.',
  TO_SEE_CONTENT: '전체 접근을 위해',
  ABOUT_AUTHOR: '작성자 정보',
  RENDERING: '렌더링',
  SERIES_NAME: '시리즈 이름',
  BOOK_NUMBER: '책 번호',
  PUBLISHER: '출판사',
  DEDICATION: '헌정',
  MESSAGE: '메시지',
  EBOOK_ISBN: '책 ISBN',
  OPTIONAL: '선택적인',
  AUTHOR: '저자',
  TITLE_PAGE: '제목 페이지',
  WEB: '웹',
  EBOOK: '전자책',
  PDF: 'PDF',
  SHOW_PUBLICATION_DATE: '출판일 표시',
  SHOW_RELEASE_DATE: '릴리스 날짜 표시',
  SHOW_VERSION: '버전 표시',
  DOWNLOAD: '다운로드',
  REGENERATE: '재생성',
  SHOW_AUTHOR_INFO: '저자 정보 표시',
  SHOW_WORKSPACE_INFO: '작업공간 정보 표시',
  BIO: '바이오',
  PICTURE_URL: '사진 URL',
  OVERRIDE: '덮어쓰기',
  CONTRIBUTORS: '공동 저자',
  IMAGE: '이미지',
  SHOW: '표시',
  COLLECTION: '컬렉션',
  LINKS: '링크',
  REMOVE_SECTION: '섹션 삭제',
  INCLUSION_AND_ACCESS: '포함 및 접근',
  FULL_ACCESS: '전체 접근',
  DELETE_ITEM: '삭제',
  COLLECTION_TITLE: '컬렉션 제목',
  SLUG: '슬러그',
  REMOVE_COLLECTION: '컬렉션 삭제',
  INCLUDES_COLLECTION_ITEMS: '컬렉션 항목 포함 {count}/{total}:',
  WITH_FULL_ACCESS: '전체 접근으로',
  VISIBLE_ITEMS: '전체 접근으로 {count}/{total} 표시',
  OFFERING_AVAILABLE_FOR_FREE: '이 제안은 무료로 사용할 수 있습니다.',
  CURRENCY_AMOUNT_TERMS: '{name} 통화의 최소 금액은 {symbol}{min_amount}이며, 최대는 {symbol}{max_amount}입니다.',
  PREVIEW_VERSION: '미리보기 버전',
  TOC: '목차',
  DOMAIN_SETUP_MESSAGE: 'CNAME 레코드를 사용하여 도메인을 *.prodmake.com에 지정해야 합니다.',
  VALIDATE: '검증',
  REMOVE_DOMAIN: '도메인 제거',
  WORDS: '단어 수',
  ORIGINALLY_PUBLISHED: '원래 발표됨',
  LAST_UPDATED: '마지막 업데이트',
  ORIGINALLY_PUBLISHED_AT: '{date}에 원래 발표됨',
  LAST_UPDATED_AT: '{date}에 마지막으로 업데이트됨',
  LOCALIZATION: '로컬라이제이션',
  SELECT_AT_LEAST_ONE_LANGUAGE: '적어도 하나의 언어를 주요로 남겨주세요',
  THIS_COPY_BELONGS_TO: '이 복사본 v{version}은 {email}에게 속합니다',
  REQUEST: '요청',
  PERKS_WORTH: '특전 가치 ~$800',
  PAY_AMOUNT: '{amount} 지불하기',
  PAGE_LEFT_BLANK: '이 페이지는 공백으로 남겨져 있습니다.',
  PRIMARY_LANGUAGE: '기본 언어',
  SUPPORTED_LANGUAGES: '지원되는 언어들',
  SUCCESSFUL_PAYMENT_MESSAGE: '결제가 성공적으로 완료되었습니다.',
  PAYMENT_IS_PROCESSING_MESSAGE: '결제가 처리 중입니다.',
  PAYMENT_UNSUCCESSFUL_MESSAGE: '결제에 실패했습니다. 결제 방법을 확인하십시오.',
  SOMETHING_WENT_WRONG: '문제가 발생했습니다. 다시 시도해 주세요.',
  INCLUDED_IN: '포함됨',
  DETAILS: '세부 사항',
  SUBTITLE: '부제목',
  PAGE_NOT_FOUND: '페이지를 찾을 수 없음',
  THANK_YOU: '감사합니다',
  START: '시작',
  PRODMAKE_PITCH_TITLE: 'Prodmake로 제품 만들기',
  PRODMAKE_PITCH_CTA: '{link}은 진정으로 글로벌 제품을 위한 편집기입니다',
  TRY_PRODMAKE: '지금 시작하기',
  SCROLL_PURCHASE: '스크롤하면 \'구매\'로',
  ITEM_INCLUDED_IN_OFFERINGS: '제안에 항목이 포함되어 있습니다',
  VISIBLE: '보이는',
  HIDDEN: '숨겨진',
  PROPERTIES: '속성',
  BANNER_CTA: '배너 CTA (호출 액션)',
  PURCHASE_FOR_FULL_ACCESS: '전체 액세스를 위해 구매',
  ACCESS: '접근',
  SAMPLE: '샘플',
  VISIBLE_OBFUSCATED: '표시되지만 난독화된',
  ALL_LANGUAGES: '모든 언어',
  PURCHASED_ON: '구매일',
  ITEM_NOT_PUBLIC: '아이템은 아직 게시되지 않았습니다',
  SUCCESSFUL_FREEBIE_MESSAGE: '접근이 허용되었습니다',
  ACCESSED_ON: '접근일',
  SETTLED: '결제 완료',
  UNIVERSAL: '유니버설',
  ALL: '모두',
  TRANSLATE: '번역',
  TRANSLATE_TO: '번역하기',
  UNIQUE: '유일한',
  'N/A': 'N/A',
  WRITING: '글쓰기',
  TASKS: '작업들',
  PAGE_LIST: '페이지 목록',
  PURCHASE_INFO: '구매 정보',
  HERO: '히어로 (배너)',
  SCROLL_PROGRESS: '스크롤 진행',
  HOME_SCROLL: '홈 스크롤',
  NAV: '네비게이션',
  PRODUCT_PROPERTIES: '제품 속성',
  PRODUCT_AUTHOR: '저자 정보',
  BLUEPRINT: '블루프린트',
  BLOCKS: '블록',
  READY_TO_USE: '사용 준비 완료',
  WEB_BOOK: '웹 북',
  COVER_RIBBON: '커버 리본',
  COVER_INTERACTION: '커버 상호작용',
  STRUCTURE: '구조',
  WIDTH: '너비',
  ENABLED: '활성화',
  DISABLED: '비활성화',
  DISTRIBUTION: '분배',
  AUTHOR_BIO: '저자 소개',
  EXPORT_EPUB: 'Epub으로 내보내기',
  EXPORT_PDF: 'PDF로 내보내기',
  VIEW_JSON: 'JSON으로 보기',
  CHOOSE_TYPE: '타입 선택',
  UNIVERSAL_BOOK: '유니버설 북',
  PLAYLIST_COURSE: '플레이리스트 코스',
  PERSONAL_WEBSITE: '개인 웹사이트',
  IMPORT: '수입',
  BILLING: '청구',
  PAYOUT: '지급',
  PLANS: '플랜',
  NOTIFICATIONS: '알림',
  PREFERENCES: '환경 설정',
  PROFILE: '프로필',
  CANCEL: '취소',
  SAVE: '저장',
  ACCOUNT: '계정',
  FULLNAME: '성명',
  PAYMENT_CARDS: '결제 카드',
  NO_PAYMENT_METHODS_FOUND: '결제 수단이 없습니다',
  CARD_ADDED_SUCCESSFULLY: '카드가 성공적으로 추가되었습니다',
  ADD_CARD: '카드 추가',
  GRIND: '그라인드',
  YEAR_ONE: '첫 해',
  PRO: '프로페셔널',
  YEARLY: '연간 플랜',
  MONTHLY: '월간 플랜',
  CHOOSE_PLAN: '플랜 선택',
  PAYMENT_METHOD: '결제 방법',
  CONFIRMATION: '확인',
  SELECT: '선택',
  SELECT_PLAN: '‘{planName}’ 선택',
  NEXT: '다음',
  YOUR_CURRENT_FREE_TRIAL_PLAN: '현재 무료 체험 기간 ‘{planName} {planPeriod}’은 {trialUntil}에 종료됩니다.',
  YOUR_CURRENT_PAID_PLAN: '현재 유료 플랜 ‘{planName} {planPeriod}’은 {paidUntil}에 갱신됩니다.',
  CURRENT_PLAN: '현재 플랜',
  INBOX: '받은편지함',
  FINISH: '완료',
  CHOOSE_PAYMENT_METHOD: '결제 방법을 선택해주세요',
  ACTIVATE_SELECTED_PLAN: '선택한 플랜 활성화',
  BACK_TO_CARD_LIST: '카드 목록으로 돌아가기',
  LAST_PRODUCT_VIEWS: '최근 상품 조회',
  NO_VIEWS_YET: '아직 조회 없음',
  FREE_TRIAL_ENDS: '{inDays}일 후에 무료 체험 종료',
  WORKSPACE_NAME: '워크스페이스 이름',
  NEW_WORKSPACE: '새 워크스페이스',
  WORKSPACE_MEMBERS: '워크스페이스 멤버',
  INVITE: '초대',
  WORKSPACE_INVITE: '워크스페이스에 초대',
  NO_MEMBERS_FOUND: '멤버 없음',
  UPGRADE_NOW: '지금 업그레이드',
  NOVICE: '초보',
  ESTABLISHED: '경험자',
  SEE_INVOICES: '청구서 보기',
  CANCEL_SUBSCRIPTION: '구독 취소',
  BACK: '뒤로',
  MONTHLY_RATE: '월간 요금',
  YEARLY_RATE: '연간 요금',
  PLAN: '플랜',
  SUBSCRIPTION_ACTIVATED_1: '구독이 성공적으로 활성화되었습니다. {date}까지 지불되었습니다.',
  SUBSCRIPTION_ACTIVATED_2: '신용카드 결제는 PRODMAKE (또는 STRIPE로 보류 중인 결제)로 명세서에 표시됩니다.',
  SUBSCRIPTION_ACTIVATED_3: '이메일로 확인을 받게됩니다. Prodmake를 선택해 주셔서 감사합니다!',
  SUCCESSFULLY_ACTIVATED: '성공적으로 활성화됨',
  CONTINUE: '계속',
  MONTHLY_PLAN_RATE_PER_SEAT: '사용자당 월 {rate}',
  YEARLY_PLAN_RATE_PER_SEAT: '사용자당 연 {rate}',
  TRIAL_PERIOD: '체험 기간',
  N_DAYS: '{count}일',
  YOU_WONT_BE_CHARGED_NOW: '지금 청구되지 않습니다',
  START_PLAN_TRIAL: '지금 체험 시작',
  NO_PURCHASES_YET: '아직 구매 없음',
  UPCOMING_PAYOUTS: '예정된 지급금',
  UPDATE_PERSONAL_DETAILS: '개인 정보 업데이트',
  UPDATE_WORKSPACE_DETAILS: '작업 공간 정보 업데이트',
  TRANSLATE_AUTOMATICALLY: '자동 번역',
  GET_NEW_PRODUCT_IDEAS: '새로운 제품 아이디어 얻기',
  MAKE_YOUR_FIRST_SALE: '첫 판매를 하다',
  GET_YOUR_FIRST_PAYOUT: '첫 지급금 받기',
  SET_UP_A_PROFILE: '프로필 설정하기',
  SET_UP_A_WORKSPACE: '작업 공간 설정하기',
  SET_UP_A_PAYMENT_METHOD: '결제 방법 설정하기',
  SET_UP_PAYOUT: '지급금 설정하기',
  CREATE_YOUR_FIRST_PRODUCT: '첫 제품 만들기',
  PUBLISH_YOUR_FIRST_PRODUCT: '첫 제품 게시하기',
  ONBOARDED: '온보딩 완료된 작업 공간',
  YOU_DONT_HAVE_UPCOMING_PAYOUTS: '예정된 지급금이 없습니다',
  IDEAS: '아이디어',
  PARAPHRASE: '패러프레이즈',
  STATS: '통계',
  CREATED: '생성됨',
  PROCESSING: '처리 중',
  PROCESSED: '처리 완료',
  AI_STRUCT_TRANSLATION: '번역',
  AI_STRUCT_REVISE: '다시 쓰기',
  AI_STRUCT_CHECK_GRAMMAR: '문법 검사',
  AI_BOOK_GENERATION: '책 생성',
  AI_WRITE_DRAFT: '초안 작성',
  AI: 'AI',
  VIEW: '보기',
  ACCEPT: '수락',
  GENERATE: '생성',
  DONT_HAVE_AN_ACCOUNT: '계정이 없으신가요?',
  JOIN_PRODMAKE: 'Prodmake 가입',
  ALREADY_HAVE_AN_ACCOUNT: '이미 계정이 있으신가요?',
  INVITATION_CODE: '초대 코드',
  PASSWORD: '비밀번호',
  LOGGED_IN_AS: '{email}로 로그인됨',
  SELECT_OR_CREATE: '선택 또는 생성',
  NEW_TASK: '새 작업',
  SUBMIT: '제출',
  FOR_WHOM: '누구를 위한',
  COMPLETED_IN: '완료 시간',
  ASSIGNEE: '담당자',
  COST: '비용',
  COST_INCLUDED: '계획에 포함된 비용',
  PAGE_COUNT: '{count} 페이지',
  DELETE: '삭제',
  HAS_NOT_BEEN_COMPLETED: '아직 완료되지 않았습니다',
  REPEAT_TASK: '작업 반복',
  SECOND_COUNT: '{count} 초',
  TASK: '작업',
}
