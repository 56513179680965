import React from 'react'
import qs from 'query-string'
import { Link, navigate } from '@reach/router'
import { toast } from 'sonner'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLiveQuery } from 'dexie-react-hooks'
import styles from './index.module.scss'
import Awareness from '../Awareness'
import AddSVG from '../../icons/remix/system/add-line.svg'
import UIControls from '../UIControls'
import Menu2LineSVG from '../../icons/remix/system/menu-2-line.svg'
import Menu3LineSVG from '../../icons/remix/system/menu-3-line.svg'
import FeedbackSVG from '../../icons/remix/communication/feedback-line.svg'
import TrashSVG from '../../icons/remix/system/delete-bin-line.svg'
import { useGlobalStore } from '../../lib/global'
import { useWorkspaceSubscription } from '../../hooks/useWorkspaceSubscription'
import WorkspaceSelect from '../WorkspaceSelect'
import UserSwitcher from '../UserSwitcher'
import { addStruct } from '../../lib/db/models/struct'

export default function Nav(props) {
  const intl = useIntl()
  const {
    auth,
    location,
    isPadded,
    togglePadded,
    toggleMinimalView,
    isMinimalView,
    tmpStruct
  } = useGlobalStore(s => ({
    auth: s.auth,
    location: s.location,
    isPadded: s.isPadded,
    togglePadded: s.togglePadded,
    toggleMinimalView: s.toggleMinimalView,
    isMinimalView: s.isMinimalView,
    tmpStruct: s.tmpStruct
  }))
  const {
    isActivated,
    trialEnds,
    isTrialLapsed,
    isOnTrial,
    maxProducts,
  } = useWorkspaceSubscription({ activeWorkspace: props.activeWorkspace })

  const { visibleStructs, trashedStructs } = React.useMemo(() => {
    const visible = []
    const trashed = []

    if (props.structs) {
      props.structs.forEach(o => {
        if (!o.is_trashed && !o.is_deleted) {
          visible.push(o)
        }
        if (o.is_trashed && !o.is_deleted) {
          trashed.push(o)
        }
      })
    }

    return {
      visibleStructs: visible,
      trashedStructs: trashed
    }
  }, [props.structs])

  const canCreateStructs = maxProducts > visibleStructs.length || (
    auth.user?.email?.includes('prodmake.com') ||
    auth.user?.email?.includes('xevol.com')
  )

  const onCreateStruct = React.useCallback(async () => {
    if (!canCreateStructs) {
      toast.error(intl.formatMessage({ id: 'YOUR_HIT_MAX_PRODUCTS_LIMIT' }))
      return
    }

    const newStruct = await addStruct({
      type: 'v0',
      lang: intl.locale,
      workspace_id: props.activeWorkspace.id
    })

    if (!props.activeWorkspace.meta?.data?.has_created_product) {
      props.activeDatabase.metas.update(props.activeWorkspace.meta?.id, {
        updated_at: (new Date()).toISOString(),
        'data.has_created_product': true
      })
    }

    navigate(`/${props.activeWorkspace?.slug}/product/${newStruct.short_id}`)
  }, [props.activeDatabase?.metas, intl.locale, props.activeWorkspace, canCreateStructs])

  const processingTasks = useLiveQuery(() => {
    if (!props.activeDatabase) return []
    return props.activeDatabase.tasks
      .where('status').equalsIgnoreCase('processing')
      .or('status').equalsIgnoreCase('ready')
      .toArray()
  }, [props.activeDatabase], [])

  const isActive = !props.isHidden && isPadded

  const productUrl = `/${props.activeWorkspace?.slug}/product/`
  const isProductView = location.pathname.includes(productUrl) && tmpStruct?.data?.blueprint

  return (
    <>
      {props.activeWorkspace && (
        <button
          onClick={togglePadded}
          className={`${styles.toggleButton} ${!isMinimalView ? 'is-active' : ''} ${isActive ? 'is-padded' : ''}`}
        >
          <span
            className={`${styles.menuLines} ${'active'}`}
          />
        </button>
      )}
      {props.activeWorkspace && isProductView && (
        <button onClick={toggleMinimalView} className={`${styles.toggleMinimalViewButton} ${isMinimalView ? 'is-minimal-view' : ''} ${isPadded ? 'is-extra' : ''} is-visible`}>
          {isMinimalView && <Menu3LineSVG />}
          {!isMinimalView && <Menu2LineSVG />}
        </button>
      )}
      <nav className={`nav ${styles.list} ${isActive ? 'is-visible' : ''}`} id='workspace-navigation'>
        {/* <div className={`${styles.block} controls`} data-tauri-drag-region> */}
        {/*   <div/> */}
        {/* </div> */}
        <div className={`${styles.block} head`}>
          <WorkspaceSelect />
          <UserSwitcher />
        </div>
        <div className={`${styles.block} body`}>
          {props.activeWorkspace && (
            <div className={styles.block}>
              <div
                id='go-to-overview'
                className={`${styles.listItem} ${location.pathname === `/${props.activeWorkspace?.slug}` ? 'is-active' : ''}`}
              >
                <Link to={`/${props.activeWorkspace?.slug}`}>
                  <FormattedMessage id='OVERVIEW' defaultMessage='Overview' />
                </Link>
              </div>
              <div className={`${styles.listItem} ${location.pathname === `/${props.activeWorkspace?.slug}/analytics` ? 'is-active' : ''}`}>
                <Link to={`/${props.activeWorkspace?.slug}/analytics`}>
                  <FormattedMessage id='ANALYTICS' defaultMessage='Analytics' />
                </Link>
              </div>
              <div
                id='go-to-ideas'
                className={`${styles.listItem} ${location.pathname === `/${props.activeWorkspace?.slug}/ideas` ? 'is-active' : ''}`}
              >
                <Link to={`/${props.activeWorkspace?.slug}/ideas`}>
                  <FormattedMessage id='IDEAS' />
                </Link>
              </div>
            </div>
          )}
          {props.activeWorkspace && (
            <div className={styles.block}>
              <div className={styles.blockHeader}>
                <h4>
                  <FormattedMessage id='PRODUCTS' defaultMessage='Products' />
                </h4>
                <button
                  onClick={onCreateStruct}
                  className='iconButton'
                  id='create-struct-button'
                  disabled={!canCreateStructs}
                >
                  <AddSVG />
                </button>
              </div>
              <div className={styles.blockBody}>
                {visibleStructs.map((struct) => {
                  const href = `/${props.activeWorkspace?.slug}/product/${struct.short_id}`
                  return (
                    <div
                      key={struct.id}
                      className={`nav-list-item ${styles.listItem} ${location.pathname.includes(href) ? 'is-active' : ''}`}
                    >
                      <Link to={href}>
                        {struct.data?.site?.title?.[intl.locale] || struct.data?.site?.title?.[struct.lang] ||
                          <FormattedMessage id='UNTITLED' defaultMessage='Untitled' />
                        }
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className={`${styles.block} footer`}>
          {auth.isAuthenticated && <Awareness {...props} />}

          <div
            className={`${styles.listItem} ${location.pathname === `/${props.activeWorkspace?.slug}/tasks` ? 'is-active' : ''}`}
            id='go-to-tasks'
          >
            <Link
              to={`${location.pathname}?${qs.stringify({
                ...location.query || {},
                createTask: '1'
              })}`}
            >
              <FormattedMessage id='TASKS' />
              {processingTasks?.length > 0 ? <span className='processing-count'>{processingTasks.length}</span> : ''}
            </Link>
          </div>

          {trashedStructs.length > 0 && (
            <div className={`${styles.listItem} ${location.pathname === `/${props.activeWorkspace?.slug}/trash` ? 'is-active' : ''}`}>
              <Link to={`/${props.activeWorkspace?.slug}/trash`}>
                <TrashSVG />
                <FormattedMessage id='TRASH' />
              </Link>
            </div>
          )}
          {/* <div className={`${styles.listItem} small`}> */}
          {/*   <Link to={`/${props.activeWorkspace?.slug}/members`}> */}
          {/*     Invite */}
          {/*   </Link> */}
          {/* </div> */}
          {auth.isAuthenticated && (
            <div className={`${styles.listItem} small ui-controls`}>
              <UIControls {...props} fixed={false} />
            </div>
          )}
          {isOnTrial && (
            <div className={`${styles.listItem} trial`}>
              <Link to={`${location.pathname}?activate=1`}>
                <FormattedMessage id='FREE_TRIAL_ENDS' values={{ inDays: trialEnds }} />
                <small>
                  <FormattedMessage id='UPGRADE_NOW' />
                </small>
              </Link>
            </div>
          )}
          {isTrialLapsed && (
            <div className={`${styles.listItem} trial`}>
              <Link to={`${location.pathname}?activate=1`}>
                <FormattedMessage id='TRIAL_ENDED' />
                <small>
                  <FormattedMessage id='UPGRADE_NOW' />
                </small>
              </Link>
            </div>
          )}
        </div>
      </nav>
    </>
  )
}
