import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../index.module.scss'

function PreferencesPage(props) {
  return (
    <div className={styles.wrapper} id='preferences'>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id='PREFERENCES' />
        </div>
      </div>
      <div className={styles.body}>
        <p>
          TBA
        </p>
      </div>
    </div>
  )
}

export default PreferencesPage
