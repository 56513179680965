import * as React from 'react'
import Gravatar from 'react-gravatar'
// import UserSVG from 'shared/icons/feather/user.svg'

export default function UserPicture({ user }) {
  return (
    <React.Fragment>
      {user?.picture_url && (
        <img
          alt={user.username}
          src={user.picture_url}
        />
      )}
      {!user?.picture_url && user?.email && (
        <Gravatar email={user.email} size={150} />
      )}
      {/* {!user?.picture_url && !user?.email && ( */}
        {/* <UserSVG /> */}
      {/* )} */}
    </React.Fragment>
  )
}
