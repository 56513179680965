import * as React from 'react'
import DatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'

export default function CustomDatepicker(props) {
  const id = props.id || ''
  const intl = useIntl()
  const [dobDate, setDOBDate] = React.useState(new Date())

  const onChange = (date) => {
    setDOBDate(date)
    if (typeof props.onChange === 'function') {
      props.onChange(date)
    }
  }

  React.useEffect(() => {
    if (props.value) setDOBDate(new Date(props.value))
  }, [props.value])

  return (
    <div className={`input-wrapper ${props.className || ''}`}>
      {props.label && (
        <label htmlFor={id} className='input-label'>
          {props.label}
        </label>
      )}
      <div>
        <DatePicker
          id={id}
          dateFormat='yyyy/MM/dd'
          selected={dobDate}
          onChange={onChange}
          locale={intl.locale}
        />
      </div>
    </div>
  )
}
