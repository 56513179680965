export function getCurrencySymbol(code) {
  switch (code) {
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    case 'JPY':
      return '¥'
    case 'KZT':
      return '₸'
    case 'GBP':
      return '£'
    case 'AUD':
      return 'AU$'
    case 'CAD':
      return 'CA$'
    case 'AED':
      return 'AED'
    default:
      return ''
  }
}
