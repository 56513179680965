import React from 'react'
import { useIntl } from 'react-intl'
import Select from '../Select'

export default function CountrySelector(props) {
  const id = props.id || ''
  const intl = useIntl()
  const [value, setValue] = React.useState()
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    async function doit() {
      const res = await fetch(`/countries/data-${intl.locale}.json`)
      const json = await res.json()
      if (json) {
        setOptions(json)
      }
    }
    doit()
  }, [intl.locale])

  const changeHandler = value => {
    setValue(value)
    if (typeof props.onChange === 'function') {
      props.onChange(value)
    }
  }

  React.useEffect(() => {
    if (props.value?.length === 2) {
      const se = options.find(o => o.value === props.value)
      setValue(se)
    } else {
      setValue(props.value)
    }
  }, [props.value])

  return (
    <div className={`input-wrapper ${props.className || ''}`}>
      {props.label && (
        <label htmlFor={id} className='input-label'>
          {props.label}
        </label>
      )}
      <Select
        options={options}
        value={value}
        onChange={changeHandler}
        placeholder={intl.formatMessage({ id: 'SELECT_COUNTRY' })}
        noOptionsMessage={() => intl.formatMessage({ id: 'NO_COUNTRY_FOUND' })}
      />
    </div>
  )
}
