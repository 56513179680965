import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '@reach/router'
import BackSVG from 'shared/icons/feather/arrow-left.svg'
import dayjs from 'shared/utils/dayjs'
import { formatPrice } from 'shared/helpers/currencies/formatPrice'
import customFetch from '../../../utils/fetch'
import './styles.scss'

export default function Invoices(props) {
  // TODO: fetch receipts from api and render
  const [state, setState] = React.useState({
    isLoading: true,
    invoices: []
  })

  React.useEffect(() => {
    if (props.activeWorkspace) {
      (async function() {
        try {
          const data = await customFetch(`/v1/workspaces/${props.activeWorkspace.id}/invoices`, {})
          setState((prev) => ({
            isLoading: false,
            invoices: data?.invoices || [],
          }))
        } catch (err) {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            error: err.message
          }))
        }
      }())
    }
  }, [props.activeWorkspace])

  return (
    <div className='workspace-plan-step'>
      <div className='workspace-plan-step-head'>
        <div>
          <Link to={`${props.location.pathname}?wsettings=1&tab=plans`} className='back-button'>
            <BackSVG />
            <FormattedMessage id='BACK' />
          </Link>
        </div>
        <h4 className='title'>
          <FormattedMessage id='INVOICES' />
        </h4>
      </div>
      <div className='workspace-plan-step-body'>
        {state.isLoading && <FormattedMessage id='LOADING' />}
        {!state.isLoading && state.error && (
          <div>
            <FormattedMessage id={state.error} />
          </div>
        )}
        {!state.isLoading && !state.error && state.invoices.length === 0 && (
          <div>
            <FormattedMessage id='NO_INVOICES_FOUND' />
          </div>
        )}
        {!state.isLoading && state.invoices.length > 0 && (
          <div>
            {state.invoices.map((invoice) => (
              <div key={invoice.id} className='invoice-row'>
                <div>
                  <FormattedMessage id='SUBSCRIPTION_CHARGE' /> • <FormattedMessage id={invoice.plan_name} /> (<FormattedMessage id={invoice.plan_period} />)
                </div>
                <div>
                  <span className='price'>
                    {formatPrice(invoice.amount, invoice.currency_code)}
                  </span>
                  <span className='paid-with-card'>
                    *{invoice.card.last4}
                  </span>
                  <span className='date'>
                    {dayjs(invoice.created_at).format('MMM D, YYYY')}
                  </span>
                </div>
                {/* <div> */}
                {/*   {invoice.id} */}
                {/* </div> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
