import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '@reach/router'
import { useGlobalStore } from '../../lib/global'
import styles from './index.module.scss'

export default function TopbarMenu(props) {
  const { auth, location } = useGlobalStore(s => ({ location: s.location, auth: s.auth }))
  const hasUserOnboarded = props?.user?.data?.has_onboarded
  if (!hasUserOnboarded) return null

  const isKaz = auth?.user?.email?.includes('kuka@xevol.com') || auth?.user?.email?.includes('kaz@prodmake.com')

  return (
    <div className={styles.topbarMenu}>
      <div>
        <a href='https://prodmake.com/?ref=app'>
          <FormattedMessage id='GO_TO_STORE' />
        </a>
        {auth.isAuthenticated && (
          <Link
            to={`${location.pathname}?settings=1`}
            className={`${styles.styledLink}`}
          >
            <FormattedMessage id='SETTINGS' defaultMessage='Settings' />
          </Link>
        )}
        {isKaz && (
          <>
            <Link
              to='/biz'
              className={`${styles.styledLink}`}
            >
              <FormattedMessage id='BIZ' defaultMessage='Biz' />
            </Link>
          </>
        )}
        {isKaz && (
          <>
            <Link
              to='/admin'
              className={`${styles.styledLink}`}
            >
              <FormattedMessage id='ADMIN' defaultMessage='Admin' />
            </Link>
          </>
        )}
      </div>
    </div>
  )
}
