import React from 'react'
import { useIntl } from 'react-intl'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import * as config from '../config'
import SetupForm from './SetupForm'

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY)

export default function StripeElements(props) {
  const intl = useIntl()
  const [secret, setSecret] = React.useState('')

  React.useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${config.API_URL}/v1/payments/create-setup-intent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.auth.token}`
          },
          body: JSON.stringify({
            locale: intl.locale
          }),
        })
        const data = await res.json()
        setSecret(data.client_secret)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  const commonProps = {
    secret,
    auth: props.auth,
    user: props.user,
    lang: intl.locale,
    caption: props.caption,
    onError: props.onError,
    onSuccess: props.onSuccess
  }

  if (typeof window !== 'undefined' && secret) {
    return (
      <Elements
        stripe={stripePromise}
        options={{ locale: intl.locale }}
      >
        <SetupForm {...commonProps} />
      </Elements>
    )
  }

  return null
}
