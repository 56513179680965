import React from 'react'
import { globalLocaleList } from '../translations'

export function useSupportedLocales(struct) {
  const list = React.useMemo(() => {
    if (struct) {
      const s = typeof struct.version === 'undefined' ? struct : struct.data
      const supportedLocales = s.data?.site?.supported_locales
      return globalLocaleList.filter(o => {
        if (supportedLocales) {
          return supportedLocales.includes(o.locale?.toLowerCase())
        }
        return o.locale?.toLowerCase() === s.lang?.toLowerCase()
      })
    }
    return globalLocaleList
  }, [struct])

  return list
}
