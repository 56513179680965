import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGlobalStore } from '../../lib/global'
import { Link, navigate } from '@reach/router'
import { createWorkspace } from '../../lib/db/models/workspace'
import './styles.scss'

export default function NewWorkspace(props) {
  const intl = useIntl()
  const { auth, location } = useGlobalStore(s => s)
  const [state, setState] = React.useState({
    name: '',
    errors: {},
    isLoading: false
  })

  const onChange = React.useCallback(ev => {
    const { currentTarget } = ev
    setState(prev => ({
      ...prev,
      [currentTarget.name]: currentTarget.value
    }))
  }, [])

  const isValid = React.useCallback(() => {
    return true
  }, [])

  const onSubmit = React.useCallback(async e => {
    e.preventDefault()

    if (isValid()) {
      setState({
        errors: {},
        isLoading: true })

      try {
        await createWorkspace(state.name, intl.locale)
        navigate('/')
      } catch (err) {
        setState({
          errors: {
            form: err
          },
          isLoading: false
        })
      }
    }
  }, [state.name, isValid])

  if (!auth.isAuthenticated) return null

  const isActive = location.query?.newWorkspace === '1'
  if (!isActive) return null

  return (
    <>
      <div className='new-workspace-window'>
        <Link
          to='/'
          className={`new-workspace-overlay ${isActive ? 'is-active' : ''}`}
        />
        <div className={'new-workspace-form-wrapper'}>
          <div className={'new-workspace-form-header'}>
            <h1 className={'title'}>
              <FormattedMessage id='NEW_WORKSPACE' />
            </h1>
          </div>
          <div className={'new-workspace-form-body'}>
            <div>
              <form onSubmit={onSubmit} className={'form'}>
                <div className={'new-workspace-form-field'}>
                  <input
                    id='name'
                    type='text'
                    placeholder={intl.formatMessage({ id: 'NAME' })}
                    onChange={onChange}
                    name='name'
                  />
                </div>
                <button type='submit'>
                  <FormattedMessage id='CREATE_WORKSPACE' />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
