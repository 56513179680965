import Dexie from 'dexie'
import { useGlobalStore } from '../../global'
import { registerSync, addons } from '../dexie'
import { SOCKET_URL, connect } from '../ws'

export class WorkspaceDatabase extends Dexie {
  constructor(id, opts) {
    super(id, opts)
    this.version(26).stores({
      structs: '$$id, short_id, parent_id, workspace_id',
      struct_items: '$$id, slug, struct_id, workspace_id',
      struct_versions: '$$id, short_id, struct_id, workspace_id',
      struct_offerings: '$$id, short_id, struct_id, workspace_id',
      tasks: '$$id, type, status, struct_item_id',
      infos: '$$id, workspace_id, short_id',
      metas: '$$id, workspace_id, short_id',
      members: '$$id, workspace_id',
      invites: '$$id, invite_id',
      struct_invites: '$$id, struct_id',
      struct_domains: '$$id, struct_id',
      struct_access: '$$id, struct_id',
      struct_publics: null,
    })

    this.structs.hook('updating', function (mods, primKey, obj, tx) {
      if (typeof tx.source === 'undefined') {
        tx.is_local = true
      } else {
        tx.is_local = false
      }
    })
  }
}

export const createWorkspaceDatabase = ({ id }) => {
  registerSync(WorkspaceDatabase)
  const db = new WorkspaceDatabase(id, { addons })

  db.startSync = async (cb = () => {}) => {
    try {
      // Prevent multiple sync attempts
      if (db.isSyncing) {
        return
      }
      
      db.isSyncing = true
      const ws = await connect({ db })
      await db.open()

      ws.runWhenReady(function() {
        const state = useGlobalStore.getState()

        db.syncable.connect('websocket', SOCKET_URL, {
          c: 'workspace',
          workspace_id: id,
          token: state.auth.token,
          lang: state.lang || 'en'
        }).catch(err => {
          console.error (`Failed to connect: ${err.stack || err}`)
        })
        db.syncable.on('statusChanged', function (status, url) {
          useGlobalStore.getState().setDbStatus({
            status,
            text: Dexie.Syncable.StatusTexts[status]
          })
        })
      })

      if (cb) cb()
    } catch (err) {
      console.log('err syncing workspace', err)
    }
  }

  db.stopSync = async () => {
    await db.open()
    db.syncable.disconnect(SOCKET_URL)
    db.isSyncing = false
  }

  db.getSyncStatus = async () => {
    await db.open()
    const status = db.syncable.getStatus(SOCKET_URL)
    return status
  }

  return db
}
