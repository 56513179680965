export function getWelcomeSteps({ intl, locale }) {
  return [
    {
      name: 'workspace',
      target: '#workspace-item-0',
      title: intl.formatMessage({ id: 'SELECT_A_WORKSPACE_FIRST' }),
      content: intl.formatMessage({ id: 'SELECT_A_WORKSPACE_FIRST_CAPTION' }),
      placement: 'top',
      locale,
      disableBeacon: true,
    },

    {
      name: 'your-workspace',
      target: 'body',
      title: intl.formatMessage({ id: 'YOUR_WORKSPACE' }),
      content: intl.formatMessage({ id: 'YOUR_WORKSPACE_CAPTION' }),
      locale,
      placement: 'center',
    },
  ]
}

