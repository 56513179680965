export const currencies = [{
  "symbol": "AED",
  "name": "United Arab Emirates Dirham",
  "symbol_native": "د.إ.‏",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "AED",
  "name_plural": "UAE dirhams",
  "prefix": "AED ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "AE"
}, {
  "symbol": "Af",
  "name": "Afghan Afghani",
  "symbol_native": "؋",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "AFN",
  "name_plural": "Afghan Afghanis",
  "prefix": "",
  "suffix": "؋",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "AF"
}, {
  "symbol": "ALL",
  "name": "Albanian Lek",
  "symbol_native": "Lek",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "ALL",
  "name_plural": "Albanian lekë",
  "prefix": "",
  "suffix": " L",
  "decimal_point": ".",
  "thousands_separator": " ",
  "default_country": "AL"
}, {
  "symbol": "AMD",
  "name": "Armenian Dram",
  "symbol_native": "դր.",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "AMD",
  "name_plural": "Armenian drams",
  "prefix": "դր.",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "AM"
}, {
  "symbol": "AR$",
  "name": "Argentine Peso",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "ARS",
  "name_plural": "Argentine pesos",
  "prefix": "AR$",
  "suffix": "",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "AR"
}, {
  "symbol": "AU$",
  "name": "Australian Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "AUD",
  "name_plural": "Australian dollars",
  "prefix": "AU$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "AU"
}, {
  "symbol": "man.",
  "name": "Azerbaijani Manat",
  "symbol_native": "ман.",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "AZN",
  "name_plural": "Azerbaijani manats",
  "prefix": "₼",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "AZ"
}, {
  "symbol": "KM",
  "name": "Bosnia-Herzegovina Convertible Mark",
  "symbol_native": "KM",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BAM",
  "name_plural": "Bosnia-Herzegovina convertible marks",
  "prefix": "KM",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "BA"
}, {
  "symbol": "Tk",
  "name": "Bangladeshi Taka",
  "symbol_native": "৳",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BDT",
  "name_plural": "Bangladeshi takas",
  "prefix": "৳",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "BD"
}, {
  "symbol": "BGN",
  "name": "Bulgarian Lev",
  "symbol_native": "лв.",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BGN",
  "name_plural": "Bulgarian leva",
  "prefix": "",
  "suffix": " лв.",
  "decimal_point": ".",
  "thousands_separator": " ",
  "default_country": "BG"
}, {
  "symbol": "FBu",
  "name": "Burundian Franc",
  "symbol_native": "FBu",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "BIF",
  "name_plural": "Burundian francs",
  "prefix": "Fr",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "BI"
}, {
  "symbol": "BN$",
  "name": "Brunei Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BND",
  "name_plural": "Brunei dollars",
  "prefix": "B$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "BN"
}, {
  "symbol": "Bs",
  "name": "Bolivian Boliviano",
  "symbol_native": "Bs",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BOB",
  "name_plural": "Bolivian bolivianos",
  "prefix": "Bs. ",
  "suffix": "",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "BO"
}, {
  "symbol": "R$",
  "name": "Brazilian Real",
  "symbol_native": "R$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BRL",
  "name_plural": "Brazilian reals",
  "prefix": "R$ ",
  "suffix": "",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "BR"
}, {
  "symbol": "BWP",
  "name": "Botswanan Pula",
  "symbol_native": "P",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BWP",
  "name_plural": "Botswanan pulas",
  "prefix": "P",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "BW"
}, {
  "symbol": "BZ$",
  "name": "Belize Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "BZD",
  "name_plural": "Belize dollars",
  "prefix": "BZ£",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "BZ"
}, {
  "symbol": "CA$",
  "name": "Canadian Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "CAD",
  "name_plural": "Canadian dollars",
  "prefix": "C$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "CA"
}, {
  "symbol": "CDF",
  "name": "Congolese Franc",
  "symbol_native": "FrCD",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "CDF",
  "name_plural": "Congolese francs",
  "prefix": "Fr",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "CD"
}, {
  "symbol": "CHF",
  "name": "Swiss Franc",
  "symbol_native": "CHF",
  "decimal_digits": 2,
  "rounding": 0.05,
  "code": "CHF",
  "name_plural": "Swiss francs",
  "prefix": "CHF ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": "‘",
  "default_country": "CH"
}, {
  "symbol": "CL$",
  "name": "Chilean Peso",
  "symbol_native": "$",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "CLP",
  "name_plural": "Chilean pesos",
  "prefix": "CLP $",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ".",
  "default_country": "CL"
}, {
  "symbol": "CN¥",
  "name": "Chinese Yuan",
  "symbol_native": "CN¥",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "CNY",
  "name_plural": "Chinese yuan",
  "prefix": "¥",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "CN"
}, {
  "symbol": "CO$",
  "name": "Colombian Peso",
  "symbol_native": "$",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "COP",
  "name_plural": "Colombian pesos",
  "prefix": "COP $",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ".",
  "default_country": "CO"
}, {
  "symbol": "₡",
  "name": "Costa Rican Colón",
  "symbol_native": "₡",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "CRC",
  "name_plural": "Costa Rican colóns",
  "prefix": "₡",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "CR"
}, {
  "symbol": "CV$",
  "name": "Cape Verdean Escudo",
  "symbol_native": "CV$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "CVE",
  "name_plural": "Cape Verdean escudos",
  "prefix": "$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "CV"
}, {
  "symbol": "Kč",
  "name": "Czech Republic Koruna",
  "symbol_native": "Kč",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "CZK",
  "name_plural": "Czech Republic korunas",
  "prefix": "",
  "suffix": " Kč",
  "decimal_point": "",
  "thousands_separator": " ",
  "default_country": "CZ"
}, {
  "symbol": "Fdj",
  "name": "Djiboutian Franc",
  "symbol_native": "Fdj",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "DJF",
  "name_plural": "Djiboutian francs",
  "prefix": "",
  "suffix": "Fdj",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "DJ"
}, {
  "symbol": "Dkr",
  "name": "Danish Krone",
  "symbol_native": "kr",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "DKK",
  "name_plural": "Danish kroner",
  "prefix": "DKK ",
  "suffix": "",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "DK"
}, {
  "symbol": "RD$",
  "name": "Dominican Peso",
  "symbol_native": "RD$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "DOP",
  "name_plural": "Dominican pesos",
  "prefix": "RD$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "DO"
}, {
  "symbol": "DA",
  "name": "Algerian Dinar",
  "symbol_native": "د.ج.‏",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "DZD",
  "name_plural": "Algerian dinars",
  "prefix": "د.ج",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "DZ"
}, {
  "symbol": "EGP",
  "name": "Egyptian Pound",
  "symbol_native": "ج.م.‏",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "EGP",
  "name_plural": "Egyptian pounds",
  "prefix": "E£",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "EG"
}, {
  "symbol": "Br",
  "name": "Ethiopian Birr",
  "symbol_native": "Br",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "ETB",
  "name_plural": "Ethiopian birrs",
  "prefix": "Br",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "ET"
}, {
  "symbol": "€",
  "name": "Euro",
  "symbol_native": "€",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "EUR",
  "name_plural": "euros",
  "prefix": "",
  "suffix": " €",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": ""
}, {
  "symbol": "£",
  "name": "British Pound Sterling",
  "symbol_native": "£",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "GBP",
  "name_plural": "British pounds sterling",
  "prefix": "£",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "GB"
}, {
  "symbol": "GEL",
  "name": "Georgian Lari",
  "symbol_native": "GEL",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "GEL",
  "name_plural": "Georgian laris",
  "prefix": "",
  "suffix": " ლ",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "GE"
}, {
  "symbol": "FG",
  "name": "Guinean Franc",
  "symbol_native": "FG",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "GNF",
  "name_plural": "Guinean francs",
  "prefix": "Fr",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "GN"
}, {
  "symbol": "GTQ",
  "name": "Guatemalan Quetzal",
  "symbol_native": "Q",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "GTQ",
  "name_plural": "Guatemalan quetzals",
  "prefix": "Q",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "GT"
}, {
  "symbol": "HK$",
  "name": "Hong Kong Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "HKD",
  "name_plural": "Hong Kong dollars",
  "prefix": "HK$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "HK"
}, {
  "symbol": "HNL",
  "name": "Honduran Lempira",
  "symbol_native": "L",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "HNL",
  "name_plural": "Honduran lempiras",
  "prefix": "L",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "HN"
}, {
  "symbol": "kn",
  "name": "Croatian Kuna",
  "symbol_native": "kn",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "HRK",
  "name_plural": "Croatian kunas",
  "prefix": "",
  "suffix": "kn",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "HR"
}, {
  "symbol": "Ft",
  "name": "Hungarian Forint",
  "symbol_native": "Ft",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "HUF",
  "name_plural": "Hungarian forints",
  "prefix": "",
  "suffix": " Ft",
  "decimal_point": ".",
  "thousands_separator": " ",
  "default_country": "HU"
}, {
  "symbol": "₪",
  "name": "Israeli New Sheqel",
  "symbol_native": "₪",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "ILS",
  "name_plural": "Israeli new sheqels",
  "prefix": "₪",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": "",
  "default_country": "IL"
}, {
  "symbol": "Rs",
  "name": "Indian Rupee",
  "symbol_native": "টকা",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "INR",
  "name_plural": "Indian rupees",
  "prefix": "",
  "suffix": " ₹",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "IN"
}, {
  "symbol": "Ikr",
  "name": "Icelandic Króna",
  "symbol_native": "kr",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "ISK",
  "name_plural": "Icelandic krónur",
  "prefix": "",
  "suffix": " ISK",
  "decimal_point": "",
  "thousands_separator": ".",
  "default_country": "IS"
}, {
  "symbol": "J$",
  "name": "Jamaican Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "JMD",
  "name_plural": "Jamaican dollars",
  "prefix": "J$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "JM"
}, {
  "symbol": "¥",
  "name": "Japanese Yen",
  "symbol_native": "￥",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "JPY",
  "name_plural": "Japanese yen",
  "prefix": "¥",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "JP"
}, {
  "symbol": "Ksh",
  "name": "Kenyan Shilling",
  "symbol_native": "Ksh",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "KES",
  "name_plural": "Kenyan shillings",
  "prefix": "KSh ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "KE"
}, {
  "symbol": "KHR",
  "name": "Cambodian Riel",
  "symbol_native": "៛",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "KHR",
  "name_plural": "Cambodian riels",
  "prefix": "៛ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "KH"
}, {
  "symbol": "CF",
  "name": "Comorian Franc",
  "symbol_native": "FC",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "KMF",
  "name_plural": "Comorian francs",
  "prefix": "Fr",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "KM"
}, {
  "symbol": "₩",
  "name": "South Korean Won",
  "symbol_native": "₩",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "KRW",
  "name_plural": "South Korean won",
  "prefix": "₩ ",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "KR"
}, {
  "symbol": "₸",
  "name": "Kazakhstani Tenge",
  "symbol_native": "тңг.",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "KZT",
  "name_plural": "Kazakhstani tenges",
  "prefix": "₸",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "KZ"
}, {
  "symbol": "LB£",
  "name": "Lebanese Pound",
  "symbol_native": "ل.ل.‏",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "LBP",
  "name_plural": "Lebanese pounds",
  "prefix": "LL ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "LB"
}, {
  "symbol": "SLRs",
  "name": "Sri Lankan Rupee",
  "symbol_native": "SL Re",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "LKR",
  "name_plural": "Sri Lankan rupees",
  "prefix": "Rs ",
  "suffix": "",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "LK"
}, {
  "symbol": "MAD",
  "name": "Moroccan Dirham",
  "symbol_native": "د.م.‏",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MAD",
  "name_plural": "Moroccan dirhams",
  "prefix": "",
  "suffix": "DH",
  "decimal_point": ",",
  "thousands_separator": " ",
  "default_country": "MA"
}, {
  "symbol": "MDL",
  "name": "Moldovan Leu",
  "symbol_native": "MDL",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MDL",
  "name_plural": "Moldovan lei",
  "prefix": "MDL",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MD"
}, {
  "symbol": "MGA",
  "name": "Malagasy Ariary",
  "symbol_native": "MGA",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "MGA",
  "name_plural": "Malagasy Ariaries",
  "prefix": "Ar",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "MG"
}, {
  "symbol": "MKD",
  "name": "Macedonian Denar",
  "symbol_native": "MKD",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MKD",
  "name_plural": "Macedonian denari",
  "prefix": "ден",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MK"
}, {
  "symbol": "MMK",
  "name": "Myanma Kyat",
  "symbol_native": "K",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "MMK",
  "name_plural": "Myanma kyats",
  "prefix": "K",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MM"
}, {
  "symbol": "MOP$",
  "name": "Macanese Pataca",
  "symbol_native": "MOP$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MOP",
  "name_plural": "Macanese patacas",
  "prefix": "MOP$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MO"
}, {
  "symbol": "MURs",
  "name": "Mauritian Rupee",
  "symbol_native": "MURs",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "MUR",
  "name_plural": "Mauritian rupees",
  "prefix": "Ɍs",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MU"
}, {
  "symbol": "MX$",
  "name": "Mexican Peso",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MXN",
  "name_plural": "Mexican pesos",
  "prefix": "MXN$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MX"
}, {
  "symbol": "RM",
  "name": "Malaysian Ringgit",
  "symbol_native": "RM",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MYR",
  "name_plural": "Malaysian ringgits",
  "prefix": "RM ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MY"
}, {
  "symbol": "MTn",
  "name": "Mozambican Metical",
  "symbol_native": "MTn",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "MZN",
  "name_plural": "Mozambican meticals",
  "prefix": "MT",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "MZ"
}, {
  "symbol": "N$",
  "name": "Namibian Dollar",
  "symbol_native": "N$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "NAD",
  "name_plural": "Namibian dollars",
  "prefix": "N£",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "NA"
}, {
  "symbol": "₦",
  "name": "Nigerian Naira",
  "symbol_native": "₦",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "NGN",
  "name_plural": "Nigerian nairas",
  "prefix": "₦",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "NG"
}, {
  "symbol": "C$",
  "name": "Nicaraguan Córdoba",
  "symbol_native": "C$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "NIO",
  "name_plural": "Nicaraguan córdobas",
  "prefix": "C$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "NI"
}, {
  "symbol": "Nkr",
  "name": "Norwegian Krone",
  "symbol_native": "kr",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "NOK",
  "name_plural": "Norwegian kroner",
  "prefix": "",
  "suffix": " kr",
  "decimal_point": ",",
  "thousands_separator": " ",
  "default_country": "NO"
}, {
  "symbol": "NPRs",
  "name": "Nepalese Rupee",
  "symbol_native": "नेरू",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "NPR",
  "name_plural": "Nepalese rupees",
  "prefix": "Rs ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "NP"
}, {
  "symbol": "NZ$",
  "name": "New Zealand Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "NZD",
  "name_plural": "New Zealand dollars",
  "prefix": "NZ$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "NZ"
}, {
  "symbol": "B/.",
  "name": "Panamanian Balboa",
  "symbol_native": "B/.",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "PAB",
  "name_plural": "Panamanian balboas",
  "prefix": "B/.",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "PA"
}, {
  "symbol": "S/.",
  "name": "Peruvian Nuevo Sol",
  "symbol_native": "S/.",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "PEN",
  "name_plural": "Peruvian nuevos soles",
  "prefix": "S/.",
  "suffix": "",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "PE"
}, {
  "symbol": "₱",
  "name": "Philippine Peso",
  "symbol_native": "₱",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "PHP",
  "name_plural": "Philippine pesos",
  "prefix": "₱",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "PH"
}, {
  "symbol": "PKRs",
  "name": "Pakistani Rupee",
  "symbol_native": "₨",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "PKR",
  "name_plural": "Pakistani rupees",
  "prefix": "Rs ",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "PK"
}, {
  "symbol": "zł",
  "name": "Polish Zloty",
  "symbol_native": "zł",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "PLN",
  "name_plural": "Polish zlotys",
  "prefix": "zł",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "PL"
}, {
  "symbol": "₲",
  "name": "Paraguayan Guarani",
  "symbol_native": "₲",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "PYG",
  "name_plural": "Paraguayan guaranis",
  "prefix": "",
  "suffix": " ₲",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "PY"
}, {
  "symbol": "QR",
  "name": "Qatari Rial",
  "symbol_native": "ر.ق.‏",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "QAR",
  "name_plural": "Qatari rials",
  "prefix": "QR",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "QA"
}, {
  "symbol": "RON",
  "name": "Romanian Leu",
  "symbol_native": "RON",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "RON",
  "name_plural": "Romanian lei",
  "prefix": "lei ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "RO"
}, {
  "symbol": "din.",
  "name": "Serbian Dinar",
  "symbol_native": "дин.",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "RSD",
  "name_plural": "Serbian dinars",
  "prefix": "",
  "suffix": " din",
  "decimal_point": ",",
  "thousands_separator": ".",
  "default_country": "RS"
}, {
  "symbol": "RUB",
  "name": "Russian Ruble",
  "symbol_native": "₽.",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "RUB",
  "name_plural": "Russian rubles",
  "prefix": "",
  "suffix": "₽",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "RU"
}, {
  "symbol": "RWF",
  "name": "Rwandan Franc",
  "symbol_native": "FR",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "RWF",
  "name_plural": "Rwandan francs",
  "prefix": "RWF ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "RW"
}, {
  "symbol": "SR",
  "name": "Saudi Riyal",
  "symbol_native": "ر.س.‏",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "SAR",
  "name_plural": "Saudi riyals",
  "prefix": "",
  "suffix": " ﷼",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "SA"
}, {
  "symbol": "Skr",
  "name": "Swedish Krona",
  "symbol_native": "kr",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "SEK",
  "name_plural": "Swedish kronor",
  "prefix": "",
  "suffix": " kr",
  "decimal_point": ",",
  "thousands_separator": " ",
  "default_country": "SE"
}, {
  "symbol": "S$",
  "name": "Singapore Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "SGD",
  "name_plural": "Singapore dollars",
  "prefix": "SG$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "SG"
}, {
  "symbol": "Ssh",
  "name": "Somali Shilling",
  "symbol_native": "Ssh",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "SOS",
  "name_plural": "Somali shillings",
  "prefix": "SOS",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "SO"
}, {
  "symbol": "฿",
  "name": "Thai Baht",
  "symbol_native": "฿",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "THB",
  "name_plural": "Thai baht",
  "prefix": "฿",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "TH"
}, {
  "symbol": "T$",
  "name": "Tongan Paʻanga",
  "symbol_native": "T$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "TOP",
  "name_plural": "Tongan paʻanga",
  "prefix": "T$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "TO"
}, {
  "symbol": "TL",
  "name": "Turkish Lira",
  "symbol_native": "TL",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "TRY",
  "name_plural": "Turkish Lira",
  "prefix": "",
  "suffix": " TL",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "TR"
}, {
  "symbol": "TT$",
  "name": "Trinidad and Tobago Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "TTD",
  "name_plural": "Trinidad and Tobago dollars",
  "prefix": "TT$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "TT"
}, {
  "symbol": "NT$",
  "name": "New Taiwan Dollar",
  "symbol_native": "NT$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "TWD",
  "name_plural": "New Taiwan dollars",
  "prefix": " NT$",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "TW"
}, {
  "symbol": "TSh",
  "name": "Tanzanian Shilling",
  "symbol_native": "TSh",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "TZS",
  "name_plural": "Tanzanian shillings",
  "prefix": "TSh ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "TZ"
}, {
  "symbol": "₴",
  "name": "Ukrainian Hryvnia",
  "symbol_native": "₴",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "UAH",
  "name_plural": "Ukrainian hryvnias",
  "prefix": "",
  "suffix": " UAH",
  "decimal_point": ",",
  "thousands_separator": " ",
  "default_country": "UA"
}, {
  "symbol": "USh",
  "name": "Ugandan Shilling",
  "symbol_native": "USh",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "UGX",
  "name_plural": "Ugandan shillings",
  "prefix": "USh ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "UG"
}, {
  "symbol": "$",
  "name": "US Dollar",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "USD",
  "name_plural": "US dollars",
  "prefix": "US$ ",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "US"
}, {
  "symbol": "$U",
  "name": "Uruguayan Peso",
  "symbol_native": "$",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "UYU",
  "name_plural": "Uruguayan pesos",
  "prefix": "U$",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ".",
  "default_country": "UY"
}, {
  "symbol": "UZS",
  "name": "Uzbekistan Som",
  "symbol_native": "UZS",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "UZS",
  "name_plural": "Uzbekistan som",
  "prefix": "лв",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "UZ"
}, {
  "symbol": "₫",
  "name": "Vietnamese Dong",
  "symbol_native": "₫",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "VND",
  "name_plural": "Vietnamese dong",
  "prefix": "",
  "suffix": "₫",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "VN"
}, {
  "symbol": "FCFA",
  "name": "CFA Franc BEAC",
  "symbol_native": "FCFA",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "XAF",
  "name_plural": "CFA francs BEAC",
  "prefix": "Fr",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "CF"
}, {
  "symbol": "CFA",
  "name": "CFA Franc BCEAO",
  "symbol_native": "CFA",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "XOF",
  "name_plural": "CFA francs BCEAO",
  "prefix": "XOF",
  "suffix": "",
  "decimal_point": "",
  "thousands_separator": ",",
  "default_country": "CI"
}, {
  "symbol": "YR",
  "name": "Yemeni Rial",
  "symbol_native": "ر.ي.‏",
  "decimal_digits": 0,
  "rounding": 0,
  "code": "YER",
  "name_plural": "Yemeni rials",
  "prefix": "﷼",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "YE"
}, {
  "symbol": "R",
  "name": "South African Rand",
  "symbol_native": "R",
  "decimal_digits": 2,
  "rounding": 0,
  "code": "ZAR",
  "name_plural": "South African rand",
  "prefix": "R",
  "suffix": "",
  "decimal_point": ".",
  "thousands_separator": ",",
  "default_country": "ZA"
}]

const map = {}
currencies.forEach((one) => {
  map[one.code] = one
})

export const currenciesMap = map
