import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '@reach/router'
import './Pitch.styles.scss'

export default function Pitch(props) {
  return (
    <div className='pitchWrapper'>
      <div className='pitchHeader'>
        <h1>
          <FormattedMessage id='PITCH_TITLE' />
        </h1>
        <p>
          <FormattedMessage id='PITCH_SUBTITLE' />
        </p>
      </div>
      {/* <div className='pitchBody'> */}
      {/* </div> */}
      <div className='pitchActions'>
        <Link to='/join' className='cta'>
          <FormattedMessage id='JOIN_PRODMAKE' />
        </Link>
        {/* <a href='https://prodmake.com/for-makers?ref=app' target='_blank' rel='noreferrer' className='opaque'> */}
        {/*   <FormattedMessage id='LEARN_MORE' /> */}
        {/* </a> */}
      </div>
      <div className='pitchFooter'>
        <p>
          <FormattedMessage id='ALREADY_HAVE_AN_ACCOUNT' />
        </p>
        <Link to='/login'>
          <FormattedMessage id='LOGIN' />
        </Link>
      </div>
    </div>
  )
}
