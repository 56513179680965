import React from 'react'
import { Link, navigate } from '@reach/router'
import { FormattedMessage, useIntl } from 'react-intl'
import Logout from '../../components/Logout'
import { API_URL } from '../../lib/config'
import { useGlobalStore } from '../../lib/global'
// import GithubSVG from '../../icons/remix/logos/github-fill.svg'
// import AppleSVG from '../../icons/remix/logos/apple-fill.svg'
import styles from './index.module.scss'

export default function Join() {
  const intl = useIntl()
  const [state, setState] = React.useState({ isSubmitting: false })
  const { auth, login } = useGlobalStore(s => s)

  const onJoin = React.useCallback(async (email, password, invitation_code) => {
    try {
      const res = await fetch(`${API_URL}/auth/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          src: 'app',
          lang: intl.locale,
          email,
          password,
          invitation_code
        })
      })

      const data = await res.json()

      login({
        user: data.user,
        token: data.token
      })

      navigate('/')
    } catch (err) {
      console.log(err)
    }
  }, [])

  const onSubmit = React.useCallback(async (ev) => {
    ev.preventDefault()
    setState(prev => ({ ...prev, isSubmitting: true }))
    const email = ev.target.elements.email.value
    const password = ev.target.elements.password.value
    const invitation_code = '' // ev.target.elements.invitation_code.value
    try {
      if (email && password) {
        await onJoin(email, password, invitation_code)
      }
    } catch (err) {
      console.log(err)
    }
    setState(prev => ({ ...prev, isSubmitting: false }))
    return false
  }, [])

  return (
    <div className={styles.joinFormWrapper}>
      <div className={styles.joinFormHeader}>
        <h1 className={styles.title}>
          <FormattedMessage id='JOIN_PRODMAKE' />
        </h1>
      </div>
      <div className={styles.joinFormBody}>
        {!auth?.isAuthenticated && (
          <div>
            <form onSubmit={onSubmit} className={styles.form}>
              <div className={styles.formField}>
                <label htmlFor='email'>
                  <FormattedMessage id='EMAIL' />
                </label>
                <input
                  id='email'
                  type='email'
                  placeholder=''
                  name='email'
                  autoFocus
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor='password'>
                  <FormattedMessage id='PASSWORD' />
                </label>
                <input
                  id='password'
                  type='password'
                  placeholder=''
                  name='password'
                />
              </div>
              {/* <div className={styles.formField}> */}
              {/*   <label htmlFor='invitation_code'> */}
              {/*     <FormattedMessage id='INVITATION_CODE' /> */}
              {/*   </label> */}
              {/*   <input */}
              {/*     id='invitation_code' */}
              {/*     type='text' */}
              {/*     placeholder='' */}
              {/*     name='invitation_code' */}
              {/*   /> */}
              {/* </div> */}
              <button type='submit' disabled={state.isSubmitting}>
                {state.isSubmitting && <FormattedMessage id='LOADING' />}
                {!state.isSubmitting && <FormattedMessage id='CONTINUE' />}
              </button>
            </form>
            <div className={styles.joinActions}>
              <p>
                <FormattedMessage id='ALREADY_HAVE_AN_ACCOUNT' />
              </p>
              <Link to='/login'>
                <FormattedMessage id='LOGIN' />
              </Link>
            </div>
          </div>
        )}
        {auth?.isAuthenticated && (
          <div>
            <FormattedMessage id='LOGGED_IN_AS' values={{ email: auth.user.email }} />
            <Logout />
          </div>
        )}
      </div>
    </div>
  )
}
